import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import { AsyncGet } from "../../../../app/services/https";

import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";

const NewItem = ({ isEdit,item, closePopup, activity }) => {
 
  const dispatch = useDispatch();
 
  const [ProjectGivenDate, setProjectGivenDate] = useState("");
  const [ProjectCompletionDate, setProjectCompletionDate] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ProblemStatement, setProblemStatement] = useState("");
  const [MethodologyOpt, setMethodologyOpt] = useState("");
  const [FacultyID, setFacultyID] = useState([]);
  const [StandardsIfAny, setStandardsIfAny] = useState("");
  const [ConsultancyFees, setConsultancyFees] = useState("");
  const [SupportingStaff, setSupportingStaff] = useState("");
  const [Event, setEvent] = useState("");
  const [Venue, setVenue] = useState("");
  const [Organisation, setOrganisation] = useState("");
  const [InternalOrExternal, setInternalOrExternal] = useState("Internal");
  const [OffLineOrOnLine, setOffLineOrOnLine] = useState("Offline");
  const [createdBy, setCreatedBy] = useState("");
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const[datevalidation, setdatevalidation] = useState(true);
  const [validate, setvalidate] = useState(false);
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
 
  const runFillActivityData = useRef();
  const clearState = () => {
    setProjectGivenDate("");
    setProjectCompletionDate("");
    setProjectName("");
    setOrganisation("");
    //setStudentID([]);
    setFacultyID([]);
    setProblemStatement("");
    setConsultancyFees("");
    setMethodologyOpt("");
    setSupportingStaff("");
    setStandardsIfAny("");

    setInternalOrExternal("Internal");
    setOffLineOrOnLine("Offline");
   // setselectedFile("");
    setCreatedBy("");
    //setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    //setFile("");
    //setfilesList([]);
    //setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };

  // const students1 = (val) => {
  //   setstudentORFaculty(val);
  // };
  const setIntExtvalue = (val) => {
    setInternalOrExternal(val);
  };
  const setOnOFFvalue = (val) => {
    setOffLineOrOnLine(val);
  };
  const projectGivenDatechange = (val) => {
    setdatevalidation(false);
    setProjectGivenDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setProjectCompletionDate('');
  };
  // const projectCompletionDatechange = (val) => {
  //   setProjectCompletionDate(val.target.value);
  // };
  const projectCompletionDatechange = (val) => {
    if (val.target.value!='')
    setFromdatevalidation(true);
    else
    setFromdatevalidation(false);
    setProjectCompletionDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };

  const onMethodologySelect = (e) => {
    setMethodologyOpt(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  // const onStudentIDChange = (data) => {
  //   setStudentID(data);
  // };

  const onFacultyIDChange = (data) => {
    setFacultyID(data);
  };
  // const onStudentIDInputChange = (data) => {
  //   if (!data) {
  //     return;
  //   }
  //   AsyncGet(API.getStudentByName + "?studentname=" + data)
  //     .then((res) => {
  //       setstudentList(res.data);
  //     })
  //     .catch(() => setstudentList([]));
  // };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName +"?facultyname=" + data)
    .then((res) => {
     
      setfacultyList(res.data);

    })
      .catch(() => setfacultyList([]));
  };

  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 18,
      
      ProjectName: ProjectName,
      ProblemStatement: ProblemStatement,
      MethodologyOpt: MethodologyOpt,
      StandardsIfAny: StandardsIfAny,
      ConsultancyFees: ConsultancyFees,
      SupportingStaff: SupportingStaff,
      //Department:JSON.parse(localStorage.getItem("user")).department,
      Organisation: Organisation,
      ProjectGivenDate: ProjectGivenDate,//new Date(ProjectGivenDate).toLocaleDateString(),
      ProjectCompletionDate: ProjectCompletionDate,//new Date(ProjectCompletionDate).toLocaleDateString(),
      
      InternalOrExternal: InternalOrExternal,
      OnlineOrOffline: OffLineOrOnLine,
     
      FacultyID,
    };
    var activityData = {
      id: 0,
      activityID: 18,
      activityName: "Consultancy",
      data: JSON.stringify(data),
      //filePath: selectedFile,
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      dispatch(fetchActivities(18,null));
      closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
   
    
  };

  const updateActivity = async () => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 18,
      //Topic: Topic,
      //Abstract: Abstarct,
      //Event: Event,
      Organisation: Organisation,
      //Venue: Venue,
      //Department:JSON.parse(localStorage.getItem("user")).department,
      ProjectName: ProjectName,
      ProblemStatement: ProblemStatement,
      MethodologyOpt: MethodologyOpt,
      StandardsIfAny: StandardsIfAny,
      ConsultancyFees: ConsultancyFees,
      SupportingStaff: SupportingStaff,
      ProjectGivenDate: ProjectGivenDate,//new Date(ProjectGivenDate).toLocaleDateString(),
      ProjectCompletionDate: ProjectCompletionDate,//new Date(ProjectCompletionDate).toLocaleDateString(),
      InternalOrExternal: InternalOrExternal,
      OnlineOrOffline: OffLineOrOnLine,
      FacultyID,
    };
    const activityData = {
      ...activity,
      activityID:18,
      activityName: "Consultancy",
      data: JSON.stringify(data),
    };
    try {
      dispatch(UpdateActivity(activityData));
    
      closePopup();
     
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (            
      ProjectGivenDate &&
      ProjectCompletionDate &&
      ProjectName &&
      (FacultyID?.length > 0) &&
      MethodologyOpt &&
      ConsultancyFees &&
      SupportingStaff &&    
      Organisation
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      
      case "facultyname":
        setFacultyID(e.target.value);
        break;
      case "projectname":
        setProjectName(e.target.value);
        break;
      case "organisation":
        setOrganisation(e.target.value);
        break;
      case "methodologyopt":
        setMethodologyOpt(e.target.value);
        break;
      case "problemstatement":
        setProblemStatement(e.target.value);
        break;
      case "standardifany":
        
        setStandardsIfAny(e.target.value);
        
      
        break;
      case "consultancyfees":
        if(/^[0-9.]*$/.test(e.target.value) == false){

          //error message

        }     else

        {
        setConsultancyFees(e.target.value);
        }
        break;
        case "supportingstaff":
          
          setSupportingStaff(e.target.value);
          
          break;
    }
  };

  const fillActivityData = (obj) => {
  
    runFillActivityData.current = false;
    setProjectGivenDate(obj.data.ProjectGivenDate);
    setProjectCompletionDate(obj.data.ProjectCompletionDate);
    setFacultyID(obj.data.FacultyID || []);
    setProjectName(obj.data.ProjectName);
    setOrganisation(obj.data.Organisation);
    setProblemStatement(obj.data.ProblemStatement);
    setConsultancyFees(obj.data.ConsultancyFees);
    setMethodologyOpt(obj.data.MethodologyOpt);
    setSupportingStaff(obj.data.SupportingStaff);
    setStandardsIfAny(obj.data.StandardsIfAny);
    setInternalOrExternal(obj.data.InternalOrExternal||"Internal");
    setOffLineOrOnLine(obj.data.OnlineOrOffline||"Offline");
    setCreatedBy(obj.createdDate);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
              
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio3"
                    value="4"
                    checked={InternalOrExternal === "Internal"}
                    onChange={() => setIntExtvalue("Internal")}
                  />
                  <label className="form-check-label checkposition">
                    Internal
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio4"
                    value="5"
                    checked={InternalOrExternal === "External"}
                    onChange={() => setIntExtvalue("External")}
                  />
                  <label className="form-check-label checkposition">
                    External
                  </label>
                </div>
              </div>
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio5"
                    value="option3"
                    checked={OffLineOrOnLine === "Offline"}
                    onChange={() => setOnOFFvalue("Offline")}
                  />
                  <label className="form-check-label checkposition">
                    Offline
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio6"
                    value="option3"
                    checked={OffLineOrOnLine === "Online"}
                    onChange={() => setOnOFFvalue("Online")}
                  />
                  <label className="form-check-label checkposition">
                    Online
                  </label>
                </div>
              </div>
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Project Given Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(ProjectGivenDate).format("YYYY-MM-DD")}
                  onChange={projectGivenDatechange}
                  max={Moment().format('YYYY-MM-DD')}
                  disabled={Fromdatevalidation1}
                />
                {!ProjectGivenDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Project Completion Date</label>
                <input
                  className="form-control"
                  type="date"
                  disabled={datevalidation}
                  min={dayjs(ProjectGivenDate).format("YYYY-MM-DD")}
                  value={dayjs(ProjectCompletionDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={projectCompletionDatechange}
                  
                />
                {!ProjectCompletionDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select To Date"}</h6>
                ) : null}

              </div>
              
              <div

                className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
              >
                <label><span style={{ color: "red" }}>*</span>Faculty ID</label>
                <SearchBox
                  options={facultyList}
                  onChangeList={(data) => onFacultyIDChange(data)}
                  onInputChange={(e) => onFacultyIDInputChange(e)}
                  selectedOptions={FacultyID || []}
                  type="faculty"
                />
                {!FacultyID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}

              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Project Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="projectname"
                  value={ProjectName}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!ProjectName && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Organisation</label>
                <input
                  className="form-control"
                  type="text"
                  id="organisation"
                  value={Organisation}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                {!Organisation && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Problem Statement</label>
                <input
                  className="form-control"
                  type="text"
                  id="problemstatement"
                  value={ProblemStatement}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
               
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Methodology Opt</label>
                <select
                  className="form-select form-control"
                  onChange={onMethodologySelect}
                  aria-label="Default select example"
                  value={MethodologyOpt}
                >
                  <option value="">Select Methodology Opt</option>
                  <option value="Design">Design</option>
                  <option value="Consultancy">Consultancy</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Services">Services</option>
                  <option value="Others">Others</option>
                </select>
              {!MethodologyOpt && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
               ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Standards If Any</label>
                <input
                  className="form-control"
                  type="text"
                  id="standardifany"
                  value={StandardsIfAny}
                  onChange={onTextValueChange}
                  maxLength={100}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Consultancy Fees</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"     
                  id="consultancyfees"
                  value={ConsultancyFees}
                  onChange={onTextValueChange}
                  maxLength={11}
                />
                {!ConsultancyFees && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Supporting Staff Details</label>
                <input
                  className="form-control"
                  type="text"
                  id="supportingstaff"
                  value={SupportingStaff}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!SupportingStaff && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>              
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
