import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddBatchDetails from "./addNewBatchDetail/index";
import {
  fetchBatches,
  batchSelector,
  //deleteBatch,
} from "../../app/redux/slices/batchDetailsSlice";

import Popup from "reactjs-popup";
import AdvanceTable from "./advanceTable";

const BatchDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, batchList } = useSelector(batchSelector);

  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [batch, setBatch] = useState();
  const [listOfBatches, setlistOfBatches] = useState(batchList || []);
  const [IsEdit, setIsEdit] = useState(false);
  console.log("List from State", batchList);

  const togglePopup = () => {
    onAssessmentSelection("Batch Details");
    setshowPopup(!showPopup);setIsEdit(false);
    if (!showPopup){
      setBatch();
    }
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", batchList);

  const deleteBatchFun = (item) => {
    //dispatch(deleteBatch(item.id));
  };

  const editBatch = (item) => {
    setBatch({ ...item, data: item });
    setshowPopup(!showPopup);
    setIsEdit(true);
  };

  useEffect(() => {
    getBatchData();
  }, []);
  
  const getBatchData = () => {
    dispatch(fetchBatches());
  };

  useEffect(() => {
    if (!batchList) {
      return;
    }
    setlistOfBatches(batchList);
  }, [batchList]);

  console.log("Before Render batchList", batchList);
  console.log("Before Render listOfBatch", listOfBatches);

  // render the items
  const renderItems = () => {
    console.log("inside render method",listOfBatches);
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Batch Details</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
            </div>
            <div className="fixed-table-container">
              <div className="fixed-table-body">
                {console.log("inside return method", listOfBatches)}
              <AdvanceTable
                  deleteBatchProp={(item) => deleteBatchFun(item)}
                  editBatchProp={(item) => editBatch(item)}
                  data={listOfBatches}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Title:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Event:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddBatchDetails
          isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            batch={batch}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default BatchDetails;
