import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const stockInventorySlice = createSlice({
  name: "stockInventoryList",
  initialState: {
    loading: false,
    error: false,
    stockInventoryList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    stockInventoryDetails: [],
    submitStockInventorySuccess: "",
    saveStockInventoryDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getStockInventory: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.stockInventoryList = payload;
    },
    saveStockInventory: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveStockInventoryDataSuccess = payload;
    },
    submitStockInventory: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getStockInventory,
  submitStockInventory,
  setError,
  saveStockInventory,
} = stockInventorySlice.actions;
export const stockInventorySelector = (state) => state.stockInventorys || [];
export default stockInventorySlice.reducer;

export const fetchStockInventorys = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getStockInventorys)
    .then((response) => {
      console.log("response,", response);
      dispatch(getStockInventory(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addStockInventoryDetails = (data) => (dispatch) => {
  debugger
  dispatch(setLoading());
  AsyncPost(API.saveStockInventorys, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveStockInventory(response));
      dispatch(fetchStockInventorys());
    })
    .catch((er) => {
      console.log("failure of add Inventory");
      dispatch(saveStockInventory(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateStockInventoryDetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updateStockInventorys, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update Inventory");
      dispatch(fetchStockInventorys());
    })
    .catch((er) => {
      console.log("failure of Update Inventory");
      dispatch(saveStockInventory(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteStockInventory = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deleteStockInventorys + "?id=" + data, "")
    .then((response) => {
      if (response!='Success')

      {      

        alert('Sorry! Unable to delete Inventory.Inventory is mapped with Inventory Issued List')

      }
      

      // dispatch(setLoading());
      console.log(response, "success of delete Inventory");
      dispatch(fetchStockInventorys());
    })
    .catch((er) => {
      console.log("failure of delete Inventory");
      dispatch(saveStockInventory(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
