import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import SearchBox from "./searchBox";
import axios from "axios";
import {
  addInventoryIssueDetails,
  UpdateInventoryIssueDetail
} from "../../../app/redux/slices/inventoryIssueSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
//import { setAriaLevel } from "ag-grid-community/dist/lib/utils/aria";
var available = 0;
var avail = 0;

const AddInventoryIssueDetails = ({ isEdit, item, closePopup, inventoryissue }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventoryissue) {
      return;
    }
    runFillInventoryIssueData.current = true;
    if (runFillInventoryIssueData.current) {
      fillInventoryIssueData(inventoryissue);
    }
  }, []);

  const [Id, setId] = useState("");
  const [IssueDate, setIssueDate] = useState("");
  const [IssuedBy, setIssuedBy] = useState("");
  const [CloseStock, setCloseStock] = useState("");
  const [Specification, setSpecification] = useState("");
  const [InventoryDescription, setInventoryDescription] = useState("");
  const [IssuedQuantity, setIssuedQuantity] = useState("");
  const [InventoryId, setInventoryId] = useState("");
  const [DepartmentId, setDepartmentId] = useState("");  
  const [DeparmentList, setDeparmentList] = useState([]);
  const [DepartmentIdAided, setDepartmentIdAided] = useState("");
  const [DepartmentIdss, setDepartmentIdss] = useState(""); 
  const [Year, setYear] = useState("");
  const [AvailableStock, setAvailableStock] = useState("");
  const [NoOfItems, setNoOfItems] = useState("");
  const [StudentOrFaculty, setStudentOrFaculty] = useState("");
  const [StudentId, setStudentId] = useState([]);
  const [FacultyId, setFacultyId] = useState([]);
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [Others, setOthers] = useState("");
  const [Name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [IsActive, setisActive] = useState(false);
  const [InventoryList, setInventoryList] = useState([]);
  const [Store, setStore] = useState("");
  const [CollegeType, setCollegeType] = useState("");
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillInventoryIssueData = useRef();

  const clearState = () => {
    setIssueDate("");
    setInventoryId("");
    setDepartmentId("");
    setYear("");
    setNoOfItems("");
    setStudentOrFaculty("");
    setStudentId([]);
    setFacultyId([]);
    setIssuedBy("");
    setstudentList([]);
    setfacultyList([]);
    //setCloseStock("");
    setIssuedQuantity("");
    setStore("");
    setCollegeType("");
    setOthers("");setDepartmentIdAided("");

    setDepartmentIdss("");
    setSpecification("");
  };
  useEffect(() => {
    debugger
    AsyncGet(API.getStockInventorys)
      .then((res) => {
        console.log(res.data, "getStockInventorys");

        setInventoryList(res.data);

      })

      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const issuedby = (val) => {


    setIssuedBy(val.target.value);

  };
  const ispecification = (val) => {

    debugger;

    setSpecification(val.target.value);
    available = InventoryList.filter(x => x.id == val.target.value)[0].availableQuantity;
    avail = available;
    setAvailableStock(available);
  };
  const idate = (val) => {

    setIssueDate(val.target.value);
  };
  const issuedqty = (e) => {
    if (/^[0-9.]*$/.test(e.target.value) == false) {
    }
    // else {
    //   setIssuedQuantity(val.target.value);
    // }
    else if (Number(e.target.value) <= Number(avail)) {

      available = Number(available) + Number(IssuedQuantity);
      //setAvailableStock(Number(available)+Number(NoOfItems));
      setIssuedQuantity(e.target.value);
      //available=Number(available)-Number(e.target.value);
      setAvailableStock(Number(available) - Number(e.target.value));
      available = Number(available) - Number(e.target.value);
    }
  }
  /*const closestock = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) == false){
    }     else
 
    {
    setCloseStock(val.target.value);
    }
  }*/
  const iname = (val) => {
    debugger;

    setInventoryId(val.target.value);


  };
  const iStore = (val) => {
    setStore(val.target.value);
  }
  const iothers = (val) => {
    setOthers(val.target.value);
    setStudentId([]);
    setFacultyId([]);
  }
  const inventoryDescription = (val) => {

    debugger;
    setInventoryDescription(val.target.value);

  };

  const iyear = (val) => {

    setYear(val.target.value);
  };
  const numberofitems = (e) => {
    debugger;
    console.log(avail);
    if (Number(e.target.value) <= Number(avail)) {

      available = Number(available) + Number(NoOfItems);
      //setAvailableStock(Number(available)+Number(NoOfItems));
      setNoOfItems(e.target.value);
      //available=Number(available)-Number(e.target.value);
      setAvailableStock(Number(available) - Number(e.target.value));
      available = Number(available) - Number(e.target.value);
    }
    /*else{
      setNoOfItems(0);
    }*/


  };
  const stdorftly = (val) => {

    setStudentOrFaculty(val.target.value);
    // setStudentId("");
    // setFacultyId("");
  };

  const dept = (val) => {
    if(CollegeType=="Aided")
    {
      setDepartmentIdAided(val.target.value);
      setDepartmentIdss("");
      setDepartmentId(val.target.value);
    }
    else if(CollegeType=="SS"){
      setDepartmentIdAided("");
      setDepartmentIdss(val.target.value);
      setDepartmentId(val.target.value);
    }
  };
  const collegetype = (val) => {
    setCollegeType(val);
  };
  const onStudentIDChange = (data) => {
    setStudentId(data);
    setFacultyId([]);
    setOthers("");
  };

  const onFacultyIDChange = (data) => {
    setFacultyId(data);
    setStudentId([]);
    setOthers("");
  };
  const onStudentIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getStudentByName + "?DepartmentId=" + DepartmentId + "&studentname=" + data)
      .then((res) => {
        setstudentList(res.data);
        setfacultyList([]);
      })
      .catch(() => setstudentList([]));
  };
  const onFacultyIDInputChange = (data) => {
    debugger
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?facultyname=" + data)
      .then((res) => {
        setfacultyList(res.data);
        setstudentList([]);
      })
      .catch(() => setfacultyList([]));
  };

  const saveInventoryIssueDetails = () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    /*  var data = [];
          for (var i = 0; i < StudentId.length; i++) {
              //var studDetails = selectedOptions[i];
              data.push({
                IssuedBy :IssuedBy,
        IssueDate:IssueDate,
        InventoryId: InventoryId,
        DepartmentId: DepartmentId,
        //Year: Year,
        StudentOrFaculty: StudentOrFaculty,
        StudentId:StudentId[i].studentID,
        FacultyId:FacultyId[i].facultyID,      
        CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
        CreatedDate: new Date(),
        ModifiedBy: "",
        ModifiedDate: new Date()
      })
    }
    var inventoryIssueData = {
        data: data
    };*/
    var inventoryIssueData = {
      Id: 0,
      Store: Store,
      IssuedBy: IssuedBy,
      IssueDate: IssueDate,
      IssuedQuantity: IssuedQuantity,
      CollegeType: CollegeType,
      //CloseStock:CloseStock,
      InventoryId: Number(Specification),
      DepartmentId: Number(DepartmentId),
      Year: Number(Year),
      AvailableStock: Number(AvailableStock),
      NoOfItems: Number(NoOfItems),
      StudentOrFaculty: StudentOrFaculty,
      StudentId: JSON.stringify(StudentId),
      FacultyId: JSON.stringify(FacultyId),
      Others: Others,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };


    // console.log("activitydata", activityData);
    dispatch(addInventoryIssueDetails(inventoryIssueData));
    closePopup();
    clearState();
  };

  const updateInventoryIssueDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    /* var data = [];
     for (var i = 0; i < StudentId.length; i++) {
       //var studDetails = selectedOptions[i];
       data.push({
         Id:
         IssuedBy: IssuedBy,
         IssueDate: IssueDate,
         InventoryId: InventoryId,
         DepartmentId: DepartmentId,
         //Year: Year,
         StudentOrFaculty: StudentOrFaculty,
         StudentId: StudentId[i].studentID,
         FacultyId: FacultyId[i].facultyID,
         CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
         CreatedDate: new Date(),
         ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
         ModifiedDate: new Date()
       })
     }
     var inventoryIssueData = {
       data: data
     };*/
    var data = {
      Id: Id,
      Store: Store,
      IssuedBy: IssuedBy,
      IssueDate: IssueDate,
      IssuedQuantity: IssuedQuantity,
      CollegeType: CollegeType,
      //CloseStock:CloseStock,
      InventoryId: Number(Specification),
      DepartmentId: Number(DepartmentId),
      Year: Number(Year),
      AvailableStock: Number(AvailableStock),
      NoOfItems: Number(NoOfItems),
      StudentOrFaculty: StudentOrFaculty,
      StudentId: JSON.stringify(StudentId),
      FacultyId: JSON.stringify(FacultyId),
      Others: Others,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const inventoryIssueData = {
      ...inventoryissue,
      data: data
    };

    console.log("UpdateInventoryIssue", inventoryIssueData.data);
    dispatch(UpdateInventoryIssueDetail(inventoryIssueData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !InventoryId ||
      !IssueDate ||
      !DepartmentId ||
      !Store ||
      !IssuedQuantity ||
      !CollegeType ||
      !(StudentId?.length > 0 || FacultyId?.length > 0 || Others) ||
      !StudentOrFaculty

    ) {
      setvalidate(false);
      seterrorMsg("Provide All Required  Field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillInventoryIssueData = (obj) => {
    debugger;
    runFillInventoryIssueData.current = false;
    if(obj.data.collegeType=="Aided")
    {
      setDepartmentId(obj.data.departmentId);
      setDepartmentIdAided(obj.data.departmentId);
      setDepartmentIdss("");
    }
    else if(obj.data.collegeType=="SS")
    {
      setDepartmentId(obj.data.departmentId);
      setDepartmentIdAided("");
      setDepartmentIdss(obj.data.departmentId);
    }
    setId(obj.data.id);
    setCollegeType(obj.data.collegeType);
    setInventoryId(obj.data.inventoryName);
    setSpecification(obj.data.inventoryId);
    setInventoryDescription(obj.data.inventoryId);
    setIssuedQuantity(obj.data.issuedQuantity);
    //setCloseStock(obj.data.closeStock);
    setIssueDate(obj.data.issueDate);
    setIssuedBy(obj.data.issuedBy);
    setStudentOrFaculty(obj.data.studentOrFaculty);
    setStudentId(JSON.parse(obj.data.studentId || []));
    setFacultyId(JSON.parse(obj.data.facultyId || []));
   
    setAvailableStock(obj.data.availableQuantity);
    setOthers(obj.data.others);
    available = (obj.data.availableQuantity);
    avail = Number(available) + Number(obj.data.issuedQuantity);
    //avail=Number(available)+Number(obj.data.noOfItems);
    setYear(obj.data.year);
    setNoOfItems(obj.data.noOfItems);
    setStore(obj.data.store);
    runFillInventoryIssueData.current = true;
  };

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Inventory Issued" : "Add New Inventory Issued"}</h2>            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">

            </div>
            <div className="row">
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="students"
                    value="2"
                    checked={CollegeType === "Aided"}
                    onChange={() => {
                      //collegetype("Aided");
                      setCollegeType("Aided");
                      setDepartmentId(DepartmentIdAided);
                    }}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Aided
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="faculty"
                    value="3"
                    checked={CollegeType === "SS"}
                    /*onChange={() => {
                      //collegetype("SS");
                  
                   setDepartmentId(DepartmentIdss)
                  }}*/
                  onChange={() => {
                    //collegetype("Aided");
                    setCollegeType("SS");
                    setDepartmentId(DepartmentIdss);
                  }}
                  />
                  <label className="form-check-label checkposition">
                    Self Supporting
                  </label>
                </div>
              </div>
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Store
                </label>
                <select
                  className="form-select form-control"
                  onChange={iStore}
                  aria-label="Default select example"
                  value={Store}
                >
                  <option value="">Select Store</option>
                  <option value="Student Store">Student Store</option>
                  <option value="Office Store">Office Store</option>

                </select>

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Name</label>

                <select
                  className="form-select form-control"
                  onChange={iname}
                  aria-label="Default select example"
                  value={InventoryId}
                >
                  <option value="">--Select Inventry--</option>

                  {InventoryList.filter(x => x.store == Store)?.map((opt) => (
                    <option key={opt.inventoryName} value={opt.inventoryName}>
                      {opt.inventoryName}

                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Specification</label>


                <select
                  className="form-select form-control"
                  onChange={ispecification}
                  aria-label="Default select example"
                  value={Specification}
                >
                  <option value="">--Select Specification--</option>

                  {InventoryList.filter(x => x.inventoryName == InventoryId)?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.specification}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Inventory Description</label>
                
                
                <select
                  className="form-select form-control"
                  onChange={inventoryDescription}
                  aria-label="Default select example"
                  value={InventoryDescription}
                >
                  <option value="">--Select Inventry--</option>
                     
                  {InventoryList.filter(x=>x.inventoryName==InventoryId)?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.inventoryDescription}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Available Stock</label>

                <input
                  className="form-control"
                  type="text"
                  value={AvailableStock}
                  disabled
                //onChange={issuedby}

                //maxLength={150}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Issue Date</label>


                <input
                  className="form-control"
                  type="date"
                  value={dayjs(IssueDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={idate}
                //disabled={validation}
                />


                {!IssueDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Requird"}</h6>
                ) : null}

              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Issued Quantity</label>

                <input
                  className="form-control"
                  type="text"
                  value={IssuedQuantity}
                  onChange={issuedqty}
                  maxLength={5}
                />
            {!IssuedQuantity && validate ? (
                  <h6 style={{ color: "red" }}>{"Requird"}</h6>
                ) : null}
              </div>


              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Department
                </label>
                <select
                  className="form-select form-control"
                  onChange={dept}
                  aria-label="Default select example"
                  value={DepartmentId}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div>
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Issued Quantity</label>
                
                <input
                  className="form-control"
                  type="number"
                  value={NoOfItems}
                  onChange={numberofitems}
                  maxLength={5}
                />
                
              </div>  */}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Student Or Faculty
                </label>
                <select
                  className="form-select form-control"
                  onChange={stdorftly}
                  aria-label="Default select example"
                  value={StudentOrFaculty}
                >
                  <option value="">--Select Role--</option>
                  <option value="Student">Student</option>
                  <option value="Faculty">Faculty</option>
                  <option value="Others">Others</option>
                </select>

              </div>
              {StudentOrFaculty === "Student" && (
                <div
                  id="students2"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label>
                    <span style={{ color: "red" }}>*</span>Student ID
                  </label>
                  <SearchBox
                    options={studentList}
                    onChangeList={(data) => onStudentIDChange(data)}
                    onInputChange={(e) => {
                      onStudentIDInputChange(e);
                    }}
                    selectedOptions={StudentId || []}
                    //reset={studentORFaculty}
                    type="student"
                  />
                  {!StudentId?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}

              {StudentOrFaculty === "Faculty" && (
                <div
                  id="students3"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label>
                    <span style={{ color: "red" }}>*</span>Faculty ID
                  </label>

                  <SearchBox
                    options={facultyList}
                    onChangeList={(data) => {
                      onFacultyIDChange(data);
                    }}
                    onInputChange={(e) => onFacultyIDInputChange(e)}
                    selectedOptions={FacultyId || []}
                    // reset={studentORFaculty}
                    type="faculty"
                  />
                  {!FacultyId?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}
              {StudentOrFaculty === "Others" && (
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
                  <label><span style={{ color: "red" }}>*</span>Others</label>

                  <input
                    className="form-control"
                    type="text"
                    value={Others}
                    onChange={iothers}
                    maxLength={150}
                  />

                </div>
              )}
            </div>

            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!inventoryissue ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveInventoryIssueDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateInventoryIssueDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInventoryIssueDetails;