import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const deptStudSlice = createSlice({
  name: "deptStudList",
  initialState: {
    loading: false,
    error: false,
    deptStudList: [],
    value: 0,
    curIndex: 0,
    deptStudDetails: [],
    submitDeptStudSuccess: "",
    saveDeptStudDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getDeptStud: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.deptStudList = payload;
    },
    saveDeptStud: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveDeptStudDataSuccess = payload;
    },
    submitDeptStud: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getDeptStud,
  submitDeptStud,
  setError,
  saveDeptStud,
} = deptStudSlice.actions;
export const deptStudSelector = (state) => state.deptStuds || [];
export default deptStudSlice.reducer;

export const fetchDeptStuds = () => (dispatch) => {
  debugger;
  dispatch(setLoading());
  AsyncGet(API.getStudentCount)
    .then((response) => {
      console.log("response,", response);
      dispatch(getDeptStud(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addDeptStudDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveDeptStudMappings, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveDeptStud(response));
      dispatch(fetchDeptStuds());
    })
    .catch((er) => {
      console.log("failure of add deptStud");
      dispatch(saveDeptStud(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateDeptStudDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.updateDeptStudData, data)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of update deptStud");
      dispatch(fetchDeptStuds());
    })
    .catch((er) => {
      console.log("failure of add deptStud");
      dispatch(saveDeptStud(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteDeptStud = (ids) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.deleteDeptStudData, ids)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of delete deptStud");
      dispatch(fetchDeptStuds());
    })
    .catch((er) => {
      console.log("failure of add deptStud");
      dispatch(saveDeptStud(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
