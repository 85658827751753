import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AddOdDetails from "./addfdpDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchods,
  odSelector,
  deleteOd,
} from "../../app/redux/slices/fdpSlice";
import { AsyncGet, AsyncPost, AsyncGetFiles } from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import Popup from "reactjs-popup";
import "../../app/styles/addnew.css"
var faculty=[];
var department=[];
const OdDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, odList } = useSelector(odSelector);
  const [deparmentList, setDeparmentList] = useState([]);
  const [FacultyList, setFacultyList] = useState([]);
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [od, setOd] = useState();
  const [listOfOd, setlistOfOd] = useState(odList || []);
  const [showAdd, setshowAdd] = useState();
  const [IsEdit, setIsEdit] = useState(false);

  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [selectedFile, setselectedFile] = useState("");
  const [TabMatced, setTabMatced] = useState([]);
  const [TabPending, setTabPending] = useState([]);
  const [TabApproved, setTabApproved] = useState([]);
  const togglePopup = () => {
    debugger;
    onAssessmentSelection("Od");
    setshowPopup(!showPopup);
    setIsEdit(false);

    if (!showPopup) {
      setOd();
    }
  };
  
  
  /*const closePopupFun = () => {
    closePopup();
    window.location.reload(false);
  };*/
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", odList);

  const deleteOdFun = (item) => {
    debugger;
    dispatch(deleteOd(item.refNo));
  };

  const editOd = (item) => {
    debugger;
    setOd({ ...item, data: item });
   // setrowData({ ...item, productInfo: item });
    setshowPopup(!showPopup);
    setIsEdit(true);

  };
  const smsOd = (item) => {
    debugger;
    AsyncPost(API.sendfacultysms + "?id=" + item.refNo + "&department=" + item.departmentName + "&currentFileQueueStatus=" + item.currentFileQueueStatus)
    // dispatch(deleteIndent(item.refNo && item.departmentName && item.currentFileQueueStatus));
  };
  useEffect(() => {
    getOdData();
  }, []);

  const getOdData = () => {
    dispatch(fetchods());
  };
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
       //department = (res.data.map(x=>x.departmentCode+"-"+x.name));
       department = (res.data.map(x=>x.name));

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    debugger;
    AsyncGet(API.getFaculties)
      .then((res) => {
        console.log(res.data, "getFacultyByName");
        setFacultyList(res.data);
      console.log(FacultyList,"facultylist");
       faculty = (res.data.map(x=>x.facultyId+"-"+x.facultyName));
       console.log(faculty,"Faculty");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(faculty,"faculty");
  useEffect(() => {
    if (!odList) {
      return;
    }
    var sortData=[...odList].sort(a => {
      return a.currentFileQueueStatus==JSON.parse(localStorage.getItem("user")).indentRoleName ?-1 :1
    });

    if((JSON.parse(localStorage.getItem("user")).fdpRoleName =="Maker")||(JSON.parse(localStorage.getItem("user")).fdpRoleName =="HOD"))
    {
      setlistOfOd(sortData.filter(x =>x.departmentId ==JSON.parse(localStorage.getItem("user")).departmentId ));
      sortData=sortData.filter(x =>x.departmentId ==JSON.parse(localStorage.getItem("user")).departmentId );
    }
    else if((JSON.parse(localStorage.getItem("user")).fdpRoleName ==null))
    {
      setlistOfOd([]);
      sortData=[];
    }
    else{
      setlistOfOd(sortData);
    }
    //setlistOfOd(odList);
    setshowAdd(JSON.parse(localStorage.getItem("user")).fdpRoleName);
    setTabMatced(sortData.filter(x => ((x.currentFileQueueStatus == JSON.parse(localStorage.getItem("user")).fdpRoleName)||(x.currentFileQueueStatus == "Approved! Feedback Pending"))));
    setTabPending(sortData.filter(x => x.currentFileQueueStatus != "Approved" && x.currentFileQueueStatus != JSON.parse(localStorage.getItem("user")).fdpRoleName && x.currentFileQueueStatus !="Approved! Feedback Pending"));
    setTabApproved(sortData.filter(x => x.currentFileQueueStatus == "Approved"));

  }, [odList]);

  console.log("Before Render odList", odList);
  //console.log("Before Render sortData", sortData);

  console.log("Before Render listOfOd", listOfOd);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">FDP Form</h2>
            {(JSON.parse(localStorage.getItem("user")).fdpRoleName) === "Maker" ||
            (JSON.parse(localStorage.getItem("user")).fdpRoleName) === "HOD" ? (
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>            

            </div>
  ) : null}
          </div>
          

<div className="row mb-5">
<Tabs
              defaultActiveKey="Tab"
              id="fill-tab-example"
              className="mb-3"
              fill
            >

              <Tab eventKey="Tab" title="Assigned">
                <div className="bootstrap-table">
                  <div className="fixed-table-toolbar"></div>
                  <div className="fixed-table-container">
                    <div
                      className="fixed-table-body"
                      style={{
                        height: "65vh",
                        overflow: "auto",
                        marginTop: 20,
                      }}
                    >
                      <AdvanceTable
                        deleteOdProp={(item) => deleteOdFun(item)}
                        editOdProp={(item) => editOd(item)}
                       // smsOdProp={(item) => smsOd(item)}
                        data={TabMatced}
                      />
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="Tab2" title="In Progress" >
                <div className="bootstrap-table">
                  <div className="fixed-table-toolbar"></div>
                  <div className="fixed-table-container">
                    <div
                      className="fixed-table-body"
                      style={{
                        height: "65vh",
                        overflow: "auto",
                        marginTop: 20,
                      }}
                    >
                      <AdvanceTable
                        deleteOdProp={(item) => deleteOdFun(item)}
                        editOdProp={(item) => editOd(item)}
                       // smsOdProp={(item) => smsOd(item)}
                        data={TabPending}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Tab3" title="Approved">
                <div className="bootstrap-table">
                  <div className="fixed-table-toolbar"></div>
                  <div className="fixed-table-container">
                    <div
                      className="fixed-table-body"
                      style={{
                        height: "65vh",
                        overflow: "auto",
                        marginTop: 20,
                      }}
                    >
                      <AdvanceTable

                        deleteOdProp={(item) => deleteOdFun(item)}
                        editOdProp={(item) => editOd(item)}
                      //  smsOdProp={(item) => smsOd(item)}
                        data={TabApproved}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
  </div>

         
        </div>
        {showPopup ? (
          <AddOdDetails
          isEdit ={IsEdit}

            item={assessment}
            closePopup={togglePopup}
            od={od}
            Department={department}
            Faculty={faculty}
        />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default OdDetails;
