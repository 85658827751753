import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const inventorySlice = createSlice({
  name: "inventoryList",
  initialState: {
    loading: false,
    error: false,
    inventoryList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    inventoryDetails: [],
    submitInventorySuccess: "",
    saveInventoryDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getInventory: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.inventoryList = payload;
    },
    saveInventory: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveInventoryDataSuccess = payload;
    },
    submitInventory: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getInventory,
  submitInventory,
  setError,
  saveInventory,
} = inventorySlice.actions;
export const inventorySelector = (state) => state.inventorys || [];
export default inventorySlice.reducer;

export const fetchInventorys = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getInventorys)
    .then((response) => {
      console.log("response,", response);
      dispatch(getInventory(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addInventoryDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveInventorys, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveInventory(response));
      dispatch(fetchInventorys());
    })
    .catch((er) => {
      console.log("failure of add Inventory");
      dispatch(saveInventory(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateInventoryDetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updateInventorys, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update Inventory");
      dispatch(fetchInventorys());
    })
    .catch((er) => {
      console.log("failure of Update Inventory");
      dispatch(saveInventory(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteInventory = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deleteInventorys + "?id=" + data, "")
    .then((response) => {
      if (response.data!='Success')
      {      
        alert('Sorry! Unable to delete inventory. Inventory is mapped with inventory specification');
      }
      

      // dispatch(setLoading());
      console.log(response, "success of delete Inventory");
      dispatch(fetchInventorys());
    })
    .catch((er) => {
      console.log("failure of delete Inventory");
      dispatch(saveInventory(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
