import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  // const navigate = useNavigate();
  //state
  const [FromDate, setFromDate] = useState("");
  const [Time, setTime] = useState("");  
  //const [Min, setMin] = useState("");
  const [Topic, setTopic] = useState("");
  const [ResourcePerson, setResourcePerson] = useState("");
  const [ResourcePersonDetails, setResourcePersonDetails] = useState("");
  const [ParticipantsFrom, setParticipantsFrom] = useState("");
  const [ExternalParticipants, setExternalParticipants] = useState("");
  const [nParticipants, setnParticipants] = useState("");
  const [nParticipantsBoys, setnParticipantsBoys] = useState("");
  const [nParticipantsGirls, setnParticipantsGirls] = useState(""); 
  const [Impact, setImpact] = useState("");
  const [OrganisedBy, setOrganisedBy] = useState(""); 
  const [OffLineOrOnLine, setOffLineOrOnLine] = useState("Offline");  
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
 const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const[datevalidation, setdatevalidation] = useState(true);

  const runFillActivityData = useRef();

  const clearState = () => {
    setFromDate("");
    setTime("");
    setTopic("");
    setImpact("");
    setOrganisedBy("");
    setExternalParticipants("");

    setnParticipants("");
    setnParticipantsBoys("");
    setnParticipantsGirls("");
    setResourcePerson("");
    setResourcePersonDetails("");
    setParticipantsFrom("");

    setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");

    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
   // setFromdatevalidation(false);
    console.log("reseted");
  };
  
  const setOnOFFvalue = (val) => {
    setOffLineOrOnLine(val);
  };
  const fromdatechange = (val) => {
    setFromDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setdatevalidation(false);
  };
 
  // const onMinSelect = (e) => {
  //   setMin(e.target.value);
  // };
   const onTimeSelect = (val) => {
    setTime(val.target.value);
   };
  const onParticipantsFromSelect = (e) => {
    setParticipantsFrom(e.target.value);
  };
  const onResourcePersonSelect = (e) => {
    setResourcePerson(e.target.value);
  };  
  const onResourcePersonDetailsSelect = (val) => {
    setResourcePersonDetails(val.target.value);
  };
  
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  const onFileUpload = async (res) => {
 if (selectedFile?.length < 1) {
      dispatch(fetchActivities(3,JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();
      return;
    }
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Guest Lecture"
    );
  console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
 dispatch(fetchActivities(3,JSON.parse(localStorage.getItem("user")).departmentId));
       closePopup();
       clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 3,
      Topic: Topic,
      ResourcePerson: ResourcePerson,  
      ResourcePersonDetails:ResourcePersonDetails,    
      OrganisedBy: OrganisedBy,
      FromDate:FromDate,//new Date(FromDate).toLocaleDateString(),
      Time:Time,
      //Min:Min,
      Department:JSON.parse(localStorage.getItem("user")).department,
      Impact: Impact,
      ParticipantsFrom:ParticipantsFrom,
      ExternalParticipants:ExternalParticipants,
      nParticipants:Number(ExternalParticipants) + Number(nParticipantsGirls)+  Number(nParticipantsBoys),
      
      
      nParticipantsBoys:nParticipantsBoys,
      nParticipantsGirls:nParticipantsGirls,
      OnlineOrOffline: OffLineOrOnLine,
      Attachment: "",
      
    };
    var activityData = {
      id: 0,
      activityID: 3,
      activityName: "Guest Lecture",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
   try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);   
       closePopup();
       clearState();
       }
  };

  const updateActivity = async() => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 3,
      Topic: Topic,
      ResourcePerson: ResourcePerson, 
      ResourcePersonDetails:ResourcePersonDetails,     
      OrganisedBy: OrganisedBy,
      FromDate:FromDate,//new Date(FromDate).toLocaleDateString(),
      Time:Time,
      //Min:Min,   
      Department:JSON.parse(localStorage.getItem("user")).department, 
      Impact: Impact,
      ParticipantsFrom:ParticipantsFrom,
      ExternalParticipants:ExternalParticipants,
      nParticipants:Number(ExternalParticipants) + Number(nParticipantsGirls)+  Number(nParticipantsBoys),
      nParticipantsBoys:nParticipantsBoys,
      nParticipantsGirls:nParticipantsGirls,
      OnlineOrOffline: OffLineOrOnLine,
      Attachment:"",
      
    };
    const activityData = {
      ...activity,
      activityID:3,
      activityName: "Guest Lecture",
      data: JSON.stringify(data),
      //filePath: selectedFile,
    };
    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };
 

  const validationFun = () => {
    setvalidate(true);
    if (
      Topic &&      
      Time &&
      ResourcePerson &&
      FromDate  &&
      OrganisedBy  
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "topic":
        setTopic(e.target.value);
        break;
        case "impact":
        setImpact(e.target.value);
        break;
      case "organisedby":
        setOrganisedBy(e.target.value);
      break;
      
      
      case "externalparticipants":
        var externalparticipants = e.target.value.replace(/\D/g,"");
          setExternalParticipants(externalparticipants);
          
          //setnParticipants (nParticipantsBoys?0+nParticipantsGirls?0=ExternalParticipants?0);
        
      break;
      case "nparticipants":

        var nparticipants = e.target.value.replace(/\D/g,"");
           setnParticipants(nparticipants);
        
      break;
      case "nparticipantsboys":
        var nparticipantsboys = e.target.value.replace(/\D/g,"");
          setnParticipantsBoys(nparticipantsboys);
        
      break;
      case "nparticipantsgirls":
        var nparticipantsgirls = e.target.value.replace(/\D/g,"");
          setnParticipantsGirls(nparticipantsgirls);
        
      
     
      
    }
  };
  // On file select (from the pop up)
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
 console.log(AllFiles);
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };


  const fillActivityData = (data) => {
    runFillActivityData.current = false;
const obj = data;
    const objData =obj.data;
     console.log(obj);
    setFromDate(objData.FromDate);
    setTime(objData.Time);
    //setMin(objData.Min);
    setTopic(objData.Topic);  
    setImpact(objData.Impact);
    setOrganisedBy(objData.OrganisedBy); 
    setExternalParticipants(objData.ExternalParticipants);
    setnParticipants(objData.nParticipants);
    setnParticipantsBoys(objData.nParticipantsBoys);
    setnParticipantsGirls(objData.nParticipantsGirls);
    setResourcePerson(objData.ResourcePerson);
    setResourcePersonDetails(objData.ResourcePersonDetails);
    setParticipantsFrom(objData.ParticipantsFrom);
    setOffLineOrOnLine(objData.OnlineOrOffline|| "Offline");  
    setselectedFile(obj.filePath);
    setCreatedBy(obj.createdDate);
 setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
    <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
             
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio5"
                    value="option3"
                    checked={OffLineOrOnLine === "Offline"}
                    onChange={() => setOnOFFvalue("Offline")}
                  />
                  <label className="form-check-label checkposition">
                    Offline
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio6"
                    value="option3"
                    checked={OffLineOrOnLine === "Online"}
                    onChange={() => setOnOFFvalue("Online")}
                  />
                  <label className="form-check-label checkposition">
                    Online
                  </label>
                </div>
              </div>
            </div>
            <div className="row formadduser">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>From Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={fromdatechange}
                  
                />{/*{!FromDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}*/}
              </div>
             { /*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Time</label>
                <input
                  className="form-control"
                  type="time"
                  value={dayjs(Time).format("HH:MM")}
                  onChange={onTimechange}
                />
                {!Time && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}
              </div>*/}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Time</label>                
                <input
                  className="form-control"
                  type={"time"}
                  id="time"
                  value={Time}
                  onChange={onTimeSelect}
                />
                {!Time && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>

             
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Resource Person</label>
                <select
                  className="form-select form-control"
                  onChange={onResourcePersonSelect}
                  aria-label="Default select example"
                  value={ResourcePerson}
                >
                  <option value ="">Select Resource Person</option>
                  <option value="External">External</option>
                  <option value="Internal">Internal</option>
                  <option value="Alumni">Alumni</option>
                  <option value="Industry">Industry</option>
                </select>
                {!ResourcePerson && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
               </div>
               <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                   Resourse Person Details
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  value={ResourcePersonDetails}
                  onChange={onResourcePersonDetailsSelect}
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Topic</label>
                <input
                  className="form-control"
                  type="text"
                  id="topic"
                  value={Topic}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!Topic && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              
             
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Organised By</label>
                <input
                  className="form-control"
                  type="text"
                  id="organisedby"
                  value={OrganisedBy}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                {!OrganisedBy && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
                <label>Impact</label>
                <textarea
                  className="form-control"
                  type="text"
                  id="impact"
                  value={Impact}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={300}
                />
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Participants From</label>
                <select
                  className="form-control"
                  onChange={onParticipantsFromSelect}
                  aria-label="Default select example"
                  value={ParticipantsFrom}
                >
                  <option value ="">select Participants From</option>
                  <option value="First Year">First Year</option>
                  <option value="Second Year">Second Year</option>
                  <option value="Third Year">Third Year</option>
                  <option value="Others">Others</option>
                </select>
                
              </div>              
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>No of Girls Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipantsgirls"
                  value={nParticipantsGirls}
                  onChange={onTextValueChange}
                  maxLength={4}
                />               
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>No of Boys Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipantsboys"
                  value={nParticipantsBoys}
                  onChange={onTextValueChange}
                  maxLength={4}
                />                
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>External Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="externalparticipants"
                  value={ExternalParticipants}
                  onChange={onTextValueChange}
                  maxLength={4}
                />                
              </div>           
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>No of Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipants"
                  //value={nParticipants}
                  value={Number(ExternalParticipants) + Number(nParticipantsGirls)+  Number(nParticipantsBoys)}
                  onChange={onTextValueChange}
                 maxLength={4}
                 
                />                
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
           
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewItem;
