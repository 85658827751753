import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const hoaDetailsSlice = createSlice({
  name: "hoaDetailsList",
  initialState: {
    loading: false,
    error: false,
    hoaDetailsList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    hoaDetails: [],
    submitHOADetailsSuccess: "",
    saveHOADetailsDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getHOADetails: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.hoaDetailsList = payload;
    },
    saveHOADetails: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveHOADetailsDataSuccess = payload;
    },
    submitHOADetails: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getHOADetails,
  submitHOADetails,
  setError,
  saveHOADetails,
} = hoaDetailsSlice.actions;
export const HOADetailsSelector = (state) => state.hoadetails || [];
export default hoaDetailsSlice.reducer;

export const fetchHOADetails = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.gethoadetails)
    .then((response) => {
      console.log("response,", response);
      dispatch(getHOADetails(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addHOADetails = (data) => (dispatch) => {
  debugger
  dispatch(setLoading());
  AsyncPost(API.savehoadetails, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveHOADetails(response));
      dispatch(fetchHOADetails());
    })
    .catch((er) => {
      console.log("failure of add HOADetails");
      dispatch(saveHOADetails(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateHOADetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updatehoadetails, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update HOADetails");
      dispatch(fetchHOADetails());
    })
    .catch((er) => {
      console.log("failure of Update HOADetails");
      dispatch(saveHOADetails(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteHOADetails = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deletehoadetails + "?id=" + data, "")
    .then((response) => {
      if (response.data!='Success')
      {      
        alert('Sorry! Unable to delete HOADetails.HOADetails is mapped with Indent form');
      }
      

      // dispatch(setLoading());
      console.log(response, "success of delete HOADetails");
      dispatch(fetchHOADetails());
    })
    .catch((er) => {
      console.log("failure of delete HOADetails");
      dispatch(saveHOADetails(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
