import { useState, useEffect, useRef } from "react";
import "./styles.css";
import dayjs from "dayjs";
import Moment from "moment";

import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
  AsyncPostFiles,
} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import RenderIf, { ExportToCsv } from "./utils";
import excel_file from "./blobfile.json";

const MarkPage = () => {
  let sequence = 0;
  var count = 0;

  const dataTable = useRef();
  const [departmentOpt, setdepartmentOpt] = useState([]);
  const [department, setdepartment] = useState("");
  const [departmentVal, setdepartmentVal] = useState("");

  const [selectedFile, setselectedFile] = useState("");

  const [year, setyear] = useState("");
  const [exam, setExam] = useState("");

  const [semester, setsemester] = useState("");
  const [section, setsection] = useState("");
  const [sectionOpt, setsectionOpt] = useState([]);
  const [multiSubject, setmultiSubject] = useState([]);
  const [subjectName, setsubjectName] = useState("");
  const [subjectOpts, setsubjectOpts] = useState([]);
  const [subjectOptsTest, setsubjectOptsTest] = useState([]);
  const [IsAttendance, setIsAttendance] = useState(false);

  const [subjectShortForm, setsubjectShortForm] = useState("");
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [unselectedSubjOpt, setunselectedSubjOpt] = useState([]);
  const [attendance, setattendance] = useState([]);
  const [formatAttendance, setformatAttendance] = useState({});
  const [ReadytosendEmail,setReadytosendEmail]=useState(true);
  /*const [batch, setbatch] = useState("");
  const [multiSubject, setmultiSubject] = useState([]);
  const [batchOpt, setbatchOpt] = useState([]);
  const [unselectedBatcOpt, setunselectedBatcOpt] = useState([]);
  const [date, setdate] = useState();
  const [formatAttendance, setformatAttendance] = useState({});
  const [attendance, setattendance] = useState([]);*/
  /* const [loading, setloading] = useState(false);
   const [subjectName, setsubjectName] = useState("");
   const [hours, sethours] = useState(0);
 let ReadytosendEmail=true;
   
   
   const [subjectOpts, setsubjectOpts] = useState([]);*/
  const [showReportOpts, setshowReportOpts] = useState(false);

  const inValidData =
    !department || !year || !semester || !section || !exam;

  const validFormatAttendance = useMemo(() => {
    return Object.keys(formatAttendance).length > 0;
  }, [formatAttendance]);

  const clearState = (type) => {
    switch (type) {
      case "department": {
        setsection("");
        //setbatch("");
        setmultiSubject([]);
        //setformatAttendance({});
        setattendance([]);
        setyear("");
        setsemester("");
        //setdate("");
        setExam("");
        setsubjectName("");
        setIsAttendance(false);
        //sethours(0);
        return;
      }
      case "year": {
        setsection("");
        //setbatch("");
        setmultiSubject([]);
       //setformatAttendance({});
        setattendance([]);
        setsemester("");
        //setdate("");
        setsubjectName("");
        setExam("");
        setIsAttendance(false);

        // sethours(0);
        return;
      }
      case "semester": {
        setsection("");
        //setbatch("");
        setmultiSubject([]);
        //setformatAttendance({});
        setattendance([]);
        //setdate("");
        setExam("");
        setIsAttendance(false);
        setsubjectName("");
        // sethours(0);
        return;
      }
      case "section": {
        //setbatch("");
        setmultiSubject([]);
        setExam("");
        //setformatAttendance({});
        // setdate("");
        setattendance([]);
        setsubjectName("");
        setIsAttendance(false);
        // sethours(0);
        return;
      }
      case "exam": {
        setmultiSubject([]);
        setsubjectName("");
        setIsAttendance(false);
      }
      /*case "batch": {
        setformatAttendance({});
        //setsubjectName("");
        setattendance([]);
        //sethours(0);
        return;
      }
     
      case "date": {
        setformatAttendance({});
        return;
      }*/
      case "multiSubject": {
        setsubjectName("");
        if (multiSubject.length <= 1) {
          setsubjectName("");
          setattendance([]);
          //setformatAttendance({});
          setIsAttendance(false);
          //sethours(0);
        }
        return;
      }
      default: {
        setdepartment("");
        setyear("");
        setsemester("");
        setsection("");
        setdepartmentVal("");
        //setformatAttendance({});
        //setattendance([]);
        //setbatch("");
        setmultiSubject([]);
        setsubjectName("");
        //sethours(0);
        setExam("");
        setattendance([]);
        return;
      }
    }
  };

  const _onChange = ({ key, data }) => {
    const functionallity = {
      department: (data) => {
        setdepartment(data);
        var dept = departmentOpt.filter(x => x.id == data)[0];
        setdepartmentVal(dept.id + '-' + dept.name);
        clearState("department");
      },
      exam: (data) => {
        setExam(data);
        clearState("exam");

      },
      year: (data) => {
        setyear(data);
        clearState("year");
      },
      semester: (data) => {
        setsemester(data);
        clearState("semester");
      },
      section: (data) => {
        setsection(data);
        clearState("section");
      },
      /* batch: (data) => {
          setbatch(data);
          const present = multiBatch.find((itm) => itm === data);
          //clearState("batch");
          if (present || !data) return;
          setmultiBatch((pre) => (pre.length > 0 ? [...pre, data] : [data]));
        },
        date: (data) => {
          setdate(data);
          clearState("date");
        },*/
      subject: (data) => {
        setsubjectName(data);
        debugger;
        if (exam == "Coaching") {
          const subjectVal = data + ',' + data + ',' + data;
          setmultiSubject(subjectVal);

        }
        else {
          const present = multiSubject.find((itm) => itm === data);
          //clearState("batch");
          if (present || !data) return;
          setmultiSubject((pre) => (pre.length > 0 ? [...pre, data] : [data]));
        }
      },
      /* hour: (data) =>{ sethours(data),
       checkBox: ({ item, checked }) => {
         const updateObj = attendance.find((obj) => obj.uiId === item.uiId);
         updateObj.isPresent = checked;
         setattendance([...attendance]);
       },*/

    };
    functionallity[key](data);
  };
  const sendemail =async ()=>{
    debugger; //API.getSubjectsForMarks + "Department=" + Number(department) +
    //const response = await AsyncPost(API.getEmailUpdate +"ReadytosendEmail=" + ReadytosendEmail);
    const response = await AsyncPost(
      API.getEmailUpdate + "ReadytosendEmail=" +ReadytosendEmail );
    
     console.log("email",response);
     if(response.status =="200")
     {
      alert("SMS will send for all the uploaded Departments.");
     }
     else{
      alert("Something went wrong!..Send Again!");
     }

  }
  const getAllDepartmentFun = async (isSubscribed) => {
    try {
      if (!isSubscribed) {
        return;
      }
      const response = await AsyncGet(API.getAllDepartment);
      setdepartmentOpt(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getSectionFun = async (isSubscribed) => {
    try {
      if (isSubscribed && department && year && semester) {
        const body = {
          DepartmentId: Number(department),
          Year: Number(year),
          Sem: semester,
        };
        const response = await AsyncPost(API.getSection, body);
        setsectionOpt(response.data);
        // console.log(response.data, "section options");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /*const getBatchFun = async (isSubscribed) => {
    try {
      if (isSubscribed && department && year && semester && section) {
        const body = {
          DepartmentId: Number(department),
          Year: Number(year),
          Sem: semester,
          DeptSection: section,
        };
        const response = await AsyncPost(API.getBatchList, body);
        setbatchOpt(response.data);
        setunselectedBatcOpt(response.data);
        // console.log(response.data, "batch options");
      }
    } catch (error) {
      console.log(error);
    }
  };*/

  const getSubjects = async (isSubscribed) => {
    debugger;
    try {

      if (isSubscribed && department && year && semester && section) {

        const response = await AsyncGet(
          API.getSubjectsForMarks + "Department=" + Number(department) + "&Year=" + year + "&Sem=" + semester + "&Section=" + section
        );
        setsubjectOpts(response.data);
        setunselectedSubjOpt(response.data);
        setsubjectOptsTest(response.data);
        // console.log(response.data, "batch options");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const isAttendance = (e) => {
    setIsAttendance(e.target.checked);
  }
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const onFileUpload = async (res) => {
    debugger;

    if (selectedFile[0].name == "Mark.xlsx") {
      debugger;
      console.log(res, "response");
      //const studentData = res ? res[0] : student;
      const formData = new FormData();
      for (let index = 0; index < selectedFile.length; index++) {
        const fileUploaded = selectedFile[index];

        formData.append("FormFiles", fileUploaded);

      }

      formData.append("TypeofUser", "Mark");
      formData.append("Department", department);
      formData.append("Sem", semester);
      formData.append("Year", year);
      formData.append("Section", section);
      //formData.append("isAttendnce", IsAttendance);

      try {
        debugger;
        const res = await AsyncPost(API.bulkupload, formData);
        alert(res.data);
       } catch (ex) {
        console.log(ex);
      }
    } else {
      alert("File Name should be Mark.xlsx only");
    }
    const response = await AsyncGet(API.getStudentMark);
    console.log(response?.data, "GetStudentMark response");
    if (response?.data?.length < 1 || !response?.data) {
      alert("Something went Worg");
      return;
    }
    const dateAddedData = response?.data?.map((element) => {
      const obj = element;
      obj.uiId = uuidv4();
      return obj;
    });
    setattendance(dateAddedData);
    formatDataFun(dateAddedData);

  };

  const getMarkTemplate = async () => {
    debugger;
    AsyncGetFiles(
      `${API.getAllMarkReports
      }department=${departmentVal}&Sem=${semester}&Section=${section}&subjects=${multiSubject?.length > 0 ? multiSubject.toString() : subjectShortForm
      }&year=${year}&AttendanceRequired=${IsAttendance}&test=${exam}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Mark.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const deleteMarkFun = async() => {
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    debugger
    
    try {
      const _attendance = [...attendance];
      const response = await AsyncPost(API.deleteMark, _attendance);
      
      if(response.data="Success"){
      alert("Deleted Successfully");
      setformatAttendance({});

      }
      //console.log(response.data);
     
    } 
    catch (error) {
      alert("Something went wrong");
      console.log(error);
    }
  };
  const generateReport = async () => {
    debugger;
    AsyncGetFiles(
      `${API.generateReport
      }departmentId=${departmentVal}&sem=${semester}&section=${section}&year=${year}&test=${exam}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "MarkReport.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const formatDataFun = (data) => {
    debugger;
    if (data?.length < 1 || attendance?.length < 1) {
      return;
    }
    const attendanceData = data || attendance;
    const formatedData = attendanceData.reduce((acc, item) => {
      acc[`group_${item.studentId}`] = acc[`group_${item.studentId + ' - ' + item.studentName}`] || [];
      acc[`group_${item.studentId}`].push(item);
      return acc;
    }, {});
    setformatAttendance(formatedData);
  };
  
  // ------------------get All Department function-------------------------
  useEffect(() => {
    if (multiSubject?.length > 0 && exam === "Weekly") {
      const result = subjectOpts.filter(
        (opt1) => !multiSubject.some((opt2) => opt2 === opt1.subjectCode)
      );
      setunselectedSubjOpt([...result]);
      return;
    }
    if (subjectOpts?.length > 0) setunselectedSubjOpt([...subjectOpts]);
  }, [multiSubject, subjectOpts]);

  useEffect(() => {
    let isSubscribed = true;
    getAllDepartmentFun(isSubscribed);
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    let isSubscribed = true;
    getSectionFun(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester]);
 
  useEffect(() => {
    debugger;
    let isSubscribed = true;
    getSubjects(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester, section]);
  useEffect(() => {
    if (attendance) {
      formatDataFun();
    }
  }, [attendance]);

  const renderItems = () => {
    return (
      <div
        className="container pb-5 pt-3 px-4 border"
        onClick={() => showReportOpts && setshowReportOpts(false)}
      >
        <p></p>
        <div className="row mb-4">
          <div className="col-lg-3">
            <p className="m-0">Department</p>
            <select
              className="form-select form-control"
              onChange={(e) =>
                _onChange({ key: "department", data: e.target.value })
              }
              value={department}
            >
              <option>
                {departmentOpt?.length > 0 ? "Select Department" : "No Options"}
              </option>
              {departmentOpt?.length > 0
                ? departmentOpt.map((opt, idx) => (
                  <option key={idx} value={opt?.id}>
                    {opt.name}
                  </option>
                ))
                : null}
            </select>
          </div>
          <RenderIf isTrue={department}>
            <div className="col-lg-3">
              <p className="m-0">Year</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "year", data: e.target.value })
                }
                value={year}
                maxLength={150}
              >
                <option>Select Year</option>
                <option value={1}>1st Year</option>
                <option value={2}>2nd Year</option>
                <option value={3}>3rd Year</option>
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={year}>
            <div className="col-lg-3">
              <p className="m-0">Semester</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "semester", data: e.target.value })
                }
                value={semester}
                maxLength={150}
              >
                <option>Select Semester</option>
                <option value={"Odd"}>Odd</option>
                <option value={"Even"}>Even</option>
              </select>
            </div>
          </RenderIf>

          <RenderIf isTrue={semester}>
            <div className="col-lg-3">
              <p className="m-0">Section</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "section", data: e.target.value })
                }
                value={section}
                maxLength={150}
              >
                <option>
                  {sectionOpt?.length > 0 ? "Select Section" : "No Options"}
                </option>
                {sectionOpt?.length > 0
                  ? sectionOpt.map((opt, idx) => (
                    <option key={idx} value={opt}>
                      {opt}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={section}>
            <div className="col-lg-3">
              <p className="m-0">Exam</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "exam", data: e.target.value })
                }
                value={exam}
                maxLength={150}
              >
                <option>Select Test</option>
                <option value={"Coaching"}>Coaching</option>
                <option value={"Weekly"}>Weekly</option>
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={exam}>
            {exam === "Coaching" &&
              <div className="col-lg-3">
                <p className="m-0">Subject</p>
                <select className="form-select form-control"
                  onChange={(e) =>
                    _onChange({ key: "subject", data: e.target.value })
                  }
                  value={subjectName}
                  maxLength={150}
                >
                  <option value="">
                    {subjectOptsTest?.length > 0 ? "Select Subject" : "No Options"}
                  </option>
                  {subjectOptsTest?.length > 0
                    ? subjectOptsTest.map((opt, idx) => (
                      <option key={idx} value={opt.subjectCode + "-" + opt.subjectShortForm}>
                        {opt.subjectName}
                      </option>
                    ))
                    : null}
                </select>
              </div>}
            {exam === "Weekly" &&
              <div className="col-lg-3">
                <p className="m-0">Subject</p>
                <select
                  className="form-select form-control"
                  onChange={(e) =>
                    _onChange({ key: "subject", data: e.target.value })
                  }
                  value={subjectName}
                  maxLength={150}
                >
                  <option value="">
                    {subjectOpts?.length > 0 ? "Select Subject" : "No Options"}
                  </option>
                  {unselectedSubjOpt?.length > 0
                    ? unselectedSubjOpt.map((opt, idx) => (
                      <option key={idx} value={opt.subjectCode + "-" + opt.subjectShortForm}>
                        {opt.subjectName}
                      </option>
                    ))
                    : null}
                </select>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {multiSubject.length > 0
                    ? multiSubject.map((itm) => (
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          margin: "4px 3px 0px 0px",
                          background: "rgb(230,230,230)",
                          padding: "0px 0px 0px 5px",
                        }}
                      >
                        <label
                          style={{ border: "0px solid" }}
                          key={itm}
                        >{`${itm}`}</label>

                        <label
                          style={{
                            background: "rgb(200,200,200)",
                            margin: "0px 0px 0px 5px",
                            padding: "0px 5px",
                          }}
                          onClick={() => {
                            console.log(itm);
                            setmultiSubject((pre) => {
                              const newArray = pre.filter(
                                (item) => item !== itm
                              );
                              clearState("multiSubject");
                              return newArray;
                            });
                          }}
                        >
                          X
                        </label>
                      </div>
                    ))
                    : null}
                </div>
              </div>}
              <div className="col-lg-3 ">
            <p className="m-0">Is Attendance Required?</p>
              <input
                className="form-check-input radio-btn i custom-control-input mx-0 radio-btn-size"
                checked={IsAttendance}
                type={"checkbox"}
                onChange={isAttendance}
              />

            </div>
          </RenderIf>
          {/* <RenderIf isTrue={multiSubject.length > 0}>
            
          </RenderIf> */}
        </div>
        {/*{JSON.parse(localStorage.getItem("user")).roleName == "Admin" ? (*/}
        {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
        <RenderIf isTrue={multiSubject.length > 0}>

          <div className="row report-btns-block mb-5 py-2">
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getMarkTemplate}
                className="btn btn-secondary w-100"
              >
                Download Template
              </button>
            </div>
            
           
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              
              <button
                type="button"
                onClick={clearState}
                className="btn btn-secondary w-100"
              >
                Reset
              </button>
              </div>
              <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
            <button
              type="button"
              onClick={sendemail}
              className="btn btn-secondary w-100"
            >Send Sms</button>
            </div>
            
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <input
                className="form-control"
                onChange={onFileChange}
                type="file"
                accept=".xls,.xlsx"
                id="formFileMultiple"
              />

            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={onFileUpload}
                className="btn btn-secondary w-100"
              >
                Upload
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={generateReport}
                className="btn btn-secondary w-100"
              >
                Generate Report
              </button>
            </div>
         </div>         
        </RenderIf>
           ): null}
           {/*{JSON.parse(localStorage.getItem("user")).roleName != "Admin" ? (*/}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() != "ADMIN" ? (
        <RenderIf isTrue={multiSubject.length > 0}>

          <div className="row report-btns-block mb-5 py-2">
            <div className="col-lg-6 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getMarkTemplate}
                className="btn btn-primary w-100"
              >
                Download Template
              </button>
            </div>
            <div className="col-lg-6 d-flex my-1 flex-column justify-content-center">
              
              <button
                type="button"
                onClick={clearState}
                className="btn btn-secondary w-100"
              >
                Reset
              </button>
              </div>
          
          </div>

        </RenderIf>
         ): null}
        <RenderIf isTrue={validFormatAttendance}>
          <div className="mb-5 mt-2 grid-table " style={{ overflow: "auto" }}>
            <table ref={dataTable} className="w-100">
              <thead className="">
                <tr>
                  <td>S.No</td>
                  <td>Reg.No </td>
                  <td>Student Name</td>
                  <td>Mark Details</td>

                </tr>
              </thead>
              <tbody className="">
                {formatAttendance
                  ? Object.values(formatAttendance).map((_item, idx) => {
                    //sequence = formatAttendance.length + 1;
                    return (
                      <tr key={idx}>
                        <td>{++count}</td>
                        <td>{_item[0].studentId || ""}</td>
                        <td>{_item[0].studentName || ""}</td>                     
                        <td>{_item[0]?.data.split(",").map((line, i) => { return (<div key={i}>{line}</div>) })}</td>
                        {/* <td>{_item[0].data || ""}</td> */}

                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
        </RenderIf>
        <RenderIf isTrue={validFormatAttendance}>
          <div className="row">
            
             <div className="col-lg-3 my-1">

              <button 
              type="button"
              onClick={deleteMarkFun}
              className="btn btn-primary w-100"
              > 
              Delete Mark
              </button>
                  </div> 
         
          </div>
        </RenderIf>
      </div>
    );
  };

  return <div>{renderItems()}</div>;
};
export default MarkPage;
