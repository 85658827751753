import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const budgetSlice = createSlice({
  name: "budgetList",
  initialState: {
    loading: false,
    error: false,
    budgetList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    budgetDetails: [],
    submitBudgetSuccess: "",
    saveBudgetDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getBudget: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.budgetList = payload;
    },
    saveBudget: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveBudgetDataSuccess = payload;
    },
    submitBudget: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getBudget,
  submitBudget,
  setError,
  saveBudget,
} = budgetSlice.actions;
export const budgetSelector = (state) => state.budget || [];
export default budgetSlice.reducer;

export const fetchBudgets = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getBudgets)
    .then((response) => {
      console.log("response,", response);
      dispatch(getBudget(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addBudgetDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveBudgets, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveBudget(response));
      dispatch(fetchBudgets());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveBudget(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateBudgetDetails = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updateBudgetData, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update faculty");
      dispatch(fetchBudgets());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveBudget(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateReAllocationDetails  = async (data, dispatch) =>  {
  debugger;
dispatch(setLoading());
AsyncPost(API.updateReAllocationData, data)
  .then((response) => {
    // dispatch(setLoading());
    console.log(response, "success of update faculty");
    dispatch(fetchBudgets());
  })
  .catch((er) => {
    console.log("failure of add faculty");
    dispatch(saveBudget(""));
    dispatch(setError(true));
    dispatch(setLoading());
  });
};

export const deleteBudget = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deleteBudgetData + "?id=" + data, "")
    .then((response) => {
      // if (response!='Success')

      // {      

      //   alert('Sorry! Unable to delete Budget')

      // }
      // dispatch(setLoading());
      console.log(response, "success of delete faculty");
      dispatch(fetchBudgets());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveBudget(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
