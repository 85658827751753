import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";


import {
  addInventorySpecDetails,
  UpdateInventorySpecDetail
} from "../../../app/redux/slices/inventorySpecSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet
} from "../../../app/services/https";
//import {inventoryList} from "../index";
const AddInventorySpecDetails = ({isEdit, item, closePopup, inventoryspec,
  //inventoryList
}) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventoryspec) {
      return;
    }
    
    runFillInventorySpecData.current = true;
    if (runFillInventorySpecData.current) {
      fillInventorySpecData(inventoryspec);
      
    }
  }, []);
  //console.log("inventoryList",inventoryList);
  const [Id, setId] = useState("");
  const [InventoryId,setInventoryId]= useState("");
  const [Description,setDescription] = useState("");
  const [Specification,setSpecification] = useState("");
  const [IsActive,setisActive] = useState(false);
  const [InventoryList, setInventoryList] = useState([]);
  const[enablename, setenablename] = useState(false);
 const[enableinventory, setenableinventory] = useState(false);
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [Store,setStore] = useState("");
  const runFillInventorySpecData = useRef();

  const clearState = () => {
    setInventoryId("");
    setDescription("");
    setSpecification("");
    setisActive("");
    setStore("");

  };
  // const iname = (val) => {
  // debugger;
      
  //   setInventoryId(val.target.value);
  //   setenableinventory(true);
  //   if(val.target.value=="")
  //   {
  //     setenableinventory(false);
  //   }
  // };
  const iInventory = (val) => {  
    debugger;
  
  setInventoryId(val.target.value);
  /*setenablename(true);
  if(val.target.value=="")
  {
    setenablename(false);
  }*/

    };
    const iStore = (val) => {
      setStore(val.target.value);
    }
    const ispecification = (val) => {
      setSpecification(val.target.value);
    }
    const idescription = (val) => {  
      setDescription(val.target.value);
    }

const isactive= (e) => {
  setisActive(e.target.checked);
  
  // if (val==1)
  // {IsActive=true;}
  // if (val==0)
  // setIsActive(false);
}
useEffect(() => {
  debugger
  AsyncGet(API.getInventorys)
    .then((res) => {
      console.log(res.data, "getInventorys");
      
      setInventoryList(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
}, []);


  
  const saveInventorySpecDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var InventorySpecData = {
      Store:Store,
      InventoryId :Number(InventoryId),
      Description:Description,
      Specification:Specification,
      IsActive:IsActive,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addInventorySpecDetails(InventorySpecData));
    closePopup();
    clearState();
  };

  const updateInventorySpecDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      Store:Store,
      InventoryId : Number(InventoryId),
      IsActive:IsActive,
      Description:Description,
      Specification:Specification,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const InventorySpecData = {
      ...inventoryspec,
        data: data
    };

    console.log("UpdateInventorySpec", InventorySpecData.data);
    dispatch(UpdateInventorySpecDetail(InventorySpecData.data));
    closePopup();
    clearState();
    
  };
 
  const validationFun = () => {
    setvalidate(true);
    if (
      !InventoryId ||
      !Store
    ) {
      setvalidate(false);
      seterrorMsg("Provide required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillInventorySpecData = (obj) => {
    debugger;
    runFillInventorySpecData.current = false;
    setId(obj.data.id);
    setInventoryId(obj.data.inventoryId);
    setSpecification(obj.data.specification);
    setDescription(obj.data.description);
    setisActive(obj.data.isActive);
    setenablename(false);
    setenableinventory(true);
    setStore(obj.data.store);
    runFillInventorySpecData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Inventory Spec" : "Add New Inventroy Spec"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>           
               
            <div className="row formadduser">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                <span style={{ color: "red" }}>*</span>Store
                </label>
                <select
                  className="form-select form-control"
                  onChange={iStore}
                  aria-label="Default select example"
                  value={Store}
                >
                  <option value="">Select Store</option>
                  <option value="Student Store">Student Store</option>
                  <option value="Office Store">Office Store</option>
                  
                </select>
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Choose Inventory</label>                
                 <select
                  className="form-select form-control"
                  onChange={iInventory}
                  aria-label="Default select example"
                  //disabled={enableinventory}
                  value={InventoryId}
                > <option value="">--Select Inventry--</option>
                  {InventoryList.filter(x=>x.store === Store)?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>                
              </div>  
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt ">
                <label>Name</label>  
               <input
                  className="form-control"
                  type="text"
                  value={Name}
                  onChange={iname}
                  maxLength={150}
                  disabled={enablename}
                />
              </div> */}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt ">
                <label>Specification</label>  
               <input
                  className="form-control"
                  type="text"
                  value={Specification}
                  onChange={ispecification}
                  maxLength={150}
                  
                />
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt ">
                <label>Description</label>   
               <input
                className="form-control"
                //type="text"
                onChange={idescription}
                maxLength={500}
                //rows="3"
                value={Description}        
              >
              </input>
              </div>  
              </div>
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="checkbox"
                    //name="eligle-radio-btn"
                    //value="1"
                    checked={IsActive}
                    //id="isActive"
                    onChange={isactive}
                  />
                 
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    IsActive
                  </label>
                </div>
                
                
              </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!inventoryspec ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveInventorySpecDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateInventorySpecDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInventorySpecDetails;