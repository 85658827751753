import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStateManager } from "react-select";
import validator from "validator";
import $ from 'jquery';

import {
    addBatchSubDetails,
    UpdateBatchSubDetails
} from '../../../app/redux/slices/batchSubjectMappingSlice';
import { API } from "../../../app/services/endpoints";
import { AsyncGet } from "../../../app/services/https";
import "./styles.css";


const AddBatchSubMappings = ({ isEdit, item, batchSub, closePopup }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (!batchSub) {
            return;
        }
        runFillBatchData.current = true;
        if (runFillBatchData.current) {
            //getBatchList();
            fillBatchData(batchSub);
        }
    }, []);
    useEffect(() => {
        AsyncGet(API.getDepartments)
            .then((res) => {
                console.log(res.data, "getDepartments");
                setDepartmentList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    useEffect(() => {
        AsyncGet(API.getSubjects)
            .then((res) => {
                console.log(res.data, "get Subjects");
                setSubjectList(res.data);
                setSubjectOptions(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        AsyncGet(API.getBatches)
            .then((res) => {
                console.log(res.data, "get Batches");
                setBatchListList(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const [Id, setId] = useState();
    const [BatchId, setBatchId] = useState("");
    const [SubjectList, setSubjectList] = useState([]);
    const [SubjectOptions, setSubjectOptions] = useState([]);
    const [SelectedSubList, setSelectedSubListFromDB] = useState([]);
    const [DepartmentId, setDepartmentId] = useState();
    const [BatchList, setBatchListList] = useState([]);
    const [errorMsg, seterrorMsg] = useState("");
    const [validate, setvalidate] = useState(false);
    const [listForEdit, selectedSubList] = useState([]);
    const [Year, setYear] = useState();
    const [Sem, setSem] = useState();
    const [DepartmentList, setDepartmentList] = useState([]);

    const runFillBatchData = useRef();
    const batchIdRef = useRef();
    const selectedListRef = useRef();
    const clearState = () => {
        setBatchId("");
        setSelectedSubListFromDB([]);
        //setStudentList([]);    
    };
    const validationFun = () => {
        setvalidate(true);
        if (!BatchId || !Year || !Sem ||!DepartmentId|| $("#lstBox2 option").length == 0) {
            if (!BatchId) {
                batchIdRef.current.focus();
                batchIdRef.current.style.border = "1px solid red";
            }
            if ($("#lstBox2 option").length == 0) {
                selectedListRef.current.focus();
                selectedListRef.current.style.border = "1px solid red";
            }
            setvalidate(false);
            seterrorMsg("Provide all required fields");
            return false;
        }
        else {
            return true;
        }
    };
    const dept = (val) => {
        setDepartmentId(val.target.value);
        setYear("");
        setSem("");
        setBatchId("");
    };
    const yearOfEducation = (val) => {
        var year = val.target.value.replace(/\D/g, "");
        setYear(year);
        setSem("");
        setBatchId("");
    };

    const semVal = (val) => {
        setSem(val.target.value);
        setBatchId("");
    };
    const batchId = (val) => {

        setBatchId(val.target.value);
        var deptId = BatchList && BatchList.filter(item => item.batchId == Number(val.target.value))[0].departmentId;
        setDepartmentId(deptId);
        // const filteredList = SubjectList.filter(item => deptId != 0 && item.departmentId == deptId &&
        //     !item.isBatchMapped);
        // setSubjectOptions(filteredList);

        if (BatchId != 0) {
            seterrorMsg("");
            batchIdRef.current.style.border = "";
        }
    };

    const saveBatchDetails = () => {
        seterrorMsg("");
        const error = validationFun();
        if (!error) {
            return;
        }
        var mappedSubjects = $("#lstBox2 option");
        var data = [];
        for (var i = 0; i < mappedSubjects.length; i++) {
            var subDetails = mappedSubjects[i].innerHTML;
            var batchName = BatchList.filter(x => x.batchId === Number(BatchId))[0].batchName;
            data.push({
                id: 0,
                name: batchName + ' - ' + subDetails.trim(),
                batchId: Number(BatchId),
                departmentId : Number(DepartmentId),
                batchName: batchName,
                subjectId: Number(mappedSubjects[i].value),
                subjectName: subDetails[0].trim(),
                createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
                createdDate: new Date(),
                modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
                modifiedDate: new Date(),
            })
        }
        var batchSubData = {
            data: data

        };

        dispatch(addBatchSubDetails(batchSubData.data));
        closePopup();
        clearState();
    };

    const updateBatchDetails = () => {
        debugger
        seterrorMsg("");
        const error = validationFun();
        if (!error) {
            return;
        }
        var mappedSubjects = $("#lstBox2 option");
        var data = [];
        for (var i = 0; i < mappedSubjects.length; i++) {
            var subDetails = mappedSubjects[i].innerHTML
            var batchName = BatchList.filter(x => x.batchId === Number(BatchId))[0].batchName;
            data.push({
                id: SelectedSubList.filter(x => x.subjectId == Number(mappedSubjects[i].value)).length > 0 ?
                    SelectedSubList.filter(x => x.subjectId == Number(mappedSubjects[i].value))[0].id : 0,
                name: batchName + ' - ' + subDetails.trim(),
                batchId: BatchId,
                batchName: batchName,
                departmentId : Number(DepartmentId),
                subjectId: Number(mappedSubjects[i].value),
                subjectName: subDetails[0].trim(),
                createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
                createdDate: new Date(),
                modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
                modifiedDate: new Date()
            })
        }
        const batchSubData = {
            data: data
        };

        // const batchSubData = {
        //     ...batchSub,
        //     subjectData: mappedData
        // };

        console.log("UpdateBatch", batchSubData.data);
        dispatch(UpdateBatchSubDetails(batchSubData.data));
        closePopup();
        clearState();
    };

    const closePopupFun = () => {
        closePopup();
        //window.location.reload(false);
    };

    const fillBatchData = (obj) => {
        debugger;
        runFillBatchData.current = false;
        //setId(obj.id);
        setDepartmentId(obj[0].DepartmentId);
        setYear(obj[0].Year);
        setSem(obj[0].Sem);
        setBatchId(obj[0].BatchId);
        
        
        var data = [];
        obj.data.filter(x => x.IsActive).map((y) => (
            data.push({
                id: y.Id,
                subjectId: y.SubjectId,
                subjectName: y.SubjectName,
                subjectCode:y.SubjectCode,
                
            }))
        )
        selectedSubList(data);
        console.log("ListForEdit", listForEdit);
        setSelectedSubListFromDB(data);
        var deptId = JSON.parse(localStorage.getItem("BatchDetails")).filter(item => item.batchId == obj[0].BatchId)[0].departmentId;
        setDepartmentId(deptId);
        runFillBatchData.current = true;
    };

    const moveRight = (e) => {
        if (BatchId != 0) {
            seterrorMsg("");
            batchIdRef.current.style.border = "";
            var selectedOpts = $("#lstBox1 option:selected");
            if (selectedOpts.length == 0) {
                alert("Nothing to move.");
                e.preventDefault();
            }
            $("#lstBox2").append($(selectedOpts).clone());
            $(selectedOpts).remove();
            e.preventDefault();
        }
        else {
            seterrorMsg("Please select the Batch to which you are mapping subjects!");
            batchIdRef.current.focus();
            batchIdRef.current.style.border = "1px solid red";
        }
    }

    const moveAllRight = (e) => {
        if (BatchId != 0) {
            seterrorMsg("");
            batchIdRef.current.style.border = "";
            var selectedOpts = $("#lstBox1 option");
            if (selectedOpts.length == 0) {
                alert("Nothing to move.");
                e.preventDefault();
            }
            $("#lstBox2").append($(selectedOpts).clone());
            $(selectedOpts).remove();
            e.preventDefault();
        } else {
            seterrorMsg("Please select the Batch to which you are mapping subjects!");
            batchIdRef.current.focus();
            batchIdRef.current.style.border = "1px solid red";
        }
    }

    const moveLeft = (e) => {
        var selectedOpts = $("#lstBox2 option:selected");
        if (selectedOpts.length == 0) {
            alert("Nothing to move.");
            e.preventDefault();
        }
        $("#lstBox1").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    }

    const moveAllLeft = (e) => {
        var selectedOpts = $("#lstBox2 option");
        if (selectedOpts.length == 0) {
            alert("Nothing to move.");
            e.preventDefault();
        }
        $("#lstBox1").append($(selectedOpts).clone());
        $(selectedOpts).remove();
        e.preventDefault();
    }

    return (
        <div className="popup">
            <div className="popup-inner modal-content">
                <div className="col">
                    <div className="formdata">
                        <h2 className="heading">{isEdit ? "Edit Subject Mapping" : "Add New Subject Mapping"}</h2>
                        <button
                            className="btn btn-lg btnclose"
                            onClick={() => closePopupFun()}
                            type="button"
                        >
                            X
                        </button>
                        <div className="d-flex justify-content-center align-items-enter">
                            {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
                        </div>
                        <div className="row formadduser">
                        <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                                <label><span style={{ color: "red" }}>*</span>Department</label>
                                <select
                                    className="form-select form-control"
                                    onChange={dept}
                                    aria-label="Default select example"
                                    value={DepartmentId}
                                >
                                    <option value="">--Select Department--</option>
                                    {DepartmentList?.map((opt) => (
                                        <option key={opt.id} value={opt.id}>
                                            {opt.name}
                                        </option>
                                    ))}
                                </select>
                                {!DepartmentId && validate ? (
                                    <h6 style={{ color: "red" }}>{"Select Department"}</h6>
                                ) : null}
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                                <label> <span style={{ color: "red" }}>*</span>Year</label>
                                <select
                                    className="form-select form-control"
                                    onChange={yearOfEducation}
                                    aria-label="Default select example"
                                    value={Year}
                                >
                                    <option value="">--select Year--</option>
                                    <option value={1}>1st Year</option>
                                    <option value={2}>2nd Year</option>
                                    <option value={3}>3rd Year</option>
                                </select>
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                                <label><span style={{ color: "red" }}>*</span>Semester</label>
                                <select
                                    className="form-select form-control"
                                    onChange={semVal}
                                    aria-label="Default select example"
                                    value={Sem}
                                >
                                    <option value="">--Select Semester--</option>
                                    <option value="Odd">Odd</option>
                                    <option value="Even">Even</option>
                                </select>
                            </div>
                            {!Sem && validate ? (
                                <h6 style={{ color: "red" }}>{"Select semester"}</h6>
                            ) : null}
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6 form-group">
                                <label><span style={{ color: "red" }}>*</span>Batch</label>
                                <select
                                    className="form-select form-control"
                                    onChange={batchId}
                                    aria-label="Default select example"
                                    value={BatchId}
                                    ref={batchIdRef}
                                    disabled={isEdit}
                                >
                                    <option value="">--Select Batch--</option>
                                    {BatchList.filter(x=>x.departmentId==DepartmentId && x.year== Year &&x.sem==Sem)?.map((opt) => (
                                        <option value={opt.batchId}>
                                          {opt.batchName}
                                        </option>
                                    ))}
                                </select>
                                {!BatchId && validate ? (
                                    <h6 style={{ color: "red" }}>{"Select Batch"}</h6>
                                ) : null}
                            </div>
                        </div>
                        <div className="row formadduser">
                            <div className="col-xs-5 col-md-5 col-lg-5 col-sm-12 form-group subject-info-box-1">
                                <label><span style={{ color: "red" }}>*</span>Subjects</label>
                                <select multiple="multiple" id='lstBox1' className="form-control">
                                    {
                                        // isEdit ?

                                            SubjectOptions.filter(val => val.subYear==Year && !val.isBatchMapped && !listForEdit.map(sub => Number(sub.subjectId)).includes(val.id))?.map((opt) => (
                                                <option value={opt.id}>
                                                    {opt.subjectCode}-{opt.subjectName}
                                                </option>
                                            )) 
                                            // :
                                            // SubjectOptions?.filter(val => !val.isBatchMapped).map((opt) => (
                                            //     <option value={opt.id}>
                                            //         {opt.subjectName}
                                            //     </option>
                                            // ))
                                    }
                                </select>
                            </div>
                            <div className="col-xs-2 col-md-2 col-lg-2 subject-info-arrows text-center" style={{ margin: "auto" }}>
                                <input type='button' onClick={moveAllRight} id='btnAllRight' value='>>' className="btn btn-default" /><br />
                                <input type='button' onClick={moveRight} id='btnRight' value='>' className="btn btn-default" /><br />
                                <input type='button' onClick={moveLeft} id='btnLeft' value='<' className="btn btn-default" /><br />
                                <input type='button' onClick={moveAllLeft} id='btnAllLeft' value='<<' className="btn btn-default" />
                            </div>
                            <div className="col-xs-5 col-md-5 col-lg-5 col-sm-12 form-group subject-info-box-2">
                                <label><span style={{ color: "red" }}>*</span>Subjects</label>
                                <select multiple="multiple" id='lstBox2' className="form-control" ref={selectedListRef}>
                                    {listForEdit?.map((opt) => (
                                        <option key={opt.subjectId} value={opt.subjectId}>
                                             {opt.subjectCode}-{opt.subjectName}
                                        </option>
                                    ))}
                                </select>


                            </div>
                        </div>
                        &nbsp;
                        <div className="d-flex justify-content-center align-items-enter">
                            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
                        </div>
                        <p>
                            {!batchSub ? (
                                <button
                                    className="btn btn-primary btn-block form-roundedbtn"
                                    type="button"
                                    onClick={() => saveBatchDetails()}
                                >
                                    Save
                                </button>
                            ) : (
                                <button
                                    className="btn btn-lg btn-primary btn-block form-roundedbtn"
                                    type="button"
                                    onClick={() => updateBatchDetails()}
                                >
                                    Update
                                </button>
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBatchSubMappings;