//
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";


import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({ isEdit,item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  // const navigate = useNavigate();
  //state
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [Topic, setTopic] = useState("");
  const [Abstract, setAbstract] = useState("");
  const [StudentID, setStudentID] = useState([]);
  const [FacultyID, setFacultyID] = useState([]);
  const [DepartmentSemester, setDepartmentSemester] = useState("");
  const [DepartmentDesignation, setDepartmentDesignation] = useState("");
  const [Event, setEvent] = useState("");
  const [Prize, setPrize] = useState("");
  const [Venue, setVenue] = useState("");
  const [Impact, setImpact] = useState("");
  const [OrganisedBy, setOrganisedBy] = useState("");
  const [studentORFaculty, setstudentORFaculty] = useState("Student");
  const [InternalOrExternal, setInternalOrExternal] = useState("Internal");
  //const [OffLineOrOnLine, setOffLineOrOnLine] = useState("Offline");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  //const [datevalidation1, setdatevalidation] = useState(true);
  const [datevalidation, setdatevalidation] = useState(true);
  const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const [ProjectTitle, setProjectTitle] = useState("");
  const [ProjectDescription, setProjectDescription] = useState("");
  const [MentorName, setMentorName] = useState("");
  const [Mode, setMode] = useState("");
  const [ProjectCostApprox, setProjectCostApprox] = useState("");
  const [FutureScope, setFutureScope] = useState("");
  const [Type, setTypes] = useState("");
  const [DesignType, setDesignType] = useState("");
  const clearState = () => {
    setFromDate("");
    setToDate("");
    setTopic("");
    setAbstract("");
    setStudentID([]);
    setFacultyID([]);

    setEvent("");
    setPrize("");
    setVenue("");
    setImpact("");
    setOrganisedBy("");
    setstudentORFaculty("Student");
    setInternalOrExternal("Internal");
    //setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    setProjectTitle("");
    setMode("");
    setProjectDescription("");
    setMentorName("");
    setProjectCostApprox("");
    setFutureScope("");
    setTypes("");
    setDesignType("");
    console.log("reseted");
  };
  const students1 = (val) => {
    setstudentORFaculty(val);
    // if (val === "Student") {
    //       setFacultyID([]);
    //     } else {
    //       setStudentID([]);
    //     }
  };
  const setIntExtvalue = (val) => {
    setInternalOrExternal(val);
  };

  const fromDatechange = (val) => {

    setFromDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setToDate('');
    setdatevalidation(false);
  };
  const toDatechange = (val) => {
    if (val.target.value != '') {
      setFromdatevalidation(true);
    }

    else {
      setFromdatevalidation(false);
    }
    setToDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);

  };
  const onPrizeSelect = (e) => {
    setPrize(e.target.value);
  };
  const mode = (e) => {
    setMode(e.target.value);
  };
  const prototype = (e) => {
    setTypes(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  const onStudentIDChange = (data) => {
    setStudentID(data);
    setFacultyID([]);
  };

  const onFacultyIDChange = (data) => {
    setFacultyID(data);
    setStudentID([]);
  };
  const onStudentIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getStudentByName + "?studentname=" + data)
      .then((res) => {
        setstudentList(res.data);
        setfacultyList([]);
      })
      .catch(() => setstudentList([]));
  };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?facultyname=" + data)
      .then((res) => {
        setfacultyList(res.data);
        setstudentList([]);
      })
      .catch(() => setfacultyList([]));
  };
  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(2, null));
      closePopup();
      clearState();
      return;
    }
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "ProjectorModel"
    );
    console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(2, null));
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };
  const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 2,
      Topic: Topic,
      Abstract: Abstract,
      Event: Event,
      OrganisedBy: OrganisedBy,
      Venue: Venue,
      Prize: Prize,
      ProjectTitle:ProjectTitle,
      MentorName:MentorName,
      ProjectDescription:ProjectDescription,
      Mode:Mode,
      ProjectCostApprox:ProjectCostApprox == "" ? 0 : ProjectCostApprox,
      FutureScope:FutureScope,
      Type:Type,
      DesignType:DesignType,
      //Department:JSON.parse(localStorage.getItem("user")).department,
      FromDate: FromDate,//new Date(FromDate).toLocaleDateString(),
      ToDate: ToDate,//new Date(ToDate).toLocaleDateString(), 
      Impact: Impact,
      studentORFaculty: studentORFaculty,
      InternalOrExternal: InternalOrExternal,
      // OnlineOrOffline: OffLineOrOnLine,
      // blended: true,
      Attachment: "",// selectedFile,
      StudentID,
      FacultyID,
    };
    var activityData = {
      id: 0,
      activityID: 2,
      activityName: "Project or Model",
      data: JSON.stringify(data),
      filePath: "",//selectedFile
      departmentID: 0,// JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateActivity = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 2,
      Topic: Topic,
      Abstract: Abstract,
      Event: Event,
      OrganisedBy: OrganisedBy,
      Venue: Venue,
      Prize: Prize,
      ProjectTitle:ProjectTitle,
      MentorName:MentorName,
      ProjectDescription:ProjectDescription,
      Mode:Mode,
      ProjectCostApprox:ProjectCostApprox == "" ? 0 : ProjectCostApprox,
      FutureScope:FutureScope,
      Type:Type,
      DesignType:DesignType,
      //Department:JSON.parse(localStorage.getItem("user")).department,
      FromDate: FromDate,//new Date(FromDate).toLocaleDateString(),
      ToDate: ToDate,//new Date(ToDate).toLocaleDateString(), 
      Impact: Impact,
      studentORFaculty: studentORFaculty,
      InternalOrExternal: InternalOrExternal,
      //OnlineOrOffline: OffLineOrOnLine,
      //blended: true,
      Attachment: "",
      StudentID,
      FacultyID,
    };
    const activityData = {
      ...activity,
      activityID: 2,
      activityName: "ProjectorModel",
      data: JSON.stringify(data),
      //filePath: selectedFile,
    };

    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };


  const validationFun = () => {
    setvalidate(true);
    if (
      Topic &&
      FromDate &&
      ToDate &&
      (StudentID?.length > 0 || FacultyID?.length > 0) &&
      Prize &&
      Venue &&
      //Impact &&
      OrganisedBy
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "topic":
        setTopic(e.target.value);
        break;
      case "abstract":
        setAbstract(e.target.value);
        break;
      case "studentname":
        setStudentID(e.target.value);
        break;
      case "facultyname":
        setFacultyID(e.target.value);
        break;
      case "departmentsemester":
        setDepartmentSemester(e.target.value);
        break;
      case "departmentdesigantion":
        setDepartmentDesignation(e.target.value);
        break;
      case "event":
        setEvent(e.target.value);
        break;
      case "impact":
        setImpact(e.target.value);
        break;
      case "organisedby":
        setOrganisedBy(e.target.value);
        break;
      case "venue":

        setVenue(e.target.value);
         break;
      case "projecttitle":
        setProjectTitle(e.target.value);
        break;
      case "mentorname":
        setMentorName(e.target.value);
        break;
      case "projectdescription":
        setProjectDescription(e.target.value);
        break;
      /*case "mode":
        setMode(e.target.value);
        break;*/
      case "designtype":
        setDesignType(e.target.value);
        break;
      case "estimationcost":
        if (/^[0-9.]*$/.test(e.target.value) === false) {
          //error message  
        }
        else{
        setProjectCostApprox(e.target.value);
        }
        break;
      case "scope":
        setFutureScope(e.target.value);
        break;

    }
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    console.log(AllFiles);
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    runFillActivityData.current = false;
    const obj = data;
    const objData = obj.data;
    console.log(obj);
    setFromDate(objData.FromDate);
    setToDate(objData.ToDate);
    setTopic(objData.Topic);
    setAbstract(objData.Abstract);
    setStudentID(objData.StudentID || []);
    setFacultyID(objData.FacultyID || []);
    setEvent(objData.Event);
    setPrize(objData.Prize);
    setVenue(objData.Venue);
    setImpact(objData.Impact);
    setOrganisedBy(objData.OrganisedBy);
    setstudentORFaculty(objData.studentORFaculty || "Student");
    setInternalOrExternal(objData.InternalOrExternal);
    // setOffLineOrOnLine(objData.OnlineOrOffline);
    setProjectTitle(objData.ProjectTitle);
    setMentorName(objData.MentorName);
    setProjectDescription(objData.ProjectDescription);
    setMode(objData.Mode);
    setProjectCostApprox(objData.ProjectCostApprox);
    setFutureScope(objData.FutureScope);
    setTypes(objData.Type);
    setDesignType(objData.DesignType);
    setfilesList(obj.files);
    setCreatedBy(obj.createdDate);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="students"
                    value="2"
                    checked={studentORFaculty === "Student"}
                    onChange={() => students1("Student")}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Student
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="faculty"
                    value="3"
                    checked={studentORFaculty === "Faculty"}
                    onChange={() => students1("Faculty")}
                  />
                  <label className="form-check-label checkposition">
                    Faculty
                  </label>
                </div>
              </div>
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio3"
                    value="4"
                    checked={InternalOrExternal === "Internal"}
                    onChange={() => setIntExtvalue("Internal")}
                  />
                  <label className="form-check-label checkposition">
                    Internal
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio4"
                    value="5"
                    checked={InternalOrExternal === "External"}
                    onChange={() => setIntExtvalue("External")}
                  />
                  <label className="form-check-label checkposition">
                    External
                  </label>
                </div>
              </div>

            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>From Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={fromDatechange}
                  disabled={Fromdatevalidation1}
                />
                {!FromDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>To Date</label>
                <input
                  className="form-control"
                  type="date"

                  disabled={datevalidation}
                  value={dayjs(ToDate).format("YYYY-MM-DD")}

                  min={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={toDatechange}
                />
                {!ToDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select To Date"}</h6>
                ) : null}
              </div>

              {studentORFaculty === "Student" && (
                <div
                  id="students2"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Student ID</label>
                  <SearchBox
                    options={studentList}
                    onChangeList={(data) => onStudentIDChange(data)}
                    onInputChange={(e) => { onStudentIDInputChange(e); }}
                    selectedOptions={StudentID || []}
                    //reset={studentORFaculty}
                    type="student"
                  />
                  {!StudentID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}

              {studentORFaculty === "Faculty" && (
                <div
                  id="students3"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Faculty ID</label>

                  <SearchBox
                    options={facultyList}
                    onChangeList={(data) => { onFacultyIDChange(data); }}
                    onInputChange={(e) => onFacultyIDInputChange(e)}
                    selectedOptions={FacultyID || []}
                    //reset={studentORFaculty}
                    type="faculty"
                  />
                  {!FacultyID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}             
              {/* <div 
                id="students4"
                className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group tt"
              >
                <label>Department, Semister</label>
                <input
                  className="form-control"
                  type="text"
                  id="departmentsemester"
                  onChange={onTextValueChange}
                />
              </div> */}
              {/* <div
                id="students5"
                className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt"
              >
                <label>Department, Designation</label>
                <input
                  className="form-control"
                  type="text"
                  id="departmentdesigantion"
                  onChange={onTextValueChange}
                />
              </div> */}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Project Title</label>
                <input
                  className="form-control"
                  type="text"
                  id="projecttitle"
                  value={ProjectTitle}
                  onChange={onTextValueChange}
                  maxLength={150}
                />                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Topic</label>
                <input
                  className="form-control"
                  type="text"
                  id="topic"
                  value={Topic}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!Topic && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
                <label>Mentor Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="mentorname"
                  value={MentorName}
                  onChange={onTextValueChange}
                  maxLength={150}
                />                
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group">
                <label>Abstract</label>
                <textarea
                  className="form-control clsabstract"

                  id="abstract"
                  value={Abstract}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={1000}
                />
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group" >
                <label>Description of the Project</label>
                <div>
                  <textarea

                    className="form-control clsabstract"
                    id="projectdescription" value={ProjectDescription}
                    onChange={onTextValueChange}
                    rows={5}
                    //cols={85}
                    maxLength={200}
                  />
                </div>
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Event</label>
                <input
                  className="form-control"
                  type="text"
                  id="event"
                  value={Event}
                  onChange={onTextValueChange}
                  maxLength={150}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Mode</label>
                {/*<input
                  className="form-control"
                  type="text"
                  id="mode"
                  value={Mode}
                  onChange={onTextValueChange}
                  maxLength={150}
                />*/}
                  <select
                  className="form-select form-control"
                  onChange={mode}
                  aria-label="Default select example"
                  value={Mode}
                >
                  <option value="">Select Mode</option>
                  <option value="Creativity">Creativity</option>
                  <option value="Innovation">Innovation</option>
                  <option value="Upgradation">Upgradation</option>
                  <option value="Special Attachment">Special Attachment</option>
                  

                </select>
              </div>
              
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Type</label>
               {/* <input
                  className="form-control"
                  type="text"
                  id="prototype"
                  value={Types}
                  onChange={onTextValueChange}
                  maxLength={150}
              />*/}
                  <select
                  className="form-select form-control"
                  onChange={prototype}
                  aria-label="Default select example"
                  value={Type}
                >
                  <option value="">Select Type</option>
                  <option value="Prototype">Prototype</option>
                  <option value="Model">Model</option>
                  <option value="Application Oriented">Application Oriented</option>
                                  

                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Design Type</label>
                <input
                  className="form-control"
                  type="text"
                  id="designtype"
                  value={DesignType}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Organised By</label>
                <input
                  className="form-control"
                  type="text"
                  id="organisedby"
                  value={OrganisedBy}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                {!OrganisedBy && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Venue</label>
                <input
                  className="form-control"
                  type="text"
                  id="venue"
                  value={Venue}
                  onChange={onTextValueChange}
                  maxLength={300}
                />
                {!Venue && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Prize</label>
                <select
                  className="form-select form-control"
                  onChange={onPrizeSelect}
                  aria-label="Default select example"
                  value={Prize}
                >
                  <option value="">Select Prize</option>
                  <option value="First">First</option>
                  <option value="Second">Second</option>
                  <option value="Third">Third</option>
                  <option value="Fourth">Fourth</option>
                  <option value="Consolation">Consolation</option>
                  <option value="Participation">Participation</option>
                  <option value="Special Prize">Special Prize</option>

                </select>
                {!Prize && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Cost of the Project (Approx)</label>
                <input
                  className="form-control"
                  type="text"
                  id="estimationcost"
                  value={ProjectCostApprox}
                  onChange={onTextValueChange}
                  maxLength={10}
                />                
              </div>
             
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group" >
                <label>Impact</label>
                <div>
                  <textarea

                    className="form-control"
                    id="impact" value={Impact}
                    onChange={onTextValueChange}
                    rows={25}
                    cols={85}
                    maxLength={300}
                  />
                </div>
              </div>
             
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
                <label>Further Scope of the Project </label>
                <textarea
                  className="form-control clsabstract"
                  type="text"
                  id="scope"
                  value={FutureScope}
                  onChange={onTextValueChange}
                  rows={5}
                  maxLength={200}
                />
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/*&nbsp;*/}
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>


            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
