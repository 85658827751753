import { Events } from "ag-grid-community";

export const options = [
  "StudentOrFaculty",
  "InternalOrExternal",
  "OffLineOnLineOrBlended",  
  "FromDate",
  "ToDate",
  "Topic",
  "Event",
  "Abstract",
  "Impact",
  "Department",
  "OrganisedBy",
  "Venue",
  "Prize",
  
];

export const projectModelOptions = [
 
  "Topic",
  "Abstract",
  "Event",
  "OrganisedBy",
  "Venue",
  "ProjectTitle",
  "MentorName",
  "ProjectDescription",
  "Mode",
  "ProjectCostApprox",
  "FutureScope",
  "Type",
  "DesignType",
  "Prize",
  "FromDate",
  "ToDate",
  //"Department",
  "Impact",
  "StudentOrFaculty",
  "InternalOrExternal",
 
];

export const guestLectureOptions = [
  
  "Topic",
  "ResourcePerson",
  "ResourcePersonDetails",
  "OrganisedBy",
  "ParticipantsFrom",
  "Department",
  "FromDate",
  "Time",
  "ExternalParticipants",
  "NParticipants",
  "NParticipantsBoys",
  "NParticipantsGirls",
  "OnlineOrOffline",
  
];

export const implantTrainingOptions = [
   "FromDate",
  "ToDate",
  "Impact",
  "Department",
  "OrganisationName",
  "OrganisationAddress",
  "StudentOrFaculty",
  "Remarks",
 
];

export const industrailVisitOptions = [
   "NParticipants",
  "FromDate",
  "ToDate",
  "Visit",
  "OrganisationName",
  "Department",
  "Remarks",
  "DateToVisit",
  "OrganisationAddress",
  "Impact",
  "StudentOrFaculty",

];

export const sportsAndGamesOptions = [
  "Event",
  "OrganisedBy",
  "Venue",
  "Prize",
  "FromDate",
  "ToDate",
  // "Department",
  "StudentOrFaculty",
  "InternalOrExternal",
  "TeamName",
 
];

export const NCCOptions = [
   "Event",
  "Venue",
  "OrganisedBy",
  "TeamName",
  "FromDate",
  "ToDate",
  // "Department",
  "Prize",
  "StudentOrFaculty",
  "InternalOrExternal",
 
];

export const NSSOptions = [
  "TeamName",
  "Event",
  "OrganisedBy",
  "Venue",
  "Prize",
  "FromDate",
  "ToDate",
  // "Department",
  "Impact",
  "StudentOrFaculty",
  "InternalOrExternal",
 
];
export const FacultyDevelopmentOptions = [
   "FDPSponsoredBy",
  "Event",
  "OrganisedBy",
  "TitleOfTheProgram",
  "TrainingAttendedBy",
  "Venue",
  "SanctionedAmount",
  "ParticipantsType",
  "NParticipants",
  "CoOrdinator",
  // "Department",
  "CoCoordinator",
  "FromDate",
  "ToDate",
  "Impact",
  "OnlineOrOffline",
  "InternalOrExternal",

];
export const JournalOptions = [
 
  "JournalIssueDate",
  "JournalPaperTitle",
  "JournalName",
  "JournalPublisher",
  "JournalType",
  "JournalAbstract",
  "TotalJournalPages",
  "BookIssueDate",
  "BookName",
  "BookPublishingOn",
  "BookAuthorName",
  "BookCoAuthor",
  "BookPublisherName",
  "BookVolumeNo",
  "BookType",
  "BookPublicationNumber",
  "BookRemarks",
  "Department",
  "StudentOrFaculty",
  
];

export const WomenDevelopmentOptions = [
  "Event",
  "OrganisedBy",
  "Venue",
  "FromDate",
  "ToDate",
  //"Department",
  "Impact",
  "ParticipantsFrom",
  "OnlineOrOffline",  
  "InternalOrExternal",
  "StudentOrFaculty",
];
export const PatentOptions = [
   "PatentTitle",
  "Abstract",
  "Status",
  "PatentType",
  "PatentRegistrationDetails",
  "Mentor",

  // "Department",
  "StudentOrFaculty",
  "NationalOrInternational",
 
];
export const SymposiumOptions = [
  "Abstract",
  "Event",
  "OrganisedBy",
  "Venue",
  "FromDate",
  "ToDate",
  //"Department",
  "Impact",
  "StudentOrFaculty",
  "InternalOrExternal",
  "ThemeOrSubTheme",
  
];
export const PlacementOptions = [
  "FromDate",
  "CompanyName",
  "CompanyAddress",
  "CompanyType",
  "Department",
  "NStudentAttended",
  "NStudentAttendedBoys",
  "NStudentAttendedGirls",
  "NStudentPlaced",
  "NStudentPlacedBoys",
  "NStudentPlacedGirls",
  "OffcampusOrOncampus",
  "SalaryPerAnnum",
];
export const GrantsOptions = [
  "SanctionedYear",
  "SanctionedBy",
  "GrantGivenBy",
  "SanctionedOrderNo",
  "ProjectName",
  "Department",
  "SanctionedGrantAmount",
  "RecurringAmount",
  "NonRecurringAmount",
  
  ];
export const AlumniEventOptions = [
  "Event",
  "OrganisedBy",
  "Venue",
  "EventDate",
  "Time",
  "Impact",
  //"Remarks",
  "ResourcePersonOrActed"
  //"Department",
  //"Nonrecurringamount",
  
 ];

export const MiscellaneousOptions = [
  "Event",
  "Venue",
  //"OrganisedBy",
  //"TeamName",
  "FromDate",
  "ToDate",
  "Department",
  "Impact",
  "Remarks",
  "ActivityName",
  "StudentOrFaculty",
  "InternalOrExternal",
  "OnlineOrOffline",
  
];
export const EventsOptions = [
  "EventDate",
  "Topic",
  "TypeOfEvent",
  //"TeamName",
  "EventName",
  "nParticipants",
  "NameOfTheChiefGuest",
  "Impact"
  
];
export const AwardsOptions = [
  "Event",
  "Venue",
  //"OrganisedBy",
  "DateOfAwardCeremony",
  "NameOfThePrincipal",
  "NameOfTheInstitution",
  "NameOfTheAwards",
  "DateOfAnnouncement",
  "DescriptionOfAwards",
  "AwardBestowedTo",
  "NameOfOrganisers",
  "Year"
  
];
export const ConsultantOptions = [
  "Organisation",
  "ProjectName",
  "ProblemStatement",
  "MethodologyOpt",
  "StandardsIfAny",
  "ConsultancyFees",
  "SupportingStaff",
  "ProjectGivenDate",
  "ProjectCompletionDate",
  
  "InternalOrExternal",
  "OnlineOrOffline",

];
export const SemesterOptions = [
  "Department",
  "NStudentAppeared",
  "NStudentPassed",
  "NBoysAppeared",
  "NGirlsAppeared",
  "NBoysPassed",
  "NGirlsPassed",
  "SemMonth",
  "Year",
  "Sem",
  "PassPercentage",
  "Remarks"
];
export const ExtensionServicesOptions = [
 
  "Topic",
  "FromDate",
  "ToDate",
  "CourseFees",
  "Participants",
  //"TotalParticipants",
  "Department",
  "NParticipants",
  "NParticipantsBoys",
  "NParticipantsGirls",
  "OrganisedBy",
  "ResourcePersonDetails",
  "Remarks",
  "Impact",
  "TotalRevenueGenerated", 
];
export const MOUsOptions = [
  "NameOfTheIndustryOrOrganization",
  "MOUEndDate",
  "MOUStartDate",
  "NatureOfServicesByTheInstitutionOrOrganization",
  "MOUDate", 
  "InstitutionOrProgramme"
];
export const AdvertisementsOptions = [
 "NameOfNewspaper",
 "AdvertisementType",
 "DescriptionOfAdmissions",
 "DescriptionofWanted",
 "DescriptionOfEvent", 
 "NewspaperEdition",
 "NewspaperPage",
 "DateOfPublished", 
  "InstitutionProgrammesOrCIICP", 
];
export const PressReportsOptions = [
 "NameOfNewspaper",
 "PressReportsType",
 "DescriptionOfAward",
 "DescriptionOfFunction",
 "DescriptionOfEvent",
 "DescriptionOfOthers", 
 "NewspaperEdition",
 "NewspaperPage",
 "DateOfPublished",
  
  "InstitutionProgrammesOrCIICP", 
];
export const UpcomingEventsOptions = [
  "EventDate",
  "Topic",
  "TypeOfEvent",
  //"TeamName",
  "EventName",
  "nParticipants",
  "NameOfTheChiefGuest",
  "NameOfTheChiefGuest2",
  "NameOfTheChiefGuest3",
  "NameOfTheChiefGuest4",
  "NameOfTheChiefGuest5", 
  
];
export const MainOptionsList = {
  1: options,
  2: projectModelOptions,
  3: guestLectureOptions,
  10: implantTrainingOptions,
  12: industrailVisitOptions,
  13: sportsAndGamesOptions,
  14: NCCOptions,
  15: NSSOptions,
  16: FacultyDevelopmentOptions,
  18: ConsultantOptions,
  20: JournalOptions,
  23: WomenDevelopmentOptions,
  25: PatentOptions,
  26: SemesterOptions,
  27: SymposiumOptions,
  29: PlacementOptions,
  31: ExtensionServicesOptions,
  32: GrantsOptions,
  33: AlumniEventOptions,
  34: MiscellaneousOptions,
  37:AwardsOptions,
  39:EventsOptions,
  41:MOUsOptions,
  45:AdvertisementsOptions,
  46:PressReportsOptions,
  48:UpcomingEventsOptions

};

export const activity_opts = [
  {
    label: "Paper Presentation",
    value: "1",
  },
  {
    label: "ProjectorModel",
    value: "2",
  },
  {
    label: "Guest Lecture",
    value: "3",
  },
  {
    label: "Inplant Training/Intership",
    value: "10",
  },
  {
    label: "IndustrialVisits",
    value: "12",
  },
  {
    label: "SportsAndGames",
    value: "13",
  },
  {
    label: "NCC",
    value: "14",
  },
  {
    label: "NSS",
    value: "15",
  },
  {
    label: "FacultyDevelopment",
    value: "16",
  },
  {
    label: "Consultancy",
    value: "18",
  },
  {
    label: "Journal/BookPublication",
    value: "20",
  },
  {
    label: "WomenInDevelopment",
    value: "23",
  },
  {
    label: "PatentDetails",
    value: "25",
  },
  {
    label: "SemesterResult",
    value: "26",
  },
  {
    label: "SymposiumAndExpos",
    value: "27",
  },
  {
    label: "Placement",
    value: "29",
  },
  {
    label: "ExtensionServices",
    value: "31",
  },
  {
    label: "Grants",
    value: "32",
  },
  {
    label: "AlumniEvent",
    value: "33",
  },
  {
    label: "Miscellaneous",
    value: "34",
  },
  {
    label:"Awards",
    value:"37",
  },
  {
    label:"Events",
    value:"39"
  },
  {
    label:"MOUs",
    value:"41"
  },
  {
    label:"Advertisements",
    value:"45"
  },
  {
    label:"PressReports",
    value:"46"
  },{
    label: "UpcomingEvents",
    value: "48",
  },
];
