import React from "react";
import TableComp from "./table";
import { TableStyles } from "./tableStyles";
//let columns;
const AdvanceTable = (props) => {
 
  const makeData = (count) => {
    let data = [];
    console.log("Props inside AdvanceTableIndex", props.data);
  
    for (let i = 0; i < props.data.length; i++) {
      const obj = props.data[i];
      data.push({ ...obj, data: obj });
    }
    return data;
  
  };
 
  const columns1 = React.useMemo(
    () => [
      {
        Header: "Ref No",
        accessor: "refNo",
      },
      {
        Header: "Form Date",
        accessor: "formDate",
      },
      {
        Header: "Name of The Programme",
        accessor: "departmentName",
      },
      {
        Header: "Form Status",
        accessor: "currentFileQueueStatus",
      },
            {
        Header: "Action",
        accessor: "",
       
      },    
    
    ],
    []
  );
 
  const columns2 = React.useMemo(
  () => [
    {
      Header: "Ref No",
      accessor: "refNo",
    },
    {
      Header: "Form Date",
      accessor: "formDate",
    },
    {
      Header: "Name of The Programme",
      accessor: "departmentName",
    },
    {
      Header: "Form Status",
      accessor: "currentFileQueueStatus",
    },
          {
      Header: "Action",
      accessor: "",
     
    },
    //(JSON.parse(localStorage.getItem("user")).indentRoleName == "Principal" ?           
    {
      
      Header: "Send Message",
      accessor: "",
      
    }
   // :""),
  
  ],
  []
);
  
  const data = React.useMemo(() => makeData(50), []);

  return (
    <TableStyles>
     {/* <TableComp columns={JSON.parse(localStorage.getItem("user")).indentRoleName == "Principal"? columns2:columns1} data={data} {...props} />*/}
     <TableComp columns={JSON.parse(localStorage.getItem("user")).indentRoleName == "Maker"? columns1:columns2} data={data} {...props} />
    </TableStyles>
  );
};

export default AdvanceTable;
