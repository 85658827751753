import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";

import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({ isEdit,item, closePopup, activity }) => {
  // console.log(activity);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  //state
  const [DateOfPublished, setDateOfPublished] = useState("");
  const [NameOfNewspaper, setNameOfNewspaper] = useState("");
  const [DescriptionOfAdmissions, setDescriptionOfAdmissions] = useState("");
  const [DescriptionofWanted, setDescriptionofWanted] = useState("");
  const [DescriptionOfEvent, setDescriptionOfEvent] = useState("");
  const [DepartmentSemester, setDepartmentSemester] = useState("");
  const [DepartmentDesignation, setDepartmentDesignation] = useState("");
  const [AdvertisementType, setAdvertisementTypes] = useState("");
  const [NewspaperPage, setNewspaperPage] = useState("");
  const [NewspaperEdition, setNewspaperEdition] = useState("");
  const [InstitutionProgrammesOrCIICP, setInstitutionProgrammesOrCIICP] = useState("Institution");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [datevalidation1, setdatevalidation] = useState(true);
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const clearState = () => {
    setDateOfPublished("");
    setDescriptionOfAdmissions("");
    setDescriptionofWanted("");
    setDescriptionOfEvent("");
    setNameOfNewspaper("");

    setDepartmentSemester("");
    setDepartmentDesignation("");
    setAdvertisementTypes("");
    setNewspaperPage("");
    setNewspaperEdition("")
    setInstitutionProgrammesOrCIICP("Institution");
    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };


  const setOnOFFvalue = (val) => {
    setInstitutionProgrammesOrCIICP(val);
  };
  const fromDatechange = (val) => {
    setDateOfPublished(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    //setToDate("");
    setdatevalidation(false);
  };

  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };



  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(
        fetchActivities(
          45, null
          //JSON.parse(localStorage.getItem("user")).departmentId
        )
      );
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Advertisements"
    );
    console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(
        fetchActivities(
          45, null
          //JSON.parse(localStorage.getItem("user")).departmentId
        )
      );
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initiateActivity = async () => {
    // debugger;
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 45,
      NameOfNewspaper: NameOfNewspaper,
      DescriptionOfAdmissions: DescriptionOfAdmissions,
      DescriptionofWanted: DescriptionofWanted,
      DescriptionOfEvent: DescriptionOfEvent,
      AdvertisementType: AdvertisementType,
      NewspaperEdition: NewspaperEdition,
      NewspaperPage: NewspaperPage,
      DateOfPublished: DateOfPublished,
      InstitutionProgrammesOrCIICP: InstitutionProgrammesOrCIICP,
      Attachment: "",

    };
    var activityData = {
      id: 0,
      activityID: 45,
      activityName: "Advertisements",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: 0,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateActivity = async () => {
    // debugger;
    // console.log("inside activity", activity);
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 45,
      NameOfNewspaper: NameOfNewspaper,
      DescriptionOfAdmissions: DescriptionOfAdmissions,
      DescriptionofWanted: DescriptionofWanted,
      DescriptionOfEvent: DescriptionOfEvent,
      AdvertisementType: AdvertisementType,
      NewspaperEdition: NewspaperEdition,
      NewspaperPage: NewspaperPage,
      DateOfPublished: DateOfPublished,
      InstitutionProgrammesOrCIICP: InstitutionProgrammesOrCIICP,
      Attachment: "",

    };
    const activityData = {
      ...activity,
      activityID: 45,
      activityName: "Advertisements",
      data: JSON.stringify(data),
    };

    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      //NameOfNewspaper &&
      DateOfPublished &&
      AdvertisementType &&
      (DescriptionOfAdmissions || DescriptionofWanted || DescriptionOfEvent)
      // NewspaperPage &&
      //NewspaperEdition
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "nameOfNewspaper":
        setNameOfNewspaper(e.target.value);
        break;
      case "descriptionOfAdmissions":
        setDescriptionOfAdmissions(e.target.value);


        break;
      case "descriptionofWanted":
        setDescriptionofWanted(e.target.value);

        break;
      case "descriptionOfEvent":
        setDescriptionOfEvent(e.target.value);
        break;

      case "departmentsemester":
        setDepartmentSemester(e.target.value);
        break;
      case "departmentdesigantion":
        setDepartmentDesignation(e.target.value);
        break;
      case "descriptionOfAdvertisement":
        setAdvertisementTypes(e.target.value);
        setDescriptionOfAdmissions("");
        setDescriptionofWanted("");
        setDescriptionOfEvent("");
        break;

      case "nameOfEdition":
        setNewspaperEdition(e.target.value);
        break;
      case "nameOfPage":

        setNewspaperPage(e.target.value);

    }
  };
  // On file select (from the pop up)
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    console.log(AllFiles);
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    debugger;
    runFillActivityData.current = false;
    const obj = data;
    const objData = obj.data;
    // console.log(obj);
    setDateOfPublished(objData.DateOfPublished);
    setDescriptionOfAdmissions(objData.DescriptionOfAdmissions);
    setDescriptionofWanted(objData.DescriptionofWanted);
    setDescriptionOfEvent(objData.DescriptionOfEvent);
    setNameOfNewspaper(objData.NameOfNewspaper);
    setAdvertisementTypes(objData.AdvertisementType);
    setNewspaperPage(objData.NewspaperPage);
    setNewspaperEdition(objData.NewspaperEdition);
    setInstitutionProgrammesOrCIICP(objData.InstitutionProgrammesOrCIICP);
    setCreatedBy(obj.createdDate);
    //setselectedFile(obj.filePath);
    setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);

      //AsyncGet(API.getActivityById + "?id=" + activity.id)
      //  .then((res) => {
      //  console.log(res, "response");
      //  })
      //catch((err) => {
      //    console.log(err, "error");
      //});
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">

              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio5"
                    value="option3"
                    checked={InstitutionProgrammesOrCIICP === "Institution"}
                    onChange={() => setOnOFFvalue("Institution")}
                  />
                  <label className="form-check-label checkposition">
                    Institution
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio6"
                    value="option3"
                    checked={InstitutionProgrammesOrCIICP === "Programmes"}
                    onChange={() => setOnOFFvalue("Programmes")}
                  />
                  <label className="form-check-label checkposition">
                    Programmes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio6"
                    value="option3"
                    checked={InstitutionProgrammesOrCIICP === "CIICP"}
                    onChange={() => setOnOFFvalue("CIICP")}
                  />
                  <label className="form-check-label checkposition">
                    CIICP
                  </label>
                </div>
              </div>
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Date of Published
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(DateOfPublished).format("YYYY-MM-DD")}
                  disabled={Fromdatevalidation1}
                  onChange={fromDatechange}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!DateOfPublished && validate ? (
                  <h6 style={{ color: "red" }}>{"Select the Date"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  Name Of Newspaper
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="nameOfNewspaper"
                  value={NameOfNewspaper}
                  onChange={onTextValueChange}
                  maxLength={250}
                />

              </div>



              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> Newspaper Edition</label>
                <input
                  className="form-control"
                  type="text"
                  id="nameOfEdition"
                  value={NewspaperEdition}
                  onChange={onTextValueChange}
                  maxLength={250}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  Newspaper Page
                </label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nameOfPage"
                  value={NewspaperPage}
                  onChange={onTextValueChange}
                  maxLength={4}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Type of Advertisement
                </label>

                <select
                  className="form-control"
                  //type="text"

                  aria-label="Default select example"
                  id="descriptionOfAdvertisement"
                  value={AdvertisementType}
                  onChange={onTextValueChange}
                >
                  <option value="">Select AdvertisementType</option>
                  <option value="Admissions">Admissions</option>
                  <option value="Wanted">Wanted </option>
                  <option value="Event">Event</option>

                </select>
                {!AdvertisementType && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              {AdvertisementType === "Admissions" && (
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                  <label>
                    <span style={{ color: "red" }}>*</span> Description of Admissions
                  </label>
                  <textarea
                    className="form-control clsabstract"
                    id="descriptionOfAdmissions"
                    value={DescriptionOfAdmissions}
                    onChange={onTextValueChange}
                    rows="5"
                    maxLength={1000}
                  />

                  {!DescriptionOfAdmissions && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>

              )}
              {AdvertisementType === "Wanted" && (
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                  <label>
                    <span style={{ color: "red" }}>*</span>  Description of Wanted
                  </label>
                  <textarea
                    className="form-control clsabstract"
                    id="descriptionofWanted"
                    value={DescriptionofWanted}
                    onChange={onTextValueChange}
                    rows="5"
                    maxLength={1000}
                  />


                  {!DescriptionofWanted && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}
              {AdvertisementType === "Event" && (
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                  <label>
                    <span style={{ color: "red" }}>*</span> Description of Event
                  </label>
                  <textarea
                    className="form-control clsabstract"
                    id="descriptionOfEvent"
                    value={DescriptionOfEvent}
                    onChange={onTextValueChange}
                    rows="5"
                    maxLength={1000}
                  />

                  {!DescriptionOfEvent && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Advertisement Clippings</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* &nbsp;*/}
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            {/*<button
              className="btn btn-secondary btn-block "
              type="button"
              onClick={onFileUpload}
            >
              upload
            </button>*/}
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
