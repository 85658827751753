import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const admissionSlice = createSlice({
  name: "admissionList",
  initialState: {
    loading: false,
    error: false,
    admissionList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    admissionDetails: [],
    submitAdmissionSuccess: "",
    saveAdmissionDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getAdmission: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.admissionList = payload;
    },
    saveAdmission: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveAdmissionDataSuccess = payload;
    },
    submitAdmission: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getAdmission,
  submitAdmission,
  setError,
  saveAdmission,
} = admissionSlice.actions;
export const admissionSelector = (state) => state.admissions || [];
export default admissionSlice.reducer;

export const fetchAdmissions = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getAdmissions)
    .then((response) => {
      console.log("response,", response);
      dispatch(getAdmission(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addAdmissionDetails =  async (data, dispatch)=> {
  try {
  dispatch(setLoading());
  const response = await AsyncPost(API.saveAdmissions, data);
   
      dispatch(setLoading());
      dispatch(saveAdmission(response.data));
      dispatch(fetchAdmissions);
      
 return response.data;
     // dispatch(fetchFaculties());
    }
    catch(error)  {
      console.log("failure of add faculty");
      dispatch(saveAdmission(""));
      dispatch(setError(true));
      dispatch(setLoading());
}
  
};
export const addAdmissionDetails1 = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveAdmissions, data)
    .then((response) => {debugger
      dispatch(setLoading());
      dispatch(saveAdmission(response));
      dispatch(fetchAdmissions());
    })
    .catch((er) => {debugger
      console.log("failure of add admission");
      dispatch(saveAdmission(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateAdmissionDetails = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.updateAdmissionData, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update admission");
      dispatch(fetchAdmissions());
    })
    .catch((er) => {
      console.log("failure of add admission");
      dispatch(saveAdmission(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteAdmission = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteAdmissionData + "?Id=" + data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete admission");
      dispatch(fetchAdmissions());
    })
    .catch((er) => {
      console.log("failure of add admission");
      dispatch(saveAdmission(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
