import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";

import {
  fetchRoleMappingDetails,
  UpdateRoleMappingDetails,
  addRoleActivityDetails,
} from "../../../app/redux/slices/roleActivityMappingSlice";
import { fetchRoles, roleSelector } from "../../../app/redux/slices/roleSlice";

const AddRoleActivityMapping = ({ isEdit, item, closePopup, roleActivity }) => {
  const { roleList } = useSelector(roleSelector);
  const [listOfRoles, setRoles] = useState(roleList || []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!roleActivity) {
      return;
    }
    runFillRoleActivityData.current = true;
    if (runFillRoleActivityData.current) {
      fillRoleActivityData(roleActivity);
      debugger;
    }
  }, []);

  useEffect(() => {
    getRoleData();
  }, []);

  const getRoleData = () => {
    dispatch(fetchRoles());
  };

  useEffect(() => {
    if (!roleList) {
      return;
    }
    setRoles(roleList);
  }, [roleList]);
  console.log(roleList);

  const [Id, setId] = useState();
  const [RoleName, setRoleName] = useState("");
  const [RoleId, setRoleId] = useState();
  const [PaperPresentation, setPaperPresentation] = useState(false);
  const [ProjectOrModel, setProjectOrModel] = useState(false);
  const [GuestLectures, setGuestLectures] = useState(false);
  const [ImplantTrainingOrInternship, setImplantTrainingOrInternship] =
    useState(false);
  const [IndustrialVisits, setIndustrialVisits] = useState(false);
  const [SportsAndGames, setSportsAndGames] = useState(false);
  const [NCC, setNCC] = useState(false);
  const [NSS, setNSS] = useState(false);
  const [FacultyDevelopment, setFacultyDevelopment] = useState(false);
  const [Consultancy, setConsultancy] = useState(false);
  const [WomenInDevelopment, setWomenInDevelopment] = useState(false);
  const [JournalOrBookpublication, setJournalOrBookpublication] =
    useState(false);
  const [PatentDetails, setPatentDetails] = useState(false);
  const [SemesterResult, setSemesterResult] = useState(false);
  const [SymposiumAndExpo, setSymposiumAndExpo] = useState(false);
  const [Placement, setPlacement] = useState(false);
  const [ExtensionServices, setExtensionServices] = useState(false);
  const [Grants, setGrants] = useState(false);
  const [AlumniEvent, setAlumniEvent] = useState(false);
  const [Miscellaneous, setMiscellaneous] = useState(false);
  const [Awards, setAwards] = useState(false);
  const [Events, setEvents] = useState(false);
  const [UpcomingEvents, setUpcomingEvents] = useState(false);

  const [MOUs, setMOUs] = useState(false);   
  const [Advertisements, setAdvertisements] = useState(false);
  const [PressReports, setPressReports] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);

  const runFillRoleActivityData = useRef();

  const closePopupFun = () => {
    closePopup();
    //window.location.reload(false);
  };

  const fillRoleActivityData = (obj) => {
    runFillRoleActivityData.current = false;
    // console.log(obj);
    setId(obj.data.id);
    setRoleId(obj.data.roleId);
    setRoleName(obj.data.roleName);
    setPaperPresentation(obj.data.paperPresentation);
    setProjectOrModel(obj.data.projectOrModel);
    setGuestLectures(obj.data.guestLectures);
    setImplantTrainingOrInternship(obj.data.implantTrainingOrInternship);
    setIndustrialVisits(obj.data.industrialVisits);
    setSportsAndGames(obj.data.sportsAndGames);
    setNCC(obj.data.ncc);
    setNSS(obj.data.nss);
    setFacultyDevelopment(obj.data.facultyDevelopment);
    setWomenInDevelopment(obj.data.womenInDevelopment);
    setJournalOrBookpublication(obj.data.journalOrBookpublication);
    setPatentDetails(obj.data.patentDetails);
    setSemesterResult(obj.data.semesterResult);
    setSymposiumAndExpo(obj.data.symposiumAndExpo);
    setPlacement(obj.data.placement);
    setExtensionServices(obj.data.extensionServices);
    setGrants(obj.data.grants);
    setAlumniEvent(obj.data.alumniEvent);
    setMiscellaneous(obj.data.miscellaneous);
    setConsultancy(obj.data.consultancy);
   setAwards(obj.data.awards);
   setEvents(obj.data.events);
   setMOUs(obj.data.moUs);
   setAdvertisements(obj.data.advertisements);
   setPressReports(obj.data.pressReports);
   setUpcomingEvents(obj.data.upcomingEvents);
    runFillRoleActivityData.current = true;
  };

  const validationFun = () => {
    setvalidate(true);
    if (!RoleId) {
      setvalidate(false);
      seterrorMsg("Select Role to map activity");
      return false;
    } else {
      return true;
    }
  };

  const saveRoleActivityDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var roleActivityMappingData = {
      Id: 0,
      RoleId: Number(RoleId),
      PaperPresentation: PaperPresentation,
      ProjectOrModel: ProjectOrModel,
      GuestLectures: GuestLectures,
      ImplantTrainingOrInternship: ImplantTrainingOrInternship,
      IndustrialVisits: IndustrialVisits,
      SportsAndGames: SportsAndGames,
      NCC: NCC,
      NSS: NSS,
      FacultyDevelopment: FacultyDevelopment,
      WomenInDevelopment: WomenInDevelopment,
      JournalOrBookpublication: JournalOrBookpublication,
      PatentDetails: PatentDetails,
      SemesterResult: SemesterResult,
      SymposiumAndExpo: SymposiumAndExpo,
      SymposiumAndExpo: SymposiumAndExpo,
      Placement: Placement,
      ExtensionServices: ExtensionServices,
      Grants: Grants,
      AlumniEvent: AlumniEvent,
      Miscellaneous: Miscellaneous,
      Consultancy: Consultancy,
      Awards:Awards,
      Events:Events,
      MOUs:MOUs,
      Advertisements:Advertisements,
      PressReports:PressReports,
      UpcomingEvents:UpcomingEvents,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };

    // console.log("activitydata", activityData);
    dispatch(addRoleActivityDetails(roleActivityMappingData));
    closePopup();
  };

  const updateRoleActivityDetails = () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      RoleId: Number(RoleId),
      PaperPresentation: PaperPresentation,
      ProjectOrModel: ProjectOrModel,
      GuestLectures: GuestLectures,
      ImplantTrainingOrInternship: ImplantTrainingOrInternship,
      IndustrialVisits: IndustrialVisits,
      SportsAndGames: SportsAndGames,
      NCC: NCC,
      NSS: NSS,
      FacultyDevelopment: FacultyDevelopment,
      WomenInDevelopment: WomenInDevelopment,
      JournalOrBookpublication: JournalOrBookpublication,
      PatentDetails: PatentDetails,
      SemesterResult: SemesterResult,
      SymposiumAndExpo: SymposiumAndExpo,
      SymposiumAndExpo: SymposiumAndExpo,
      Placement: Placement,
      ExtensionServices: ExtensionServices,
      Grants: Grants,
      AlumniEvent: AlumniEvent,
      Miscellaneous: Miscellaneous,
      Consultancy: Consultancy,
      Awards:Awards,
      Events:Events,
      MOUs:MOUs,
      Advertisements:Advertisements,
      PressReports:PressReports,
      UpcomingEvents:UpcomingEvents,

      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };

    const roleActivityData = {
      ...roleActivity,
      data: data,
    };

    console.log("UpdateFaculty", roleActivityData.data);
    dispatch(UpdateRoleMappingDetails(roleActivityData.data));
    closePopup();
  };

  const roleId = (e) => {
    setRoleId(listOfRoles[+e.target.value]);
  };
  const paperPresentation = (e) => {
    setPaperPresentation(e.target.checked);
  };
  const projectOrModel = (e) => {
    setProjectOrModel(e.target.checked);
  };
  const guestLectures = (e) => {
    setGuestLectures(e.target.checked);
  };
  const consultancy = (e) => {
    setConsultancy(e.target.checked);
  };
  const implantTrainingOrInternship = (e) => {
    setImplantTrainingOrInternship(e.target.checked);
  };
  const industrialVisits = (e) => {
    setIndustrialVisits(e.target.checked);
  };
  const sportsAndGames = (e) => {
    setSportsAndGames(e.target.checked);
  };
  const ncc = (e) => {
    setNCC(e.target.checked);
  };
  const nss = (e) => {
    setNSS(e.target.checked);
  };
  const facultyDevelopment = (e) => {
    setFacultyDevelopment(e.target.checked);
  };
  const womenInDevelopment = (e) => {
    setWomenInDevelopment(e.target.checked);
  };
  const journalOrBookpublication = (e) => {
    setJournalOrBookpublication(e.target.checked);
  };
  const patentDetails = (e) => {
    setPatentDetails(e.target.checked);
  };
  const semesterResult = (e) => {
    setSemesterResult(e.target.checked);
  };
  const symposiumAndExpo = (e) => {
    setSymposiumAndExpo(e.target.checked);
  };
  const placement = (e) => {
    setPlacement(e.target.checked);
  };
  const extensionServices = (e) => {
    setExtensionServices(e.target.checked);
  };
  const grants = (e) => {
    setGrants(e.target.checked);
  };
  const alumniEvent = (e) => {
    setAlumniEvent(e.target.checked);
  };
  const miscellaneous = (e) => {
    setMiscellaneous(e.target.checked);
  };
  const upcomingevents = (e) => {
    setUpcomingEvents(e.target.checked);
  };
const awards = (e) => {
    setAwards(e.target.checked);
  };
  const events = (e) => {
    setEvents(e.target.checked);
  };
  const mous= (e) => {
    setMOUs(e.target.checked);
  };
  const advertisements = (e) => {
    setAdvertisements(e.target.checked);
  };
  const pressReports = (e) => {
    setPressReports(e.target.checked);
  };
  return (
    <div className="popup" style={{ height: "100vh" }}>
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">Edit Activity Mapping</h2>

            {/* {isEdit ? (
              <h2 className="heading">Edit Activity Mapping</h2>
            ) : (
              <h2 className="heading">Add New Mapping</h2>
            )} */}
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  {isEdit ? null : <span style={{ color: "red" }}>*</span>}
                  Role:
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={RoleName}
                  disabled={isEdit}
                />
                {/* <select
                                    className="form-select form-control"
                                    onChange={roleId}
                                    aria-label="Default select example"
                                    value={RoleId}
                                    disabled={isEdit}
                                    {
                                    ...listOfRoles.map((option, index) => (
                                        <option key={option.name} value={option.name}>
                                            {option.name}
                                            {console.log("options", option.name, option.roleId)}
                                        </option>
                                        
                                    ))}
                                >
                                    <option>--Select Role--</option>
                                    <option value="1">Principal</option>
                                    <option value="2">HOD</option>
                                    <option value="4">ADMIN</option>
                                    <option value="6">Ass.Professor</option>
                                    <option value="11">PT faculty</option>
                                </select> */}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <input
                  type="checkbox"
                  checked={PaperPresentation}
                  id="isActive"
                  onChange={paperPresentation}
                />
                &nbsp;
                <label>Paper Presentation</label>
                <br></br>
                <input
                  type="checkbox"
                  checked={ProjectOrModel}
                  id="isActive"
                  onChange={projectOrModel}
                />
                &nbsp;
                <label>Project or Model</label>
                <br></br>
                <input
                  type="checkbox"
                  checked={GuestLectures}
                  id="isActive"
                  onChange={guestLectures}
                />
                &nbsp;
                <label>Guest Lectures</label>
                <br></br>
                <input
                  type="checkbox"
                  checked={Consultancy}
                  id="isActive"
                  onChange={consultancy}
                />
                &nbsp;
                <label>Consultancy</label>
                <br></br>
                <input
                  type="checkbox"
                  checked={ImplantTrainingOrInternship}
                  id="isActive"
                  onChange={implantTrainingOrInternship}
                />
                &nbsp;
                <label>Inplant Traning or Internship</label> <br></br>
                <input
                  type="checkbox"
                  checked={IndustrialVisits}
                  id="isActive"
                  onChange={industrialVisits}
                />
                &nbsp;
                <label>Industrial Visits</label> <br></br>
                <input
                  type="checkbox"
                  checked={SportsAndGames}
                  id="isActive"
                  onChange={sportsAndGames}
                />
                &nbsp;
                <label>Sports and Games</label> <br></br>
                <input
                  type="checkbox"
                  checked={NCC}
                  id="isActive"
                  onChange={ncc}
                />
                &nbsp;
                <label>NCC</label> <br></br>
                <input
                  type="checkbox"
                  checked={NSS}
                  id="isActive"
                  onChange={nss}
                />
                &nbsp;
                <label>NSS</label> <br></br>
                <input
                  type="checkbox"
                  checked={FacultyDevelopment}
                  id="isActive"
                  onChange={facultyDevelopment}
                />
                &nbsp;
                <label>Faculty Development</label> <br></br>
                <input
                  type="checkbox"
                  checked={WomenInDevelopment}
                  id="isActive"
                  onChange={womenInDevelopment}
                />
                &nbsp;
                <label>Women In Development</label> <br></br>
                <input
                  type="checkbox"
                  checked={JournalOrBookpublication}
                  id="isActive"
                  onChange={journalOrBookpublication}
                />
                &nbsp;
                <label>Journal or Book Publication</label> <br></br>
                <input
                  type="checkbox"
                  checked={PatentDetails}
                  id="isActive"
                  onChange={patentDetails}
                />
                &nbsp;
                <label>Patent Details</label> <br></br>
                <input
                  type="checkbox"
                  checked={SemesterResult}
                  id="isActive"
                  onChange={semesterResult}
                />
                &nbsp;
                <label>Semester Result</label> <br></br>
                <input
                  type="checkbox"
                  checked={SymposiumAndExpo}
                  id="isActive"
                  onChange={symposiumAndExpo}
                />
                &nbsp;
                <label>Symposium And Expo</label> <br></br>
                <input
                  type="checkbox"
                  checked={Placement}
                  id="isActive"
                  onChange={placement}
                />
                &nbsp;
                <label>Placement</label> <br></br>
                <input
                  type="checkbox"
                  checked={ExtensionServices}
                  id="isActive"
                  onChange={extensionServices}
                />
                &nbsp;
                <label>Extension Services</label> <br></br>
                <input
                  type="checkbox"
                  checked={Grants}
                  id="isActive"
                  onChange={grants}
                />
                &nbsp;
                <label>Grants</label> <br></br>
                <input
                  type="checkbox"
                  checked={AlumniEvent}
                  id="isActive"
                  onChange={alumniEvent}
                />
                &nbsp;
                <label>Alumni Event</label> <br></br>
                <input
                  type="checkbox"
                  checked={Miscellaneous}
                  id="isActive"
                  onChange={miscellaneous}
                />
                &nbsp;
                <label>Miscellaneous</label> <br></br>
                 <input
                  type="checkbox"
                  checked={Awards}
                  id="isActive"
                  onChange={awards}
                />
                &nbsp;
                <label>Awards</label> <br></br>
                <input
                  type="checkbox"
                  checked={Events}
                  id="isActive"
                  onChange={events}
                />
                &nbsp;
                <label>Events</label> <br></br>
                <input
                  type="checkbox"
                  checked={MOUs}
                  id="isActive"
                  onChange={mous}
                />
                &nbsp;
                <label>MOUs</label> <br></br>
                <input
                  type="checkbox"
                  checked={Advertisements}
                  id="isActive"
                  onChange={advertisements}
                />
                &nbsp;
                <label>Advertisements</label> <br></br>
                <input
                  type="checkbox"
                  checked={PressReports}
                  id="isActive"
                  onChange={pressReports}
                />
                &nbsp;
                <label>Press Reports</label> <br></br>
                <input
                  type="checkbox"
                  checked={UpcomingEvents}
                  id="isActive"
                  onChange={upcomingevents}
                />
                &nbsp;
                <label>Upcoming Events</label> <br></br>
                
              </div>
              
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!roleActivity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveRoleActivityDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateRoleActivityDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRoleActivityMapping;
