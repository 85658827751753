import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addStockInventoryDetails,
  UpdateStockInventoryDetail
} from "../../../app/redux/slices/stockInventorySlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet
} from "../../../app/services/https";
const AddStockInventoryDetails = ({ item, closePopup, stockInventory }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!stockInventory) {
      return;
    }
    runFillStockInventoryData.current = true;
    if (runFillStockInventoryData.current) {
      fillStockInventoryData(stockInventory);
    }
  }, []);
  
  const [Id, setId] = useState("");
  const [InventoryId,setInventoryId]= useState("");
  //const [InventoryDescription,setInventoryDescription]= useState("");
  const [AvailableQuantity,setAvailableQuantity]= useState("");
  const [StockDate,setStockDate] = useState("");
  //const [PurchasedBy,setPurchasedBy] = useState("");
  const [Store,setStore] = useState("");
  const [InventoryList, setInventoryList] = useState([]);
 
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillStockInventoryData = useRef();
  useEffect(() => {
    AsyncGet(API.getInventorys)
      .then((res) => {
        console.log(res.data, "getInventorys");

        setInventoryList(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);
  const clearState = () => {
   setInventoryId("");
    setStockDate("");
   

    setAvailableQuantity("");
    setStore("");
  };
  const iname = (val) => {
  
      
    setInventoryId(val.target.value);
      
  };
  const availableqty = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
    setAvailableQuantity(val.target.value);
    }
  }
  const idate = (val) => {  
  
  setStockDate(val.target.value);
    };

  
  const iStore = (val) => {
    setStore(val.target.value);
  }
  
  // if (val==1)
  // {IsActive=true;}
  // if (val==0)
  // setIsActive(false);

 

  
  const saveStockInventoryDetails = () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var stockInventoryData = {
      Id:0,
      InventoryId :Number(InventoryId),
      StockDate:new Date(StockDate),
      
      Store:Store,
      AvailableQuantity:AvailableQuantity,
      
      
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addStockInventoryDetails(stockInventoryData));
    closePopup();
    clearState();
  };

  const updateStockInventoryDetails = () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      InventoryId :Number(InventoryId),
      StockDate:new Date(StockDate),
     
      Store:Store,
      AvailableQuantity:AvailableQuantity,
      
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const stockInventoryData = {
      ...stockInventory,
        data: data
    };

    console.log("UpdateStockInventory", stockInventoryData.data);
    dispatch(UpdateStockInventoryDetail(stockInventoryData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !InventoryId ||
      !StockDate     
    ) {
      setvalidate(false);
      seterrorMsg("Provide required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillStockInventoryData = (obj) => {
    debugger;
    runFillStockInventoryData.current = false;
    setId(obj.data.id);
    setInventoryId(obj.data.inventoryId);
   // setInventoryDescription(obj.data.inventoryId);
    setStockDate(obj.data.stockDate);
    setAvailableQuantity(obj.data.availableQuantity);
   
    setStore(obj.data.store);
    runFillStockInventoryData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">Add New</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Name</label>
                
                
                <select
                  className="form-select form-control"
                  onChange={iname}
                  aria-label="Default select example"
                  value={InventoryId}
                >
                  <option value="">--Select Inventry--</option>

                  {InventoryList.filter(x=>x.store === Store)?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div> 
               
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Stock Date</label>
                
               
                <input
                className="form-control"
                type="date"
                value={dayjs(StockDate).format("YYYY-MM-DD")}
                max={Moment().format('YYYY-MM-DD')}
                onChange={idate}
                //disabled={validation}
              />

              
               {!StockDate && validate ? (
                 <h6 style={{ color: "red" }}>{"Requird"}</h6>
               ) : null}
               
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                 Store
                </label>
                <select
                  className="form-select form-control"
                  onChange={iStore}
                  aria-label="Default select example"
                  value={Store}
                >
                  <option value="">Select Store</option>
                  <option value="Student Store">Student Store</option>
                  <option value="Office Store">Office Store</option>
                  
                </select>
                
              </div>
               
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Available Quantity</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={AvailableQuantity}
                  onChange={availableqty}
                  maxLength={15}
                  //disabled
                />
              </div>
              </div>
             
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!stockInventory ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveStockInventoryDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateStockInventoryDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStockInventoryDetails;