import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddMemberDetails from "./addMemberDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchMemberDetails,
  memberSelector,
  deleteMember,
} from "../../app/redux/slices/memberSlice";
import { AsyncGet, AsyncPost, AsyncGetFiles } from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import Popup from "reactjs-popup";

const MemberDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, memberList } = useSelector(memberSelector);

  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [member, setMember] = useState();
  const [listOfMember, setlistOfMember] = useState(memberList || []);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);
  const [fileName, setFileName] = useState();
  const [selectedFile, setselectedFile] = useState("");
  const togglePopup = () => {
    debugger;
    onAssessmentSelection("Member Details");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setMember();
    }
  };
  const onFileUpload = async (res) => {
    debugger;
    /*if (selectedFile?.length < 1) {
      dispatch(fetchStudents);
      closePopup();
      return;
    }*/

    if (selectedFile[0].name == "Member.xlsx") {
      debugger;
      console.log(res, "response");
      //const studentData = res ? res[0] : student;
      const formData = new FormData();
      for (let index = 0; index < selectedFile.length; index++) {
        const fileUploaded = selectedFile[index];

        formData.append("FormFiles", fileUploaded);
      }

      formData.append("TypeofUser", "Member");

      try {
        debugger;
        const res = await AsyncPost(API.bulkupload, formData);
        alert(res.data);
        dispatch(fetchMemberDetails());
        window.location.reload(true);
      } catch (ex) {
        console.log(ex);
      }
    } else {
      alert("File Name should be Member.xlsx only");
    }

    //formData.append("id", Number(studentData?.id || 0));
  };

  const downloadFileFun = async () => {
    AsyncGetFiles(API.DownloadTemplate)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );

        const link = document.createElement("a");

        link.href = url;

        link.setAttribute("download", "file.zip");

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  /*const closePopupFun = () => {
    closePopup();
    window.location.reload(false);
  };*/
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", memberList);

  const deleteMemberFun = (item) => {
    dispatch(deleteMember(item.id));
  };

  const editMember = (item) => {
    setMember({ ...item, data: item });
    setshowPopup(!showPopup);
    setIsEdit(true);

  };

  useEffect(() => {
    getMemberData();
  }, []);

  const getMemberData = () => {
    dispatch(fetchMemberDetails());
  };

  useEffect(() => {
    if (!memberList) {
      return;
    }
    setlistOfMember(memberList);
  }, [memberList]);

  console.log("Before Render memberList", memberList);
  console.log("Before Render listOfMember", listOfMember);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Member Details</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>

              <button
                className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                type="Button"
                onClick={() => downloadFileFun()}
                //buttonStyle={{width : '160px !important'}}
              >
                <span className="glyphicon glyphicon-remove"></span> Download
                Template
              </button>

              {/*<button
                className="btn btn-lg btn-secondary btn-block form-roundedbtn2"
                type="submit"
              >
                <span className="glyphicon glyphicon-remove"></span> DELETE
               </button>*/}
            </div>
          </div>
          <h4 class="w-100  fw-bold mb-0 blue-heading">Bulk Upload </h4>

          <div className="col-xs-3 col-md-3 col-lg-3 col-sm-12  form-group">
            <input
              className="form-control"
              onChange={onFileChange}
              type="file"
              accept=".xls,.xlsx"
              id="formFileMultiple"
            />
          </div>
          <button
            className="btn btn-lg btn-secondary btn-block form-roundedbtn2"
            type="submit"
            onClick={onFileUpload}
          >
            <span className="glyphicon glyphicon-remove"></span> Upload
          </button>

          <div className="bootstrap-table">
            <div className="fixed-table-toolbar"></div>
            <div className="fixed-table-container">
              <div
                className="fixed-table-body"
                style={{
                  height: "65vh",
                  overflow: "auto",
                  marginTop: 20,
                }}
              >
                <AdvanceTable
                  deleteMemberProp={(item) => deleteMemberFun(item)}
                  editMemberProp={(item) => editMember(item)}
                  data={listOfMember}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Title:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Event:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddMemberDetails
          isEdit ={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            member={member}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default MemberDetails;
