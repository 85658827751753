import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import "../../../../app/styles/addnew.css";
//import { AsyncGet } from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  //state
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  const [Topic, setTopic] = useState("");
  // const [IeiStudentsChapter, setIeiStudentsChapter] = useState("");
  // const [IsteStudentsChapter, setIsteStudentsChapter] = useState("");
  // const [EDC, setEDC] = useState("");
  // const [TamilIllakyaMandram, setTamilIllakyaMandram] = useState("");
  // const [EnglishClub, setEnglishClub] = useState("");
  // const [FineArtsClub, setFineArtsClub] = useState("");
  // const [EcoClub, setEcoClub] = useState("");
  // const [TrainingAndPlacementCell, setTrainingAndPlacementCell] = useState("");
  const [Impact, setImpact] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [ResourcePersonDetails, setResourcePersonDetails] = useState("");
  const [CourseFees, setCourseFees] = useState("");
  //const [Duration, setDuration] = useState("");   
  const [Participants, setParticipants] = useState("");
  const [nParticipants, setnParticipants] = useState("");
  const [nParticipantsBoys, setnParticipantsBoys] = useState("");
  const [nParticipantsGirls, setnParticipantsGirls] = useState("");
  const [OrganisedBy, setOrganisedBy] = useState("");
  const [TotalRevenueGenerated, setTotalRevenueGenerated] = useState("");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [datevalidation, setdatevalidation] = useState(true);
  const [validate, setvalidate] = useState(false);
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  //const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();

  const clearState = () => {
    setFromDate("");
    setToDate("");
    setTopic("");
    setCourseFees("");
    setImpact("");
    setRemarks("");
    setResourcePersonDetails("");
    // setStudentID([]);
    // setFacultyID([]);
    //setDepartmentSemester("");
    //setDepartmentDesignation("");
    setParticipants("");
    setnParticipants("");
    setnParticipantsBoys("");
    setnParticipantsGirls("");
    setOrganisedBy("");
    

    setTotalRevenueGenerated("");
   
    setCreatedBy("");

    
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setselectedFile("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };
  /*const students1 = (val) => {
      setstudentORFaculty(val);
  if (val === "Student") {
        setFacultyID([]);
      } else {
        setStudentID([]);
      }
    };*/
  const fromDatechange = (val) => {
    setdatevalidation(false);
    setFromDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setToDate('');
  };
  const toDatechange = (val) => {
    if (val.target.value != '')
      setFromdatevalidation(true);
    else
      setFromdatevalidation(false);
    setToDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  // const duarationchange = (val) => {
  //   setDuration(val.target.value);
  // };

  const onOrganisedBySelect = (e) => {
    setOrganisedBy(e.target.value);
  };
  const onParticipantsSelect = (e) => {
    setParticipants(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(31, JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();
      return;
    }
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "ExtensionServices"
    );

    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(31, JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 31,

      Topic: Topic,
      FromDate: FromDate,//new Date(FromDate).toLocaleDateString(),
      ToDate: ToDate,//new Date(ToDate).toLocaleDateString(),   
      CourseFees: CourseFees == "" ? 0 : CourseFees,
      //Duration:Duration,
      Department: JSON.parse(localStorage.getItem("user")).department,
      Impact: Impact,
      Remarks: Remarks,
      ResourcePersonDetails: ResourcePersonDetails,
      Participants: Participants,
      nParticipants: Number(nParticipantsGirls) + Number(nParticipantsBoys),
      nParticipantsBoys: nParticipantsBoys,
      nParticipantsGirls: nParticipantsGirls,
      OrganisedBy: OrganisedBy,
      TotalRevenueGenerated: TotalRevenueGenerated,
      Attachment: "",

    };
    var activityData = {
      id: 0,
      activityID: 31,
      activityName: "ExtensionServices",
      data: JSON.stringify(data),
      filePath: "",//selectedFile,
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      dispatch(fetchActivities(31, JSON.parse(localStorage.getItem("user")).departmentId));
      await onFileUpload(resp);
      closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }

  };

  const updateActivity = async () => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 31,

      Topic: Topic,
      FromDate: FromDate,//new Date(FromDate).toLocaleDateString(),
      ToDate: ToDate,//new Date(ToDate).toLocaleDateString(), 
      CourseFees: CourseFees == "" ? 0 : CourseFees,
      // IeiStudentsChapter: IeiStudentsChapter,
      // IsteStudentsChapter: IsteStudentsChapter,
      // EcoClub: EcoClub,
      // EnglishClub: EnglishClub,
      // TamilIllakyaMandram: TamilIllakyaMandram,
      // FineArtsClub: FineArtsClub,
      // TrainingAndPlacementCell: TrainingAndPlacementCell,
      // EDC: EDC,
      //Duration:Duration,
      Impact: Impact,
      Remarks: Remarks,
      ResourcePersonDetails: ResourcePersonDetails,
      Department: JSON.parse(localStorage.getItem("user")).department,
      Participants: Participants,
      nParticipants: Number(nParticipantsGirls) + Number(nParticipantsBoys),
      nParticipantsBoys: nParticipantsBoys,
      nParticipantsGirls: nParticipantsGirls,
      OrganisedBy: OrganisedBy,
      TotalRevenueGenerated: TotalRevenueGenerated,
      Attachment: "",
    };
    const activityData = {
      ...activity,

      activityID: 31,
      data: JSON.stringify(data),
      //filePath: selectedFile,
    };

    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      closePopup();
      //clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      Topic &&
      FromDate &&
      ToDate &&
      OrganisedBy &&
      nParticipantsBoys &&
      nParticipantsGirls
      //TotalRevenueGenerated    
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "topic":
        setTopic(e.target.value);
        break;
      case "coursefees":
        if (/^[0-9.]*$/.test(e.target.value) == false) {

          //error message

        } else {
          setCourseFees(e.target.value);
        }
        break;

      case "impact":
        setImpact(e.target.value);
        break;
      case "remarks":
        setRemarks(e.target.value);
        break;
      case "resourcePersonDetails":
        setResourcePersonDetails(e.target.value);
        break;
      case "organisedby":
        setOrganisedBy(e.target.value);
        break;

      case "participants":
        var participants = e.target.value.replace(/\D/g, "");
        setParticipants(participants);

        break;
      case "nparticipants":
        var nparticipants = e.target.value.replace(/\D/g, "");
        setnParticipants(nparticipants);

        break;
      case "nparticipantsboys":
        var nparticipantsboys = e.target.value.replace(/\D/g, "");
        setnParticipantsBoys(nparticipantsboys);

        break;
      case "nparticipantsgirls":
        var nparticipantsgirls = e.target.value.replace(/\D/g, "");
        setnParticipantsGirls(nparticipantsgirls);

        break;
      case "totalrevenuegenerated":
        if (/^[0-9.]*$/.test(e.target.value) == false) {

          //error message

        } else {
          setTotalRevenueGenerated(e.target.value);

        }
    }
  };
  // On file select (from the pop up)
  // const onFileChange = (event) => {
  //   // Update the state
  //   const img = event.target.files[0];
  //   if (!img) {
  //     return;
  //   }
  // const reader = new FileReader();
  // reader.onloadend = () => {
  //   setselectedFile(reader.result);
  // };
  // reader.readAsDataURL(img);

  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const fillActivityData = (obj) => {
    runFillActivityData.current = false;
    // console.log(obj);
    setFromDate(obj.data.FromDate);
    setToDate(obj.data.ToDate);
    setTopic(obj.data.Topic);
    //setDuration(obj.data.Duration);
    setCourseFees(obj.data.CourseFees);
    setOrganisedBy(obj.data.OrganisedBy);
    setImpact(obj.data.Impact);
    setRemarks(obj.data.Remarks);
    setResourcePersonDetails(obj.data.ResourcePersonDetails);
    setFromdatevalidation(obj.data.Fromdatevalidation1);
    setParticipants(obj.data.Participants)
    setnParticipants(obj.data.nParticipants);
    setnParticipantsBoys(obj.data.nParticipantsBoys);
    setnParticipantsGirls(obj.data.nParticipantsGirls);
    setTotalRevenueGenerated(obj.data.TotalRevenueGenerated);
    setselectedFile(obj.filePath);
    setfilesList(obj.files);
    setCreatedBy(obj.createdDate);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row"></div>

            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>From Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FromDate).format("YYYY-MM-DD")}
                  disabled={Fromdatevalidation1}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={fromDatechange}
                />{!FromDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>To Date</label>
                <input
                  className="form-control"
                  type="date"

                  disabled={datevalidation}

                  value={dayjs(ToDate).format("YYYY-MM-DD")}

                  min={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={toDatechange}
                />{!ToDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select To Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Topic</label>
                <input
                  className="form-control"
                  type="text"
                  id="topic"
                  value={Topic}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!Topic && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Course Fees</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="coursefees"
                  value={CourseFees}
                  onChange={onTextValueChange}
                  maxLength={11}
                />
              </div>

              {/* <div
                id="students5"
                className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt"
              >
                <label>Department, Designation</label>
                <input
                  className="form-control"
                  type="text"
                  id="departmentdesigantion"
                  onChange={onTextValueChange}
                />
              </div> */}
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Duration</label>
                <input
                  className="form-control"
                  type="text" 
                  pattern="[0-9]*"
                  placeholder="No of Days"               
                  id="duration"
                  value={Duration}
                  onChange={onTextValueChange}
                  maxlength={4}
                />{!Duration && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
                </div>*/}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Organised By</label>
                <select
                  className="form-select form-control"
                  onChange={onOrganisedBySelect}
                  aria-label="Default select example"
                  value={OrganisedBy}
                >
                  <option value="">Select Organised By</option>
                  <option value="CIICP">CIICP</option>
                  <option value="CEC">CEC</option>
                  <option value="DOTE">DOTE</option>
                  <option value="Eco Club">Eco Club</option>
                  <option value="EDC">EDC</option>
                  <option value="English Club">English Club</option>
                  <option value="Fine Arts Club">Fine Arts Club</option>
                  <option value="IEI Student's Chapter">IEI Student's Chapter</option>
                  <option value="ISTE Student's Chapter">ISTE Student's Chapter</option>
                  <option value="Tamil Illakya Mandram">Tamil Illakya Mandram</option>
                  <option value="Training and Placement Cell">Training and Placement Cell</option>
                  <option value="Others">Others</option>

                </select>
                {!OrganisedBy && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Participants</label>
                <select
                  className="form-select form-control"
                  onChange={onParticipantsSelect}
                  aria-label="Default select example"
                  value={Participants}
                >
                  <option value="">Select Participants</option>
                  <option value="Internal">Internal</option>
                  <option value="External">External</option>
                  <option value="Internal and External">Internal and External</option>
                  <option value="Industry">Industry</option>
                  <option value="Others">Others</option>
                </select>

              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group" >
                <label>Resource Person Details</label>
                <textarea
                  className="form-control clsabstract"
                  id="resourcePersonDetails" value={ResourcePersonDetails}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={512}
                />
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                <label>Impact</label>
                <div>
                  <textarea
                    className="form-control clsabstract"
                    id="impact" value={Impact}
                    onChange={onTextValueChange}
                    rows={25}
                    cols={85}
                    maxLength={512}
                  />
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                <label>Remarks</label>
                <textarea
                  className="form-control clsabstract"
                  id="remarks"
                  value={Remarks}
                  onChange={onTextValueChange}
                  rows={25}
                  cols={85}
                  maxLength={512}
                />
              </div>
             
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Boys Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipantsboys"
                  value={nParticipantsBoys}
                  onChange={onTextValueChange}
                  maxlength={4}
                />{!nParticipantsBoys && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Girls Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipantsgirls"
                  value={nParticipantsGirls}
                  onChange={onTextValueChange}
                  maxlength={4}
                />{!nParticipantsGirls && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>No of Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipants"
                  value={Number(nParticipantsGirls) + Number(nParticipantsBoys)}
                  onChange={onTextValueChange}
                  maxlength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Total Revenue Generated</label>
                <input
                  className="form-control"
                  type="Text"
                  pattern="[0-9]*"
                  id="totalrevenuegenerated"
                  value={TotalRevenueGenerated}
                  onChange={onTextValueChange}
                  maxLength={11}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>

            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
