import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const aluminiSlice = createSlice({
  name: "aluminiList",
  initialState: {
    loading: false,
    error: false,
    aluminiList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    aluminiDetails: [],
    submitAluminiSuccess: "",
    saveAluminiDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getAlumini: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.aluminiList = payload;
    },
    saveAlumini: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveAluminiDataSuccess = payload;
    },
    submitAlumini: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getAlumini,
  submitAlumini,
  setError,
  saveAlumini,
} = aluminiSlice.actions;
export const aluminiSelector = (state) => state.aluminies || [];
export default aluminiSlice.reducer;

export const fetchAluminies = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getAluminies)
    .then((response) => {
      console.log("response,", response);
      dispatch(getAlumini(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addAluminiDetails =async (data, dispatch)  => {
try{
  dispatch(setLoading());
 const response = await  AsyncPost(API.saveAluminies, data);
   
      dispatch(setLoading());
      dispatch(saveAlumini(response.data));
      dispatch(fetchAluminies());
      return response.data;
    
    }
    catch(error) {
      console.log("failure of add alumini");
      dispatch(saveAlumini(""));
      dispatch(setError(true));
      dispatch(setLoading());
    }
};

export const UpdateAluminiDetails = async (data, dispatch)  => {
  // dispatch(setLoading());
  AsyncPost(API.updateAluminiData, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update alumini");
      dispatch(fetchAluminies());
    })
    .catch((er) => {
      console.log("failure of add alumini");
      dispatch(saveAlumini(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteAlumini = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteAluminiData + "?id=" + data,"")
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete alumini");
      dispatch(fetchAluminies());
    })
    .catch((er) => {
      console.log("failure of add alumini");
      dispatch(saveAlumini(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
