import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import { AsyncGet, AsyncGetFiles, AsyncPost } from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [DeparmentList, setDeparmentList] = useState([]);
  const [Sanctionedby, setSanctionedby] = useState("");
  const [SanctionedYear, setSanctionedYear] = useState("");
  //const [Projectduration, setProjectduration] = useState("");
  const [Grantgivenby , setGrantgivenby ] = useState(""); 
  const [SanctionedOrderno, setSanctionedOrderno] = useState("");  
  //const [Department, setDepartment] = useState("");
  const [FacultyID, setFacultyID] = useState([]);
  const [DepartmentDesignation, setDepartmentDesignation] = useState(""); 
  const [ProjectName, setProjectName] = useState("");
  const [Sanctionedgrantamount, setSanctionedgrantamount] = useState("");
  const [Recurringamount , setRecurringamount ] = useState("");
  const [Nonrecurringamount, setNonrecurringamount] = useState("");
  
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();

  const runFillActivityData = useRef();
  const clearState = () => {
    setGrantgivenby("");
    setSanctionedYear("");
    setSanctionedOrderno("");
    setProjectName("");
    //setStudentID([]);
    setFacultyID([]);
    setSanctionedgrantamount("");
    setRecurringamount("");
    setNonrecurringamount("");
    setSanctionedby("");  
    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
   // setdatevalidation(true);
   // setFromdatevalidation(false);
    console.log("reseted");
  }
  /*const onDepartmentSelect = (e) => {
    setDepartment(e.target.value);
  };*/
  const sanctionedyear = (val) => {
    setSanctionedYear(val.target.value);
  };
 
  const onSanctionedbySelect = (e) => {
    setSanctionedby(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  
  const onFacultyIDChange = (data) => {
    setFacultyID(data);
  };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?DepartmentId="+JSON.parse(localStorage.getItem("user")).departmentId+ "&facultyname=" + data)
      .then((res) => {
        setfacultyList(res.data);
      })
      .catch(() => setfacultyList([]));
  };

  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(32,JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();
      return;
    }


    console.log(res, "response");
     const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Grants"
    );
console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(32,JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();

    } catch (ex) {
      console.log(ex);
    }
  };

 const downloadFileFun = async () => {

    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
    .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async() => {
    debugger;
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = { 
      activityID: 32,
      //Projectduration:Projectduration,
      SanctionedYear:SanctionedYear,
      Sanctionedby: Sanctionedby,
      Grantgivenby: Grantgivenby,     
      SanctionedOrderno: SanctionedOrderno,
      //Department: Department,   
      Department:JSON.parse(localStorage.getItem("user")).department,  
      ProjectName: ProjectName,      
      Sanctionedgrantamount: Sanctionedgrantamount,
      Recurringamount: Recurringamount,
      Nonrecurringamount: Nonrecurringamount,
      Attachment: "",
      FacultyID,     
     
      
    };
    var activityData = {
      id: 0,
      activityID: 32,
      activityName: "Grants",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
try {
    const resp = await addActivity(activityData, dispatch);
    await onFileUpload(resp);
  } catch (error) {
    console.log(error);
    closePopup();
    clearState();
  }
  };

  const updateActivity = async() => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      
      activityID: 32,
      //Projectduration:Projectduration,
      SanctionedYear:SanctionedYear,
      Sanctionedby: Sanctionedby,
      Grantgivenby: Grantgivenby,     
      SanctionedOrderno: SanctionedOrderno,
      //Department: Department,    
      Department:JSON.parse(localStorage.getItem("user")).department, 
      ProjectName: ProjectName,    
      Sanctionedgrantamount: Sanctionedgrantamount,
      Recurringamount: Recurringamount,
      Nonrecurringamount: Nonrecurringamount,
      FacultyID,     
      Attachment: "",
    };
    const activityData = {
      ...activity,      
      activityID:32,
      activityName: "Grants",
      data: JSON.stringify(data),
      //filePath: selectedFile,
    };
    try {
       dispatch(UpdateActivity(activityData));
      await onFileUpload();
     // closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      FacultyID?.length > 0 &&
     
      Grantgivenby &&
      SanctionedYear &&
      SanctionedOrderno &&
      //Department &&
      //Projectduration &&
      ProjectName &&     
      Sanctionedgrantamount 
        
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "grantgivenby":
       
        setGrantgivenby(e.target.value);
        
        break;
      case "sanctionedyear":
        setSanctionedYear(e.target.value);
        break;
      case "sanctionedorderno":
       
        setSanctionedOrderno(e.target.value);
        
        
        break;
      
      case "projectname":
        setProjectName(e.target.value);
        break;
      // case "department":
      //   setDepartment(e.target.value);
      //   break;
      
      case "sanctionedgrantamount":
        if(/^[0-9.]*$/.test(e.target.value) == false){

          //error message

        }     else

        {
        setSanctionedgrantamount(e.target.value);
        }
        break;
        case "recurringamount":
          if(/^[0-9.]*$/.test(e.target.value) == false){

            //error message
  
          }     else
  
          {
          setRecurringamount(e.target.value);
          }
          break;
          case "nonrecurringamount":
            if(/^[0-9.]*$/.test(e.target.value) == false){

              //error message
    
            }     else
    
            {
            setNonrecurringamount(e.target.value);
            }
            
        
    }
  };
 
  const onFileChange = async (e) => {
  debugger;
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  
  const fillActivityData = (data) => {
  debugger;
    runFillActivityData.current = false;
 const obj = data;
  const objData = obj.data;
    // console.log(obj);
    setFacultyID(objData.FacultyID || []);
    setGrantgivenby(objData.Grantgivenby);
    setSanctionedYear(objData.SanctionedYear);
    setSanctionedOrderno(objData.SanctionedOrderno);
    //setProjectduration(objData.Projectduration);    
    setProjectName(objData.ProjectName);
    //setDepartment(objData.Department);
    //setProjectcoordinator(objData.Projectcoordinator);
    //setProjectCocoordinator(objData.ProjectCocoordinator);
    setSanctionedgrantamount(objData.Sanctionedgrantamount);
    setRecurringamount(objData.Recurringamount);
    setNonrecurringamount(objData.Nonrecurringamount);    
    setSanctionedby(objData.Sanctionedby)
    setCreatedBy(obj.createdDate);
    setselectedFile(obj.filePath);
 	  setfilesList(obj.files);
    runFillActivityData.current = true;
   
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
}, []);
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="popup">
    <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row"></div>
              
            <div className="row formadduser">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Sanctioned Year</label>
                <input
                  className="form-control"
                  type="Month"
                  value={SanctionedYear}
                  onChange={sanctionedyear}
                  max={Moment().format('YYYY-MM')}
                />{!SanctionedYear && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Sanctioned Order No</label>
                <input
                  className="form-control"
                  type="text"
                 
                  id="sanctionedorderno"
                  value={SanctionedOrderno}
                  onChange={onTextValueChange}
                  maxLength={20}
                />
               {!SanctionedOrderno && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              {/* <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Department </label>
                <select
                  className="form-select form-control"
                  onChange={onDepartmentSelect}
                  aria-label="Default select example"
                  value={Department}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.name}>
                      {opt.name}
                    </option>
                  ))}
                </select>
                {!Department && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div> */}
              
                <div
                  id="students3"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Faculty ID</label>

                  <SearchBox
                    options={facultyList}
                    onChangeList={(data) => onFacultyIDChange(data)}
                    onInputChange={(e) => onFacultyIDInputChange(e)}
                    selectedOptions={FacultyID || []}
                    type="faculty"
                  />{!FacultyID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Grant Given By</label>
                <input
                  className="form-control"
                  type="text"
                  id="grantgivenby"
                  value={Grantgivenby}
                  onChange={onTextValueChange}
                  maxLength={150}
                />{!Grantgivenby && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Project Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="projectname"
                  value={ProjectName}
                  onChange={onTextValueChange}
                  maxLength={150}
                /> {!ProjectName && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Sanctioned Amount</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="sanctionedgrantamount"
                  value={Sanctionedgrantamount}
                  onChange={onTextValueChange}
                  maxLength={11}
                /> {!Sanctionedgrantamount && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Sanctioned By</label>
                <select
                  className="form-select form-control"
                  onChange={onSanctionedbySelect}
                  aria-label="Default select example"
                  value={Sanctionedby}
                >
                  <option  value ="">Select Sanctioned By</option>
                  <option value="State Govt">State Govt</option>
                  <option value="Central Govt">Central Govt</option>
                  <option value="Industry">Industry</option>
                  <option value="Others">Others</option>
                  
                </select>
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Recurring Amount</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="recurringamount"
                  value={Recurringamount}
                  onChange={onTextValueChange}
                  maxLength={11}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6 form-group">
                <label>Non-Recurring Amount</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nonrecurringamount"
                  value={Nonrecurringamount}
                  onChange={onTextValueChange}
                  maxLength={11}
                /> 
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>Attachment</label>
              <input
                className="form-control"
                onChange={onFileChange}
                type="file"
                id="formFileMultiple"
                multiple
              />
              {filesList?.length > 0 && (
                <div className="d-flex align-items-center mt-2  rounded">
                  <div className="border rounded download-list-block">
                    {filesList?.map((item, index) => (
                      <h6 className="border-bottom ms-1" key={index}>
                        {item || "No File Name"}
                      </h6>
                    ))}
                  </div>
                  <button
                    className="btn btn-sm btn-primary btn-block "
                    type="button"
                    onClick={() => downloadFileFun()}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
            </div>
            
           
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
           
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
