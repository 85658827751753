import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddBatchStudMappings from "./addBatchStudentMapping/index";
import {
  fetchBatchStuds,
  batchStudSelector,
  deleteBatchStud
} from "../../app/redux/slices/batchStudentMappingSlice";

import Popup from "reactjs-popup";
import AdvanceTable from "./advanceTable";

import { API } from "../../app/services/endpoints";
import { AsyncGet } from "../../app/services/https";

const BatchStudMappings = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, batchStudList } = useSelector(batchStudSelector);

  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [batchStud, setBatchStud] = useState();
  const [listOfBatchStuds, setlistOfBatchStuds] = useState(batchStudList || []);
  const [BatchDetailsList, setBatchDetailsList] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);
  console.log("List from State", batchStudList);

  const togglePopup = () => {
    onAssessmentSelection("BatchStud Details");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setBatchStud();
    }
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", batchStudList);

  const deleteBatchStudFun = (item) => {
    debugger
    var ids = item.map(x => x.Id);
    var batchId = item[0].BatchId;
    dispatch(deleteBatchStud(ids,batchId ));
  };

  const editBatchStud = (item) => {
    setBatchStud({ ...item, data: item });
    setshowPopup(!showPopup);
    setIsEdit(true);
  };

  useEffect(() => {
    getBatchStudData();
  }, []);

  const getBatchStudData = () => {
    dispatch(fetchBatchStuds());
  };

  useEffect(() => {
    if (!batchStudList) {
      return;
    }
    AsyncGet(API.getBatches)
      .then((res) => {
        console.log(res.data, "get Batches");
        setBatchDetailsList(res.data);
        localStorage.setItem("BatchDetails", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
    setlistOfBatchStuds(batchStudList);
  }, [batchStudList]);

  console.log("Before Render batchStudList", batchStudList);
  console.log("Before Render listOfBatchStud", listOfBatchStuds);

  // render the items
  const renderItems = () => {
    console.log("inside render method", listOfBatchStuds);
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Batch Student Details</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
            </div>
            <div className="fixed-table-container">
              <div className="fixed-table-body">
                {console.log("inside return method", listOfBatchStuds)}
                <AdvanceTable
                  deleteBatchStudProp={(item) => deleteBatchStudFun(item)}
                  editBatchStudProp={(item) => editBatchStud(item)}
                  data={listOfBatchStuds}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Title:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Event:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddBatchStudMappings
            isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            batchStud={batchStud}
          />
        ) : null}
      </div>
    );
  };


  return (
    <div>{renderItems()}</div>
  );
};
export default BatchStudMappings;
