import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";

import {
    addBatchDetails,
    //UpdateBatchDetails,
} from '../../../app/redux/slices/batchDetailsSlice';
import { API } from "../../../app/services/endpoints";
import { AsyncGet } from "../../../app/services/https";


const AddBatchDetails = ({isEdit, item, batch, closePopup }) => {
  
    const dispatch = useDispatch();

    useEffect(() => {
        if (!batch) {
            return;
        }
        runFillBatchData.current = true;
        if (runFillBatchData.current) {
            fillBatchData(batch);
        }
    }, []);



    useEffect(() => {
        AsyncGet(API.getDepartments)
            .then((res) => {
                console.log(res.data, "getDepartments");
                setDepartmentList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        AsyncGet(API.getSubjects)
            .then((res) => {
                console.log(res.data, "get all Subjects");
                setSubjectList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);



    const [BatchId, setBatchId] = useState();
    const [BatchName, setBatchName] = useState("");
    const [DepartmentId, setDepartmentId] = useState("");
    const [DeptSection, setDeptSection] = useState("");
    const [SubjectId, setSubjectId] = useState("");
    const [Year, setYear] = useState();
    const [Sem, setSem] = useState([]);
    const [DepartmentList, setDepartmentList] = useState([]);
    const [SubjectList, setSubjectList] = useState([]);
    const [errorMsg, seterrorMsg] = useState("");
    const [validate, setvalidate] = useState(false);

    const runFillBatchData = useRef();
	const clearState = () => {
        setBatchId("");
        setBatchName("");
        setDepartmentId("");
        setDeptSection("");
        setSubjectId("");
        setYear("");
        setSem("");
        //setStudentList([]);    
    };
    
    const batchName = (val) => {
        if(/^[a-z A-Z0-9-]*$/.test(val.target.value) == false){
            //error message
          }     else
          {
        setBatchName(val.target.value);
          }
    };

    const dept = (val) => {
        setDepartmentId(val.target.value);
    };

    const deptSection = (val) => {
        if(/^[a-z A-Z0-9-]*$/.test(val.target.value) == false){
            //error message
          }     else
          {
        setDeptSection(val.target.value);
          }
    };

    const subjectId = (val) => {
        setSubjectId(val.target.value);
    };

    const yearOfEducation = (val) => {
        var year = val.target.value.replace(/\D/g, "");
        setYear(year);
    };

    const semVal = (val) => {
        setSem(val.target.value);
    };

    const saveBatchDetails = () => {
        seterrorMsg("");
        const error = validationFun();
        if (!error) {
            return;
        }
        var batchData = {
            BatchId: 0,
            BatchName: BatchName,
            DepartmentId: Number(DepartmentId),
            DeptSection: DeptSection,
            SubjectId: 0, //Number(SubjectId),
            Year: Number(Year),
            Sem: Sem,
            CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
            CreatedDate: new Date(),
            ModifiedBy: "",
            ModifiedDate: new Date(),
        };

        // console.log("activitydata", activityData);
        dispatch(addBatchDetails(batchData));
        closePopup();
        clearState();
    };

    const updateBatchDetails = () => {
        debugger
        seterrorMsg("");
        const error = validationFun();
        if (!error) {
            return;
        }
        var data = {
            BatchId: BatchId,
            BatchName: BatchName,
            DepartmentId: Number(DepartmentId),
            DeptSection: DeptSection,
            SubjectId: 0, //Number(SubjectId),
            Year: Number(Year),
            Sem: Sem,
            CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
            CreatedDate: new Date(),
            ModifiedBy: "",
            ModifiedDate: new Date(),
        };
        const batchData = {
            ...batch,
            data: data
        };

        console.log("UpdateBatch", batchData.data);
        //dispatch(UpdateBatchDetails(batchData.data));
        closePopup();
        clearState();
    };

    const validationFun = () => {
        setvalidate(true);
        if (
            !BatchName ||
            !DepartmentId ||
            !DeptSection ||
            //!SubjectId ||
            !Year ||
            !Sem 
        ) {
            setvalidate(false);
            seterrorMsg("Provide all required fields");
            return false;
        }
        else {
            return true;
        }
    };

    const closePopupFun = () => {
        closePopup();
        //window.location.reload(false);
    };

    const fillBatchData = (obj) => {
        debugger
        runFillBatchData.current = false;
        setBatchId(obj.data.batchId);
        setBatchName(obj.data.batchName);
        setDepartmentId(obj.data.departmentId);
        setDeptSection(obj.data.deptSection);
        setSubjectId(obj.data.subjectId);
        setYear(obj.data.year);
        setSem(obj.data.sem);
        runFillBatchData.current = true;
    };

    return (
        <div className="popup">
        <div className="popup-inner modal-content">
                <div className="col">
                    <div className="formdata">
                    <h2 className="heading">{isEdit ? "Edit" : "Add New Batch"}</h2>
                        <button
                            className="btn btn-lg btnclose"
                            onClick={() => closePopupFun()}
                            type="button"
                        >
                            X
                        </button>
                        <div className="d-flex justify-content-center align-items-enter">
                           {/* {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
                        </div>
                        <div className="row formadduser">
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                                <label><span style={{ color: "red" }}>*</span>Batch Name</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={BatchName}
                                    onChange={batchName}
                                    maxLength={150}
                                />
                            </div>
                            {!BatchName && validate ? (
                                <h6 style={{ color: "red" }}>{"Enter first name"}</h6>
                            ) : null}
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                                <label><span style={{ color: "red" }}>*</span>Department</label>
                                <select
                                    className="form-select form-control"
                                    onChange={dept}
                                    aria-label="Default select example"
                                    value={DepartmentId}
                                >
                                    <option value="">--Select Department--</option>
                                    {DepartmentList?.map((opt) => (
                                        <option key={opt.id} value={opt.id}>
                                            {opt.name}
                                        </option>
                                    ))}
                                </select>
                                {!DepartmentId && validate ? (
                                    <h6 style={{ color: "red" }}>{"Select Department"}</h6>
                                ) : null}
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                                <label><span style={{ color: "red" }}>*</span>Department Section</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={DeptSection}
                                    onChange={deptSection}
                                    maxLength={2}
                                />
                            </div>
                            {/* <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                                <label><span style={{ color: "red" }}>*</span>Subject</label>
                                <select
                                    className="form-select form-control"
                                    onChange={subjectId}
                                    aria-label="Default select example"
                                    value={SubjectId}
                                >
                                    <option value="">--Select Subject--</option>
                                    {SubjectList?.map((opt) => (
                                        <option key={opt.id} value={opt.id}>
                                            {opt.subjectName}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                             <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                                <label> <span style={{ color: "red" }}>*</span>Year</label>
                                <select
                                    className="form-select form-control"
                                    onChange={yearOfEducation}
                                    aria-label="Default select example"
                                    value={Year}
                                >
                                    <option value="">--select Year--</option>
                                    <option value={1}>1st Year</option>
                                    <option value={2}>2nd Year</option>
                                    <option value={3}>3rd Year</option>
                                </select>
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                                <label><span style={{ color: "red" }}>*</span>Semester</label>
                                <select
                                    className="form-select form-control"
                                    onChange={semVal}
                                    aria-label="Default select example"
                                    value={Sem}
                                >
                                    <option value="">--Select Semester--</option>
                                    <option value="Odd">Odd</option>
                                    <option value="Even">Even</option>
                                </select>
                            </div>
                            {!Sem && validate ? (
                                <h6 style={{ color: "red" }}>{"Select semester"}</h6>
                            ) : null}


                        </div>
                        &nbsp;
                        <div className="d-flex justify-content-center align-items-enter">
                            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
                        </div>
                        <p>
                            {!batch ? (
                                <button
                                    className="btn btn-primary btn-block form-roundedbtn"
                                    type="button"
                                    onClick={() => saveBatchDetails()}
                                >
                                    Save
                                </button>
                            ) : null
                            // (
                            //     <button
                            //         className="btn btn-lg btn-primary btn-block form-roundedbtn"
                            //         type="button"
                            //         onClick={() => updateBatchDetails()}
                            //     >
                            //         Update
                            //     </button>)
                                }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBatchDetails;
