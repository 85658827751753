import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import AddBudgetDetails from "./addBudgetDetails/index";

import AddReAllocationDetails from "./addReAllocationDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchBudgets,
  budgetSelector,
  deleteBudget,
} from "../../app/redux/slices/budgetSlice";

import Popup from "reactjs-popup";

const BudgetDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, budgetList } = useSelector(budgetSelector);
  const [IsEdit, setIsEdit] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [budget, setBudget] = useState();
  const[reallocation,setreallocation]=useState();
  const [listOfBudget, setlistOfBudget] = useState(budgetList || []);

  const [showPopupReallocation, setshowPopupReallocation] = useState(false);
  const [assessmentReallocation, setassessmentReallocation] = useState("");
  //const [budget, setBudget] = useState();

  const togglePopup = () => {
    onAssessmentSelection("Budget Details");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setBudget();
    }
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  const togglePopupReallocation= () => {
    onAssessmentSelectionReallocation("Budget Details");
    setshowPopupReallocation(!showPopupReallocation);
    if (!showPopupReallocation) {
      setreallocation();
    }
  };

  const onAssessmentSelectionReallocation = (assessmentReallocation) => {
    setassessmentReallocation(assessmentReallocation);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", budgetList);

  const deleteBudgetFun = (item) => {
    debugger;
    if(Number(item.headAmt)>=Number(item.budgetAmt))
    {
      dispatch(deleteBudget(item.sNo));

    }
    else{
      alert("Unable to delete this Budget, Budget Amount is higher than Total Budget");
    }
  };

  const editBudget = (item) => {
    debugger;
    setBudget({ ...item, data: item });
    setshowPopup(!showPopup);
    setIsEdit(true);
  };

  useEffect(() => {
    getBudgetData();
  }, []);
  
  const getBudgetData = () => {
    dispatch(fetchBudgets());
  };

  useEffect(() => {
    if (!budgetList) {
      return;
    }
    setlistOfBudget(budgetList);
  }, [budgetList]);

  console.log("Before Render budgetList", budgetList);
  console.log("Before Render listOfBudget", listOfBudget);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Budget Details</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
              <button
                className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                type="submit"
                //style="width:160px!important"
                onClick={() => togglePopupReallocation()}
                
              >
                <span className="glyphicon glyphicon-remove"></span> Reallocation
              </button>
              
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
            </div>
            <div className="fixed-table-container">
              <div className="fixed-table-body">
              <AdvanceTable
                  deleteBudgetProp={(item) => deleteBudgetFun(item)}
                  editBudgetProp={(item) => editBudget(item)}
                  data={listOfBudget}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                       SNo:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Department:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Budget:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div> */}
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddBudgetDetails
          isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            budget={budget}
          />
        ) : null}
        {showPopupReallocation ? (
          <AddReAllocationDetails
            item={assessmentReallocation}
            closePopup={togglePopupReallocation}
            reallocation={reallocation}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default BudgetDetails;
