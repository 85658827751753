import React, { useState, useRef, useEffect } from "react";
import { BsShop, BsList } from "react-icons/bs";

import { Link, useNavigate } from "react-router-dom";
import "./header.css";

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const authenticate = (routeName) => {
    const getUser = JSON.parse(localStorage.getItem("user"));
    if (getUser) {
      navigate(routeName);
    } else {
      navigate("/");
    }
  };
  const _onClick = ({ key, data }) => {
    setShowMenu(false);
    const functionality = {
      home: () => authenticate("/Welcome"),
    };
    functionality[key](data);
  };

  const onNavigate = (ref) => {
    console.log(ref, "ref");
    navigate(ref);
    setShowMenu(false);
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setShowMenu(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <>
      <nav className="navbar_full">
        {JSON.parse(localStorage.getItem("user")) != null ? (
          <div className="sidebar-nav">
            <ul className="nav">
              <li className="active" onClick={() => _onClick({ key: "home" })}>
                <a>
                  <i className="glyphicon glyphicon-home"></i> Home
                </a>
              </li>
            </ul>
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/facultyDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Faculty
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/studentDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Student
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/aluminiDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Alumni
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/roleDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Role
                  </a>
                </li>
              </ul>
            ) : null}
            {/* {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/formRoleDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Form Role
                  </a>
                </li>
              </ul>
            ) : null}  */}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/subjectDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Subject
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/departmentDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Department
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/roleActivity");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> RoleActivity
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/batchDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Batch Details
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/batchSubMapping");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Subject Mapping
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/batchStudMapping");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Student Mapping
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (

              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/facultysubjectmapping");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Faculty Subject Mapping
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/labdetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i>Lab Details
                  </a>
                </li>
              </ul>
            ) : null}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/hoadetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i>HOA Details
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||

              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/budgetDetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Budget Details
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/inventory");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Inventory
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/inventoryspec");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Inventory Specification
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/purchasedorder");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Purchased Order Details
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/stockinventory");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i>  Stock List
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/inventoryissueddetails");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i>  Inventory Issued List
                  </a>
                </li>
              </ul>
            ) : null}
            {/* <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/attendance");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Attendance
                  </a>
                </li>
              </ul> */}
            {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (

              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/updateStudentSemDate");
                  }} >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Student Config
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN")
              ? (
                <ul className="nav">
                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/sendFeedback");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i>Feedback</a>
                  </li>
                </ul>
              ) : null}
            <ul className="nav">
              <li
                className="active"
                onClick={() => {
                  onNavigate("/indent");
                }}
              >
                <a>
                  <i className="glyphicon glyphicon-home"></i> Indent Form
                </a>
              </li>
            </ul>
            <ul className="nav">

              <li
                className="active"
                onClick={() => {
                  onNavigate("/FDP");
                }}
              >
                <a>
                  <i className="glyphicon glyphicon-home"></i> FDP Form
                </a>
              </li>
            </ul>
            <ul className="nav">

              <li
                className="active"
                onClick={() => {
                  onNavigate("/ODP");
                }}
              >
                <a>
                  <i className="glyphicon glyphicon-home"></i> ODP Form
                </a>
              </li>
            </ul>
            <ul className="nav">

              <li
                className="active"
                onClick={() => {
                  onNavigate("/BPE");
                }}
              >
                <a>
                  <i className="glyphicon glyphicon-home"></i> BPE Form
                </a>
              </li>
            </ul>
            {/* {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
            (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL")? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/memberDetails");
                  }}
                >
                   <a>
                    <i className="glyphicon glyphicon-home"></i>Member Details</a>
                </li>
              </ul>
              ) : null} */}
 
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ? (

              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/reports");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Activity Report
                  </a>
                </li>
              </ul>
            ) : null}
            <ul className="nav"> <li className="active" onClick={() => {
              onNavigate("/markreports");
            }}>  <a>
                <i className="glyphicon glyphicon-home"></i> Mark Report  </a>
            </li></ul>
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/stockReports");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Stock Report
                  </a>
                </li>
              </ul>
            ) : null}
{(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
              (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/feedbackReport");
                  }}
                >
                  <a>
                    <i className="glyphicon glyphicon-home"></i> Feedback Report
                  </a>
                </li>
              </ul>
            ) : null}
            {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
            (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
            (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD")? (
             
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/attendanceReports");
                  }}
                >
                   <a>
                    <i className="glyphicon glyphicon-home"></i>Attendance Report</a>
                </li>
              </ul>
             ) : null}
              {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
            (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL")? (
              <ul className="nav">
                <li
                  className="active"
                  onClick={() => {
                    onNavigate("/announcement");
                  }}
                >
                   <a>
                    <i className="glyphicon glyphicon-home"></i>Announcement</a>
                </li>
              </ul>
              ) : null}
          </div>
        ) : null}

        {/* </div> */}
      </nav>
      <nav className="navbar_mob">
        {JSON.parse(localStorage.getItem("user")) != null ? (
          <>
            <div className="sidebar-nav">
              <div class="dropdown" ref={wrapperRef}>
                <span>
                  <BsList
                    style={{ height: "30px", width: "30px" }}
                    onClick={() => {
                      setShowMenu(!showMenu);
                    }}
                  />
                </span>
                <div
                  ref={wrapperRef}
                  class="dropdown-content"
                  style={{ display: showMenu ? "block" : "none" }}
                >
                  <li
                    className="active"
                    onClick={() => _onClick({ key: "home" })}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> Home
                    </a>
                  </li>

                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/facultyDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Faculty
                      </a>
                    </li>
                  ) : null}

                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/studentDetails");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> Student
                    </a>
                  </li>

                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/aluminiDetails");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> Alumni
                    </a>
                  </li>

                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/roleDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Role
                      </a>
                    </li>
                  ) : null}
                  {/* {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN" ? (
                    <ul className="nav">
                      <li
                        className="active"
                        onClick={() => {
                          onNavigate("/formRoleDetails");
                        }}
                      >
                        <a>
                          <i className="glyphicon glyphicon-home"></i> Form Role
                        </a>
                      </li>
                    </ul>
                  ) : null} */}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/subjectDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Subject
                      </a>
                    </li>
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/departmentDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Department
                      </a>
                    </li>
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/roleActivity");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i>{" "}
                        RoleActivity
                      </a>
                    </li>
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/batchDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Batch
                        Details
                      </a>
                    </li>
                  ) : null}

                

                  {/* {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                  "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/admissionDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Admission
                      </a>
                    </li>
                  ) : null} */}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/batchSubMapping");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Subject
                        Mapping
                      </a>
                    </li>
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/batchStudMapping");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Student
                        Mapping
                      </a>
                    </li>
                  ) : null}

                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==

                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/facultysubjectmapping");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Faculty Subject Mapping
                      </a>
                    </li>
                  ) : null}


                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/labdetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Lab Details
                      </a>
                    </li>
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/hoadetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> HOA Details
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||

                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/budgetDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Budget Details
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/inventory");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Inventory
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/inventoryspec");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Inventory Specification
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/purchasedorder");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Purchased Order Details
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/stockinventory");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Stock List
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/inventoryissueddetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Inventory Issued List
                      </a>
                    </li>
                  ) : null}
                  {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                    "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/updateStudentSemDate");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Student Config
                      </a>
                    </li>
                  ) : null}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ?
                    (<li
                      className="active"
                      onClick={() => {
                        onNavigate("/sendFeedback");
                      }} >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Feedback
                      </a>
                    </li>
                    ) : null}
                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/indent");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> Indent Form
                    </a>
                  </li>

                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/FDP");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> FDP Form
                    </a>
                  </li>
                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/ODP");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> ODP Form
                    </a>
                  </li>
                  <li
                    className="active"
                    onClick={() => {
                      onNavigate("/BPE");
                    }}
                  >
                    <a>
                      <i className="glyphicon glyphicon-home"></i> BPE Form
                    </a>
                  </li>
                  {/* {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
                 (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL")   ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/memberDetails");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Member Details
                     
                      </a>
                    </li>
                  ) : null} */}
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/reports");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i>Activity Report
                      </a>
                    </li>
                  ) : null}
                  <li className="active" onClick={() => {
                    onNavigate("/markreports");
                  }} > <a>
                      <i className="glyphicon glyphicon-home">
                      </i> Mark Report </a>
                  </li>
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "OFFICE ESTABLISHMENT") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/stockReports");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Stock Report
                      </a>
                    </li>
                  ) : null}
                {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||                    
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOS") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD") ||
                    (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/feedbackReport");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i>Feedback Report
                      </a>
                    </li>
                  ) : null}
                  {/* {JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() ==
                  "ADMIN" ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/deptStudMapping");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Department
                        Mapping
                      </a>
                    </li>
                  ) : null}  */}

                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
                 (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL") ||
                 (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "HOD")? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/attendanceReports");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Attendance Reports
                     
                      </a>
                    </li>
                  ) : null} 
                  {(JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "ADMIN") ||
                 (JSON.parse(localStorage.getItem("user")).roleName.toUpperCase() == "PRINCIPAL")   ? (
                    <li
                      className="active"
                      onClick={() => {
                        onNavigate("/announcement");
                      }}
                    >
                      <a>
                        <i className="glyphicon glyphicon-home"></i> Announcement
                     
                      </a>
                    </li>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}


      </nav>
    </>
  );
};
export default Navigation;
