import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const batchSubSlice = createSlice({
  name: "batchSubList",
  initialState: {
    loading: false,
    error: false,
    batchSubList: [],
    value: 0,
    curIndex: 0,
    batchSubDetails: [],
    submitBatchSubSuccess: "",
    saveBatchSubDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getBatchSub: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.batchSubList = payload;
    },
    saveBatchSub: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveBatchSubDataSuccess = payload;
    },
    submitBatchSub: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getBatchSub,
  submitBatchSub,
  setError,
  saveBatchSub,
} = batchSubSlice.actions;
export const batchSubSelector = (state) => state.batchSubs || [];
export default batchSubSlice.reducer;

export const fetchBatchSubs = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getBatchSubMappings)
    .then((response) => {
      console.log("response,", response);
      dispatch(getBatchSub(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addBatchSubDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveBatchSubMappings, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveBatchSub(response));
      dispatch(fetchBatchSubs());
    })
    .catch((er) => {
      console.log("failure of add batchSub");
      dispatch(saveBatchSub(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateBatchSubDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.updateBatchSubData, data)
    .then((response) => {
      //dispatch(setLoading());
      console.log(response, "success of update batchSub");
      dispatch(fetchBatchSubs());
    })
    .catch((er) => {
      console.log("failure of add batchSub");
      dispatch(saveBatchSub(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteBatchSub = (ids) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.deleteBatchSubData, ids)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of delete batchSub");
      dispatch(fetchBatchSubs());
    })
    .catch((er) => {
      console.log("failure of add batchSub");
      dispatch(saveBatchSub(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
