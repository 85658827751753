import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const memberSlice = createSlice({
  name: "memberList",
  initialState: {
    loading: false,
    error: false,
    memberList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    memberDetails: [],
    submitMemberSuccess: "",
    saveMemberDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getMember: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.memberList = payload;
    },
    saveMember: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveMemberDataSuccess = payload;
    },
    submitMember: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getMember,
  submitMember,
  setError,
  saveMember,
} = memberSlice.actions;
export const memberSelector = (state) => state.memberDetails || [];
export default memberSlice.reducer;

export const fetchMemberDetails = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getmembers)
    .then((response) => {
      console.log("response,", response);
      dispatch(getMember(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addMemberDetails =  async (data, dispatch)=> {
  try {
  //dispatch(setLoading());
  debugger;
  const response = await AsyncPost(API.savemembers, data);
      //dispatch(setLoading());
      dispatch(saveMember(response.data));      
      dispatch(fetchMemberDetails());
     // return response.data;
     // dispatch(fetchFaculties());
    }
    catch(error)  {
      console.log("failure of add member");
      alert("Failure of add member.");
      dispatch(saveMember(""));
      dispatch(setError(true));
      dispatch(setLoading());
}  
};
//export const UpdateMemberDetails = (data) => (dispatch) =>{ 
export const UpdateMemberDetails =  async (data, dispatch)=> {
  dispatch(setLoading());
  AsyncPost(API.updateMemberData, data)
    .then((response) =>{ 
      debugger;
      dispatch(setLoading());
      console.log(response, "success of update member");     
     
      dispatch(fetchMemberDetails());
    })
    .catch((er) => {
      console.log("failure of add member");
      alert("Failure of update member.");

      dispatch(saveMember(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteMember = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteMemberData + "?id=" + data, "")
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete member");
      dispatch(fetchMemberDetails());
    })
    .catch((er) => {
      console.log("failure of add member");
      dispatch(saveMember(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
