import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import "./styles.css";
import NewItem from "./newitem/index";
import {
  fetchActivities,
  activitySelector,
  deleteActivity,
} from "../../../app/redux/slices/activitySlice";
import Popup from "reactjs-popup";
import AdvanceTable from "./advanceTable";

const Miscellaneous = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, activityList } = useSelector(activitySelector);

  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [activity, setactivity] = useState();
  const [listOfActivity, setlistOfActivity] = useState(activityList || []);
  const [IsEdit, setIsEdit] = useState(false);

  const togglePopup = () => {
    onAssessmentSelection("Miscellaneous Activity");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setactivity();
    }
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(activityList);

  const deleteActivityFun = (item) => {
    // console.log(item, "deleteActivityFun");
    dispatch(deleteActivity(item.id,34,JSON.parse(localStorage.getItem("user")).departmentId));
  };

  const editActivity = (item) => {
    // console.log(item, "editActivity");
    setactivity({ ...item, data: JSON.parse(item.data) });
    setshowPopup(!showPopup);
    setIsEdit(true);

  };

  useEffect(() => {
    getActivityData();
  }, []);
  const getActivityData = () => {
    console.log("manoj");
    dispatch(fetchActivities(34,JSON.parse(localStorage.getItem("user")).departmentId));
  };

  useEffect(() => {
    if (!activityList) {
      return;
    }
    setlistOfActivity(activityList);
  }, [activityList]);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Miscellaneous Activity</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
              {/* <button
                className="btn btn-lg btn-warning btn-block form-roundedbtn4"
                type="submit"
              >
                <span className="glyphicon glyphicon-edit"></span> EDIT
              </button> */}
              {/*<button
                className="btn btn-lg btn-secondary btn-block form-roundedbtn2"
                type="submit"
              >
                <span className="glyphicon glyphicon-remove"></span> DELETE
              </button>*/}
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-container">
              <div className="fixed-table-body">
                <AdvanceTable
                  deleteActivityProp={(item) => deleteActivityFun(item)}
                  editActivityProp={(item) => editActivity(item)}
                  data={listOfActivity}
                />
              </div>
            </div>
          </div>

         {/* <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Activity Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Event:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
        {showPopup ? (
          <NewItem
          isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            activity={activity}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default Miscellaneous;
