import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';

import {
  addSubjectDetails,
  UpdateSubjectDetails,
} from "../../../app/redux/slices/subjectSlice";

const AddSubjectDetails = ({ isEdit, item, closePopup, subject }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!subject) {
      return;
    }
    runFillSubjectData.current = true;
    if (runFillSubjectData.current) {
      fillSubjectData(subject);
    }
  }, []);
  
  const [Id, setId] = useState("");
  const [SubjectCode, setSubjectCode] = useState("");
  const [SubjectName, setSubjectName]= useState("");
  const [SubSem, setSubSem]= useState("");
  const [SubYear, setSubYear]= useState("");
  const [SubjectShortForm, setSubjectShortForm]= useState("");

  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillSubjectData = useRef();
  const clearState = () => {
    setSubjectCode("");
    setSubjectName("");
    //setTopic("");
    setSubYear("");
    setSubSem("");
    setSubjectShortForm("");
   // setId("");
  };
  const sName = (val) => {
    if(/^[a-z A-Z]*$/.test(val.target.value) == false){
      //error message
    }     else
    {
    setSubjectName(val.target.value);
    }
  };
  const sCode = (val) => {
    if(/^[a-zA-Z 0-9-]*$/.test(val.target.value) == false){
      //error message
    }     else
    {
    setSubjectCode(val.target.value);
    }
  };
  const sSem = (e) => {
    setSubSem(e.target.value);
  };
  const sYear = (e) => {
    setSubYear(e.target.value);
  };
  
  const shortform = (val) => {
    
    setSubjectShortForm(val.target.value);
    
  };

  
  const saveSubjectDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var subjectData = {
      SubjectCode:SubjectCode,
      SubjectName : SubjectName,
      SubjectShortForm:SubjectShortForm,
      SubSem:SubSem,
      SubYear:SubYear,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addSubjectDetails(subjectData));
    closePopup();
    clearState();
  };

  const updateSubjectDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id:Id,
      SubjectCode:SubjectCode,
      SubjectName : SubjectName,
      SubjectShortForm:SubjectShortForm,
      SubSem:SubSem,
      SubYear:SubYear,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const subjectData = {
      ...subject,
        data: data
    };

    console.log("UpdateSubject", subjectData.data);
    dispatch(UpdateSubjectDetails(subjectData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !SubjectName||
      !SubjectCode||!SubSem||!SubYear
      ||!SubjectShortForm
    ) {
      setvalidate(false);
      seterrorMsg("Provide All Required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillSubjectData = (obj) => {
      runFillSubjectData.current = false;
    setId(obj.data.id);
    setSubjectCode(obj.data.subjectCode);
    setSubjectName(obj.data.subjectName);
    setSubjectShortForm(obj.data.subjectShortForm);
    setSubYear(obj.data.subYear);
    setSubSem(obj.data.subSem);    
    runFillSubjectData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
      <div className="col">
        <div className="formdata">
        <h2 className="heading">{isEdit ? "Edit Subject" : "Add New Subject"}</h2>          <button
            className="btn btn-lg btnclose"
            onClick={() => closePopupFun()}
            type="button"
          >
            X
          </button>
          <div className="row">
            
          </div>
          <div className="row formadduser">
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Subject Code</label>
                <input
                  className="form-control"
                  type="text"
                  value={SubjectCode}
                  onChange={sCode}
                  maxLength={15}
                />
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Sem</label>
                <select
                  className="form-select form-control"
                  onChange={sSem}
                  aria-label="Default select example"
                  value={SubSem}
                >
                  <option value="">select Sem</option>
                  <option value="Odd">Odd</option>
                  <option value="Even">Even</option>
                </select>
               
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Year</label>
                <select
                  className="form-select form-control"
                  onChange={sYear}
                  aria-label="Default select example"
                  value={SubYear}
                >
                  <option value="">select Year</option>
                  <option value={1}>1st Year</option>
                  <option value={2}>2nd Year</option>
                  <option value={3}>3rd Year</option>
                </select>
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Subject Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={SubjectName}
                  onChange={sName}
                  maxLength={150}
                />
              </div>       
            
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Subject Short Form</label>
                <input
                  className="form-control"
                  type="text"
                  value={SubjectShortForm}
                  onChange={shortform}
                  maxLength={4}
                />
              </div>
            
          </div>
          &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!subject ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveSubjectDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateSubjectDetails()}
                >
                  Update
                </button>
              )}
            </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubjectDetails;