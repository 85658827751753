import React from "react";
import TableComp from "./table";
import { TableStyles } from "./tableStyles";

const AdvanceTable = (props) => {
  const makeData = (count) => {
    let data = [];
    for (let i = 0; i < props.data.length; i++) {
      const obj = props.data[i];
      data.push({ ...obj, data: JSON.parse(obj.data) });
    }
    return data;
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "Topic",
        accessor: "data.Topic",
      },
      {
        Header: "Event Name",
        accessor: "data",
      },
      {
        Header: "Event Date",
        accessor: "data.EventDate",
      },
      {
      Header: "Action",
      accessor: "",
    },
    ],
    []
  );

  const data = React.useMemo(() => makeData(50), []);

  return (
    <TableStyles>
      <TableComp columns={columns} data={data} {...props} />
    </TableStyles>
  );
};

export default AdvanceTable;
