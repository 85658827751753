import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddIndentDetails from "./addIndentDetails/index";
//import MessageModal from "./popup/index";
import AdvanceTable from "./advanceTable";
import {
  fetchindents,
  indentSelector,
  deleteIndent,
} from "../../app/redux/slices/indentSlice";
import { AsyncGet, AsyncPost, AsyncGetFiles } from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import Popup from "reactjs-popup";
import "../../app/styles/addnew.css";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const IndentDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, indentList } = useSelector(indentSelector);
  const [Toggle, setToggle] = useState(1);
  console.log(Toggle,"Toggle");
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [indent, setIndent] = useState();
  const [listOfIndent, setlistOfIndent] = useState(indentList || []);
  const [showAdd, setshowAdd] = useState();
  const [budgetHeadList, setbudgetHeadList] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);


  const [TabMatced, setTabMatced] = useState([]);
  const [TabPending, setTabPending] = useState([]);
  const [TabApproved, setTabApproved] = useState([]);

  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [selectedFile, setselectedFile] = useState("");
  const [MessageOpen, setMessageOpen] = useState(false);
  const [Message, setMessage] = useState();
  const [assessmentMsg, setassessmentMsg] = useState("");
  const togglePopup = () => {
    debugger;
    onAssessmentSelection("Indent");
    setIsEdit(false);

    setshowPopup(!showPopup);
    if (!showPopup) {
      setIndent();
    }
  };
  const smsWhatsAppMsgIndent = () => {
    debugger;
    onAssessmentSelectionMsg("Message");
    setMessageOpen(!MessageOpen);
    if (!MessageOpen) {
      setMessage();
    }
    //AsyncPost(API.sendfacultysms + "?id=" + item.refNo + "&department=" + item.departmentName + "&currentFileQueueStatus=" + item.currentFileQueueStatus)
    // dispatch(deleteIndent(item.refNo && item.departmentName && item.currentFileQueueStatus));
  };
  const onAssessmentSelectionMsg = (assessmentMsg) => {
    setassessmentMsg(assessmentMsg);
  };

 
  /*const tabMatced = () => {
    debugger;
    setTabMatced(indentList.filter(x => x.currentFileQueueStatus == JSON.parse(localStorage.getItem("user")).indentRoleName));

  }
  const tabPending = () => {
    debugger;
    setTabPending(indentList.filter(x => x.currentFileQueueStatus != "Quotation Approved" && x.currentFileQueueStatus != JSON.parse(localStorage.getItem("user")).indentRoleName));

  }
  const tabApproved = () => {
    debugger;
    setTabApproved(indentList.filter(x => x.currentFileQueueStatus == "Quotation Approved"));
  }*/
  /*const closePopupFun = () => {
    closePopup();
    window.location.reload(false);
  };*/
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", indentList);

  const deleteIndentFun = (item) => {
    debugger;
    dispatch(deleteIndent(item.refNo));
  };


  const editIndent = (item) => {
    debugger;
    setIndent({ ...item, data: item });
    //localStorage.setItem("formDetails", item);

    // setrowData({ ...item, productInfo: item });
    setshowPopup(!showPopup);
    setIsEdit(true);

  };
  
  useEffect(() => {
    getIndentData();
  }, []);
  useEffect(() => {
    AsyncGet(API.getBudgetHeads)
      .then((res) => {
        console.log(res.data, "getBudgetHead");
        setbudgetHeadList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getIndentData = () => {
    dispatch(fetchindents());
  };

  useEffect(() => {
    debugger;
    if (!indentList) {
      return;
    }
    var sortData = [...indentList].sort(a => {
      return a.currentFileQueueStatus == JSON.parse(localStorage.getItem("user")).indentRoleName ? -1 : 1
    });
    if ((JSON.parse(localStorage.getItem("user")).indentRoleName == "Maker") || (JSON.parse(localStorage.getItem("user")).indentRoleName == "HOD")) {
      setlistOfIndent(sortData.filter(x => x.nameOfTheProgramme == JSON.parse(localStorage.getItem("user")).departmentId));
      sortData=(sortData.filter(x => x.nameOfTheProgramme == JSON.parse(localStorage.getItem("user")).departmentId));
    }
    else if ((JSON.parse(localStorage.getItem("user")).indentRoleName == null)) {
      setlistOfIndent([]);
      sortData=[];
    }

    else {
      setlistOfIndent(sortData);
    }
    setshowAdd(JSON.parse(localStorage.getItem("user")).indentRoleName);
  
   setTabMatced(sortData.filter(x => ((x.currentFileQueueStatus == JSON.parse(localStorage.getItem("user")).indentRoleName)||(x.principalFlag <3 && x.principalFlag !=0))));
   console.log(TabMatced,"TabMatched");
   setTabPending(sortData.filter(x => ((x.currentFileQueueStatus != JSON.parse(localStorage.getItem("user")).indentRoleName) &&(x.principalFlag == 0))));
   setTabApproved(sortData.filter(x => ((x.principalFlag == 3))));
  }, [indentList]);

  console.log("Before Render indentList", indentList);
  console.log("Before Render listOfIndent", listOfIndent);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Indent</h2>

            {(JSON.parse(localStorage.getItem("user")).indentRoleName) === "Maker"
              || (JSON.parse(localStorage.getItem("user")).indentRoleName) === "HOD" ? (
              <div className="but col text-end">
                <button
                  onClick={() => togglePopup()}
                  className="btn btn-lg btn-success btn-block form-roundedbtn3"
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  data-bs-whatever="@mdo"
                >
                  <span className="glyphicon glyphicon-plus"></span> ADD NEW
                </button>

              </div>
            ) : null}
          </div>
         
          <div className="row mb-5">
          

            <Tabs
              defaultActiveKey="Tab"
              id="fill-tab-example"
              className="mb-3"
              fill
            >

              <Tab eventKey="Tab" title="Assigned">
                  <div className="bootstrap-table">
                  <div className="fixed-table-toolbar"></div>
                  <div className="fixed-table-container">
                    <div
                      className="fixed-table-body"
                      style={{
                        height: "65vh",
                        overflow: "auto",
                        marginTop: 20,
                       
                      }}
                    >
                      <AdvanceTable
                        deleteIndentProp={(item) => deleteIndentFun(item)}
                        editIndentProp={(item) => editIndent(item)}
                        //SendWhatsAppMsg={(item) => smsIndent(item)}
                        data={TabMatced}
                      />
                    </div>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="Tab2" title="In Progress" >
                <div className="bootstrap-table">
                  <div className="fixed-table-toolbar"></div>
                  <div className="fixed-table-container">
                    <div
                      className="fixed-table-body"
                      style={{
                        height: "65vh",
                        overflow: "auto",
                        marginTop: 20,
                      }}
                    >
                      <AdvanceTable
                        deleteIndentProp={(item) => deleteIndentFun(item)}
                        editIndentProp={(item) => editIndent(item)}
                        //SendWhatsAppMsg={(item) => smsIndent(item)}
                        data={TabPending}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Tab3" title="Approved">
                <div className="bootstrap-table">
                  <div className="fixed-table-toolbar"></div>
                  <div className="fixed-table-container">
                    <div
                      className="fixed-table-body"
                      style={{
                        height: "65vh",
                        overflow: "auto",
                        marginTop: 20,
                      }}
                    >
                      <AdvanceTable
                        deleteIndentProp={(item) => deleteIndentFun(item)}
                        editIndentProp={(item) => editIndent(item)}
                       // smsIndentProp={(item) => smsIndent(item)}
                        data={TabApproved}
                      />
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
              {/*<li class="nav-item ">
                <a class="nav-link" data-bs-toggle="tab" role="tab" style={{ fontSize: "16px", textAlign: "left" }} onClick={() => ToggleTab(2)} >
                  Tab2
                </a>
              </li>
              <li class="nav-item ">
                <a class="nav-link" data-bs-toggle="tab" role="tab" style={{ fontSize: "16px", textAlign: "left" }} onClick={() => ToggleTab(3)}>
                  Tab3
                </a>
                  </li>*/}
          
          </div>
         
        </div>
        {showPopup ? (
          <AddIndentDetails
            isEdit={IsEdit}

            item={assessment}
            closePopup={togglePopup}
            indent={indent}
            BudgetHeadList={budgetHeadList}

          />
        ) : null}
       {/* {MessageOpen ? (
          
          <MessageModal          
          closePopupMgs={smsWhatsAppMsgIndent}
           //BudgetHeadList={budgetHeadList}

          />
        ) : null}*/}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default IndentDetails;
