import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const batchStudSlice = createSlice({
  name: "batchStudList",
  initialState: {
    loading: false,
    error: false,
    batchStudList: [],
    value: 0,
    curIndex: 0,
    batchStudDetails: [],
    submitBatchStudSuccess: "",
    saveBatchStudDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getBatchStud: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.batchStudList = payload;
    },
    saveBatchStud: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveBatchStudDataSuccess = payload;
    },
    submitBatchStud: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getBatchStud,
  submitBatchStud,
  setError,
  saveBatchStud,
} = batchStudSlice.actions;
export const batchStudSelector = (state) => state.batchStuds || [];
export default batchStudSlice.reducer;

export const fetchBatchStuds = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getBatchStudMappings)
    .then((response) => {
      console.log("response,", response);
      dispatch(getBatchStud(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addBatchStudDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveBatchStudMappings, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveBatchStud(response.data));
      dispatch(fetchBatchStuds());
    })
    .catch((er) => {
      console.log("failure of add batchStud");
      dispatch(saveBatchStud(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateBatchStudDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.updateBatchStudData, data)
    .then((response) => {
      //dispatch(setLoading());
      console.log(response, "success of update batchStud");
      dispatch(fetchBatchStuds());
    })
    .catch((er) => {
      console.log("failure of add batchStud");
      dispatch(saveBatchStud(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteBatchStud = (ids, batchId) => (dispatch) => {
  debugger
  console.log(batchId);
  dispatch(setLoading());
  AsyncPost(API.deleteBatchStudData+"?batchId="+batchId, ids)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of delete batchStud");
      dispatch(fetchBatchStuds());
    })
    .catch((er) => {
      console.log("failure of add batchStud");
      dispatch(saveBatchStud(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
