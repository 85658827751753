import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AdvanceTable from "./advanceTable";
import {
  fetchRoleMappingDetails,
  roleActivitySelector,
  UpdateRoleMappingDetails,
} from "../../app/redux/slices/roleActivityMappingSlice";

import Popup from "reactjs-popup";
import AddRoleActivityMapping from "./addRoleActivityMapping";

const RoleActivityMapping = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, roleActivityDetails, rolesList } =
    useSelector(roleActivitySelector);

  const [showPopup, setshowPopup] = useState(false);
  const [IsEdit, setIsEdit] = useState(false);
  const [assessment, setassessment] = useState("");
  const [roleActivity, setRoleActivity] = useState();
  const [listOfRoleActivity, setlistOfRoleActivity] = useState(
    roleActivityDetails || []
  );
  const [listOfRoles, setlistOflistOfRoles] = useState(rolesList || []);

  const togglePopup = () => {
    onAssessmentSelection("Role Activity Mapping Details");
    setshowPopup(!showPopup);    setIsEdit(false);

  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", roleActivityDetails);

  //  const deleteRoleMappingFun = (item) => {

  //    dispatch(deleteRoleActivityMapping(item.id));
  //  };

  const editRoleMapping = (item) => {
    setRoleActivity({ ...item, data: item });
    setshowPopup(!showPopup);
    setIsEdit(true);
  };

  useEffect(() => {
    getRoleActivity();
  }, []);

  const getRoleActivity = () => {
    debugger;
    dispatch(fetchRoleMappingDetails());
  };

  useEffect(() => {
    if (!roleActivityDetails) {
      return;
    }
    setlistOfRoleActivity(roleActivityDetails);
  }, [roleActivityDetails]);

  console.log("Before Render roleActivityDetails", roleActivityDetails);
  console.log("Before Render listOfRoleActivity", listOfRoleActivity);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Role Activity Details</h2>
            <div className="but col text-end">
              {/* <button
               onClick={() => togglePopup()}
               className="btn btn-lg btn-success btn-block form-roundedbtn3"
               type="submit"
               data-bs-toggle="modal"
               data-bs-target="#exampleModal"
               data-bs-whatever="@mdo"
             >
               <span className="glyphicon glyphicon-plus"></span> ADD NEW
             </button> */}
              {/* <button
               className="btn btn-lg btn-secondary btn-block form-roundedbtn2"
               type="submit"
             >
               <span className="glyphicon glyphicon-remove"></span> DELETE
             </button> */}
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar"></div>
            <div className="fixed-table-container">
              <div
                className="fixed-table-body"
                style={{
                  height: "65vh",
                  overflow: "auto",
                }}
              >
                <AdvanceTable
                  //  deleteRoleMappingProp={(item) => deleteRoleMappingFun(item)}
                  editRoleMappingProp={(item) => editRoleMapping(item)}
                  data={listOfRoleActivity}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Title:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Event:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddRoleActivityMapping
            // isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            roleActivity={roleActivity}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default RoleActivityMapping;
