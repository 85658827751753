import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import Moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import {
  addAluminiDetails,
  fetchAluminies,
  UpdateAluminiDetails,
} from "../../../app/redux/slices/aluminiSlice";
import { API } from "../../../app/services/endpoints";
import { AsyncGet, AsyncPost, AsyncGetFiles } from "../../../app/services/https";


const AddAluminiDetails = ({ isEdit, item, alumini, closePopup }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!alumini) {
      return;
    }
    runFillAluminiData.current = true;
    if (runFillAluminiData.current) {
      fillAluminiData(alumini);
    }
  }, []);



  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);



  const [Id, setId] = useState();
  const [A_FirstName, setFirstName] = useState("");
  const [A_MiddleName, setMiddleName] = useState("");
  const [A_LastName, setLastName] = useState("");
  const [AlumniId, setAlumniId] = useState("");
  const [Gender, setGender] = useState("");
  const [DepartmentId, setDepartment] = useState();
  const [DeparmentList, setDeparmentList] = useState([]);
  const [PassOutYear, setPassOutYear] = useState("");
  const [Contact_Primary, setContact_Primary] = useState("");
  const [Contact_Alt, setContact_Alt] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [Alumini_Photo, setAlumini_Photo] = useState("");
  const [Profession, setProfession] = useState("");
  const [CurrentDesignation, setCurrentDesignation] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddr, setCompanyAddr] = useState("");
  const [CurrentWorkLocation, setCurrentWorkLocation] = useState("");
  const [CommunicationAddr, setCommunicationAddr] = useState("");
  const [PermanentAddr, setPermanentAddr] = useState("");
  const [ContributionToOrganisation, setContributionToOrganisation] = useState("");
  const [PanNumber, setPanNumber] = useState("");
  const [FundContribution, setFundContribution] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [panErrorMsg, setpanErrorMsg] = useState("");

  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const emailRef = useRef();
  const [alumniMobErrorMsg, setalumniMobErrorMsg] = useState("");
  const [selectedFile, setselectedFile] = useState("");
  const [filesList, setfilesList] = useState([]);
  const [alumniMob1ErrorMsg, setalumniMob1ErrorMsg] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const runFillAluminiData = useRef();
  const clearState = () => {
    setId("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setGender("");
    setDepartment("");
    setPassOutYear("");
    setContact_Primary("");
    setContact_Alt("");
    setEmailID("");
    setAlumini_Photo("");
    setProfession("");
    setCurrentDesignation("");
    setCompanyName("");
    setCompanyAddr("");
    setCurrentWorkLocation("");
    setCommunicationAddr("");
    setPermanentAddr("");
    setContributionToOrganisation("");
    setPanNumber("");
    setFundContribution("");
    setselectedFile("");
    setCreatedBy("");
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    //setdatevalidation(true);
    //setFromdatevalidation(false);
    console.log("reseted");
  };
  const fName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setFirstName(val.target.value);
    }
  };
  const mName = (val) => {

    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setMiddleName(val.target.value);
    }
  };
  const lName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setLastName(val.target.value);
    }
    };
  const alumniId = (val) => {
    setAlumniId(val.target.value);
    // if (/^[a-zA-Z0-9]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setAlumniId(val.target.value);
    // }
  };
  const genderVal = (val) => {
    setGender(val.target.value);
  };
  const dept = (val) => {
    setDepartment(val.target.value);
  };
  const passOutYear = (val) => {
    setPassOutYear(val.target.value);
  };
  /*const passOutYear = (val) => {
    var year = val.target.value.replace(/\D/g, "");
    setPassOutYear(year);
  };*/
  const contactPrimary = (e) => {

    var phNo1 = e.target.value.replace(/\D/g, "");

    if (phNo1.length == 10) {

      setContact_Primary(phNo1);

      setalumniMobErrorMsg("")

    }

    else {
      setContact_Primary(phNo1);

      setalumniMobErrorMsg("Please enter valid Mobile No")

    }

  };
  const contactAlt = (e) => {

    var phNo2 = e.target.value.replace(/\D/g, "");

    if (phNo2.length == 10) {

      setContact_Alt(phNo2);

      setalumniMob1ErrorMsg("")

    }

    else {
      setContact_Alt(phNo2);
      if (phNo2 != '')
        setalumniMob1ErrorMsg("Please enter valid Mobile No")
      else
        setalumniMob1ErrorMsg("")
    }

  };

  const emailID = (val) => {
    if (validator.isEmail(val.target.value)) {
      setEmailErrorMsg('')
      emailRef.current.style.border = "";
    } else {
      emailRef.current.focus();
      emailRef.current.style.border = "1px solid red";
      setEmailErrorMsg('Enter valid Email!')
    }
    setEmailID(val.target.value);
  };
  const alumini_Photo = (event) => {
    setAlumini_Photo(event.target.files[0]);
  }
  const profession = (val) => {
    setProfession(val.target.value);
  }
  const currentDesignation = (val) => {
    setCurrentDesignation(val.target.value);
  }
  const companyAddr = (val) => {
    setCompanyAddr(val.target.value);
  }
  const companyName = (val) => {
    setCompanyName(val.target.value);
  }
  const currentWorkLocation = (val) => {
    setCurrentWorkLocation(val.target.value);
    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setCurrentWorkLocation(val.target.value);
    // }
  }
  const communicationAddr = (val) => {
    setCommunicationAddr(val.target.value);
  }
  const permanentAddr = (val) => {
    setPermanentAddr(val.target.value);
  }
  const contToOrganisation = (val) => {
    setContributionToOrganisation(val.target.value);
  }
  const panNumber = (val) => {
    if (/^[a-zA-Z0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      if (val.target.value.length < 10)

        setpanErrorMsg("Pan should be 10 digits")
      else
        setpanErrorMsg("")
      setPanNumber(val.target.value);
    }


  }
  const fundContribution = (val) => {
    setFundContribution(val.target.value);
  }


  const saveAluminiDetails = async () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var aluminiData = {
      Id: Id,
      AlumniId: AlumniId,
      AlumniFirstName: A_FirstName,
      AlumniMiddleName: A_MiddleName,
      AlumniLastName: A_LastName,
      PassedOutYear: PassOutYear,
      DepartmentId: Number(DepartmentId),
      Gender: Gender,
      AlumniMobileNumber_1: Contact_Primary,
      AlumniMobileNumber_2: Contact_Alt,
      Email: EmailID,
      Photo: "",
      Profession: Profession,
      CurrentDesignation: CurrentDesignation,
      CompanyNameOrOrganisation: CompanyName,
      AddressOfCompanyOrOrganisation: CompanyAddr,
      CurrentWorkingPlace: CurrentWorkLocation,
      CommunicationAddress: CommunicationAddr,
      PermanentAddress: PermanentAddr,
      ContributionTowardsInstitution: ContributionToOrganisation,
      FundContribution: FundContribution,
      PanNumber: PanNumber,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };
    try {
      console.log("aluminiData", aluminiData);
      const resp = await addAluminiDetails(aluminiData, dispatch);
      //debugger;
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  /*  try{
      console.log("aluminiData", aluminiData);
    const resp = await  addAluminiDetails(aluminiData,dispatch);
    //dispatch(addAluminiDetails(aluminiData));
    await onFileUpload(resp);
   
    closePopup();
    dispatch(fetchAluminies)
    //window.location.reload(true);
  }
    catch(error)
      {
        console.log(error);
      closePopup();
      dispatch(fetchAluminies);
      window.location.reload(true);
      }
  };*/

  const updateAluminiDetails = async () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      AlumniId: AlumniId,
      AlumniFirstName: A_FirstName,
      AlumniMiddleName: A_MiddleName,
      AlumniLastName: A_LastName,
      PassedOutYear: PassOutYear,
      DepartmentId: Number(DepartmentId),
      Gender: Gender,
      AlumniMobileNumber_1: Contact_Primary,
      AlumniMobileNumber_2: Contact_Alt,
      Email: EmailID,
      Photo: "",
      Profession: Profession,
      CurrentDesignation: CurrentDesignation,
      CompanyNameOrOrganisation: CompanyName,
      AddressOfCompanyOrOrganisation: CompanyAddr,
      CurrentWorkingPlace: CurrentWorkLocation,
      CommunicationAddress: CommunicationAddr,
      PermanentAddress: PermanentAddr,
      ContributionTowardsInstitution: ContributionToOrganisation,
      FundContribution: FundContribution,
      PanNumber: PanNumber,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };

    const aluminiData = {
      ...alumini,
      data: data
    };
    try {
      await UpdateAluminiDetails(aluminiData.data, dispatch);
      await onFileUpload();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };
  /* console.log("UpdateAlumini", aluminiData.data);
   dispatch(UpdateAluminiDetails(aluminiData.data));
   await onFileUpload();
   window.location.reload(true);
   //dispatch(fetchAluminies)
   closePopup();
 };*/

  const validationFun = () => {
    setvalidate(true);
    if (
      !A_FirstName ||
      !A_LastName ||
      !Gender ||
      !DepartmentId ||
      !ContributionToOrganisation ||
      !CommunicationAddr ||
      !PermanentAddr ||
      !Contact_Primary ||

      !EmailID

    ) {
      setvalidate(false);
      seterrorMsg("Provide all required fields");
      return false;
    }
    else {
      return true;
    }
  };



  const onFileUpload = async (res) => {
    debugger
    if (selectedFile?.length < 1) {
      dispatch(fetchAluminies());
      closePopup();
      clearState();
      return;
    }


    console.log(res, "response");
    const aluminiData = res ? res[0] : alumini;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("TypeofUser", "Alumni");

    formData.append("id", Number(aluminiData?.id || 0));


    try {
      debugger
      const res = await AsyncPost(API.uploadFacultyfiles, formData);
      dispatch(fetchAluminies());
      closePopup();
      clearState();
      //window.location.reload(true);

    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    debugger

    AsyncGetFiles(API.downloadAluminiFiles + "?id=" + alumini.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
      .catch((err) => {
        console.log(err);
      });
  };


  const closePopupFun = () => {
    closePopup();
    window.location.reload(false);
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };


  const fillAluminiData = (obj) => {
    runFillAluminiData.current = false;
    setId(obj.data.id);
    setAlumniId(obj.data.alumniId)
    setFirstName(obj.alumniFirstName);
    setMiddleName(obj.alumniMiddleName);
    setLastName(obj.alumniLastName);
    setGender(obj.gender);
    setDepartment(obj.departmentId);
    debugger
    setPassOutYear(obj.passedOutYear);
    setContact_Primary(obj.alumniMobileNumber_1);
    setContact_Alt(obj.alumniMobileNumber_2);
    setEmailID(obj.email);
    setAlumini_Photo(obj.photo);
    setProfession(obj.profession);
    setCurrentDesignation(obj.currentDesignation);
    setCompanyName(obj.companyNameOrOrganisation);
    setCompanyAddr(obj.addressOfCompanyOrOrganisation);
    setCurrentWorkLocation(obj.currentWorkingPlace);
    setCommunicationAddr(obj.communicationAddress);
    setPermanentAddr(obj.permanentAddress);
    setContributionToOrganisation(obj.data.contributionTowardsInstitution);
    setPanNumber(obj.panNumber);
    setFundContribution(obj.fundContribution);
    seterrorMsg("");
    debugger
    setfilesList(obj.files);
    runFillAluminiData.current = true;
  };

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Alumni" : "Add New Alumni"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>First Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={A_FirstName}
                  onChange={fName}
                  maxLength={150}
                />
              </div>
              {!A_FirstName && validate ? (
                <h6 style={{ color: "red" }}>{"Enter first name"}</h6>
              ) : null}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Middle Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={A_MiddleName}
                  onChange={mName}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Last Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={A_LastName}
                  onChange={lName}
                  maxLength={150}
                />
              </div>
              {!A_LastName && validate ? (
                <h6 style={{ color: "red" }}>{"Enter last name"}</h6>
              ) : null}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Alumni ID</label>
                <input
                  className="form-control"
                  type="text"
                  value={AlumniId}
                  onChange={alumniId}
                  maxLength={15}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Gender</label>
                <select
                  className="form-select form-control"
                  onChange={genderVal}
                  aria-label="Default select example"
                  value={Gender}
                >
                  <option value="">--Select Gender--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>
              {!Gender && validate ? (
                <h6 style={{ color: "red" }}>{"Select gender"}</h6>
              ) : null}

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Department</label>
                <select
                  className="form-select form-control"
                  onChange={dept}
                  aria-label="Default select example"
                  value={DepartmentId}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div>
              {!DepartmentId && validate ? (
                <h6 style={{ color: "red" }}>{"Select Department"}</h6>
              ) : null}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Passed Out Year</label>
                <DatePicker
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                  maxDate={new Date()}
                  selected={
                    PassOutYear ? new Date(`${PassOutYear}/01/01`) : new Date()
                  }
                  onChange={(date) => {
                    const dateIs = {
                      target: { value: dayjs(date).format("YYYY") },
                    };
                    passOutYear(dateIs);
                  }}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Primary Contact No.</label>
                <input
                  className="form-control"
                  type="text"
                  value={Contact_Primary}
                  onChange={contactPrimary}
                  minLength={10}
                  maxLength={10}
                />
                <h6 style={{ color: "red" }}>{alumniMobErrorMsg}</h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Alternative Contact No.</label>
                <input
                  className="form-control"
                  type="text"
                  value={Contact_Alt}
                  onChange={contactAlt}
                  minLength={10}
                  maxLength={10}
                />
                <h6 style={{ color: "red" }}>{alumniMob1ErrorMsg}</h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Email Address</label>
                <input
                  ref={emailRef}
                  className="form-control"
                  type="text"
                  value={EmailID}
                  onChange={(e) => emailID(e)}
                />
                <h6 style={{
                  color: 'red',
                }}>{emailErrorMsg}</h6>
              </div>
              {!EmailID && validate ? (
                <h6 style={{ color: "red" }}>{"Enter EmailID"}</h6>
              ) : null}

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Profession</label>
                <select
                  className="form-select form-control"
                  onChange={profession}
                  aria-label="Default select example"
                  value={Profession}
                >
                  <option value="">select Profession</option>
                  <option value="Employee">Employee</option>
                  <option value="Entrepreneur">Entrepreneur</option>
                  <option value="Business">Business</option>
                </select>
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Current Designation</label>
                <input
                  className="form-control"
                  type="text"
                  value={CurrentDesignation}
                  onChange={currentDesignation}
                  maxLength={150}
                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Current Organization</label>
                <input
                  className="form-control"
                  type="text"
                  value={CompanyName}
                  onChange={companyName}
                  maxLength={200}
                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Organization Address</label>
                <textarea
                  className="form-control"
                  type="text"
                  value={CompanyAddr}
                  onChange={companyAddr}
                  maxLength={500}
                >
                </textarea>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Current work location</label>
                <input
                  className="form-control"
                  type="text"
                  value={CurrentWorkLocation}
                  onChange={currentWorkLocation}
                  maxLength={50}
                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Communication Address</label>
                <textarea

                  className="form-control"
                  type="text"
                  value={CommunicationAddr}
                  onChange={communicationAddr}
                  maxLength={500}
                >
                </textarea>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Permanent Address</label>
                <textarea
                  className="form-control"
                  type="text"
                  value={PermanentAddr}
                  onChange={permanentAddr}
                  maxLength={500}
                >
                </textarea>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">

                <label><span style={{ color: "red" }}>*</span>Contribution Towards Institution</label>
                <select
                  className="form-control"
                  onChange={contToOrganisation}
                  aria-label="Default select example"
                  value={ContributionToOrganisation}
                >
                  <option value="">select Contribution</option>
                  <option value="Scholarship Support">Scholarship Support</option>
                  <option value="Resource Person">Resource Person</option>
                  <option value="Industrial Development Support">Industrial Development Support</option>
                  <option value="Visiting Lecturer">Visiting Lecturer</option>
                  <option value="Jury for Technical Events">Jury for Technical Events</option>
                  <option value="Startups/Projects Development">Startups/Projects Development</option>
                  <option value="Training offering">Training offering</option>
                  <option value="Industrial Visiting offering">Industrial Visiting offering</option>
                  <option value="Others">Others</option>

                </select>

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Pan Number</label>
                <input
                  className="form-control"
                  type="text"
                  value={PanNumber}
                  onChange={panNumber}
                  maxLength={10}
                />
                <h6 style={{
                  color: 'red',
                }}>{panErrorMsg}</h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Fund Contribution</label>
                <select
                  className="form-select form-control"
                  onChange={fundContribution}
                  aria-label="Default select example"
                  value={FundContribution}
                >
                  <option value="">select FundContribution</option>
                  <option value="Bank">Bank</option>
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                  <option value="RTGS">RTGS</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Alumini Photo</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!alumini ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveAluminiDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateAluminiDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAluminiDetails;