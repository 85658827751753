import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import Moment from "moment";
/*import {
 // addSubjectDetails,
 UpdateStudentSemDateDetails,
} from "../../app/redux/slices/updateStudentSemDateSlice";*/
import dayjs, { Dayjs } from "dayjs";
import { AsyncPost } from "../../app/services/https";
//import { AsyncGet , AsyncPost,AsyncGetFiles} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
const AddUpdateStudentSemDateDetails = ({ isEdit, item,closePopup,  UpdateStudentSemDate }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!UpdateStudentSemDate) {
      return;
    }
    runFillUpdateStudentSemDate.current = true;
    if (runFillUpdateStudentSemDate.current) {
      fillUpdateStudentSemDate(UpdateStudentSemDate);
    }
  }, []);
  
  const [Id, setId] = useState("");
  const [FirstYearStartDate, setFirstYearStartDate] = useState("");
  const [FirstYearEndDate, setFirstYearEndDate] = useState("");
  const [SecondYearStartDate, setSecondYearStartDate] = useState("");
  const [SecondYearEndDate, setSecondYearEndDate] = useState("");
  const [ThirdYearStartDate, setThirdYearStartDate] = useState("");
  const [ThirdYearEndDate, setThirdYearEndDate] = useState("");
  const [FeedbackStartDate, setFeedbackStartDate] = useState("");
  const [FeedbackEndDate, setFeedbackEndDate] = useState("");

  const [Firststartdatevalidation, setFirststartdatevalidation] = useState(false);
  const [firstenddatevalidation, setfirstenddatevalidation] = useState(true);

  const [Secondstartdatevalidation, setSecondstartdatevalidation] = useState(false);
  const [Secondenddatevalidation, setSecondenddatevalidation] = useState(true);
 
  const [Thirdstartdatevalidation, setThirdstartdatevalidation] = useState(false);
  const [Thirdenddatevalidation, setThirdenddatevalidation] = useState(true);

  const [Feedbackstartdatevalidation, setFeedbackstartdatevalidation] = useState(false);
  const [Feedbackenddatevalidation, setFeedbackenddatevalidation] = useState(true);

  const [Sem, setSubSem]= useState("");
 

  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillUpdateStudentSemDate = useRef();
  const clearState = () => {
    debugger;
    setFirstYearStartDate("");
    setFirstYearEndDate("");
    setSecondYearStartDate("");
    setSecondYearEndDate("");
    setThirdYearStartDate("");
    setThirdYearEndDate("");
    setvalidate(false);
    setFirststartdatevalidation(false);
    setfirstenddatevalidation(true);
    setSecondstartdatevalidation(false);
    setSecondenddatevalidation(true);
    setThirdstartdatevalidation(false);
    setThirdenddatevalidation(true);
   setSubSem("");
   setFeedbackStartDate("");
   setFeedbackEndDate("");
   setFeedbackstartdatevalidation("");
   setFeedbackenddatevalidation("");
    
   
  };

  const firststartdate = (val) => {
    setFirstYearStartDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setFirstYearEndDate("");
    setfirstenddatevalidation(false);
  };
  const firstenddate = (val) => {
    if (val.target.value != "") setFirststartdatevalidation(true);
    else setFirststartdatevalidation(false);
    setFirstYearEndDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };

  const secoundstartdate = (val) => {
    setSecondYearStartDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setSecondYearEndDate("");
    setSecondenddatevalidation(false);
  };
  const secondenddate = (val) => {
    if (val.target.value != "") setSecondstartdatevalidation(true);
    else setSecondstartdatevalidation(false);
    setSecondYearEndDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  
  const thirdstartdate = (val) => {
    setThirdYearStartDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setThirdYearEndDate("");
    setThirdenddatevalidation(false);
  };
  const thirdenddate = (val) => {
    if (val.target.value != "") setThirdstartdatevalidation(true);
    else setThirdstartdatevalidation(false);
    setThirdYearEndDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };

  const feedbackstartdate = (val) => {
    setFeedbackStartDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setFeedbackEndDate("");
    setFeedbackenddatevalidation(false);
  };
  const feedbackenddate = (val) => {
    if (val.target.value != "") setFeedbackstartdatevalidation(true);
    else setFeedbackstartdatevalidation(false);
    setFeedbackEndDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  
  const sSem = (e) => {
    setSubSem(e.target.value);
  };
  

  
const updateStudentSemDate = async () =>{
  debugger;
  seterrorMsg("");
  const error = validationFun();
  if (!error) {
    return;
  }
  var data={
    Sem:Sem,
    FirstYearStartDate:FirstYearStartDate,
    FirstYearEndDate:FirstYearEndDate,
    SecondYearStartDate:SecondYearStartDate,
    SecondYearEndDate:SecondYearEndDate,
    ThirdYearStartDate:ThirdYearStartDate,
    ThirdYearEndDate:ThirdYearEndDate,
    FeedbackEndDate:FeedbackEndDate,
    FeedbackStartDate:FeedbackStartDate,
   // CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
    //CreatedDate: new Date(),
    ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
    ModifiedDate: new Date()
  }
    
  const updateStudentSemDate = {
    ...UpdateStudentSemDate,
      data: data
  };
    try {
      const response = await AsyncPost(API.updateStudentSemDateDetails, updateStudentSemDate.data);
debugger;
      if(response.data.length<=0){
      alert ("Updated Successfully");
      clearState();
    }
    else {
     alert ("Something went wrong");
    }
   
      
     
    } catch (error) {
      console.log(error);
     
      clearState();
    }
  
  /*AsyncPost(
    API.updateStudentSemDateDetails +
      "Sem=" +
      Sem +
      "&FirstYearStartDate=" +
      FirstYearStartDate +
      "&FirstYearEndDate=" +
      FirstYearEndDate+
      "&SecondYearStartDate="+
      SecondYearStartDate+
      "&SecondYearEndDate=" +
      SecondYearEndDate +
      "&ThirdYearStartDate=" +
      ThirdYearStartDate+
      "&ThirdYearEndDate="+
      ThirdYearEndDate+
      "&FeedbackStartDate="+
      FeedbackStartDate+
      "&FeedbackEndDate="+
      FeedbackEndDate

  )
 
  .then((response) => {
    if(response.data="Success"){
      alert ("Updated Successfully");
      clearState();
    }
    else {
     alert ("Something went wrong");
    }
   
    
    
  })
  .catch((er) => {
    console.log(er,"failure of update Student Sem Date");
    
  });*/


}
  const validationFun = () => {
    debugger;
    setvalidate(true);
    if (
      !FirstYearEndDate||
      !FirstYearStartDate||!Sem||!SecondYearStartDate
      ||!SecondYearEndDate || !ThirdYearStartDate ||!ThirdYearEndDate ||!FeedbackStartDate
      ||!FeedbackEndDate
    ) {
      setvalidate(false);
      seterrorMsg("Provide All Required field");
      return false;
    }
    else {
      return true;
    }
  };
  const closePopupFun = () => {
    clearState();
    closePopup();
   
    //window.location.reload(false);
  };
  
  const fillUpdateStudentSemDate = (obj) => {
      runFillUpdateStudentSemDate.current = false;
    setId(obj.data.id);
    setFirstYearStartDate(obj.data.firstYearStartDate);
    setFirstYearEndDate(obj.data.firstYearEndDate);

    setSecondYearStartDate(obj.data.secondYearStartDate);
    setSecondYearEndDate(obj.data.secondYearEndDate);

    setThirdYearStartDate(obj.data.thirdYearStartDate);
    setThirdYearEndDate(obj.data.thirdYearEndDate);

    setFeedbackStartDate(obj.data.feedbackStartDate);
    setFeedbackEndDate(obj.data.feedbackEndDate);

   setSubSem(obj.data.subSem);


   
   
    runFillUpdateStudentSemDate.current = true;
  };

  return (
    
  //  <div        className="container pb-5 pt-3 px-4">
      <div className="row mb-4">
     
        <div className="formdata">
        <h2 className="heading">Student Configuration</h2>      
                     
          <div className="row formadduser">
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Sem</label>
                <select
                  className="form-select form-control"
                  onChange={sSem}
                  aria-label="Default select example"
                  value={Sem}
                >
                  <option value="">select Sem</option>
                  <option value="Odd">Odd</option>
                  <option value="Even">Even</option>
                </select>
               
              </div> 
              {!Sem && validate ? (
                  <h6 style={{ color: "red" }}>{"Select the Sem"}</h6>
                ) : null}
            </div>
            <div className="row formadduser">
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> First Year Sem Start Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FirstYearStartDate).format("YYYY-MM-DD")}
                  disabled={Firststartdatevalidation}
                  onChange={firststartdate}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!FirstYearStartDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select Start Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>First Year Sem End Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  disabled={firstenddatevalidation}
                  min={dayjs(FirstYearStartDate).format("YYYY-MM-DD")}
                  value={dayjs(FirstYearEndDate).format("YYYY-MM-DD")}
                 // max={Moment().format("YYYY-MM-DD")}
                  onChange={firstenddate}
                />
                {!FirstYearEndDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select End Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Second Year Sem Start Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(SecondYearStartDate).format("YYYY-MM-DD")}
                  disabled={Secondstartdatevalidation}
                  onChange={secoundstartdate}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!SecondYearStartDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select Start Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Second Year Sem End Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  disabled={Secondenddatevalidation}
                  min={dayjs(SecondYearStartDate).format("YYYY-MM-DD")}
                  value={dayjs(SecondYearEndDate).format("YYYY-MM-DD")}
                  //max={Moment().format("YYYY-MM-DD")}
                  onChange={secondenddate}
                />
                {!SecondYearEndDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select End Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Third Year Sem Start Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(ThirdYearStartDate).format("YYYY-MM-DD")}
                  disabled={Thirdstartdatevalidation}
                  onChange={thirdstartdate}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!ThirdYearStartDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select Start Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Third Year Sem End Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  disabled={Thirdenddatevalidation}
                 min={dayjs(ThirdYearStartDate).format("YYYY-MM-DD")}
                  value={dayjs(ThirdYearEndDate).format("YYYY-MM-DD")}
                  //max={Moment().format("YYYY-MM-DD")}
                  onChange={thirdenddate}
                />
                {!ThirdYearEndDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select End Date"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Feedback Start Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FeedbackStartDate).format("YYYY-MM-DD")}
                  disabled={Feedbackstartdatevalidation}
                  onChange={feedbackstartdate}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!FeedbackStartDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select Start Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Feedback End Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  disabled={Feedbackenddatevalidation}
                 min={dayjs(FeedbackStartDate).format("YYYY-MM-DD")}
                  value={dayjs(FeedbackEndDate).format("YYYY-MM-DD")}
                  //max={Moment().format("YYYY-MM-DD")}
                  onChange={feedbackenddate}
                />
                {!FeedbackEndDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select End Date"}</h6>
                ) : null}
              </div>
              
            
          </div>
          &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {/*{!UpdateStudentSemDate ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveSubjectDetails()}
                >
                  Save
                </button>
              ) : (*/}
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateStudentSemDate()}
                >
                  Update
                </button>
              
            </p>
            </div>
        </div>
      
       // </div>
      
   
  );
};

export default AddUpdateStudentSemDateDetails;