import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from "@ag-grid-community/all-modules"
import "../addodpDetails/styles.css";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  addOdpDetails,
  fetchodps,
  UpdateOdpDetails,
} from "../../../app/redux/slices/odpSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
import { enUS, GridAutoSizer } from "@mui/x-data-grid";
import { GridApi, ValueFormatterParams } from "ag-grid-community";

import { SetLeftFeature } from "@ag-grid-community/core";
import NumericCellEditor from "./numericCellEditor";
import { style } from "@mui/system";
let sequence = 0, flag = 0;
//let makerOrHodFlag = 0;

let isMakerCompleted = false;
let isChecker1Completed = false, isChecker2Completed = false, isChecker3Completed = false, isChecker4Completed = false;
let maker = "", checker1 = "", checker2 = "", checker3 = "", checker4 = "";
let makerStatus = "", checker1Status = "", checker2Status = "", checker3Status = "", checker4Status = "";
let currentFileQueueStatus = "Office Establishment";
let makerDate; let checker1Date;let checker2Date;let checker3Date;let checker4Date;

//var date = new Date().getDate();
const AddOdpDetails = ({ isEdit, item, closePopup, odp, Department,Faculty}) => {
  const dispatch = useDispatch();

  const [refNo, setrefNo] = useState(); 
  const [Note, setNote] = useState("");

  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();  
  const [discriptionOfOthers, setDescriptionOfOthers] = useState("");
 
  
  const [FormDate, setformDate] = useState("");
  const [ReleivingOrderIssuedOn, setReleivingOrderIssuedOn] = useState("");
  const [datevalidation, setdatevalidation] = useState(true);
  const [PostApproValid, setPostApproValid] = useState(false);
  const [FieldValid, setFieldValid] = useState(false);
  const [NoteValid, setNoteValid] = useState(false);
  const [ExamSession, setExamSession] = useState("");
  const [DoteReference, setDoteReference] = useState("");
  /*const [sNo, setSNo] = useState("");
  const [nameofFaculty, setnameofFaculty] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [dutyAs, setDutyAs] = useState("");
  const [doteStaffId, setDoteStaffId] = useState("");
  const [designation, setDesignation] = useState("");
  const [collegeCodeAndName, setCollegeCodeAndName] = useState("");
  const [collegeAddress, setCollegeAddress] = useState("");*/
  const [DutyOthers, setDutyOthers] = useState("");
  const [validation, setvalidation] = useState(false);
  const [DisableValid, setDisableValid] = useState(true);
  const [ProgrammeName, setProgrammeName] = useState("");
  const [ProgrammeNameAided, setProgrammeNameAided] = useState("");
  const [ProgrammeNamess, setProgrammeNamess] = useState("");

  const [deparmentList, setDeparmentList] = useState([]);
  const [selectedFile, setselectedFile] = useState("");
 
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [ExaminersInfo, setExaminersInfo] = useState("");

  const [sNo, setSNo] = useState("");
  const [nameofFaculty, setnameofFaculty] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [collegeCodeAndName, setCollegeCodeAndName] = useState("");
  const [designation, setDesignation] = useState("");
  const [doteStaffId, setDoteStaffId] = useState("");
  const [collegeAddress, setCollegeAddress] = useState("");
  const [dutyAs, setDutyAs] = useState("");
  const [DOTEType, setDOTEType] = useState("");
  const [DoteOthers, setDoteOthers] = useState("");
  const [ReleivingOrderNo, setReleivingOrderNo] = useState("");

  
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();
  const [NoOfDays, setNoOfDays] = useState();
  const [HeadList, setHeadList] = useState([]);
  const [collegeType, setcollegeType] = useState("");

  
  const runFillOdpData = useRef();

  let cars = [...new Array()].map(() => createOneCarReport());
  let  valuation = [...new Array()].map(() => createOneCarReportValuation());
  const [sizeValidate, setsizeValidate] = useState("");
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const clearState = () => {
    setrefNo("");   
    setNote("");    
    setformDate("");
    setReleivingOrderNo("");
    setReleivingOrderIssuedOn("");
    setselectedFile("");   
    setDescriptionOfOthers("");
    //setDeparmentList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setcollegeType("");
    setDutyOthers("");
    //setFacultyName("");
    //makerOrHodFlag = 0;
    setFieldValid(false);
    setFromDate("");
    setToDate("");
    setNoOfDays();
    setHeadList([]);
    //setlevelOthers("");
    setSNo("");
    setnameofFaculty("");
    setDesignation("");
    setDoteStaffId("");
    setDepartmentName("");
    setCollegeAddress("");
    setCollegeCodeAndName("");
    setDutyAs("");
    
    setNoteValid(false);    
    setPostApproValid(false);
    setvalidation(false);
    setExaminersInfo("");
    setProgrammeNameAided("");
    setProgrammeNamess("");
    setDisableValid(true);
    
    isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false;
    isChecker3Completed = false;
    isChecker4Completed = false;
    maker = ""; checker1 = ""; checker2 = ""; checker3 = ""; checker4 = "";
    makerStatus = ""; checker1Status = ""; checker2Status = ""; checker3Status = ""; checker4Status = "";
    currentFileQueueStatus = "Office Establishment";
    flag = 0;
    sequence = 0; 
    //makerOrHodFlag = 0;
    console.log("reseted");
  };
  const gridRef = useRef();
  var curFormatter = Intl.NumberFormat('en-US', {

    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  });

  var myCurrencyFormatter = p => curFormatter.format(p.value);
  const cellEditorSelector = (params) => {
    return {
      component: NumericCellEditor,
    };
  };

  var numberValueFormatter = function (params) {
    return params.value.toFixed(2);
  };
  const duty =["Chief Supdt.","Addl.Chief Supdt.","Hall Supdt.","Coordinator","Asst.Coordinator","Cocordinator","Others"];
  const department=deparmentList.map(x=>x.name);
const dutyV=["Chief Examiner","Examiner","Others"];
  console.log("department--",department, "Duty---",duty);
  const [rowData, setRowData] = useState(cars);
  const [columnDefs, setColumnDefs] = useState([

    { headerName: 'S.No', field: 'sNo', width: 90, editable: false },
    { headerName: '*Name of Faculty', field: 'nameofFaculty', cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Faculty}, width: 300 },
    { headerName: 'DOTE Staff ID', field: 'doteStaffId', width: 220 },
    { headerName: 'Designation', field: 'designation' },
    { headerName: 'Department', field: 'departmentName',cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Department}, }, 

    //{ headerName: 'Department', field: 'departmentName', cellEditor: 'agSelectCellEditor', cellEditorParams: { values: department }, },
    { headerName: 'Duty As', field: 'dutyAs', cellEditor: 'agSelectCellEditor', cellEditorParams: { values: duty },},
    { headerName: 'College Code & Name', field: 'collegeCodeandName',width:250 },
    { headerName: 'College Address', field: 'collegeAddress',width:280 },

  ])

  const [rowDatavaluation, setRowDatavaluation] = useState(valuation);
  const [columnDefsvaluation, setColumnDefsvaluation] = useState([ 

    { headerName: 'S.No', field: 'sNo', width: 90, editable: false },
    { headerName: '*Name of Faculty', field: 'nameofFaculty', cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Faculty}, width: 300  },
    { headerName: 'DOTE Staff ID', field: 'doteStaffId', width: 220 },
    { headerName: 'Designation', field: 'designation' },
    { headerName: 'Department', field: 'departmentName', cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Department}, }, 
    { headerName: 'Duty As', field: 'duty', cellEditor: 'agSelectCellEditor', cellEditorParams: { values: dutyV },  },    
     { headerName: 'Subject', field: 'subject' },
      { headerName: 'Valuation Centre', field: 'valuationCentre',width:280 },
  ])
  function createOneCarReport() {
    debugger;
    sequence = rowData.length + 1;

    const res = {
      sNo: sequence,
      nameofFaculty: "",
      doteStaffId:"",
      designation: "",
      departmentName:"",
      collegeCodeandName:"",
      collegeAddress:"",
      dutyAs:""
    };

    return res;
  }
  const defaultColDef = useMemo(() => ({
    sortable: true,
    width: 150,
    autoHeaderHeight: true,
    rowSelection: 'multiple',
    domLayout: 'autoHeight',
    wrapText: true,
    autoHeight: true,
    singleClickEdit:true,
    lockPinned: true,
    cellClass: 'locked-col',
    lockPosition: 'left',
    filter:true,
    resizeable: true,
    suppressSizeToFit: true,
    editable: true,

  }), []);

  const gridOptions = {
    domLayout: 'autoHeight'
  }

  const OnInsertOne = useCallback(() => {
    debugger;
    let i = rowData.length - 1;
    let newrecord;
    setRowDatavaluation([]);
    if ((i == -1) || (rowData[i].nameofFaculty != "")) {
      const newrecord = createOneCarReport();
      cars = [newrecord, ...cars];
      setRowData(rowData.slice(0).concat(cars));
    }


  });



  const onRemove = useCallback(() => {

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map(node => node.data.sNo);
    cars = rowData;
    cars = cars.filter(car => selectedIds.indexOf(car.sNo) < 0);
    console.log(cars);
    for (let i = 0; i < cars.length; i++) {
      cars[i].sNo = i + 1;
    }
    setRowData(cars);
  });


  //Valuation
    

  function createOneCarReportValuation() {
    debugger;
    sequence = rowDatavaluation.length + 1;

    const res = {
      sNo: sequence,
      nameofFaculty: "",
      doteStaffId:"",
      designation: "",
      departmentName:"",
      dutyAs:"",
      subject:"",
      valuationCentre:"",
     
    };

    return res;
  }
  /*const defaultColDefValuation = useMemo(() => ({
    sortable: true,
    width: 170,
    autoHeaderHeight: true,
    rowSelection: 'multiple',
    domLayout: 'autoHeight',
    wrapText: true,
    autoHeight: true,
    singleClickEdit:true,
    lockPinned: true,
    cellClass: 'locked-col',
    lockPosition: 'left',
    filter:true,
    resizeable: true,
    suppressSizeToFit: true,
    editable: true,

  }), []);

  const gridOptionsValuation = {
    domLayout: 'autoHeight'
  }*/

  const OnInsertOneValuation = useCallback(() => {
    debugger;
    let i = rowDatavaluation.length - 1;
    let newrecord;
    setRowData([]);

    if ((i == -1) || (rowDatavaluation[i].nameofFaculty != "")) {
      const newrecord = createOneCarReportValuation();
      valuation = [newrecord, ...valuation];
      setRowDatavaluation(rowDatavaluation.slice(0).concat(valuation));
    }


  });



  const onRemoveValuation = useCallback(() => {

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map(node => node.data.sNo);
    valuation = rowDatavaluation;
    valuation = valuation.filter(car => selectedIds.indexOf(car.sNo) < 0);
    console.log(valuation);
    for (let i = 0; i < valuation.length; i++) {
      valuation[i].sNo = i + 1;
    }
    setRowDatavaluation(valuation);
  });

  
  const examsession = (val) => {
    setExamSession(val.target.value);
  
  };
  const dotereference = (val) => {
    setDoteReference(val.target.value);
  
  };
  const dutyas = (val) => {
    setDutyAs(val.target.value);
  
  };
  const relievingOrderno = (val) => {
    debugger;
    setReleivingOrderNo(val.target.value);
  };
  
  /*const dotestaffid = (val) => {
    setDoteStaffId(val.target.value);
  
  };
  const collegename = (val) => {
    setCollegeCodeAndName(val.target.value);
  
  };
  const collegeaddress = (val) => {
    setCollegeAddress(val.target.value);
  
  };
  const designation = (val) => {
    setDesignation(val.target.value);
  
  };*/
  const setnote = (val) => {
    setNote(val.target.value);
  }
  
  const intothers = (val) => {
    setDescriptionOfOthers(val.target.value);
  };
  const dutyasothers = (val) => {
    setDutyOthers(val.target.value);
  };
  /*const intlevel = (val) => {
    setLevel(val.target.value);
  };
  const levelothers = (val) => {
    setlevelOthers(val.target.value);
  };
  const headOfTheaccount = (val) => {
    setHeadOfTheAccount(val.target.value);
  };*/
  const noOfDays = (val) =>{
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
      setNoOfDays(val.target.value);
    }
  }; 
  const dept = (val) => {
    if(collegeType=="Aided"){

    setProgrammeNameAided(val.target.value);
    setProgrammeNamess("");
    setProgrammeName(val.target.value);
    }
    else if(collegeType=="SS")
    {
      setProgrammeNamess(val.target.value);
      
      setProgrammeNameAided("");
      setProgrammeName(val.target.value);
    }
  };
  const setcollege = (val) => {
    setcollegeType(val);
  }
  const dotetype = (val) => {
    setDOTEType(val.target.value);
  }

  const doteasothers =(val)=>{
    setDoteOthers(val.target.value);
  }
  const formdate = (val) => {
    debugger;
    setformDate(val.target.value);
    //setReleivingOrderIssuedOn(val.target.value);
  };
  const relievingOrderIssud = (val) => {
    debugger;
    setReleivingOrderIssuedOn(val.target.value);
  };

  const startdateChange = (date) => {
    debugger;
    //setStartDate(val.target.value);
    setFromDate(new Date(date));
    //setStartDate(Moment(new Date(date)).format());
    //console.log(new Date(datetime));

  }
  const enddateChange = (date) => {
    debugger;
    //setEndDate(val.target.value);
    setToDate(new Date(date));
    //setEndDate(Moment(new Date(date)).format());
    console.log(new Date(date));
  }

  /*const setrelievingOrder = (val) => {
    setSubmittedReleivingOrder(val);
  };
  const taanddaBorne = (val) => {
    setTaDaBroneBy(val);
  };
  const intcertificate = (val) => {
    setSubmittedCopyOfCertificate(val);
  };*/

  const submitOdpDetails1 = async () => {
    debugger;
    /*if (makerOrHodFlag == 2) {
      isChecker1Completed = true;
      checker1Status = "Submitted";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      //currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveOdpDetails();
    }
    else {*/
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "Office Superintendent";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveOdpDetails();
    
  };

  console.log(isMakerCompleted + "MAker value......");
  const submitOdpDetails2 = async () => {
    debugger;
    flag = 1;
    updateOdpDetails();
  };
  const rejectOdpDetails = async () => {
    // if (currentFileQueueStatus == "HOD") {
    //   checker1Status = "Rejected";
    // }
    // else if (currentFileQueueStatus == "Office Establishment") {
    //   checker2Status = "Rejected";
    // } else 
    if (currentFileQueueStatus == "Office Superintendent") {
      checker1Status = "Rejected";
    } else if (currentFileQueueStatus == "Principal") {
      checker2Status = "Rejected";
    }
    // else if (currentFileQueueStatus == "Checker3") {
    //   checker3Status = "Rejected";
    // }
    isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false;
    // isChecker3Completed = false;
    // isChecker4Completed = false;
    currentFileQueueStatus = "Office Establishment";
    checker1 = "";
      checker2 = "";
      
    updateOdpDetails();
  }

  useEffect(() => {
    if (!odp) {
      return;
    }
    runFillOdpData.current = true;
    debugger;
    if (runFillOdpData.current) {
      // if (JSON.parse(localStorage.getItem("user")).fdpRoleName != "Maker") {
      //   setvalidation(true);
      // }

      fillOdpData(odp);
    }
  }, []);


  const DownloadForm = () => {
    AsyncGetFiles(API.downloadOdpForm + "?id=" + refNo)
      .then((res) => {
        console.log(res);
        debugger;
        const url = window.URL.createObjectURL(
          new Blob([res.data], {

            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ODPForm.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        // alert("");
      });
  };

  const validationFun = () => {
    setvalidate(true);
    if (      
      NoOfDays &&
      DoteReference&&
      DOTEType &&
      ExamSession &&
      
      //DoteStaffId &&
      //CollegeCodeAndName &&
      //CollegeAddress &&
      ProgrammeName &&
      FromDate &&
      ToDate &&
      //Designation &&
      //DutyAs &&    
      FormDate
    ) {
      setvalidate(false);

      return false;
    } else {
      seterrorMsg("Provide all required fields");
      return true;
    }
  };
  const onFileUpload = async (res) => {
    debugger;
    if (selectedFile?.length < 1) {
      dispatch(fetchodps());
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const odpData = res ? res[0] : odp;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("TypeofUser", "ODP");
    formData.append("id", Number(odpData?.refNo || 0));

    try {
      //debugger;
      const res = await AsyncPost(API.uploadFacultyfiles, formData);
      dispatch(fetchodps());
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    //debugger;

    AsyncGetFiles(API.downloadOdpFiles + "?id=" + odp.refNo)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const saveOdpDetails = async () => {
    debugger;
    seterrorMsg("");
    //makerOrHodFlag = (JSON.parse(localStorage.getItem("user")).fdpRoleName) == "Maker" ? 1 : 2;
    /*if (makerOrHodFlag == 2 && isMakerCompleted == false) {
      currentFileQueueStatus = "HOD";
    }*/
    const error = validationFun();
    if (error) {
      return;
    }

    var odpData = {

      //Id: 0,
      refNo: refNo,
      FormDate: (FormDate),
      ReleivingOrderIssuedOn: (FormDate),
      //makerOrHodFlag: makerOrHodFlag,
      ProgrammeName: Number(ProgrammeName),
      ExamSession:ExamSession,
      DOTEType:DOTEType,
      DoteReference:DoteReference,
      /*DutyAs:DutyAs,
      DutyOthers:DutyOthers,
      DoteStaffId:DoteStaffId,
      Designation:Designation,
      CollegeCodeAndName:CollegeCodeAndName,
      CollegeAddress:CollegeAddress,*/
      FromDate: (FromDate),
      ToDate: (ToDate),
      Note: Note,
      Photo: "",
      
      ExaminersInfo: rowData.length>0 ? JSON.stringify(rowData) :JSON.stringify( rowDatavaluation),

      //level: level,
      
      NoOfDays: Number(NoOfDays),
     
      //submittedReleivingOrder: submittedReleivingOrder,
      
      collegeType: collegeType,
      //submittedCopyOfCertificate: submittedCopyOfCertificate,
      discriptionOfOthers: discriptionOfOthers,
      ReleivingOrderNo: ReleivingOrderNo,     

      isMakerCompleted: isMakerCompleted,
      makerStatus: makerStatus,
      isChecker1Completed: isChecker1Completed,
      currentFileQueueStatus: currentFileQueueStatus,
      checker1: checker1,
      checker2: checker2,
      //checker3: checker3,
      //programmeDetails: JSON.stringify(rowData),
      maker: maker,
      //FacultyName: FacultyName,
      //startDate: startDate,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };
    try {
      console.log("odpData", odpData);
      const resp = await addOdpDetails(odpData, dispatch);
      //debugger;
      closePopup();
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }


  };

  const updateOdpDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }

    // if (currentFileQueueStatus == "Principal" && isChecker3Completed == true) {
    //   isChecker4Completed = true;
    //   checker4Status = "Approved";
    //   currentFileQueueStatus = "Approved";
    //   checker4 = JSON.parse(localStorage.getItem("user")).facultyName;
    // }
    // if (currentFileQueueStatus == "Office Superintendent" && isChecker2Completed == true) {
    //   isChecker3Completed = true;
    //   checker3Status = "Approved";
    //   currentFileQueueStatus = "Principal";
    //   checker3 = JSON.parse(localStorage.getItem("user")).facultyName;
    // }
    if (currentFileQueueStatus == "Principal" && isChecker1Completed == true) {
      isChecker2Completed = true;
      checker2Status = "Approved";
      currentFileQueueStatus = "Approved";
      checker2 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "Office Superintendent") {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Principal";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
   
    else if (currentFileQueueStatus == "Office Establishment" && flag == 1) {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "Office Superintendent";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      checker1Status = "";
      checker2Status = "";  
    }

    const odpData = {
      refNo: refNo,
      FormDate: (FormDate),
      ReleivingOrderIssuedOn: (ReleivingOrderIssuedOn),
      ProgrammeName: Number(ProgrammeName),      
      FromDate: (FromDate),
      ToDate: (ToDate),
      ExamSession:ExamSession,
      DOTEType:DOTEType,
      DoteReference:DoteReference,
      
      //DutyAs:DutyAs,
      DutyOthers:DutyOthers,
      /*DoteStaffId:DoteStaffId,
      //Designation:Designation,
      CollegeCodeAndName:CollegeCodeAndName,
      CollegeAddress:CollegeAddress,*/
      Note: Note,
      Photo: "",
      ExaminersInfo: rowData.length>0 ? JSON.stringify(rowData) :JSON.stringify( rowDatavaluation),
      ReleivingOrderNo: ReleivingOrderNo,     

      NoOfDays: Number(NoOfDays),
      
      /*submittedReleivingOrder: submittedReleivingOrder,
      
      submittedCopyOfCertificate: submittedCopyOfCertificate,*/
      isMakerCompleted: isMakerCompleted,
      discriptionOfOthers: discriptionOfOthers,
      isChecker1Completed: isChecker1Completed,
      isChecker2Completed: isChecker2Completed,
      currentFileQueueStatus: currentFileQueueStatus,
      collegeType: collegeType,
      makerStatus: makerStatus,
      checker1Status: checker1Status,
      checker2Status: checker2Status,
      // checker3Status: checker3Status,
      // // checker4Status: checker4Status,
      // isChecker3Completed: isChecker3Completed,
      // isChecker4Completed: isChecker4Completed,
      checker1: checker1,
      checker2: checker2,
      // checker3: checker3,
      // checker4: checker4,

      //startDate: startDate,
      //endDate: endDate,
      //programmeDetails: JSON.stringify(rowData),
      maker: maker,
      //FacultyName: FacultyName,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };


    try {
      // dispatch(UpdateFacultyDetails(indentData));
      dispatch(UpdateOdpDetails(odpData));
      await onFileUpload();
      closePopup();
      clearState();

    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const fillOdpData = (obj) => {
    debugger;
    runFillOdpData.current = false;
    // console.log(obj);
    setrefNo(obj.data.refNo);
    //setMaker(obj.data.isMakerCompleted);
    maker = obj.data.maker;
    checker1 = (obj.data.checker1);
    checker2 = (obj.data.checker2);
    // checker3 = (obj.data.checker3);
    // checker4 = (obj.data.checker4);
    makerStatus = (obj.data.makerStatus);
    checker1Status = obj.data.checker1Status;
    checker2Status = obj.data.checker2Status;
    // checker3Status = obj.data.checker3Status;
    // checker4Status = obj.data.checker4Status;
    makerDate=obj.data.makerDate;
    checker1Date=obj.data.checker1Date;
    checker2Date=obj.data.checker2Date;
   
    isMakerCompleted = (obj.data.isMakerCompleted);
    isChecker1Completed = obj.data.isChecker1Completed;
    isChecker2Completed = obj.data.isChecker2Completed;
    // isChecker3Completed = obj.data.isChecker3Completed;
    // isChecker4Completed = obj.data.isChecker4Completed;
    //makerOrHodFlag = (obj.data.makerOrHodFlag);

    currentFileQueueStatus = (obj.data.currentFileQueueStatus);
    setformDate(obj.data.formDate);
    setReleivingOrderIssuedOn(obj.data.releivingOrderIssuedOn);
    setReleivingOrderNo(obj.data.releivingOrderNo);
    setDOTEType(obj.data.doteType);
    setExamSession(obj.data.examSession);
    setDoteReference(obj.data.doteReference);
    setSNo(obj.data.sNo);
    setnameofFaculty(obj.data.nameofFaculty);
    setDepartmentName(obj.data.departmentName);
    setDesignation(obj.data.designation);
    setDoteStaffId(obj.data.doteStaffId);
    setCollegeCodeAndName(obj.data.collegeCodeAndName);
    setCollegeAddress(obj.data.collegeAddress);
        setNote(obj.data.note);
    setfilesList(obj.files);

    if(obj.data.collegeType=="Aided")
    {
      
    setProgrammeName(obj.data.programmeName);
    setProgrammeNameAided(obj.data.programmeName);
    setProgrammeNamess("");
    }
    else if(obj.data.collegeType=="SS"){
      setProgrammeName(obj.data.programmeName);
    setProgrammeNamess(obj.data.programmeName);
    setProgrammeNameAided("");
    }
    setNoOfDays(obj.data.noOfDays);
    setDescriptionOfOthers(obj.data.discriptionOfOthers);
   
    setFromDate(new Date(obj.data.fromDate));
    setToDate(new Date(obj.data.toDate));
    
    setcollegeType(obj.data.collegeType);
    (obj.data.doteType) =="Central Valuation/Revaluation"?setRowDatavaluation(JSON.parse(obj.data.examinersInfo)):setRowData(JSON.parse(obj.data.examinersInfo));


    setPostApproValid(((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Establishment") &&
      (obj.data.currentFileQueueStatus == "Approved")) ? true : false);
    setDisableValid((obj.data.currentFileQueueStatus == "Approved") ? false : true);
    setvalidation((obj.data.currentFileQueueStatus == "Office Establishment") ? false : true);
    setFieldValid((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Superintendent") || (JSON.parse(localStorage.getItem("user")).fdpRoleName == "Principal")
      || (obj.data.currentFileQueueStatus == "Approved") ? true : false);
    setNoteValid((obj.data.currentFileQueueStatus == "Approved") && (JSON.parse(localStorage.getItem("user")).fdpRoleName != "Office Establishment") ? true : false);
    //&&(JSON.parse(localStorage.getItem("user")).fdpRoleName != "Office Establishment")
    seterrorMsg("");
    console.log(rowData);

    runFillOdpData.current = true;
  };

  return (
    <div className="popup">

      <div className="col">
        <div className="formdata-check">
          <button
            className="col-xs-1 col-md-1 col-lg-1 col-sm-1 btn btn-lg btnclose-x"
            onClick={() => closePopupFun()}
            type="button"
          >
            X
          </button>
          <div className="row">

            <h2 className="col heading">{isEdit ? "Edit ODP Form" : "ODP Form"}</h2>
            {((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Establishment")
              || (JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Superintendent")) && currentFileQueueStatus === "Approved" ?
              <div className="but col text-end">
                <button className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                  type="button" onClick={() => DownloadForm()}>
                  <span className="glyphicon glyphicon-remove"></span> Download Form</button>
              </div> : null}
          </div>


          <div className="d-flex justify-content-center align-items-enter">
            {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
          </div>
          <div className="row">

            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn"
                  id="inlineRadio"
                  value="5"
                  checked={collegeType === "Aided"}
                  onChange={() => {
                    setcollegeType("Aided");
                    setProgrammeName(ProgrammeNameAided);
                  }}

                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn"
                  id="inlineRadio"
                  value="6"
                  checked={collegeType === "SS"}
                  onChange={() => {
                    setcollegeType("SS");
                    setProgrammeName(ProgrammeNamess);
                    //setLabListUpdated(LabList.filter(x => x.collegeType == "SS"));


                    //setHeadListUpdated(HeadList.filter(x =>x.collegeType == "SS"));
                  }}
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  SS
                </label>

              </div>

            </div>

          </div>

          <div className="row formadduser">
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>DOTE Exam Type
              </label>
              <select
                className="form-select form-control"
                onChange={dotetype}
                aria-label="Default select example"
                value={DOTEType}
                disabled={FieldValid}
              >
                <option value="">--Select DOTE Type--</option>
                <option value="Theory Exam">Theory Exam</option>
                <option value="Central Valuation/Revaluation">Central Valuation/Revaluation</option>
                <option value="Other Exams/Activities">Other Exams/Activities</option>
              </select>
              {!DOTEType && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            {DOTEType === "Other Exams/Activities" && (
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6 form-group">
                <label>
                  Description of Other Exams/Activities
                </label>
                <input
                  className="form-control"
                  //id="descriptionOfAdmissions"
                  value={discriptionOfOthers}
                  onChange={intothers}
                  //rows="5"
                  maxLength={150}
                  disabled={FieldValid}
                >
                </input>
              </div>

            )}
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label><span style={{ color: "red" }}>*</span>ODP Request Form Date</label>
              <input
                className="form-control"
                type="date"
                value={dayjs(FormDate).format("YYYY-MM-DD")}
                // max={Moment().format('YYYY-MM-DD')}
                onChange={formdate}
                disabled={FieldValid}
              />
              {!FormDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
            <span style={{ color: "red" }}>*</span><label>Exam Session</label>
              <input
                className="form-control"
                type="month"
                value={ExamSession}
                onChange={examsession}
                //maxLength={15}
                disabled={FieldValid}
              />
              {!ExamSession && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Programme Name
              </label>
              <select
                className="form-select form-control"
                onChange={dept}
                aria-label="Default select example"
                value={ProgrammeName}
                disabled={FieldValid}
              >
                <option value="">--Select Programme--</option>
                {deparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {!ProgrammeName && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
              </div>
            
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>DOTE Reference
              </label>
              <input
                className="form-control"
                type="text"
                value={DoteReference}
                onChange={dotereference}
                maxLength={15}
                disabled={FieldValid}

              />
              {!DoteReference && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
          
            

            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>From Date
              </label>
              <DatePicker
                className="form-select form-control"

                placeholderText="Select From Date"
                showTimeSelect
                //value={startDate}
                timeFormat="HH:mm"
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                selected={FromDate}
                //maxDate={new Date()}
                //minDate={new Date()}
                dataTimezone='IST'
                displayTimezone='IST'
                selectsStart
                FromDate={FromDate}
                ToDate={ToDate}
                onChange={startdateChange}
                disabled={FieldValid}
              />
              {!FromDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>To Date
              </label>
              {/* <DateTimePicker
                
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              /> */}
              <DatePicker
                className="form-select form-control"
                //value={endDate}
                timeFormat="HH:mm"
                timeCaption="time"
                placeholderText="Select To Date"
                showTimeSelect
                //dateFormat="dd-mm-yyyy hh:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                selected={ToDate}
                dataTimezone='IST'
                displayTimezone='IST'
                selectsEnd
                FromDate={FromDate}
                ToDate={ToDate}
                minDate={FromDate}
                onChange={enddateChange}
                disabled={FieldValid}
              />
              {!ToDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Duration in Day(s)
              </label>
              <input
                className="form-control"
                type="text"
                onChange={noOfDays}
                maxLength={3}
                value={NoOfDays}
                disabled={FieldValid}
              >
              </input>
              {!NoOfDays && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
             {(DOTEType === "Theory Exam"  ||DOTEType === "Other Exams/Activities" )&&(
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  ag-theme-alpine form-group ">
              <div className="row">

                <label className="col pt-2 form-group-x" style={{ fontSize: 16 }}><span style={{ color: "red" }}>*</span>Examiners Information</label>

                {DisableValid ?
                  <div className="but col text-end">

                    <button
                      className="btn btn-lg btn-success btn-block form-roundedbtn3 mb-3"

                      onClick={OnInsertOne}>
                      <span className="glyphicon glyphicon-plus"></span> Add
                    </button>
                    <button
                      className="btn btn-lg btn-danger btn-block form-roundedbtn2 mb-3"
                      onClick={onRemove}>
                      <span className="glyphicon glyphicon-plus"></span> Delete
                    </button>
                    {/* <button onClick={onRemove}>Remove Row</button> */}
                  </div>
                  : null}

                <div style={{ pointerEvents: (DisableValid) ? 'auto' : 'none', fontFamily: 'Open sans', fontSize: 16, width: '100%', height: '100%', fontWeight: 'normal' }}>
                  <AgGridReact
                    modules={AllCommunityModules}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    rowSelection={'multiple'}
                    editable={DisableValid}
                    columnDefs={columnDefs}

                  >
                  </AgGridReact>
                </div>
                {!rowData.length && validate ? (
                  <h6 style={{ color: "red", fontWeight: "normal" }}>{"Requird"}</h6>
                ) : null}

              </div>
            </div>
             )}  
            
             {DOTEType === "Central Valuation/Revaluation" && (
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  ag-theme-alpine form-group ">
              <div className="row">

                <label className="col pt-2 form-group-x" style={{ fontSize: 16 }}><span style={{ color: "red" }}>*</span>Examiners Information</label>

                {DisableValid ?
                  <div className="but col text-end">

                    <button
                      className="btn btn-lg btn-success btn-block form-roundedbtn3 mb-3"

                      onClick={OnInsertOneValuation}>
                      <span className="glyphicon glyphicon-plus"></span> Add
                    </button>
                    <button
                      className="btn btn-lg btn-danger btn-block form-roundedbtn2 mb-3"
                      onClick={onRemoveValuation}>
                      <span className="glyphicon glyphicon-plus"></span> Delete
                    </button>
                    {/* <button onClick={onRemove}>Remove Row</button> */}
                  </div>
                  : null}
      
                <div style={{ pointerEvents: (DisableValid) ? 'auto' : 'none', fontFamily: 'Open sans', fontSize: 16, width: '100%', height: '100%', fontWeight: 'normal' }}>
                  <AgGridReact
                    modules={AllCommunityModules}
                    ref={gridRef}
                    defaultColDef={defaultColDef}
                    rowData={rowDatavaluation}
                    gridOptions={gridOptions}
                    rowSelection={'multiple'}
                    editable={DisableValid}
                    columnDefs={columnDefsvaluation}

                  >
                </AgGridReact>
                </div>
                {!rowDatavaluation.length && validate ? (
                  <h6 style={{ color: "red", fontWeight: "normal" }}>{"Requird"}</h6>
                ) : null}

              </div>
            </div>
             )}
            {PostApproValid ?
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Releiving OrderNo</label>
                <input
                  className="form-control"
                  type="text"
                  value={ReleivingOrderNo}
                  maxLength={15}
                  onChange={relievingOrderno}
                //disabled={validation}
                />

              </div> : null}
            {PostApproValid ?
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Relieving Order Issued On</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(ReleivingOrderIssuedOn).format("YYYY-MM-DD")}
                  // max={Moment().format('YYYY-MM-DD')}
                  onChange={relievingOrderIssud}
                //disabled={validation}
                />

              </div> : null}
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
              <label>
                Note/Remarks
              </label>
              <textarea
                className="form-control clsabstract"
                id="note"
                value={Note}
                onChange={setnote}
                rows="5"
                maxLength={1000}
                disabled={NoteValid}
              />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>Attachment</label>
              <input
                className="form-control"
                onChange={onFileChange}
                type="file"
                id="formFileMultiple"
                disabled={NoteValid}

              />
              {filesList?.length > 0 && (
                <div className="d-flex align-items-center mt-2  rounded">
                  <div className="border rounded download-list-block">
                    {filesList?.map((item, index) => (
                      <h6 className="border-bottom ms-1" key={index}>
                        {item || "No File Name"}
                      </h6>
                    ))}
                  </div>
                  <button
                    className="btn btn-sm btn-primary btn-block "
                    type="button"
                    onClick={() => downloadFileFun()}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="row formadduser form-group"><label className="form-check-label"style={{color: "#515151"}}>Required Attachments</label>
          <ul   style={{ listStyleType: 'disc', marginLeft:20 }}>
            <li className="form-check-label checkposition">Brochure /Poster</li>
            <li className="form-check-label checkposition">Permission letter from the Head of Institution</li>
            <li className="form-check-label checkposition">Acceptance letter from Organization/Industry</li>
            </ul> </div>
            <div className="row formadduser form-group">

            <label className="form-check-label" style={{ color: "#515151" }}> Approved By</label>

            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
              <ul >
                {maker == null || maker == "" ? " " : maker + " [" + dayjs(makerDate).format("DD-MM-YYYY") + "]"}
                <br></br>
                <label className="mr-5" style={{ fontWeight: 'bold' }}>Office Establishment</label>
              </ul>
            </div>
            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
              <ul >
                {checker1 == null || checker1 == "" ? " " : checker1 + " [" + dayjs(checker1Date).format("DD-MM-YYYY") + "]"}
                <br></br>
                <label style={{ fontWeight: 'bold' }}>Office Superintendent </label>
              </ul>
            </div>
            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
              <ul >
                {checker2 == null || checker2 == "" ? " " : checker2 + " [" + dayjs(checker2Date).format("DD-MM-YYYY") + "]"}
                <br></br>
                <label style={{ fontWeight: 'bold' }}>PRINCIPAL</label>
              </ul>
            </div>
          </div>


          {/*<div className="row formadduser form-group">
            <div className="col-xs-6 col-md-6 col-lg-4 col-sm-12 mt-4">
              <ul>
                {checker3 == null || checker3 == "" ? " " : checker3 + " [" + dayjs(checker3Date).format("DD-MM-YYYY") + "]"}
                <br></br>
                <label>Office Superintend</label>
              </ul>
            </div>

            <div className="col-xs-6 col-md-6 col-lg-4 col-sm-12 mt-4">
              <ul>
                {checker4 == null || checker4 == "" ? " " : checker4 + " [" + dayjs(checker4Date).format("DD-MM-YYYY") + "]"}
                <br></br>
                <label style={{ fontWeight: 'bold' }}>PRINCIPAL</label>
              </ul>
            </div>
          </div>*/}
          &nbsp;
          <div className="d-flex justify-content-center align-items-enter">
            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
          </div>
          {PostApproValid ? <div>
            <button
              className="btn btn-lg btn-primary btn-block form-btnverify"
              type="button"
              onClick={() => updateOdpDetails()}
            >
              Submit
            </button></div> : <p>{
              DisableValid ?
                <p>
                  {
                    validation && isMakerCompleted ?
                      (
                        <div>
                          {(JSON.parse(localStorage.getItem("user")).fdpRoleName) == 'HOD' ?
                            <button
                              className="btn btn-lg btn-primary btn-block form-btnapprove"
                              type="button"
                              onClick={() => updateOdpDetails()}
                            >
                              Submit
                            </button> :
                            (JSON.parse(localStorage.getItem("user")).fdpRoleName) == 'Principal' ?
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnapprove"
                                type="button"
                                onClick={() => updateOdpDetails()}
                              >
                                Approve
                              </button> :
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnverify"
                                type="button"
                                onClick={() => updateOdpDetails()}
                              >
                                Verify & Submit
                              </button>}
                          <button
                            className="btn btn-lg btn-primary btn-block form-btnreject"
                            type="button"
                            onClick={() => rejectOdpDetails()}
                          >
                            Reject
                          </button>
                        </div>
                      ) :
                      (!odp ? (
                        <div>
                          <button
                            className="btn btn-primary btn-block form-roundedbtn"
                            type="button"
                            onClick={() => saveOdpDetails()}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-primary btn-block form-roundedsubmitbtn "
                            type="button"
                            onClick={() => submitOdpDetails1()}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            className="btn btn-lg btn-primary btn-block form-roundedbtn"
                            type="button"
                            onClick={() => updateOdpDetails()}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-lg btn-primary btn-block form-roundedsubmitbtn "
                            type="button"
                            onClick={() => submitOdpDetails2()}
                          >
                            Submit
                          </button>
                        </div>
                      ))
                  }
                </p>
                : <div>
                 { /*<button
                    className="btn btn-lg btn-primary btn-block form-roundedbtn3 buttonW"
                    type="button"
                    onClick={() => updateOdpDetails()}
                  >
                    Submit Feedback
                </button>*/}</div>}</p>}
        </div>
      </div >
    </div >
  );
};

export default AddOdpDetails;