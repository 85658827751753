import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddStockInventoryDetails from "./addstockInventoryDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchStockInventorys,
  stockInventorySelector,
  deleteStockInventory,
} from "../../app/redux/slices/stockInventorySlice";

import Popup from "reactjs-popup";
import { AsyncGet , AsyncPost,AsyncGetFiles} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
const StockInventoryDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, stockInventoryList } = useSelector(stockInventorySelector);

  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [stockInventory, setStockInventory] = useState();
  const [listOfStockInventory, setlistOfStockInventory] = useState(stockInventoryList || []);
  const [IssueDate,SetIssueDate]=useState("");
  const [Store,SetStore]=useState("");
  const togglePopup = () => {
    onAssessmentSelection("StockInventory Details");
    setshowPopup(!showPopup);
    if (!showPopup) {
      setStockInventory();
    }
  };
  const downloadReport = async () => {
    debugger;
    AsyncGetFiles(
      API.generatestockreport +
        "IssueDate=" +
        IssueDate +
        "&Store=" +
        Store 
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // "application/vnd.ms-excel",
          })
          //URL.createObjectURL(new Blob([res.date]));
          //, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert("No Attendance");
      });
  };
  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", stockInventoryList);

  const deleteStockInventoryFun = (item) => {
    debugger;
    dispatch(deleteStockInventory(item.id));
  };

  const editStockInventory = (item) => {
    debugger;
    setStockInventory({ ...item, data: item });
    setshowPopup(!showPopup);
  };

  useEffect(() => {
    getStockInventoryData();
  }, []);
  
  const getStockInventoryData = () => {
    dispatch(fetchStockInventorys());
  };

  useEffect(() => {
    if (!stockInventoryList) {
      return;
    }
    setlistOfStockInventory(stockInventoryList);
  }, [stockInventoryList]);

  console.log("Before Render stockInventoryList", stockInventoryList);
  console.log("Before Render listOfStockInventory", listOfStockInventory);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2"> Stock List</h2>
            <div className="but col text-end">
              {/* <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button> */}
              {/* <button
                className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                type="submit"
                //style="width:160px!important"
                onClick={() => downloadReport()}
                
              >
                <span className="glyphicon glyphicon-remove"></span> Download Reports
              </button> */}
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
            </div>
            <div className="fixed-table-container">
              <div className="fixed-table-body">
              <AdvanceTable
                  deleteStockInventoryProp={(item) => deleteStockInventoryFun(item)}
                  editStockInventoryProp={(item) => editStockInventory(item)}
                  data={listOfStockInventory}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Role Id:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Role Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div> */}
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddStockInventoryDetails
            item={assessment}
            closePopup={togglePopup}
            stockInventory={stockInventory}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default StockInventoryDetails;
