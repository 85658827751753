import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../../app/services/endpoints";
import {
    AsyncGet,
    AsyncPost,
    AsyncGetFiles,
} from "../../../app/services/https";
import SearchBox from "./searchBox";
import './Style.css';

import dayjs, { Dayjs } from "dayjs";
var StudentFilterList=[];
const AddsinglestudentFeedback = ({ item, Feedback, closePopup }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        if (!Feedback) {
            return;
        }
        runFillFeedbackData.current = true;
        if (runFillFeedbackData.current) {
            fillFeedbackData(Feedback);
        }
    }, []);
    const [DepartmentId, setdepartmentId] = useState(0);
    const [DepartmentList, setDepartmentList] = useState([]);
    const [Sem, setSem] = useState("");
    const [Year, setYear] = useState("");
    const [StudentID, setStudentID] = useState([]);
    const [StudentList, setStudentList] = useState([]);
    //const [StudentFilterList, setStudentFilterList] = useState([]);

    const [errorMsg, seterrorMsg] = useState("");
    const [validate, setvalidate] = useState(false);
    const runFillFeedbackData = useRef();

    useEffect(() => {
        AsyncGet(API.getDepartments)
            .then((res) => {
                console.log(res.data, "getDepartments");
                setDepartmentList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    const clearState = () => {
        setdepartmentId();
        setYear("");
        setSem("");
    }
    const dept = (val) => {
        setdepartmentId(val.target.value);
    };
    const year = (val) => {
        setYear(val.target.value);
    };
    const sem = (val) => {
        setSem(val.target.value);
    };
    const onStudentIDChange = (data) => {
        setStudentID(data); 
      };
      const onStudentIDInputChange = (data) => {
        if (!data) {
          return;
        }
        AsyncGet(API.getMappedStudentByName + "?DepartmentId=" + DepartmentId+"&Sem="+Sem+"&Year="+Year + "&StudentName=" + data)
          .then((res) => {debugger;
             //var StudentList =res.data;
            // console.log(StudentList);
            setStudentList(res.data);
          })
          .catch(() => setStudentList([]));
      };
 
      console.log(StudentFilterList)  ;
      console.log((JSON.stringify(StudentID)).StudentID,"studentID");
      const  sendstudentFeedback = () => {
        AsyncPost(API.sendstudentFeedback + "?StudentId=" +JSON.stringify(StudentID))
        .then((response) => {
            if (response.data = "Success") {
              alert("Feedback send successfully");
              clearState();
              closePopup();
            }
            else {
              alert("Something went wrong");
            }       
          })
          .catch((er) => {
            console.log(er, "Something went wrong");    
          });
    
      }
      
    const validationFun = () => {
        setvalidate(true);
        if (
            !DepartmentId ||
            !Year ||
            !Sem       
                    ) {
            setvalidate(false);
            seterrorMsg("Provide all required fields");
            return false;
        }
        else {
            return true;
        }
    };
    const closePopupFun = () => {
        clearState();
        closePopup();
        //window.location.reload(false);
    };
    const fillFeedbackData = (obj) => {
        setdepartmentId(obj.data.departmentId);
        setYear(obj.data.year);
        setSem(obj.data.sem);
        setStudentID(obj.studentID);   

        runFillFeedbackData.current = true;
    }
    return (
        <div className="popup">
            <div className="popup-inner modal-content">
                <div className="col">
                    <div className="formdata">
                        <h2 className="heading">Feedback</h2>
                        <button
                            className="btn btn-lg btnclose"
                            onClick={() => closePopupFun()}
                            type="button"
                        >
                            X
                        </button>
                        <div className="d-flex justify-content-center align-items-enter">

                        </div>
                        <div className="row formadduser">
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                                <label><span style={{ color: "red" }}>*</span>Department</label>
                                <select
                                    className="form-select form-control"
                                    onChange={dept}
                                    aria-label="Default select example"
                                    value={DepartmentId}
                                >
                                    <option value="">--Select Department--</option>
                                    {DepartmentList?.map((opt) => (
                                        <option key={opt.id} value={opt.id}>
                                            {opt.name}
                                        </option>
                                    ))}

                                    
                                </select>
                                {!DepartmentId && validate ? (
                                    <h6 style={{ color: "red" }}>{"Select Department"}</h6>
                                ) : null}
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                                <label> <span style={{ color: "red" }}>*</span>Year</label>
                                <select
                                    className="form-select form-control"
                                    onChange={year}
                                    aria-label="Default select example"
                                    value={Year}
                                >
                                    <option value="">--select Year--</option>
                                    <option value={1}>1st Year</option>
                                    <option value={2}>2nd Year</option>
                                    <option value={3}>3rd Year</option>
                                </select>
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                                <label><span style={{ color: "red" }}>*</span>Semester</label>
                                <select
                                    className="form-select form-control"
                                    onChange={sem}
                                    aria-label="Default select example"
                                    value={Sem}
                                >
                                    <option value="">--Select Semester--</option>
                                    <option value="Odd">Odd</option>
                                    <option value="Even">Even</option>
                                </select>
                            </div>


                             <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                  >
                    <label><span style={{ color: "red" }}>*</span>Student ID</label>
                    <SearchBox
                      options={StudentList}
                      onChangeList={(data) => onStudentIDChange(data)}
                      onInputChange={(e) => onStudentIDInputChange(e)}
                      selectedOptions={StudentID || []}    
                      type="student"
                    />
                    {!StudentID?.length > 0 && validate ? (
                      <h6 style={{ color: "red" }}>{"Required"}</h6>
                    ) : null}
                  </div> 
                        </div>
                        &nbsp;
                        <div className="d-flex justify-content-center align-items-enter">
                            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
                        </div>
                        <p>
                             <button
                                className="btn btn-primary btn-block form-roundedbtn"
                                type="button"
                            onClick={() => sendstudentFeedback()}
                            >
                                Send
                            </button>

                            

                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddsinglestudentFeedback;