export const API = {
  Login: "connect/token",
  validateUser: "/getuserdetails",
  resetPassword: "api/user/",
  getShops: "shop/",
  getShopdbyLocation: "shop/GetShopsByLocation",
  getCategories: "ProductCategory/",
  getSubCategories: "subcategory/",
  getProducts: "Product/",
  getProductsbySubcategory: "ShopProducts/GetShopProductsBySubCategory",
  getShopAds: "ShopAds/",
  postOrder: "ShopOrder/CreateOrder",
  getLookup: "LookUp/",
  generateOtp: "otp/GenerateOtp",
  verifyOtp: "otp/VerifyOTP",
  sendOrderSMS: "otp/SendPikatooSMS",
  updateProfile: "otp/UpdateProfile",
  getProfile: "otp/UserProfile",
  getShopSubcategory: "ShopSubCategory",
  putShop: "Shop",
  getShop: "Shop/getShopMaster/",
  putLocation: "Location",
  getLocation: "Location",
  saveShopProfilePic: "ShopGallery",
  getShopOrderDetails: "ShopOrder/OrderDetails",
  getShopProductsBysearch: "ShopProducts/GetShopProductsBySearch",
  putShopProducts: "ShopProducts",
  GetAllShopProducts: "ShopProducts/GetAllShopProducts",
  putBulkShopProducts: "ShopProducts/AddMultiShopProducts",
  placeOrder: "Sales/CreateOrder",
  getOrders: "Sales/Orders",
  getOrderDetails: "Sales/OrderDetails",
  getOrderStatus: "Sales/OrderStatus",
  updateOrderStatus: "Sales/CreateOrderStatus",
  addproduct: "Product",

  getStudentByName: "/getstudentbyname",
  getFacultyByName: "/getfacultybyName",
  getAlumniByName: "/GetAlumniByName",

  /*AMS endpoints */
  getActivities: "/getallactivitydata",
  getActivityById: "/getactivitydata",
  saveActivities: "/InsertActivityData",
  updateActivityData: "/updateactivitydata",
  deleteActivityData: "/deleteactivitydata",

  getAluminies: "/GetAllAlumniDetails",
  saveAluminies: "/InsertAlumniDetails",
  updateAluminiData: "/UpdateAlumniDetails",
  deleteAluminiData: "/DeleteAlumniDetails",

  getStudents: "/GetAllStudent",
  saveStudents: "/InsertStudent",
  updateStudentData: "/UpdateStudent",
  deleteStudentData: "/DeleteStudent",

  getFaculties: "/GetAllFaculty",
  saveFaculties: "/InsertFaculty",
  updateFacultyData: "/UpdateFaculty",
  deleteFacultyData: "/DeleteFaculty",

  getRoles: "/GetAllRole",

  saveRoles: "/InsertRole",
  updateRoleData: "/UpdateRole",
  deleteRoleData: "/DeleteRole",
  downloadFiles: "/downloadfiles",

  getDepartments: "/GetAllDepartment",
  saveDepartments: "/InsertDepartment",
  updateDepartmentData: "/UpdateDepartment",
  deleteDepartmentData: "/DeleteDepartment",

  getSubjects: "/GetAllSubject",
  saveSubjects: "/InsertSubject",
  updateSubjectData: "/UpdateSubject",
  deleteSubjectData: "/DeleteSubject",

  getRoleActivityMappings: "/GetRoleActivity",
  updateRoleActivityMapping: "/UpdateRoleActivity",
  insertRoleActivityMapping: "/InsertRoleActivity",

  uploadFiles: "/uploadfile",
  uploadFacultyfiles: "/UploadfacultystudentFile",
  downloadStudentFiles: "/DownloadStudentFiles",
  downloadFacultyFiles: "/DownloadFacultyFiles",
  downloadAluminiFiles: "/DownloadAlumniFiles",
  
  downloadAnnouncementFiles: "/DownloadAnnouncementFiles",

  
  bulkupload: "/bulkupload",
  DownloadTemplate: "/DownloadTemplate",

  getBatches: "/GetAllBatchDetails",
  saveBatches: "/InsertBatchDetails",
  updateBatchData: "/UpdateBatchDetails",
  deleteBatchData: "/DeleteBatchDetails",

  getDeptStudMappings: "/GetAllDeptStudMappingDetails",
  saveDeptStudMappings: "/InsertDeptStudMappingDetails",
  updateDeptStudData: "/UpdateDeptStudMapping",
  deleteDeptStudData: "/DeleteDeptStudMapping",

  getBatchStudMappings: "/GetAllBatchStudMappingDetails",
  saveBatchStudMappings: "/InsertBatchStudMappingDetails",
  updateBatchStudData: "/UpdateBatchStudMapping",
  deleteBatchStudData: "/DeleteBatchStudMapping",
  deleteDeptStudData: "/DeleteDeptStudMapping",

  getReports: "/GetReports",

  getAdmissions: "/GetAllAdmission",
  saveAdmissions: "/InsertAdmission",
  updateAdmissionData: "/UpdateAdmission",
  deleteAdmissionData: "/DeleteAdmission",

  getBatchSubMappings: "/GetAllBatchSubMappingDetails",
  saveBatchSubMappings: "/InsertBatchSubMappingDetails",
  updateBatchSubData: "/UpdateBatchSubMapping",
  deleteBatchSubData: "/DeleteBatchSubMapping",

  getAllDepartment: "/GetAllDepartment?id=",
  getSection: "/GetSection",
  getBatchList: "/GetBatchList",
  getAllAttendance: "/GetAllAttendance",
  insertAttendance: "/InsertAttendance",
  deleteAttendance: "/DeleteAttendance",

  getSubjectsForAttendance: "/Getsubjectsforattendance?",

  getStudentCount: "/GetStudentDeptCount",
  getUnMappedStudent: "/GetDeptUnMappedStudent",
  getMappedStudent: "/GetDeptMappedStudent",
  // Downloads
  generateAttendancesubjectwisereport: "/generateAttendanceSubjectwiseMonthlyreport?",
  generateAttendancedynamicreport: "/generateAttendancedynamicreport?",
  generateAttendanceMonthwisereport: "/generateAttendanceMonthwisereport?",
  generateAttendanceSemwisereport: "/generateAttendanceSemwisereport?",


  verifypassword: "/GetVerifyPassword",
  updatePasswordData: "/UpdateVerifyPassword",

  getFormRoles: "/GetAllFormRole",
  saveFormRoles: "/InsertFormRole",
  updateFormRoleData: "/UpdateFormRole",
  deleteFormRoleData: "/DeleteFormRole",

  getIndents: "/GetAllIndent",
  saveIndents: "/InsertIndent",
  updateIndentData: "/UpdateIndent",
  updateQuatationStatus:"/UpdateQuatationStatus",
  deleteIndentData: "/DeleteIndent",
  downloadIndentForm: "/SearchAndReplaceIndentForm",

  getFdsForms: "/GetAllFDPForm",
  saveFdsForms: "/InsertFDPForm",
  updateFdsFormData: "/UpdateFDPForm",
  deleteFdsFormData: "/DeleteFDPForm",
 
  downloadFdpForm: "/SearchAndReplaceFdpForm",


  downloadQuatationForm: "/SearchAndReplaceQuatationForm",
  getInventorys: "/GetAllInventory",
  saveInventorys: "/InsertInventory",
  updateInventorys: "/UpdateInventory",
  deleteInventorys: "/DeleteInventory",


  getInventorySpecs: "/GetAllInventorySpec",
  saveInventorySpecs: "/InsertInventorySpec",
  updateInventorySpecs: "/UpdateInventorySpec",
  deleteInventorySpecs: "/DeleteInventorySpec",


  getStockInventorys: "/GetAllStockInventory",
  saveStockInventorys: "/InsertStockInventory",
  updateStockInventorys: "/UpdateStockInventory",
  deleteStockInventorys: "/DeleteStockInventory",

  getInventoryIssuedDetails: "/GetAllInventoryIssuedDetails",
  saveInventoryIssuedDetails: "/InsertInventoryIssuedDetails",
  updateInventoryIssuedDetails: "/UpdateInventoryIssuedDetails",
  deleteInventoryIssuedDetails: "/DeleteInventoryIssuedDetails",

  generateDatereport: "/DownloadStockReport?",


  getlabdetails: "/GetAllLabDetails",
  savelabdetails: "/InsertLabDetails",
  updatelabdetails: "/UpdateLabDetails",
  deletelabdetails: "/DeleteLabDetails",

  gethoadetails: "/GetAllHOADetails",
  savehoadetails: "/InsertHOADetails",
  updatehoadetails: "/UpdateHOADetails",
  deletehoadetails: "/DeleteHOADetails",
  getPurchasedOrders: "/GetAllPurchasedOrder",
  savePurchasedOrders: "/InsertPurchasedOrder",
  updatePurchasedOrders: "/UpdatePurchasedOrder",
  deletePurchasedOrders: "/DeletePurchasedOrder",

  getBudgets: "/GetBudgetLine",
  saveBudgets: "/InsertBudgetLine",
  updateBudgetData: "/UpdateBudgetLine",
  deleteBudgetData: "/DeleteBudgetLine",

  getBudgetHeads:"/GetBudgetHead",
updateReAllocationData:"/ReallocateBudget",

downloadFdpFiles:"/DownloadFdpFiles",
downloadIndentFiles:"/DownloadIndentFiles",

generatecumulativereport:"/DownloadCumulativeReport?",

getOdpForms: "/GetAllODPForm",
saveOdpForms: "/InsertODPForm",
updateOdpFormData: "/UpdateODPForm",
deleteOdpFormData: "/DeleteODPForm",
downloadOdpForm: "/SearchAndReplaceOdpForm",
downloadOdpFiles:"/DownloadOdpFiles",

getBpesForms:"/GetAllBpeForm",
saveBpesForms:"/InsertBpeForm",
updateBpesFormData:"/UpdateBpeForm",
deleteBpesFormData:"/DeleteBpeForm",
downloadBpeFiles:"/DownloadBpeFiles",
downloadBpeForm:"/SearchAndReplaceBpeForm",

downloadOdpForm: "/SearchAndReplaceOdpForm",
downloadOdpFiles:"/DownloadOdpFiles",

getAllMarkReports:"/GetAllMarkReport?",
getSubjectsForMarks:"/Getsubjectsformarks?",
getAllMarkReports:"/GetAllMarkReport?",
getStudentMark:"/GetStudentMark",


getEmailUpdate:"/UpdateReadytosendEmail?",
getFacultySubjectMappings:"/GetAllFacultySubMappingDetails",
saveFacultySubjectMappings:"/InsertFacultySubMappingDetails",
updateFacultySubjectMappings:"/UpdateFacultySubMapping",
deleteFacultySubjectMappings:"/DeleteFacultySubMapping",

getTransulate:"/translate?",
saveannouncementdetails:"/InsertAnnouncementDetails",
getAnnouncementDetails:"/GetAnnouncementDetails",
deleteAnnouncement:"/DeleteAnnouncementDetails",
updateStudentSemDateDetails:"/updateStudentSemDateDetails?",
deleteMark:"/DeleteMark",
updateStdconfig:"/UpdateStdconfigFeedback?",
generateReport:"/GenerateMarkReport?",
getstudconfig:"/GetAllStudentConfiguration",
getSecWiseFeedbackReport:"/DownloadSemWiseFeedbackReport?",
getFacultySubjectforfeedback:"/getFacultySubjectforfeedback?",
getTeachingWiseReport:"/DownloadFeedbackFacultyReport?",
getYearWiseSecFeedbackReport:"/DownloadYearWiseSecFeedbackReport?",
getQuestionWiseReport:"/DownloadFeedbackQnsReport?",
sendstudentFeedback:"/SendStudentFeeedback",
getMappedStudentByName: "/GetMappedStudentByName",
downloadEvaluationFiles:"/DownloadStdAssReports",


getmembers:"/GetMembersDetails",
savemembers:"/InsertMembersDetails",
updateMemberData:"/UpdateMembersDetails",
deleteMemberData:"/DeleteMembersDetails",

SendWhatsAppMsg:"/SendWhatsAppMsg",
UploadBulkAnnouncementFile: "/UploadBulkAnnouncementFile"
};
