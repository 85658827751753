import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const formRoleSlice = createSlice({
  name: "formRoleList",
  initialState: {
    loading: false,
    error: false,
    formRoleList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    roleDetails: [],
    submitRoleSuccess: "",
    saveRoleDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getFormRole: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.formRoleList = payload;
    },
    saveFormRole: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveRoleDataSuccess = payload;
    },
    submitFormRole: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getFormRole,
  submitFormRole,
  setError,
  saveFormRole,
} = formRoleSlice.actions;
export const formRoleSelector = (state) => state.formRole || [];
export default formRoleSlice.reducer;

export const fetchFormRoles = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getFormRoles)
    .then((response) => {
      console.log("response,", response);
      dispatch(getFormRole(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addFormRoleDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveFormRoles, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveFormRole(response));
      dispatch(fetchFormRoles());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveFormRole(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateFormRoleDetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updateFormRoleData, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update faculty");
      dispatch(fetchFormRoles());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveFormRole(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteFormRole = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deleteFormRoleData + "?id=" + data, "")
    .then((response) => {
      if (response!='Success')

      {      

        alert('Sorry! Unable to delete FormRole. FormRole is mapped with Faculties')

      }
      

      // dispatch(setLoading());
      console.log(response, "success of delete faculty");
      dispatch(fetchFormRoles());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveFormRole(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
