import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import Moment from "moment";
import dayjs from "dayjs";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
import {
  //addReAllocationDetails,
  UpdateReAllocationDetails,
} from "../../../app/redux/slices/budgetSlice";
var budgetdata;
var OldAmount;
var toBudgetOldAmt;
var fromBudgetOldAmt;
//var headAmt;

const AddReAllocationDetails = ({ item, closePopup, reallocation }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!reallocation) {
      return;
    }
    runFillReAllocationData.current = true;
    if (runFillReAllocationData.current) {
      fillReAllocationData(reallocation);
    }
  }, []);
  
const [SNoFrom,setSNoFrom] = useState(0);
const [SNoTo,setSNoTo] = useState(0);

  const [budgetDate, setBudgetDate] = useState("");
  const [collegeTypeFrom, setcollegeTypeFrom] = useState("");
  const [collegeTypeTo, setcollegeTypeTo] = useState("");
 const[HeadOfAccountFrom,setHeadOfAccountFrom]=useState("");
 const[HeadOfAccountTo,setHeadOfAccountTo]=useState("");
 const[HeadOfAccountFromAided,setHeadOfAccountFromAided]=useState("");
 const[HeadOfAccountToAided,setHeadOfAccountToAided]=useState("");
 const[HeadOfAccountFromSS,setHeadOfAccountFromSS]=useState("");
 const[HeadOfAccountToSS,setHeadOfAccountToSS]=useState("");
  const [departmentIdFrom, setdepartmentIdFrom] = useState("");
  const [departmentIdFromAided, setdepartmentIdFromAided] = useState("");
  const [departmentIdFromSS, setdepartmentIdFromSS] = useState("");
  const [departmentIdToAided, setdepartmentIdToAided] = useState("");
  const [departmentIdTo, setdepartmentIdTo] = useState("");
  const [departmentIdToSS, setdepartmentIdToSS] = useState("");

  const [DeparmentList, setDeparmentList] = useState([]);
  const [DeparmentListReAllocation, setDeparmentListReAllocation] = useState([]);
  const [BudjectHeadList, setBudjectHeadList] = useState([]);
  const [BudgetAmtFrom, setBudgetAmtFrom] = useState(0);
  const [BudgetAmtTo, setBudgetAmtTo] = useState(0);
  const [BudgetAmtFromAided, setBudgetAmtFromAided] = useState(0);
  const [BudgetAmtToAided, setBudgetAmtToAided] = useState(0);
  const [BudgetAmtFromSS, setBudgetAmtFromSS] = useState(0);
  const [BudgetAmtToSS, setBudgetAmtToSS] = useState(0);
  const [UpdatedAmt, setUpdatedAmt] = useState(0);
  const [BudgetAmtRealloc, setBudgetAmtRealloc] = useState(0);
  const [BudgetAmtReallocAided, setBudgetAmtReallocAided] = useState(0);
  const [BudgetAmtReallocSS, setBudgetAmtReallocSS] = useState(0);

  const [HeadList, setHeadList] = useState([]);
  
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillReAllocationData = useRef();

  const clearState = () => {  
    setBudgetDate("");
    setBudgetAmtFrom("");
    setBudgetAmtTo("");
    // setBudgetAmtFromAided("");
    // setBudgetAmtToAided("");setBudgetAmtFromSS("");
    // setBudgetAmtToSS("");
    setBudgetAmtRealloc("");
    setUpdatedAmt("");
    setSNoFrom("");
    setSNoTo("");
    setHeadOfAccountFrom("");
    setHeadOfAccountTo("");
    // setHeadOfAccountFromAided("");
    // setHeadOfAccountToSS("");
    // setHeadOfAccountFromSS("");
    // setHeadOfAccountToAided("");
    setdepartmentIdTo("");
    setdepartmentIdFrom("");
    // setdepartmentIdToAided("");
    // setdepartmentIdFromAided("");setdepartmentIdToSS("");
    // setdepartmentIdFromSS("");
    // setBudgetAmtReallocAided("");
    // setBudgetAmtReallocSS("");
    toBudgetOldAmt=0;
    fromBudgetOldAmt=0;
  };
 
  const budgetdate = (val) => {
    debugger;
    setBudgetDate(val.target.value);
  };
  const budgetamtfrom = (val)=>{
    if (/^[0-9.]*$/.test(val.target.value) == false) {
      //error message  
    }
    else {
      setBudgetAmtFrom(Number(fromBudgetOldAmt)-Number(val.target.value));
      if(collegeTypeFrom =="Aided"){
        setBudgetAmtFromAided(Number(fromBudgetOldAmt)-Number(val.target.value));
        setBudgetAmtFromSS(0);
      }
      else if(collegeTypeFrom =="SS"){
        setBudgetAmtFromAided(0);
        setBudgetAmtFromSS(Number(fromBudgetOldAmt)-Number(val.target.value));
      }
    }
  };
  const budgetamtto = (val)=>{
    if (/^[0-9.]*$/.test(val.target.value) == false) {
      //error message  
    }
    else if(Number(val.target.value) < Number(fromBudgetOldAmt)) {

      setBudgetAmtTo(val.target.value);
      if(collegeTypeFrom =="Aided"){
        setBudgetAmtToAided(val.target.value);
        setBudgetAmtToSS(0);
      }
      else if(collegeTypeFrom =="SS"){
        setBudgetAmtToAided(0);
        setBudgetAmtToSS(val.target.value);
      }
      //budgetamtfrom(val);
    }
   
  };
  const headOfTheaccountfrom = (val) => {
    debugger;setBudgetAmtRealloc("");
    if(collegeTypeFrom=="Aided")
    {
    
    setBudgetAmtReallocAided("");

    setHeadOfAccountFrom(val.target.value);
    setHeadOfAccountFromSS("");
    setHeadOfAccountFromAided(val.target.value);

    budgetdata=BudjectHeadList.filter(x=>x.departmentId == departmentIdFrom && x.headOfAccount == val.target.value);
    if(budgetdata.length>0){

     setBudgetAmtFrom(budgetdata[0].headAmt);
     setBudgetAmtFromAided(budgetdata[0].headAmt);
     fromBudgetOldAmt =budgetdata[0].headAmt;
     setSNoFrom(budgetdata[0].sNo);}
     else{
      setBudgetAmtFrom(0);      
      setBudgetAmtFromAided(0);setBudgetAmtFromSS(0);
      fromBudgetOldAmt =0;
      setSNoFrom(0);
    }}
    else if(collegeTypeFrom=="SS")
    {
    setBudgetAmtReallocSS("");

    setHeadOfAccountFrom(val.target.value);
    setHeadOfAccountFromAided("");
    setHeadOfAccountFromSS(val.target.value);

    budgetdata=BudjectHeadList.filter(x=>x.departmentId == departmentIdFrom && x.headOfAccount == val.target.value);
    if(budgetdata.length>0){

     setBudgetAmtFrom(budgetdata[0].headAmt);
     setBudgetAmtFromSS(budgetdata[0].headAmt);
     fromBudgetOldAmt =budgetdata[0].headAmt;
     setSNoFrom(budgetdata[0].sNo);}
     else{
      setBudgetAmtFrom(0);      
      setBudgetAmtFromAided(0);setBudgetAmtFromSS(0);
      fromBudgetOldAmt =0;
      setSNoFrom(0);
    }
    }
    
  };
  const headOfTheaccountto = (val) => {
    debugger;
    //(val.target.value);
    setBudgetAmtRealloc("");
    if(collegeTypeFrom =="Aided")
    {    setBudgetAmtReallocAided("");

    if((Number(val.target.value)===Number(HeadOfAccountFrom))&&
    Number(departmentIdFrom)===Number(departmentIdTo))
    {      alert("Sorry!Unable to select same HeadofAccount");
     }
    else{
      setHeadOfAccountTo(val.target.value);
      setHeadOfAccountToAided(val.target.value);
      setHeadOfAccountToSS("");

      budgetdata=BudjectHeadList.filter(x=>x.departmentId == departmentIdTo && x.headOfAccount == val.target.value);
      if(budgetdata.length>0){
       setBudgetAmtTo(budgetdata[0].headAmt);
       setBudgetAmtToAided(budgetdata[0].headAmt);
        setBudgetAmtToSS(0);
       toBudgetOldAmt =budgetdata[0].headAmt;
      setSNoTo(budgetdata[0].sNo);}
       else{
        setBudgetAmtTo(0);
        setBudgetAmtToAided(0);
        setBudgetAmtToSS(0);
        toBudgetOldAmt =0; setSNoTo(0);
      }
    }}
    else if(collegeTypeFrom =="SS")
    {    setBudgetAmtReallocSS("");

      if((Number(val.target.value)===Number(HeadOfAccountFrom))&&
      Number(departmentIdFrom)===Number(departmentIdTo))
      {      alert("Sorry!Unable to select same HeadofAccount");
       }
      else{
        setHeadOfAccountTo(val.target.value);
        setHeadOfAccountToSS(val.target.value);
        setHeadOfAccountToAided("");
  
        budgetdata=BudjectHeadList.filter(x=>x.departmentId == departmentIdTo && x.headOfAccount == val.target.value);
        if(budgetdata.length>0){
         setBudgetAmtTo(budgetdata[0].headAmt);
         setBudgetAmtToSS(budgetdata[0].headAmt);
         setHeadOfAccountToAided(0);
         toBudgetOldAmt =budgetdata[0].headAmt;
        setSNoTo(budgetdata[0].sNo);}
         else{
          setBudgetAmtTo(0);
          setBudgetAmtToAided(0);
          setBudgetAmtToSS(0);
          toBudgetOldAmt =0; setSNoTo(0);
        }
      }

    }
  };
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    AsyncGet(API.gethoadetails)
      .then((res) => {
        console.log(res.data, "getHOADetails");
        setHeadList(res.data);
        //setHeadListUpdated(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  useEffect(() => {
    AsyncGet(API.getBudgetHeads)
      .then((res) => {
        console.log(res.data, "getBudgetHeads");
        setBudjectHeadList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deptfrom = (val) => {
    debugger;
    setBudgetAmtFrom(0);
    fromBudgetOldAmt =0;
    setHeadOfAccountFrom("");
    setBudgetAmtRealloc("");
   // setHeadOfAccountFromAided("");
    //setBudgetAmtFromAided("");
    setdepartmentIdFrom(val.target.value);
    if(collegeTypeFrom =="Aided")
    {
      setHeadOfAccountFromAided("");
      setBudgetAmtReallocAided("");
      setBudgetAmtFromAided("");
      setdepartmentIdFromAided(val.target.value);
      setdepartmentIdFromSS("");
    }
    else if(collegeTypeFrom =="SS")
    {
      setBudgetAmtFromSS("");
      setBudgetAmtReallocSS("");
      setHeadOfAccountFromSS("");
      setdepartmentIdFromAided("");
      setdepartmentIdFromSS(val.target.value);
    }
    
  };

  const deptto = (val) => {
    debugger;
    setBudgetAmtTo(0);
    toBudgetOldAmt =0;
    setHeadOfAccountTo("");
   
    setdepartmentIdTo(val.target.value);
    if(collegeTypeFrom =="Aided")
    {
      setHeadOfAccountToAided("");
      
      setBudgetAmtToAided("");
      setdepartmentIdToAided(val.target.value);
      setdepartmentIdToSS("");
    }
    else if(collegeTypeFrom =="SS")
    {
      setHeadOfAccountToSS("")
      setBudgetAmtToSS("");
      setdepartmentIdToAided("");
      setdepartmentIdToSS(val.target.value);
    }
    /*setBudgetAmtRealloc("");

    if(Number(val.target.value)!=Number(departmentIdFrom))
    {setdepartmentIdTo(val.target.value);
    budgetdata=BudjectHeadList.filter(x=>x.departmentId == val.target.value && x.collegeType == collegeTypeFrom);
    if(budgetdata.length>0){
     setBudgetAmtTo(budgetdata[0].headAmt);
     toBudgetOldAmt =budgetdata[0].headAmt;
    setSNoTo(budgetdata[0].sNo);}
     else{
      setBudgetAmtTo(0);

    }}
    else{
      alert("Sorry!Unable to select same department");
    }*/
  };
const budgetamtrealloc= (val)=>

{ if (/^[0-9.]*$/.test(val.target.value) == false) {
  //error message  
}

else if(Number(val.target.value) <= Number(fromBudgetOldAmt)) {

  setBudgetAmtRealloc(val.target.value);
  setBudgetAmtFrom(Number(fromBudgetOldAmt) -Number(val.target.value));
  setBudgetAmtTo(Number(toBudgetOldAmt) +Number(val.target.value));
  //budgetamtfrom(val);
  if(collegeTypeFrom =="Aided")
  {
    setBudgetAmtReallocAided(val.target.value);
    setBudgetAmtFromAided(Number(fromBudgetOldAmt) -Number(val.target.value));
    setBudgetAmtToAided(Number(toBudgetOldAmt) +Number(val.target.value));
  }
  else if(collegeTypeFrom =="SS")
  {
    setBudgetAmtReallocSS(val.target.value);
    setBudgetAmtFromSS(Number(fromBudgetOldAmt) -Number(val.target.value));
    setBudgetAmtToSS(Number(toBudgetOldAmt) +Number(val.target.value));
  }
}

};
  
  {/*const saveReAllocationDetails = () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var reAllocationData = {
      SNoFrom:Number(SNoFrom),
      setSNoTo:Number(setSNoTo),
      budgetDate : budgetDate,
      collegeTypeFrom:collegeTypeFrom,
      collegeTypeTo:collegeTypeTo,
      departmentIdFrom:Number(departmentIdFrom),
      BudgetAmtTo:parseFloat(BudgetAmtTo).toFixed(2),
      departmentIdTo:Number(departmentIdTo),
      BudgetAmtFrom:(parseFloat(BudgetAmtFrom).toFixed(2)),
      UpdatedAmt:((parseFloat(UpdatedAmt)+parseFloat(BudgetAmtFrom)).toFixed(2)),
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
   // dispatch(addReAllocationDetails(reAllocationData));
    closePopup();
    clearState();
  };*/}

  const updateReAllocationDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      SNoFrom:Number(SNoFrom),
      SNoTo:Number(SNoTo),
      budgetDate : budgetDate,
      collegeTypeFrom:collegeTypeFrom,
      //HeadOfAccountFrom:Number(HeadOfAccountFrom),
      HeadOfAccountTo:Number(HeadOfAccountTo),
      //collegeTypeTo:collegeTypeTo,
      //departmentIdFrom:Number(departmentIdFrom),
      BudgetAmtTo:parseFloat(BudgetAmtTo).toFixed(2),
      departmentIdTo:Number(departmentIdTo),
      BudgetAmtFrom:(parseFloat(BudgetAmtFrom).toFixed(2)),      
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const reAllocationData = {
      ...reallocation,
        data: data
    };

    console.log("UpdateReAllocation", reAllocationData.data);
    //await UpdateAluminiDetails(aluminiData.data, dispatch);
    await UpdateReAllocationDetails(reAllocationData.data,dispatch);
    closePopup();    
    clearState();
    //window.location.reload(false);
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !budgetDate || !departmentIdFrom || !BudgetAmtFrom ||!departmentIdTo ||!BudgetAmtTo ||!BudgetAmtRealloc||
      !HeadOfAccountFrom ||!HeadOfAccountTo
    ) {
      setvalidate(false);
      seterrorMsg("Provide All Required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

   const fillReAllocationData = (obj) => {
  //   runFillReAllocationData.current = false;
  //   setSNoFrom(obj.data.SNoFrom);
  //   setBudgetDate(obj.data.budgetDate);
  //   setdepartmentId(obj.data.departmentId);
  //   setcollegeTypeFrom(obj.data.collegeType);
  //   setBudgetAmt(obj.data.budgetAmt);
  //   OldAmount=(obj.data.budgetAmt);
  //   setUpdatedAmt(obj.data.budgetAmt);
  //   setdepartmentIdReAllocation(obj.data.departmentIdReAllocation);
  //   setReAllocationBudgetAmt(obj.data.reAllocationBudgetAmt);
  //   runFillReAllocationData.current = true;
   };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">Budget Reallocation</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row">

            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn1"
                  id="inlineRadio1"
                  value="1"
                  checked={collegeTypeFrom === "Aided"}
                  onChange={() => {setcollegeTypeFrom("Aided");
                  setdepartmentIdFrom(departmentIdFromAided);
                  setdepartmentIdTo(departmentIdToAided);
                  setHeadOfAccountFrom(HeadOfAccountFromAided);
                  setHeadOfAccountTo(HeadOfAccountToAided);
                  setBudgetAmtTo(BudgetAmtToAided);
                  setBudgetAmtFrom(BudgetAmtFromAided);
                  setBudgetAmtRealloc(BudgetAmtReallocAided);
                  }}
                                

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn2"
                  id="inlineRadio2"
                  value="2"
                  checked={collegeTypeFrom === "SS"}
                  onChange={() =>{ setcollegeTypeFrom("SS");
                  setdepartmentIdFrom(departmentIdFromSS);
                  setdepartmentIdTo(departmentIdToSS);
                  setHeadOfAccountFrom(HeadOfAccountFromSS);
                  setHeadOfAccountTo(HeadOfAccountToSS);
                  setBudgetAmtTo(BudgetAmtToSS);
                  setBudgetAmtFrom(BudgetAmtFromSS);
                  setBudgetAmtRealloc(BudgetAmtReallocSS);
                  }}

                />
                <label className="form-check-label checkposition">
                  Self Supporting
                </label>
              </div>
              {/* <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio3"
                  value="3"
                  checked={collegeTypeTo === "Aided"}
                  onChange={() => setcollegeTypeTo("Aided")}
                                

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn4"
                  id="inlineRadio4"
                  value="4"
                  checked={collegeTypeTo === "SS"}
                  onChange={() => setcollegeTypeTo("SS")}

                />
                <label className="form-check-label checkposition">
                  SS
                </label>
              </div>*/}
              </div></div> 
              <div className="row formadduser">  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label><span style={{ color: "red" }}>*</span>Date</label>
              <input
                className="form-control"
                type="date"
                value={dayjs(budgetDate).format("YYYY-MM-DD")}
                max={Moment().format('YYYY-MM-DD')}
                onChange={budgetdate}
              />
              {!budgetDate && validate ? (
                <h6 style={{ color: "red" }}>{"Required"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group"></div>
                          
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>From Department
              </label>
              <select
                className="form-select form-control"
                onChange={deptfrom}
                aria-label="Default select example"
                value={departmentIdFrom}              
                >
                <option value="">--Select Department--</option>
                {DeparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {!departmentIdFrom && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>To Department
              </label>
              <select
                className="form-select form-control"
                onChange={deptto}
                aria-label="Default select example"
                value={departmentIdTo}              
                >
                <option value="">--Select Department--</option>
                {DeparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {!departmentIdTo && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <select
                className="form-select form-control"
                onChange={headOfTheaccountfrom}
                aria-label="Default select example"
                value={HeadOfAccountFrom}
               // disabled={validation && OfficeValid} 
                >
                <option value="">--Select Head--</option>
                {(HeadList)?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.headOfAccount}
                  </option>
                ))}
              </select>
              {!HeadOfAccountFrom && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <select
                className="form-select form-control"
                onChange={headOfTheaccountto}
                aria-label="Default select example"
                value={HeadOfAccountTo}
               // disabled={validation && OfficeValid} 
                >
                <option value="">--Select Head--</option>
                {(HeadList)?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.headOfAccount}
                  </option>
                ))}
              </select>
              {!HeadOfAccountTo && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Budget</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="budget"
                  value={BudgetAmtFrom}
                  onChange={budgetamtfrom}
                  maxLength={10}
                  disabled
                />
                {/* {!BudgetAmtFrom && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null} */}

              </div>
             
             
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Budget</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="budget"
                  value={BudgetAmtTo}
                  onChange={budgetamtto}
                  maxLength={10}
                  disabled
                />
                {/* {!SanctionedAmount && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null} */}

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Reallocated Budget</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="budget"
                  value={BudgetAmtRealloc}
                  onChange={budgetamtrealloc}
                  maxLength={10}
                />
                {!BudgetAmtRealloc && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}

              </div>
              </div>             
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!reallocation ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateReAllocationDetails()}
                >
                  Reallocate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateReAllocationDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddReAllocationDetails;