import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const odSlice = createSlice({
  name: "odList",
  initialState: {
    loading: false,
    error: false,
    odList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    odDetails: [],
    submitOdSuccess: "",
    saveOdDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getOd: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.odList = payload;
    },
    saveOd: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveOdDataSuccess = payload;
    },
    submitOd: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getOd,
  submitOd,
  setError,
  saveOd,
} = odSlice.actions;
export const odSelector = (state) => state.ods || [];
export default odSlice.reducer;

export const fetchods = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getFdsForms)
    .then((response) => {
      console.log("response,", response);
      dispatch(getOd(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addOdDetails =  async (data, dispatch)=> {
  debugger;
  try {
  //dispatch(setLoading());
  const response = await AsyncPost(API.saveFdsForms, data);
      //dispatch(setLoading());
      dispatch(saveOd(response.data));
      dispatch(fetchods());
      return response.data;
     // dispatch(fetchods());
    }
    catch(error)  {
      console.log("failure of add faculty");
      dispatch(fetchods());

      // dispatch(saveOd(""));
      // dispatch(setError(true));
     // dispatch(setLoading());
}  
};
export const UpdateOdDetails = (data) => (dispatch) =>{ 
  debugger;
//export const UpdateIndentDetails =  async (data, dispatch)=> {
  dispatch(setLoading());
  AsyncPost(API.updateFdsFormData, data)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of update Indent");
      dispatch(fetchods());
    })
    .catch((er) => {
      console.log("failure of add faculty");

     // dispatch(saveOd(""));
      //dispatch(setError(true));
      //dispatch(setLoading());
    });
};
export const deleteOd = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteFdsFormData + "?id=" + data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete indent");
      dispatch(fetchods());
    })
    .catch((er) => {
      console.log("failure of add indent");
      dispatch(saveOd(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
