import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";

import Moment from "moment";


import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import { AsyncGet, AsyncPost } from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";
const NewItem = ({ isEdit,item, closePopup, activity }) => {
  const dispatch = useDispatch();
  const [SemMonth, setSemMonth] = useState("");
  //const [Month, setMonth] = useState([]);
  const [Department, setDepartment] = useState("");
  const [DeparmentList, setDeparmentList] = useState([]);
  const [nStudentAppeared, setnStudentAppeared] = useState("");
  const [nStudentPassed, setnStudentPassed] = useState("");
  const [nBoysAppeared, setnBoysAppeared] = useState("");
  const [nGirlsAppeared, setnGirlsAppeared] = useState("");
  const [nBoysPassed, setnBoysPassed] = useState("");
  const [nGirlsPassed, setnGirlsPassed] = useState("");
  const [PassPercentage, setPassPercentage] = useState("");
  const [Remarks, setRemarks] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [Sem, setSemester] = useState("");
  const [Year, setYearOfEducation] = useState("");
  // const [enableBoyspassed, setenableBoyspassed] = useState(true);
  // const [enableGirlspassed, setenableGirlspassed] = useState(true);
  const [enableBoysAppeared, setenableBoysAppeared] = useState(false);
  const [enableGirlsAppeared, setenableGirlsAppeared] = useState(false);
  const [validate, setvalidate] = useState(false);
  const [datevalidation, setdatevalidation] = useState(true);
  const runFillActivityData = useRef();
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const clearState = () => {
    setDepartment("");
    setnStudentAppeared("");
    setnStudentPassed("");
    setnBoysAppeared("");

    setnGirlsAppeared("");
    setnBoysPassed("");
    setnGirlsPassed("");
    setSemMonth("");
    //setMonth("");
    setSemester("");
    setYearOfEducation("");
    // setstudentORFaculty("Student");
    // setInternalOrExternal("Internal");
    //setOffLineOrOnLine("Offline");
    setPassPercentage("");
    setRemarks("");

    seterrorMsg("");
    setvalidate("");
    //setFile("");
    //setfilesList([]);
    //setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };
  /*const monthchange = (val) => {
    if (val.target.value != '')
      setFromdatevalidation(true);
    else
      setFromdatevalidation(false);
    // setToDate(val.target.value);
    setMonth(val.target.value);
  };*/
  const onDepartmentSelect = (e) => {
    setDepartment(e.target.value);
  };
  const sem = (val) => {
    setSemester(val.target.value);
  };
  
  const yearOfEducation = (val) => {
    setYearOfEducation(val.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };
  const onSemMonthYearSelect = (e) => {

    setSemMonth(e.target.value);
    //setMonth('');
    setdatevalidation(false);
  };

  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 26,
      Department: Department,
      nStudentAppeared: Number(nBoysAppeared) + Number(nGirlsAppeared),
      nStudentPassed: Number(nBoysPassed) + Number(nGirlsPassed),
      nBoysAppeared: nBoysAppeared,
      nGirlsAppeared: nGirlsAppeared,
      nBoysPassed: nBoysPassed,
      nGirlsPassed: nGirlsPassed,
      SemMonth: SemMonth,
      //Month: Month,
      Sem: Sem,
      Year: Year,
      PassPercentage: parseFloat(((Number(nBoysPassed) + Number(nGirlsPassed)) / (Number(nBoysAppeared) + Number(nGirlsAppeared))) * 100).toFixed(2),
      Remarks: Remarks,
    };
    var activityData = {
      id: 0,
      activityID: 26,
      activityName: "Semester Result",
      data: JSON.stringify(data),
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      dispatch(fetchActivities(26, null));
      closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
      
    }
  };

  const updateActivity = async () => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 26,
      Department: Department,
      nStudentAppeared: Number(nBoysAppeared) + Number(nGirlsAppeared),
      nStudentPassed: Number(nBoysPassed) + Number(nGirlsPassed),
      nBoysAppeared: nBoysAppeared,
      nGirlsAppeared: nGirlsAppeared,
      nBoysPassed: nBoysPassed,
      nGirlsPassed: nGirlsPassed,
      SemMonth: SemMonth,
      //Month: Month,
      Sem: Sem,
      Year: Year,
      PassPercentage: parseFloat(((Number(nBoysPassed) + Number(nGirlsPassed)) / (Number(nBoysAppeared) + Number(nGirlsAppeared))) * 100).toFixed(2),
      Remarks: Remarks,

    };
    const activityData = {
      ...activity,
      activityID: 26,
      data: JSON.stringify(data),
    };
    try {
      dispatch(UpdateActivity(activityData));
      closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      Sem &&
      Year &&
      Department &&
      nBoysPassed &&
      nGirlsPassed &&
      nBoysAppeared &&
      nGirlsAppeared
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
   // window.location.reload(false);
  };

  const onTextValueChange = (e) => {

    switch (e.target.id) {
      case "nstudentappeared":

        var studentAppeared = e.target.value.replace(/\D/g, "");
        //if(e.target.value>nStudentPassed)
        setnStudentAppeared(studentAppeared);

        break;
      case "nstudentpassed":

        var studentPassed = e.target.value.replace(/\D/g, "");
        //if (Number(nStudentPassed) <= Number(nStudentAppeared))
        setnStudentPassed(studentPassed);
        break;
      case "nboysappeared":

        var nboysappeared = e.target.value.replace(/\D/g, ""); 
          setnBoysAppeared(nboysappeared);
          setnBoysPassed("");
        break;
      case "ngirlsappeared":
        var ngirlsappeared = e.target.value.replace(/\D/g, "");
          setnGirlsAppeared(ngirlsappeared);
          setnGirlsPassed("");
        break;
      case "nboyspassed":
        var nboyspassed = e.target.value.replace(/\D/g, "");
        //setnBoysPassed(nboyspassed);
        if (Number(nboyspassed) <= Number(nBoysAppeared)) {
          if (nboyspassed == "")
            setenableBoysAppeared(false);
          else
            setenableBoysAppeared(true);
          setnBoysPassed(nboyspassed);

        }
        break;
      case "ngirlspassed":
        var ngirlspassed = e.target.value.replace(/\D/g, "");
        if (Number(ngirlspassed) <= Number(nGirlsAppeared)) {
          if (ngirlspassed == "")
            setenableGirlsAppeared(false);
          else
            setenableGirlsAppeared(true);
          setnGirlsPassed(ngirlspassed);
        }
        break;
      case "passpercentage":

        if (e.target.value < 100)
          setPassPercentage(e.target.value);
        break;
      case "semmonth":
        setSemMonth(e.target.value);
        break;
      

      case "remarks":
        setRemarks(e.target.value);
        break;


    }
  };


  const fillActivityData = (obj) => {
    runFillActivityData.current = false;
    setDepartment(obj.data.Department);
    setnStudentAppeared(obj.data.nStudentAppeared);
    setnStudentPassed(obj.data.nStudentPassed);
    setnBoysAppeared(obj.data.nBoysAppeared);
    setnGirlsAppeared(obj.data.nGirlsAppeared);
    setnBoysPassed(obj.data.nBoysPassed);
    setnGirlsPassed(obj.data.nGirlsPassed);
    setSemMonth(obj.data.SemMonth);
    //setMonth(obj.data.Month);
    setPassPercentage(obj.data.PassPercentage);
    setRemarks(obj.data.Remarks);
    setSemester(obj.data.Sem);
    setYearOfEducation(obj.data.Year);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">

            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Department </label>
                <select
                  className="form-select form-control"
                  onChange={onDepartmentSelect}
                  aria-label="Default select example"
                  value={Department}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.name}>
                      {opt.name}
                    </option>
                  ))}
                </select>
                {!Department && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">

                <label>Sem Month&Year</label>

                <input

                  className="form-control"

                  type="month"
                  disabled={Fromdatevalidation1}
                  value={SemMonth}
                  max={Moment().format('YYYY-MM')}
                  onChange={onSemMonthYearSelect}

                />
              </div>
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Result Month</label>
                <input
                  className="form-control"
                  type="month"
                  value={Month}
                  min={dayjs(SemMonth).format("YYYY-MM")}
                  max={Moment().format('YYYY-MM')}
                  disabled={datevalidation}
                  onChange={monthchange}
                />

                </div>*/}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> <span style={{ color: "red" }}>*</span>Year Of Education</label>
                <select
                  className="form-select form-control"
                  onChange={yearOfEducation}
                  aria-label="Default select example"
                  value={Year}
                >
                  <option value="">--Select Year--</option>
                  <option value={1}>1st Year</option>
                  <option value={2}>2nd Year</option>
                  <option value={3}>3rd Year</option>
                </select>
                {!Year && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Semester
                </label>
                <select
                  className="form-select form-control"
                  onChange={sem}
                  aria-label="Default select example"
                  value={Sem}
                >
                  <option value="">--Select Sem--</option>
                  <option value="Odd">Odd</option>
                  <option value="Even">Even</option>
                </select>
                {!Sem && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Boys Appeared</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nboysappeared"
                  value={nBoysAppeared}
                  //disabled={enableBoysplaced}
                  onChange={onTextValueChange}
                  maxLength={4}
                  disabled={enableBoysAppeared}
                />
                {!nBoysAppeared && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Girls Appeared</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="ngirlsappeared"
                  value={nGirlsAppeared}
                  onChange={onTextValueChange}
                  maxLength={4}
                  disabled={enableGirlsAppeared}
                />
                {!nGirlsAppeared && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>No of Student Appeared</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentappeared"
                  value={Number(nBoysAppeared) + Number(nGirlsAppeared)}
                  onChange={onTextValueChange}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Boys Passed</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nboyspassed"
                  value={nBoysPassed}
                  //disabled={enableBoysplaced}
                  onChange={onTextValueChange}
                  maxLength={4}
                //disabled={enableBoyspassed}
                />
                {!nBoysPassed && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Girls Passed</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="ngirlspassed"
                  //disabled={enableGirlsplaced}
                  value={nGirlsPassed}
                  onChange={onTextValueChange}
                  maxLength={4}
                //disabled={enableGirlspassed}
                />
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>No of Student Passed</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentpassed"
                  //value={nStudentPassed}
                  value={Number(nBoysPassed) + Number(nGirlsPassed)}
                  onChange={onTextValueChange}
                  maxLength={4}
                />
               
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt">
                <label>Remarks</label>
                <textarea
                  className="form-control"
                  id="remarks" value={Remarks}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={300}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Pass Percentage</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  disabled={true}
                  id="passpercentage"
                  
                  value={parseFloat(((Number(nBoysPassed) + Number(nGirlsPassed)) / (Number(nBoysAppeared) + Number(nGirlsAppeared))) * 100).toFixed(2) == 'NaN' ? '' : parseFloat(((Number(nBoysPassed) + Number(nGirlsPassed)) / (Number(nBoysAppeared) + Number(nGirlsAppeared))) * 100).toFixed(2)}
                 
                  onChange={onTextValueChange}
                  maxLength={5}
                />

              </div>    
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
