
const Activity = () => {

        return (
            <div >
                <lable>Activity Page</lable>
            </div>
        )

    
}
export default Activity;