import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';

import {
  addDepartmentDetails,
  UpdateDepartmentDetails,
} from "../../../app/redux/slices/departmentSlice";

const AddDepartmentDetails = ({ isEdit, item, closePopup, department }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!department) {
      return;
    }
    runFillDepartmentData.current = true;
    if (runFillDepartmentData.current) {
      fillDepartmentData(department);
    }
  }, []);
  const [Id, setId] = useState();
  const [Name, setName] = useState("");
  const [DepartmentCode, setDepartmentCode] = useState("");
  const [IsActive, setIsActive] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const runFillDepartmentData = useRef();
  const clearState = () => {
    setName("");
    setDepartmentCode("");
    setIsActive("");
  };
  const dName = (val) => {
    if(/^[a-z A-Z 0-9.()]*$/.test(val.target.value) == false){
      //error message
    }     else
    {
    setName(val.target.value);
    }
  };
  const dCode = (val) => {
    if(/^[a-z A-Z 0-9]*$/.test(val.target.value) == false){
      //error message
    }     else
    {
    setDepartmentCode(val.target.value);
    }
  };
  const isActive= (e) => {
    setIsActive(e.target.checked);
    
    // if (val==1)
    // {IsActive=true;}
    // if (val==0)
    // setIsActive(false);
  }
  const saveDepartmentDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var departmentData = {
      Id: 0,
      Name: Name,
      DepartmentCode:DepartmentCode,
      IsActive:IsActive,
      // == 1 ? true:false
      CreatedByName: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedByName: "",
      ModifiedDate: new Date()
    };
    
    dispatch(addDepartmentDetails(departmentData));
    closePopup();
    clearState();
  };

  const updateDepartmentDetails = () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      Name:Name,
      IsActive: IsActive,
      DepartmentCode:DepartmentCode,
      CreatedByName: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedByName: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const departmentData = {
      ...department,
      data: data
    };

    console.log("UpdateDepartment", departmentData.data);
    dispatch(UpdateDepartmentDetails(departmentData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !Name 
    ) {
      setvalidate(false);
      seterrorMsg("Provide all required fields");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillDepartmentData = (obj) => {
    debugger;
    runFillDepartmentData.current = false;
    // console.log(obj);
    setId(obj.data.id);
    setName(obj.data.name);
    setDepartmentCode(obj.data.departmentCode);
    setIsActive(obj.data.isActive);
    // ==true?1:0
    runFillDepartmentData.current = true;
  };

  return (
    <div className="popup">
    <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Department" : "Add New Department"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Department Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={Name}
                  onChange={dName}
                  maxLength={50}
                />
              </div> 
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Department Code</label>
                <input
                  className="form-control"
                  type="text"
                  value={DepartmentCode}
                  onChange={dCode}
                  maxLength={5}
                />
              </div> 
            </div>
            <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="checkbox"
                    //name="eligle-radio-btn"
                    //value="1"
                    checked={IsActive}
                    onChange={isActive}
                  />
                 
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    IsActive
                  </label>
                </div>
                
                
              </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!department ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveDepartmentDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateDepartmentDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDepartmentDetails;