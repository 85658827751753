import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";

import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import { AsyncGet, AsyncGetFiles, AsyncPost } from "../../../../app/services/https";
import axios from "axios";
const NewItem = ({isEdit, item, closePopup, activity }) => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [EventDate, setEventDate] = useState("");
  const [Time, setTime] = useState("");
  const [Min, setMin] = useState("");
  const [AlumniID, setAlumniID] = useState([]);
  const [Department, setDepartment] = useState("");
  const [Event, setEvent] = useState("");
  const [Venue, setVenue] = useState("");
  const [Impact, setImpact] = useState("");
  const [ResourcePersonOrActed, setResourcePersonOrActed] = useState("");
  const [OrganisedBy, setOrganisedBy] = useState("");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [alumniList, setalumniList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [datevalidation, setdatevalidation] = useState(true);
  const [fileName, setFileName] = useState();
  const [filesList, setfilesList] = useState([]);
  const runFillActivityData = useRef();
  const clearState = () => {

    setEventDate("");

    setEvent("");

    setVenue("");

    setTime("");

    setAlumniID([]);

    setImpact("");

    setOrganisedBy("");

    setselectedFile("");

    setCreatedBy("");

    setalumniList([]);

    seterrorMsg("");

    setvalidate("");

    setFile("");

    setfilesList([]);

    setFileName("");

    setdatevalidation(true);
    setResourcePersonOrActed("");

    //setFromdatevalidation(false);

    console.log("reseted");

  };
  const EventDatechange = (val) => {
    setEventDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  const onTimeSelect = (e) => {
    setTime(e.target.value);
  };
  const onMinSelect = (e) => {
    setMin(e.target.value);
  };
  const onDepartmentSelect = (e) => {
    setDepartment(e.target.value);
  };
  const onAlumniIDChange = (data) => {
    debugger;
    setAlumniID(data);
  };
  const onAlumniIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getAlumniByName + "?AlumniName=" + data)
      .then((res) => {

        setalumniList(res.data);
      })
      .catch(() => setalumniList([]));
  };
  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(33, null));
      closePopup();
      clearState();
      return;
    }


    console.log(res, "response");
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "AlumniEvent"
    );

    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(33, null));
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {

    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 33,
      Event: Event,
      OrganisedBy: OrganisedBy,
      Venue: Venue,
      EventDate: EventDate, //new Date(EventDate).toLocaleDateString(),
      //Department:JSON.parse(localStorage.getItem("user")).department,
      Time: Time,
      Impact: Impact,
      ResourcePersonOrActed: ResourcePersonOrActed,
      Attachment: "",
      AlumniID,
    };
    var activityData = {
      //id: 0,
      activityID: 33,
      activityName: "Alumni Event",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: 0,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateActivity = async () => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 33,
      Event: Event,
      OrganisedBy: OrganisedBy,
      Venue: Venue,
      //Department:JSON.parse(localStorage.getItem("user")).department,
      Time: Time,
      //Min:Min,
      EventDate: EventDate, //new Date(EventDate).toLocaleDateString(),
      ResourcePersonOrActed: ResourcePersonOrActed,
      Impact: Impact,
      Attachment: "",
      AlumniID,
    };
    const activityData = {
      ...activity,
      activityID: 33,
      activityName: "Alumni Event",
      data: JSON.stringify(data),

    };
    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };
  const validationFun = () => {
    setvalidate(true);
    if (
      EventDate &&
      (AlumniID?.length > 0) &&
      Time &&
      AlumniID &&
      Venue &&

      OrganisedBy
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "alumniname":
        setAlumniID(e.target.value);
        break;
      case "department":
        setDepartment(e.target.value);
        break;
      case "resourcePersonOrActed":
        setResourcePersonOrActed(e.target.value);
        break;
      case "event":
        setEvent(e.target.value);
        break;
      case "impact":
        setImpact(e.target.value);
        break;
      case "organisedby":
        setOrganisedBy(e.target.value);
        break;
      case "venue":
        setVenue(e.target.value);
    }
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    runFillActivityData.current = false;
    const obj = data;
    const objData = obj.data;
    setEventDate(objData.EventDate);
    setAlumniID(objData.AlumniID || []);
    setEvent(objData.Event);
    setVenue(objData.Venue);
    setImpact(objData.Impact);
    setOrganisedBy(objData.OrganisedBy);
    //setMin(objData.Min);
    setTime(objData.Time);
    setResourcePersonOrActed(objData.ResourcePersonOrActed);
    setCreatedBy(obj.createdDate);
    setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row"></div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Event Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(EventDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={EventDatechange}
                />
                {!EventDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From EventDate"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Time</label>

                <input
                  className="form-control"
                  type={"time"}
                  id="time"
                  value={Time}
                  onChange={onTimeSelect}
                />
                {!Time && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>

              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt">
                <label><span style={{ color: "red" }}>*</span>Alumni ID</label>
                <SearchBox
                  options={alumniList}
                  onChangeList={(data) => onAlumniIDChange(data)}
                  onInputChange={(e) => onAlumniIDInputChange(e)}
                  selectedOptions={AlumniID || []}
                  type="alumni"
                />
                {!AlumniID?.length > 0 && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                <label>Resource Person/Acted</label>
                <textarea
                  className="form-control clsabstract"

                  id="resourcePersonOrActed"
                  value={ResourcePersonOrActed}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={512}
                />
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                <label>Impact</label>
                <textarea
                  className="form-control clsabstract"
                  id="impact"
                  value={Impact}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={512}
                />
              </div>
              
              {/* <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Department</label>
                <select
                  className="form-select form-control"
                  onChange={onDepartmentSelect}
                  aria-label="Default select example"
                  value={Department}
                >
                  <option>select menu</option>
                  <option value="Computer Science">Computer Science</option>
                  <option value="B.Sc Maths">B.Sc Maths</option>
                  <option value="English">English</option>
                  <option value="4">Bio Technology</option>
                  <option value="5">BBA</option>
                  <option value="6">BCom.CA</option>
                </select>
                {!Department && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>*/}

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Event</label>
                <input
                  className="form-control"
                  type="text"
                  id="event"
                  value={Event}
                  onChange={onTextValueChange}
                  maxLength={150}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Organised By</label>
                <input
                  className="form-control"
                  type="text"
                  id="organisedby"
                  value={OrganisedBy}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                {!OrganisedBy && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Venue</label>
                <input
                  className="form-control"
                  type="text"
                  id="venue"
                  value={Venue}
                  onChange={onTextValueChange}
                  maxLength={300}
                />
                {!Venue && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>


              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}

              </div>
            </div>

            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>

            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
