import React, { PureComponent, Fragment } from 'react';
import logoFaculty from '../../app/assets/images/faculty.png';
import logoGuest from '../../app/assets/images/guest.png';
import logoImplant from '../../app/assets/images/implant.png';
import logoIndustrial from '../../app/assets/images/industrial.png';
import logoJournel from '../../app/assets/images/journal.png';
import logoMain from '../../app/assets/images/main.png';
import logoNCC from '../../app/assets/images/ncc.png';
import logoPaper from '../../app/assets/images/nss.png';
import logoProject from '../../app/assets/images/paper.png';
import logoSem from '../../app/assets/images/project.png';
import logoSemester from '../../app/assets/images/semester.png';
import logoSports from '../../app/assets/images/sports.png';
import logoSymposium from '../../app/assets/images/symposym.png';
import logoWomen from '../../app/assets/images/women.png';
import logoConsultant from '../../app/assets/images/consultant.png';
import logoExtension from '../../app/assets/images/extension.png';
import logoPatent from '../../app/assets/images/patentDetails.png';
import logoAlumni from '../../app/assets/images/alumni.png';
import logoGrants from '../../app/assets/images/grants.png';
import logoPlacement from '../../app/assets/images/placement.png';
import logoMiscellaneous from '../../app/assets/images/misc.png';
import logoAttend from '../../app/assets/images/attend.png';
import logoAds from '../../app/assets/images/ads.png';
import logoEvent from '../../app/assets/images/event.png';
import logoAwards from '../../app/assets/images/awards.png';
import logoPress from '../../app/assets/images/press.png';
import logoMous from '../../app/assets/images/agreement.png';
import logoUpcomingEvent from '../../app/assets/images/event.png';

import './addnew.css';
import './bootstrap.css';
import './signin.css';
import './bootstrap.min.css';

import { Navigate } from "react-router-dom";


export default class Welcome extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {

            redirectProjectModel: false,
            redirectGuestLectures: false,
            redirectImplantTraining: false,
            redirectIndustrialVisit: false,
            redirectSportsAndGames: false,
            redirectNCC: false,
            redirectExtensionServices: false,
            redirectPlacement: false,
            redirectJournalAndBookPublication: false,
            redirectGrants: false,
            redirectPaper: false,
            redirectAlumniEvent: false,
            redirectPatent: false,
            redirectSem: false,
            redirectSymposium: false,
            redirectConsultant: false,
            redirectFaculty: false,
            redirectWomen: false,
            redirectNSS: false,
            redirectAwards: false,
            redirectEvents: false,
            redirectAdvertisements:false,
            redirectPressReports: false,
            redirectMOUs:false,
            redirectMiscellaneous:false,
            redirectAttendancePage:false,
            redirectUpcomingEvents: false,

            success: ""
        }


        this.goToProjectModel = this.goToProjectModel.bind(this);
        this.goToGuestLectures = this.goToGuestLectures.bind(this);
        this.goToImplantTraining = this.goToImplantTraining.bind(this);
        this.goToIndustrialVisit = this.goToIndustrialVisit.bind(this);
        this.goToSportsAndGames = this.goToSportsAndGames.bind(this);
        this.goToNCC = this.goToNCC.bind(this);
        this.goToExtensionServices = this.goToExtensionServices.bind(this);
        this.goToPlacement = this.goToPlacement.bind(this);
        this.goToJournalAndBookPublication = this.goToJournalAndBookPublication.bind(this);
        this.goToGrants = this.goToGrants.bind(this);
        this.goToPaperPResentation = this.goToPaperPResentation.bind(this);
        this.goToAlumniEvent = this.goToAlumniEvent.bind(this);
        this.goToConsultant = this.goToConsultant.bind(this);
        this.goToFaculty = this.goToFaculty.bind(this);
        this.goToPatent = this.goToPatent.bind(this);
        this.goToSem = this.goToSem.bind(this);
        this.goToSymposium = this.goToSymposium.bind(this);
        this.goToWomen = this.goToWomen.bind(this);
        this.goToNSS = this.goToNSS.bind(this);
        this.goToMiscellaneous = this.goToMiscellaneous.bind(this);
        this.goToAttendancePage =this.goToAttendancePage.bind(this);
        this.goToAwards = this.goToAwards.bind(this);
        this.goToEvents = this.goToEvents.bind(this);
        this.goToAdvertisements = this.goToAdvertisements.bind(this);
        this.goToMOUs = this.goToMOUs.bind(this);
        this.goToPressReports = this.goToPressReports.bind(this);
        this.goToUpcomingEvents = this.goToUpcomingEvents.bind(this);

    }


    goToProjectModel = () => {
        this.setState({ redirectProjectModel: true })
    };
    goToGuestLectures = () => {
        this.setState({ redirectGuestLectures: true })
    };
    goToImplantTraining = () => {
        this.setState({ redirectImplantTraining: true })
    };
    goToIndustrialVisit = () => {
        this.setState({ redirectIndustrialVisit: true })
    };
    goToSportsAndGames = () => {
        this.setState({ redirectSportsAndGames: true })
    };
    goToNCC = () => {
        this.setState({ redirectNCC: true })
    };
    goToExtensionServices = () => {
        this.setState({ redirectExtensionServices: true })
    };
    goToPlacement = () => {
        this.setState({ redirectPlacement: true })
    };

    goToJournalAndBookPublication = () => {
        this.setState({ redirectJournalAndBookPublication: true })
    };
    goToGrants = () => {
        this.setState({ redirectGrants: true })
    };
    goToPaperPResentation = () => {
        this.setState({ redirectPaper: true })
    };
    goToFaculty = () => {
        this.setState({ redirectFaculty: true })
    };
    goToWomen = () => {
        this.setState({ redirectWomen: true })
    };
    goToConsultant = () => {
        this.setState({ redirectConsultant: true })
    };
    goToPatent = () => {
        this.setState({ redirectPatent: true })
    };
    goToSem = () => {
        this.setState({ redirectSem: true })
    };
    goToSymposium = () => {
        this.setState({ redirectSymposium: true })
    };
    goToAlumniEvent = () => {
        this.setState({ redirectAlumniEvent: true })
    };

    goToNSS = () => {
        this.setState({ redirectNSS: true })
    };
    
    goToMiscellaneous = () => {
        this.setState({ redirectMiscellaneous: true })
    };
    goToAttendancePage =()=>{
        this.setState({redirectAttendancePage:true})
    };
    goToAwards = () => {
        this.setState({ redirectAwards: true })
    };
    goToEvents = () => {
        this.setState({ redirectEvents: true })
    };
    goToAdvertisements = () => {
        this.setState({ redirectAdvertisements: true })
    };
    goToMOUs = () => {
        this.setState({ redirectMOUs: true })
    };
    goToPressReports = () => {
        this.setState({ redirectPressReports: true })
    };
    goToUpcomingEvents = () => {
        this.setState({ redirectUpcomingEvents: true })
    };
    render() {

        return (
            // <div className="col-xs-12 col-sm-8 col-md-9 col-lg-10">
            <div className="formsec dashboard dashboard_menu">
                {/*<div className="row"> <div className="col"><h2 className="heading">Dashboard</h2></div><div className="col"> <span className="title-section d-flex justify-content-end"><a
                    //</span>className="btn btn-archive btn-sm" href="#" role="button">Export Report</a></span></div></div>*/}



                <section id="focus" className="focus-section">

                    <div className="row row-cols-1 row-cols-lg-6 row-cols-md-4 row-cols-sm-2 row-cols-2  g-2">
                        {
                            JSON.parse(localStorage.getItem("user")).paperPresentation ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoProject} className="card-img-top widthauto" alt="..." />
                                                <div className="bottom-right-tag text-uppercase">
                                                    <a  onClick={this.goToPaperPResentation}>Paper Presentation</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectPaper && <Navigate to='/paperpresentation' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null

                        }

                        {
                            JSON.parse(localStorage.getItem("user")).projectOrModel ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoSem} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToProjectModel}>Project/Model</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectProjectModel && <Navigate to='/projectmodel' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        {
                            JSON.parse(localStorage.getItem("user")).guestLectures ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoGuest} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToGuestLectures}>Guest Lectures</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectGuestLectures && <Navigate to='/guestlectures' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                          JSON.parse(localStorage.getItem("user")).implantTrainingOrInternship ?

                             <div className="col">

                              <div className="card shadow-sm h-100">

                                <div className="card-image">

                                   <div className="hover-text">

                                    <img src={logoImplant} className="card-img-top" alt="..." />

                                    <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToImplantTraining}>Inplant Training</a></div>

                                          {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}

                                      {this.state.redirectImplantTraining && <Navigate to='/implanttraining' replace={true} />}
                                      </div>

                                 <div className="image-overlay"></div>

                               </div>

                            </div>
 
                         </div>: null
                        }

                 
                        {
                            JSON.parse(localStorage.getItem("user")).industrialVisits ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoIndustrial} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToIndustrialVisit}>Industrial Visit</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectIndustrialVisit && <Navigate to='/industrialvisit' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                        {JSON.parse(localStorage.getItem("user")).sportsAndGames ?
                            <div className="col">
                                <div className="card shadow-sm h-100">
                                    <div className="card-image">
                                        <div className="hover-text">
                                            <img src={logoSports} className="card-img-top" alt="..." />
                                            <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToSportsAndGames}>Sports & Games</a></div>
                                            {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                            {this.state.redirectSportsAndGames && <Navigate to='/sportsandgames' replace={true} />}
                                        </div>
                                        <div className="image-overlay"></div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).ncc ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoNCC} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToNCC}>NCC</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectNCC && <Navigate to='/ncc' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                         {
                            JSON.parse(localStorage.getItem("user")).nss ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoPaper} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToNSS}>NSS</a></div>
                                                {this.state.redirectNSS && <Navigate to='/nss' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).facultyDevelopment ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoFaculty} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToFaculty}>Faculty Development</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectFaculty && <Navigate to='/facultydevelopment' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                         {

                            JSON.parse(localStorage.getItem("user")).consultancy ?

                        <div className="col">

                            <div className="card shadow-sm h-100">

                                <div className="card-image">

                                    <div className="hover-text">

                                        <img src={logoConsultant} className="card-img-top" alt="..." />

                                        <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToConsultant}>Consultancy</a></div>

                                        {this.state.redirectConsultant && <Navigate to='/consultant' replace={true} />}

                                    </div>

                                    <div className="image-overlay"></div>

                                </div>

                            </div>

                        </div> : null

                        }                     
                      {

                            JSON.parse(localStorage.getItem("user")).womenInDevelopment ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoWomen} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase">
                                                    <a  onClick={this.goToWomen}>Women-in Development</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectWomen && <Navigate to='/womenindevelopment' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                         {
                            JSON.parse(localStorage.getItem("user")).journalOrBookpublication ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoJournel} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToJournalAndBookPublication}>Journals/Book</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectJournalAndBookPublication && <Navigate to='/journalandbookpublication' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                         {
                            JSON.parse(localStorage.getItem("user")).patentDetails ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoPatent} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToPatent}>Patent Details</a></div>
                                                {this.state.redirectPatent && <Navigate to='/patent' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).semesterResult ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoSemester} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToSem}>Semester Results</a></div>
                                                {this.state.redirectSem && <Navigate to='/semester' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).symposiumAndExpo ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoSymposium} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToSymposium}>Symposium & Expo</a></div>
                                                {this.state.redirectSymposium && <Navigate to='/symposiumexpo' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>



                                    </div>
                                </div> : null
                        }

                        {
                            JSON.parse(localStorage.getItem("user")).extensionServices ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoExtension} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToExtensionServices}>Extension Services</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectExtensionServices && <Navigate to='/extensionservices' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).placement ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoPlacement} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToPlacement}>Placement</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectPlacement && <Navigate to='/placement' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).grants ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoGrants} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToGrants}>Grants</a></div>
                                                {/* <button className="btn" onClick={this.goToPaperPResentation}>Paper Presentation</button> */}
                                                {this.state.redirectGrants && <Navigate to='/grants' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>


                                    </div>
                                </div>
                                : null
                        }                                      
                                              
                  
                     
                     {

                            JSON.parse(localStorage.getItem("user")).alumniEvent ?

                        <div className="col">

                            <div className="card shadow-sm h-100">

                                <div className="card-image">

                                    <div className="hover-text">

                                        <img src={logoAlumni} className="card-img-top" alt="..." />

                                        <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToAlumniEvent}>Alumni Event</a></div>

                                        {this.state.redirectAlumniEvent && <Navigate to='/alumnievent' replace={true} />}

                                    </div>

                                    <div className="image-overlay"></div>

                                </div>
                            </div>

                        </div> : null

                         }

                       
                        {
                            JSON.parse(localStorage.getItem("user")).miscellaneous ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoMiscellaneous} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToMiscellaneous}>Miscellaneous</a></div>
                                                {this.state.redirectMiscellaneous && <Navigate to='/miscellaneous' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                        
                         {
                             JSON.parse(localStorage.getItem("user")).events ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoEvent} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToEvents}>Events</a></div>
                                                {this.state.redirectEvents && <Navigate to='/events' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                : null
                        }
                         {
                             JSON.parse(localStorage.getItem("user")).awards ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoAwards} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToAwards}>Awards</a></div>
                                                {this.state.redirectAwards && <Navigate to='/awards' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                 : null
                        }
                         {
                             JSON.parse(localStorage.getItem("user")).moUs ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoMous} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToMOUs}>MOUs</a></div>
                                                {this.state.redirectMOUs && <Navigate to='/mous' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                 : null
                        }
                          {
                             JSON.parse(localStorage.getItem("user")).advertisements ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoAds} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToAdvertisements}>Advertisements</a></div>
                                                {this.state.redirectAdvertisements&& <Navigate to='/advertisements' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                 : null
                        }
                         {
                             JSON.parse(localStorage.getItem("user")).pressReports ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoPress} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToPressReports}>Press Reports</a></div>
                                                {this.state.redirectPressReports && <Navigate to='/pressreports' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                 : null
                        }
                         {
                              JSON.parse(localStorage.getItem("user")).upcomingEvents ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoUpcomingEvent} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToUpcomingEvents}>Upcoming Events</a></div>
                                                {this.state.redirectUpcomingEvents && <Navigate to='/upcomingevents' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                   :null
                        }
                        {
                            JSON.parse(localStorage.getItem("user")).roleName!="Admin" ?
                                <div className="col">
                                    <div className="card shadow-sm h-100">
                                        <div className="card-image">
                                            <div className="hover-text">
                                                <img src={logoAttend} className="card-img-top" alt="..." />
                                                <div className="bottom-right-tag text-uppercase"><a  onClick={this.goToAttendancePage}>Attendance</a></div>
                                                {this.state.redirectAttendancePage && <Navigate to='/attendance' replace={true} />}
                                            </div>
                                            <div className="image-overlay"></div>
                                        </div>
                                    </div>
                                </div> 
                                : null
                        }

                    </div>


                </section>
            </div>
            // </div>
        )

    }
}