import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddLabDetails from "./addlabDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchLabDetails,
  LabDetailsSelector,
  deleteLabDetails,
} from "../../app/redux/slices/labDetailsSlice";

import Popup from "reactjs-popup";
import { AsyncGet , AsyncPost,AsyncGetFiles} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
const LabDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, labDetailsList } = useSelector(LabDetailsSelector);
  const [IsEdit, setIsEdit] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [labDetails, setLabDetails] = useState();
  const [listOfLabDetails, setlistOfLabDetails] = useState(labDetailsList || []);
  const [IssueDate,SetIssueDate]=useState("");
  const [Store,SetStore]=useState("");
  const togglePopup = () => {
    onAssessmentSelection("Lab Details");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setLabDetails();
    }
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", labDetailsList);

  const deleteLabDetailsFun = (item) => {
    debugger;
    dispatch(deleteLabDetails(item.siNo));
  };

  const editLabDetails = (item) => {
    debugger;
    setLabDetails({ ...item, data: item });
    setshowPopup(!showPopup);
    setIsEdit(true);
  };

  useEffect(() => {
    getLabDetailsData();
  }, []);
  
  const getLabDetailsData = () => {
    dispatch(fetchLabDetails());
  };

  useEffect(() => {
    if (!labDetailsList) {
      return;
    }
    setlistOfLabDetails(labDetailsList);
  }, [labDetailsList]);

  console.log("Before Render labDetailsList", labDetailsList);
  console.log("Before Render listOfLabDetails", listOfLabDetails);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2"> Laboratory Details</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
              
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
            </div>
            <div className="fixed-table-container">
              <div className="fixed-table-body">
              <AdvanceTable
                  deleteLabDetailsProp={(item) => deleteLabDetailsFun(item)}
                  editLabDetailsProp={(item) => editLabDetails(item)}
                  data={listOfLabDetails}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Role Id:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Role Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div> */}
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddLabDetails
          isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            labDetails={labDetails}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default LabDetails;
