import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";

import {
  addMemberDetails,  
  UpdateMemberDetails,
} from "../../../app/redux/slices/memberSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";

const AddMemberDetails = ({ isEdit, item, closePopup, member }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!member) {
      return;
    }
    runFillMemberData.current = true;
    if (runFillMemberData.current) {
      fillMemberData(member);
    }
  }, []);


  const [Id, setId] = useState();
  const[MemberType,setMemberType]=useState("");
  const [Name, setName] = useState("");
  const [Gender, setGender] = useState(""); 
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [Address, setAddress] = useState("");
  const [Email, setEmailID] = useState("");    
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [memberMobErrorMsg, setmemberMobErrorMsg] = useState("");
  const runFillMemberData = useRef();
  const emailRef = useRef();  

  const fName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setName(val.target.value);
    }
  };
  const clearState = () => {
    setId("");
    setName("");   
    setGender("");   
    setMemberType("");
    setPhoneNumber("");    
    setEmailID("");
    setAddress("");
   
   
    seterrorMsg("");
    setvalidate("");
    
    
    console.log("reseted");
  };
 

  const genderVal = (val) => {
    setGender(val.target.value);
  };
  const members = (val) => {
    setMemberType(val.target.value);
  };
  const address = (val) => {
    setAddress(val.target.value);
  };
  
  
  const contactPrimary = (e) => {
    var phNo1 = e.target.value.replace(/\D/g, "");

    if (phNo1.length == 10) {
      setPhoneNumber(phNo1);

      setmemberMobErrorMsg("");
    } else {
      setPhoneNumber(phNo1);

      setmemberMobErrorMsg("Please enter valid Mobile No");
    }
  };

 

  
  const emailID = (val) => {
    if (validator.isEmail(val.target.value)) {
      setEmailErrorMsg("");
      emailRef.current.style.border = "";
    } else {
      emailRef.current.focus();
      emailRef.current.style.border = "1px solid red";
      setEmailErrorMsg("Enter valid Email!");
    }
    setEmailID(val.target.value);
  };
  

  const validationFun = () => {
    setvalidate(true);
    if (
      Name &&      
      MemberType &&       
      PhoneNumber 
      
    ) {
      setvalidate(false);
      return false;
    } 
    seterrorMsg("Provide all required fields");

      return true;
    
  };
 

  const saveMemberDetails = async () => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var memberData = {
      Id: 0,      
      Name: Name,      
      Gender: Gender,
      MemberType:MemberType,
      PhoneNumber: PhoneNumber,
      Address: Address,
      Email: Email,     
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };
    try {
      console.log("memberData", memberData);
      const resp = await addMemberDetails(memberData, dispatch);
      //debugger;
      //await onFileUpload(resp);
      closePopup();
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }

   
  };

  const updateMemberDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      Id: Id,
      Name: Name,      
      Gender: Gender,
      MemberType:MemberType,
      PhoneNumber: PhoneNumber,
      Address: Address,
      Email: Email,   
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };

    const memberData = {
      ...members,
      //Photo: "",
      data: data,
    };

    try {
      // dispatch(UpdateMemberDetails(facultyData));
      await UpdateMemberDetails(memberData.data, dispatch);
     //await onFileUpload();
     closePopup();
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };
  

  const closePopupFun = () => {
    closePopup();
    //window.location.reload(false);
  };
  

  const fillMemberData = (obj) => {
    runFillMemberData.current = false;
    // console.log(obj);
    setId(obj.data.id);
    setName(obj.data.name);
    setAddress(obj.data.address);
    setMemberType(obj.data.memberType);
    setGender(obj.data.gender);    
    setPhoneNumber(obj.data.phoneNumber);    
    //setFaculty_Photo(obj.data.photo);
    setEmailID(obj.data.email);
    seterrorMsg("");
    runFillMemberData.current = true;
  };

  return (
    <div className="popup" style={{ height: "120vh" }}>
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Member Details" : "Add New Member Details"}</h2> 
           <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={Name}
                  onChange={fName}
                  maxLength={150}
                />
                {!Name && validate ? (
                  <h6 style={{ color: "red" }}>{"Requird"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <span style={{ color: "red" }}>*</span> <label>Member Type</label>
                <input
                  className="form-control"
                  type="text"
                  value={MemberType}
                  onChange={members}
                  maxLength={50}
                />
                {!MemberType && validate ? (
                  <h6 style={{ color: "red" }}>{"Requird"}</h6>
                ) : null}
              </div>              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  Gender
                </label>
                <select
                  className="form-select form-control"
                  onChange={genderVal}
                  aria-label="Default select example"
                  value={Gender}
                >
                  <option value="">--Select Gender--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
                
              </div>

              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Contact Number.
                </label>

                <input
                  className="form-control"
                  type="text"
                  value={PhoneNumber}
                  onChange={contactPrimary}
                  minLength={10}
                  maxLength={10}
                />

                <h6 style={{ color: "red" }}>{memberMobErrorMsg}</h6>
                {!PhoneNumber && validate ? (
                  <h6 style={{ color: "red" }}>{"Requird"}</h6>
                ) : null}
              </div>

              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  Email Address
                </label>
                <input
                  ref={emailRef}
                  className="form-control"
                  type="text"
                  value={Email}
                  onChange={(e) => emailID(e)}
                />
                <h6
                  style={{
                    color: "red",
                  }}
                >
                  {emailErrorMsg}
                </h6>
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  Address
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  value={Address}
                  onChange={address}
                  maxLength={500}
                ></textarea>
              </div>
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!member ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveMemberDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateMemberDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMemberDetails;
