import React, { useEffect, useRef, useState } from "react";
import { useDispatch} from "react-redux";
import Moment from "moment";
import dayjs from "dayjs";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet
} from "../../../app/services/https";
import {
  addBudgetDetails,
  UpdateBudgetDetails,
} from "../../../app/redux/slices/budgetSlice";
// var budgetdata ;
// var OldAmount =0;
//var headAmt;

const AddBudgetDetails = ({ isEdit, item, closePopup, budget }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!budget) {
      return;
    }
    runFillBudgetData.current = true;
    if (runFillBudgetData.current) {
      fillBudgetData(budget);
    }
  }, []);
  
const [SNo,setSNo] = useState(0);
  const [budgetDate, setBudgetDate] = useState("");
  const [collegeType, setcollegeType] = useState("");
  const [departmentId, setdepartmentId] = useState(0);
  const [DeparmentList, setDeparmentList] = useState([]);
  const[DepartmentIdAided,setDepartmentIdAided]=useState("");
  const[DepartmentIdss,setDepartmentIdss]=useState("");
  const [BudjectHeadList, setBudjectHeadList] = useState([]);
  const [BudgetAmt, setBudgetAmt] = useState(0);
  const [BudgetAmtAided, setBudgetAmtAided] = useState();
  const [BudgetAmtss, setBudgetAmtss] = useState();
  
  const [OldAmt, setOldAmt] = useState(0);
const [deptValidation, setDeptValidation]=useState(false)
const [HeadOfAccount, setHeadOfTheAccount] = useState("");
const [HeadAmt, setHeadAmt] = useState(0);
const [HeadList, setHeadList] = useState([]);
const [HeadOfAccountAided, setHeadOfTheAccountAided] = useState("");
  const [HeadOfAccountss, setHeadOfTheAccountss] = useState("");
const [Budgeterrormgs, setBudgeterrormgs] = useState("");
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillBudgetData = useRef();

  const clearState = () => {  
    setBudgetDate("");
    setBudgetAmt(0);
    setHeadAmt(0);
   // setUpdatedAmt(0);
   setBudgetAmtAided("");
   setBudgetAmtss("");
   setHeadOfTheAccountAided("");
   setHeadOfTheAccountss("");
   setDepartmentIdAided("");
   setDepartmentIdss("");			   
    setSNo("");
    setDeptValidation(false);
  };
 
  const budgetdate = (val) => {
    debugger;
   
    setBudgetDate(val.target.value);
    

  };
  const budgetamt = (val)=>{
    if (/^[0-9.]*$/.test(val.target.value) === false) {
      //error message  
    }
    else if(collegeType=="Aided"){
      setBudgetAmt(val.target.value);
      setBudgeterrormgs("");
      setBudgetAmtAided(val.target.value);
    setBudgetAmtss("");
    }
    else if(collegeType=="SS"){
      setBudgetAmt(val.target.value);
      setBudgeterrormgs("");
      setBudgetAmtAided("");
    setBudgetAmtss(val.target.value);
    }    
  };
  const headOfTheaccount = (val) => {
    if(collegeType=="Aided"){
    setHeadOfTheAccount(val.target.value);
    setHeadOfTheAccountAided(val.target.value);
    setHeadOfTheAccountss("");
    }
    else if(collegeType=="SS"){
      setHeadOfTheAccount(val.target.value);
    setHeadOfTheAccountAided("");
    setHeadOfTheAccountss(val.target.value);
    }
    
  };
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.gethoadetails)
      .then((res) => {
        console.log(res.data, "getHOADetails");
        setHeadList(res.data);
        //setHeadListUpdated(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getBudgetHeads)
      .then((res) => {
        console.log(res.data, "getBudgetHeads");
        setBudjectHeadList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const dept = (val) => {
    debugger;
    if(collegeType=="Aided"){
      setBudgetAmtAided("");
      setBudgetAmt("");
      setHeadOfTheAccount("");
      setHeadOfTheAccountAided("");
      setdepartmentId(val.target.value);
      setDepartmentIdss("");
      setDepartmentIdAided(val.target.value);
    }
    else if(collegeType=="SS"){
      setBudgetAmtss("");
      setBudgetAmt("");
      setHeadOfTheAccount("");
      setHeadOfTheAccountss("");
      setdepartmentId(val.target.value);
      setDepartmentIdss(val.target.value);
      setDepartmentIdAided("");

    }
   
    // budgetdata=BudjectHeadList.filter(x=>x.departmentId == val.target.value);
    //  setUpdatedAmt(budgetdata[0].headAmt);
    //  console.log(budgetdata[0].headAmt);
  };
  const saveBudgetDetails = () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var budgetData = {
      SNo:SNo,
      budgetDate : budgetDate,
      collegeType:collegeType,
      departmentId:Number(departmentId),
      BudgetAmt:(parseFloat(BudgetAmt).toFixed(2)),
      HeadOfAccount: Number(HeadOfAccount),
      //UpdatedAmt:((parseFloat(UpdatedAmt)+parseFloat(BudgetAmt)).toFixed(2)),
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addBudgetDetails(budgetData));
    closePopup();
    clearState();
  };

  const updateBudgetDetails = () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var balance=0;
    if(Number(HeadAmt)<Number(OldAmt)){
      balance = Number(OldAmt)-Number(HeadAmt);
     
    }
  if((Number(balance)<=Number(BudgetAmt)) || (Number(HeadAmt)>=Number(OldAmt) ) ){    
    var data = {
      SNo:SNo,
      budgetDate : budgetDate,
      collegeType:collegeType,
      departmentId:Number(departmentId),
      BudgetAmt:parseFloat(BudgetAmt).toFixed(2),
      OldAmt:OldAmt,
      HeadOfAccount: Number(HeadOfAccount),
      //UpdatedAmt:((parseFloat(UpdatedAmt)-parseFloat(OldAmount))+parseFloat(BudgetAmt)).toFixed(2),
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const budgetData = {
      ...budget,
        data: data
    };

    console.log("UpdateBudget", budgetData.data);
    dispatch(UpdateBudgetDetails(budgetData.data));
    closePopup();
    clearState();
  }
  else{
    setBudgeterrormgs("Unable to update budget");
  }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !budgetDate || !departmentId || !BudgetAmt || !HeadOfAccount
    ) {
      setvalidate(false);
      seterrorMsg("Provide required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillBudgetData = (obj) => {
    debugger;
    runFillBudgetData.current = false;
    if(obj.data.collegeType=="Aided"){
     
      setdepartmentId(obj.data.departmentId);
      setDepartmentIdAided(obj.data.departmentId);
      setDepartmentIdss("");
      setHeadOfTheAccount(obj.data.headOfAccount);
      setHeadOfTheAccountAided(obj.data.headOfAccount);
      setHeadOfTheAccountss("");
      setBudgetAmt(obj.data.budgetAmt);
      setBudgetAmtAided(obj.data.budgetAmt);
      setBudgetAmtss("");

    }
    else if (obj.data.collegeType=="SS"){
      setdepartmentId(obj.data.departmentId);
      setDepartmentIdAided("");
      setDepartmentIdss(obj.data.departmentId);
      setHeadOfTheAccount(obj.data.headOfAccount);
      setHeadOfTheAccountAided("");
      
      setHeadOfTheAccountss(obj.data.headOfAccount);
      setBudgetAmt(obj.data.budgetAmt);
      setBudgetAmtAided("");
      setBudgetAmtss(obj.data.budgetAmt);
    }
    setSNo(obj.data.sNo);
    setBudgetDate(obj.data.budgetDate);
   
    setcollegeType(obj.data.collegeType);
   
    setOldAmt(obj.data.budgetAmt);
    
    //setUpdatedAmt(obj.data.budgetAmt);
    setDeptValidation(true);
    setHeadAmt(obj.data.headAmt);
    runFillBudgetData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Budget" : "Add New Budget"}</h2>            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row">

            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio5"
                  value="5"
                  checked={collegeType === "Aided"}
                  onChange={() => {setcollegeType("Aided")
                   setdepartmentId(DepartmentIdAided);
                   setHeadOfTheAccount(HeadOfAccountAided);
                   setBudgetAmt(BudgetAmtAided);
                  }}
                  disabled={deptValidation}              

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio6"
                  value="6"
                  checked={collegeType === "SS"}
                  onChange={() => {setcollegeType("SS");
                  setdepartmentId(DepartmentIdss);
                   setHeadOfTheAccount(HeadOfAccountss);
                   setBudgetAmt(BudgetAmtss);
                  }}
                  disabled={deptValidation}
                  />
                <label className="form-check-label checkposition">
                Self Supporting
                </label>

              </div>
              </div></div>
              <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label><span style={{ color: "red" }}>*</span>Date</label>
              <input
                className="form-control"
                type="date"
                value={dayjs(budgetDate).format("YYYY-MM-DD")}
                max={Moment().format('YYYY-MM-DD')}
                onChange={budgetdate}                
              />
              {!budgetDate && validate ? (
                <h6 style={{ color: "red" }}>{"Required"}</h6>
              ) : null}
            </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Department
              </label>
              <select
                className="form-select form-control"
                onChange={dept}
                aria-label="Default select example"
                value={departmentId}    
                disabled={deptValidation}
          
                >
                <option value="">--Select Department--</option>
                {DeparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {!departmentId && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <select
                className="form-select form-control"
                onChange={headOfTheaccount}
                aria-label="Default select example"
                value={HeadOfAccount}
               // disabled={validation && OfficeValid} 
                >
                <option value="">--Select Head--</option>
                {(HeadList)?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.headOfAccount}
                  </option>
                ))}
              </select>
              {!HeadOfAccount && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Budget</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="budget"
                  value={parseFloat(BudgetAmt).toFixed(2) =='NaN'?0:parseFloat(BudgetAmt).toFixed(2)}
																										   
                  onChange={budgetamt}
                  maxLength={10}
                />
                 <h6 style={{ color: "red" }}>{Budgeterrormgs}</h6>
                {/* {!SanctionedAmount && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null} */}

              </div></div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!budget ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveBudgetDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateBudgetDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBudgetDetails;