import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';

import {
  addFormRoleDetails,
  UpdateFormRoleDetail
} from "../../../app/redux/slices/formRoleSlice";

const AddFormRoleDetails = ({isEdit, item, closePopup, formRole }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!formRole) {
      return;
    }
    runFillFormRoleData.current = true;
    if (runFillFormRoleData.current) {
      fillFormRoleData(formRole);
    }
  }, []);
  
  const [RoleId, setRoleId] = useState("");
  const [RoleName,setName]= useState("");
  const [RolePage,setRolePage] = useState("");
  const [IsActive,setisActive] = useState(false);
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillFormRoleData = useRef();

  const clearState = () => {
    setName("");
    setRolePage("");
    setisActive("");
  };
  const rName = (val) => {
  
      
    setName(val.target.value);
      
  };
  const rPage = (val) => {  
  
  setRolePage(val.target.value);
    };

const isactive= (e) => {
  setisActive(e.target.checked);
  
  // if (val==1)
  // {IsActive=true;}
  // if (val==0)
  // setIsActive(false);
}
 

  
  const saveFormRoleDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var formRoleData = {
      RoleName :RoleName,
      RolePage:RolePage,
      IsActive:IsActive,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addFormRoleDetails(formRoleData));
    closePopup();
    clearState();
  };

  const updateFormRoleDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      RoleId: RoleId,
      RoleName : RoleName,
      IsActive:IsActive,
      RolePage:RolePage,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const formRoleData = {
      ...formRole,
        data: data
    };

    console.log("UpdateFormRole", formRoleData.data);
    dispatch(UpdateFormRoleDetail(formRoleData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !RoleName ||
      !RolePage
    ) {
      setvalidate(false);
      seterrorMsg("Provide Role Name field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillFormRoleData = (obj) => {
    debugger;
    runFillFormRoleData.current = false;
    setRoleId(obj.data.roleId);
    setName(obj.data.roleName);
    setRolePage(obj.data.rolePage);
    setisActive(obj.data.isActive);
    runFillFormRoleData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Form Role" : "Add New Form Role"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Role Name</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={RoleName}
                  onChange={rName}
                  maxLength={50}
                />
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Role Page</label>
                
                <select
                 className="form-select form-control"
                 onChange={rPage}
                 aria-label="Default select example"
                 value={RolePage}
               >
                 <option value="">--Select RolePage--</option>
                 <option value="Fdp Form">Fdp Form</option>
                 <option value="Indent Form">Indent Form</option>
                 
               </select>
               {!RolePage && validate ? (
                 <h6 style={{ color: "red" }}>{"Requird"}</h6>
               ) : null}
               
              </div>  
              </div>
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="checkbox"
                    //name="eligle-radio-btn"
                    //value="1"
                    checked={IsActive}
                    //id="isActive"
                    onChange={isactive}
                  />
                 
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    IsActive
                  </label>
                </div>
                
                
              </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!formRole ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveFormRoleDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateFormRoleDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFormRoleDetails;