import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../app/services/https";
import dayjs, { Dayjs } from "dayjs";
import AddsinglestudentFeedback from './singlestudentfeedback/index';
import Moment from "moment";
var startDate = "";
var endDate = "";
var today = new Date();
today =  today.setHours(0, 0, 0, 0);
const AddFeedBack = ({ isEdit, item,  closePopup, studentConfigList }) => {
  const [showPopupReallocation, setshowPopupReallocation] = useState(false);
  const [assessmentReallocation, setassessmentReallocation] = useState("");
  const [Department, setDepartment] = useState(0);
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [DeparmentList, setDeparmentList] = useState([]);
  const [StudentConfigList, setStudentConfigList] = useState([]);
  const [Feedback, setFeedBack] = useState();

  const [FeedbackSend, setFeedbackSend] = useState(true);

  const runFillFeedbackData = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!Feedback) {
      return;
    }
    runFillFeedbackData.current = true;
    if (runFillFeedbackData.current) {
      fillFeedbackData(Feedback);
    }
  }, []);
  useEffect(() => {
    AsyncGet(API.getstudconfig)
      .then((res) => {
        console.log(res.data, "getstudentconfig");
        setStudentConfigList(res.data);
        startDate = res.data[0].feedbackStartDate;
        endDate = res.data[0].feedbackEndDate;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  console.log(startDate + endDate, "date");
  const clearState = () => {
    setDepartment(0);
    setFeedbackSend(true);
  }

  const dept = (val) => {
    setDepartment(val.target.value);
  };
  const updateStdconfig = async () => {
    debugger;
   
    if(new Date(startDate)<= new Date(today) && new Date(endDate)>=new Date(today) )
    {
    AsyncPost(
      API.updateStdconfig +
      "Department=" + Department + "&IsFeebackSend=" + FeedbackSend)

      .then((response) => {
        if (response.data = "Success") {
          alert("Feedback start to send");
          clearState();
          //closePopup();
        }
        else {
          alert("Something went wrong");
        }
      })
      .catch((er) => {
        console.log(er, "failure of update Student Sem Date");

      });
    }
    else{
      alert("Unable to send feedback! Kindly check the feedback date.");
    }
  }
  // const closePopupFun = () => {
  //   closePopup();
  //   //window.location.reload(false);
  // };
  const fillFeedbackData = (obj) => {
    setDepartment(obj.data.departmentId);
    runFillFeedbackData.current = true;
  }

  const togglePopup = () => {
    debugger;
    if((new Date(startDate) <= new Date(today)) && ( new Date(endDate) >= new Date(today) ))
     {
    onAssessmentSelection("Send Single Feedback");
    setshowPopup(!showPopup);
    if (!showPopup) {
      setFeedBack();
    }}
    else{
      alert("Unable to send feedback! Kindly check the feedback date.");
    }

  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  return (
    <div className="row mb-4">

      <div className="formdata">

        <h2 className="heading">Send Feedback</h2>

        <div className="row">
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group mb-5">
            <label style={{ fontSize: 20 }}>
              FeedBack Start Date: {startDate.split(' ').shift()}
              {/* {studentConfigList[0].feedbackStartDate.split(' ').shift()} */}

            </label>
          </div>
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group mb-5">
            <label style={{ fontSize: 20 }}>
              End Date: {endDate.split(' ').shift()}
              {/* {studentConfigList[0].feedbackEndDate.split(' ').shift()} */}


            </label>
          </div>
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group mb-5">
            <label>
              Department
            </label>
            <select
              className="form-select form-control"
              onChange={dept}
              aria-label="Default select example"
              value={Department}
            >
              <option value="">--Select Department--</option>
              {DeparmentList?.map((opt) => (
                <option key={opt.id} value={opt.id}>
                  {opt.name}
                </option>
              ))}
            </select>

          </div>
        </div>
        <button
          className="btn btn-lg btn-success btn-block form-roundedbtn3"
          type="button"
          onClick={() => updateStdconfig()}
        >
          Send All
        </button>
        <button
          className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
          type="button"
          onClick={() => togglePopup()}
        >
          Send Student
        </button>
      </div>
      {showPopup ? (
    <AddsinglestudentFeedback
   
      item={assessment}
      closePopup={togglePopup}
     Feedback={Feedback}
    />
  ) : null}
    </div>

  );
  
};

export default AddFeedBack;
