import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const roleActivitySlice = createSlice({
  name: "roleActivityDetails",
  initialState: {
    loading: false,
    error: false,
    rolesList: [],
    value: 0,
    curIndex: 0,
    roleActivityDetails: [],
    submitRoleActivitySuccess: "",
    saveRoleActivitySuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getRoles: (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.error = false;
        state.rolesList = payload;
      },
    getRoleActivityDetails: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.roleActivityDetails = payload;
    },
    saveRoleActivity: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveRoleActivitySuccess = payload;
    },
    submitRoleActivity: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getRoles,
  getRoleActivityDetails,
  submitRoleActivity,
  setError,
  saveRoleActivity,
} = roleActivitySlice.actions;
export const roleActivitySelector = (state) => state.roleActivity || [];
export default roleActivitySlice.reducer;

export const fetchRoleMappingDetails = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getRoleActivityMappings)
    .then((response) => {
      console.log("response,", response);
      dispatch(getRoleActivityDetails(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addRoleActivityDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.insertRoleActivityMapping, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveRoleActivity(response));
      dispatch(fetchRoleMappingDetails());
    })
    .catch((er) => {
      console.log("failure of add faculty");
      dispatch(saveRoleActivity(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const fetchRoles = () => (dispatch) => {
 
    dispatch(setLoading());
    AsyncGet(API.getRoles)
      .then((response) => {
        console.log("response,", response);
        dispatch(getRoles(response.data));
      })
      .catch((er) => {
        console.log("am in error,", er);
        dispatch(setError());
        dispatch(setLoading());
      });
  };

export const UpdateRoleMappingDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.updateRoleActivityMapping, data)
    .then((response) => {
     // dispatch(setLoading());
      console.log(response, "success of update faculty");
      dispatch(fetchRoleMappingDetails());
    })
    .catch((er) => {
      console.log("failure of add role activity");
      dispatch(saveRoleActivity(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

