import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";
import Moment from "moment";

import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import { AsyncGet, AsyncGetFiles, AsyncPost } from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({ isEdit,item, closePopup, activity }) => {
  const dispatch = useDispatch();
const [id, setId] = useState("");
  const [PatentTitle, setPatentTitle] = useState("");
  const [Mentor, setMentor] = useState("");
  const [Abstract, setAbstract] = useState("");
  const [Status, setStatus] = useState("");
  const [StudentID, setStudentID] = useState([]);
  const [FacultyID, setFacultyID] = useState([]);
  const [DepartmentSemester, setDepartmentSemester] = useState("");
  const [DepartmentDesignation, setDepartmentDesignation] = useState("");
  const [PatentType, setPatentType] = useState("");
  const [PatentRegistrationDetails, setPatentRegistrationDetails] = useState("");
  const [studentORFaculty, setstudentORFaculty] = useState("Student");
  const [NationalOrInternational, setNationalOrInternational] = useState("National");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
 const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();

 const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const clearState = () => {
    setPatentTitle("");  
    setAbstract();
    setStudentID([]);
    setFacultyID([]);
    setPatentRegistrationDetails("");
    setPatentType("");
    setMentor("");  
    setStatus("");
    setstudentORFaculty("Student");
    setNationalOrInternational("National");
    //setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    //setdatevalidation(true);
    //setFromdatevalidation(false);
    console.log("reseted");
  };
  const students1 = (val) => {
    debugger;
    setstudentORFaculty(val);
// if (val === "Student") {
//       setFacultyID([]);
//     } else {
//       setStudentID([]);
//     }
  };
  const setNatIntvalue = (val) => {
    setNationalOrInternational(val);
  };
  const onPatentTypeSelect = (e) => {
    setPatentType(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  const onStudentIDChange = (data) => {
    setStudentID(data);
    setFacultyID([]);
  };

  const onFacultyIDChange = (data) => {
    setFacultyID(data);
    setStudentID([]);
  };
  const onStudentIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getStudentByName + "?studentname=" + data)
      .then((res) => {
        setstudentList(res.data);
 setfacultyList([]);
      })
      .catch(() => setstudentList([]));
  };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?facultyname=" + data)
      .then((res) => {
        setfacultyList(res.data);
setstudentList([]);
      })
      .catch(() => setfacultyList([]));
  };

  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(25,null));
      closePopup();
      clearState();
      return;
    }


    console.log(res, "response");
     const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
  formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "PatentDetails"
    );

    try {
      const res = await AsyncPost(API.uploadFiles, formData);
dispatch(fetchActivities(25,null));
      closePopup();
      clearState();

    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {

    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
    .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async() => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 25,
      PatentTitle: PatentTitle,
      Abstract: Abstract,
      Status: Status,
      PatentType: PatentType,
      //Department:0,
      PatentRegistrationDetails: PatentRegistrationDetails,
      Mentor: Mentor,
      NationalOrInternational: NationalOrInternational,
      studentORFaculty: studentORFaculty,      
      Attachment: "",
      StudentID,
      FacultyID,
    };
    var activityData = {
      //id: 0,
      activityID: 25,
      activityName: "Patent",
      data: JSON.stringify(data),
      filePath: "",

      departmentID: 0,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate:  new Date(),
    };
 try {
    const resp = await addActivity(activityData, dispatch);
    await onFileUpload(resp);
  } catch (error) {
    console.log(error);
    closePopup();
    clearState();

  }

  };

  const updateActivity = async() => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 25,
      PatentTitle: PatentTitle,
      Abstract: Abstract,
      Status: Status,
      PatentType:PatentType,
      //Department:0,
      PatentRegistrationDetails:PatentRegistrationDetails,
      Mentor:Mentor,
      studentORFaculty: studentORFaculty,
      NationalOrInternational: NationalOrInternational,
      Attachment: "",
      StudentID,
      FacultyID,
    };
    const activityData = {
      ...activity,
      activityID:25,
activityName: "Patent",
      data: JSON.stringify(data),
     
    };
   
    try {
       dispatch(UpdateActivity(activityData));
      await onFileUpload();
     // closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();

    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      PatentTitle &&
      (StudentID?.length > 0 || FacultyID?.length > 0) &&
      Mentor &&
      PatentType     
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();

    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "PatentTitle":
        setPatentTitle(e.target.value);
        break;
      case "abstract":
        setAbstract(e.target.value);
        break;
        case "status":
        setStatus(e.target.value);
        break;
      case "studentname":
        debugger;
        setStudentID(e.target.value);
        setFacultyID([]);
        break;
      case "facultyname":
        debugger;
        setFacultyID(e.target.value);
        setStudentID([]);
        break;
      case "patentregistrationdetails":
        setPatentRegistrationDetails(e.target.value);
        break;
      case "mentor":
        
        setMentor(e.target.value);
        
        break;
      case "patenttitle":
        setPatentTitle(e.target.value);
        break;
      
    }
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const fillActivityData = (data) => {
    runFillActivityData.current = false;
 const obj = data;
  const objData = obj.data;
    setPatentTitle(objData.PatentTitle);
    setAbstract(objData.Abstract);
    setStatus(obj.data.Status);
    setStudentID(objData.StudentID || []);
    setFacultyID(objData.FacultyID || []);
    setPatentRegistrationDetails(objData.PatentRegistrationDetails);
    setPatentType(objData.PatentType);
    setMentor(objData.Mentor);
    setstudentORFaculty(objData.studentORFaculty || "Student");
    setNationalOrInternational(objData.NationalOrInternational||"National");
    setCreatedBy(obj.createdDate);
 setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
    <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >X
            </button>
            <div className="row">
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="students"
                    value="2"
                    checked={studentORFaculty === "Student"}
                    onChange={() => students1("Student")}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Student
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="faculty"
                    value="3"
                    checked={studentORFaculty === "Faculty"}
                    onChange={() => students1("Faculty")}
                  />
                  <label className="form-check-label checkposition">
                    Faculty
                  </label>
                </div>
              </div>
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio3"
                    value="4"
                    checked={NationalOrInternational === "National"}
                    onChange={() => setNatIntvalue("National")}
                  />
                  <label className="form-check-label checkposition">
                  National
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio4"
                    value="5"
                    checked={NationalOrInternational === "International"}
                    onChange={() => setNatIntvalue("International")}
                  />
                  <label className="form-check-label checkposition">
                  International
                  </label>
                </div>
              </div>
              
                
            </div>
            <div className="row formadduser">
             
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Patent Title</label>
                <input
                  className="form-control"
                  type="text"
                  id="patenttitle"
                  value={PatentTitle}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
               {!PatentTitle && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Patent Registration Details</label>
                <input
                  className="form-control"
                  type="text"
                  id="patentregistrationdetails"
                  value={PatentRegistrationDetails}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
               
              </div>
              
              
              {studentORFaculty === "Student" && (
                <div
                  id="students2"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Student ID</label>
                  <SearchBox
                    options={studentList}
                    onChangeList={(data) => onStudentIDChange(data)}
                    onInputChange={(e) => onStudentIDInputChange(e)}
                    selectedOptions={StudentID || []}
                    reset={studentORFaculty}
                    type="student"
                  />
                   {!StudentID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
               
              )}

              {studentORFaculty === "Faculty" && (
                <div
                  id="students3"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Faculty ID</label>

                  <SearchBox
                    options={facultyList}
                    onChangeList={(data) => onFacultyIDChange(data)}
                    onInputChange={(e) => onFacultyIDInputChange(e)}
                    selectedOptions={FacultyID || []}
 reset={studentORFaculty}
                    type="faculty"
                  />
                {!FacultyID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt">
                <label>Abstract</label>
                <textarea
                  className="form-control clsabstract"
                  id="abstract" value={Abstract}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={1000}
                />
                
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt">
                <label>Status</label>
                <textarea
                  className="form-control clsabstract"
                  id="status" value={Status}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={100}
                />
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Mentor</label>
                <input
                  className="form-control"
                  type="text"
                  id="mentor"
                  value={Mentor}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!Mentor && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
                </div>
              
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Patent Type</label>
                <select
                  className="form-select form-control"
                  onChange={onPatentTypeSelect}
                  aria-label="Default select example"
                  value={PatentType}
                >
                  <option value="">Select Patent</option>
                  <option value="Applied">Applied</option>
                  <option value="Granted">Granted</option>
                  <option value="Functional">Functional</option>
                  <option value="Others">Others</option>
                </select>
                {!PatentType && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
 {filesList?.length > 0 && (
                <div className="d-flex align-items-center mt-2  rounded">
                  <div className="border rounded download-list-block">
                    {filesList?.map((item, index) => (
                      <h6 className="border-bottom ms-1" key={index}>
                        {item || "No File Name"}
                      </h6>
                    ))}
                  </div>
                  <button
                    className="btn btn-sm btn-primary btn-block "
                    type="button"
                    onClick={() => downloadFileFun()}
                  >
                    Download
                  </button>
                </div>
              )}
              </div>
            </div>
           
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
           
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
