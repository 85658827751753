import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";  

import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";

import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  //state
  const [StudentID, setStudentID] = useState([]);
  const [FacultyID, setFacultyID] = useState([]);
  const [JournalPaperTitle, setJournal_PaperTitle] = useState("");
  const [JournalName, setJournal_Name] = useState("");
  const [JournalPublisher, setJournal_Publisher] = useState("");  
  const [JournalType, setJournal_Type] = useState("");
  const [JournalAbstract, setJournal_Abstract] = useState("");
  const [JournalIssueDate, setJournal_IssueDate] = useState("");
  const [TotalJournalPages, setJournal_nPage] = useState("");
  const [BookName, setBook_Name] = useState("");
  const [BookPublishingOn, setBook_PublishingOn] = useState("");
  const [BookAuthorName, setBook_AuthorName] = useState("");
  const [BookCoAuthor, setBook_CoAuthor] = useState("");
  const [BookPublisherName, setBook_PublisherName] = useState("");
  const [BookVolumeNo, setBook_VolumeNo] = useState("");
  const [BookIssueDate, setBook_IssueDate] = useState("");
  const [BookType, setBook_Type] = useState("");
  const [BookPublicationNumber, setBook_PublicationNumber] = useState("");
  const [BookRemarks, setBook_Remarks] = useState("");
  const [studentORFaculty, setstudentORFaculty] = useState("Student");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
 const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const[datevalidation, setdatevalidation] = useState(true);
  const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const clearState = () => {
    setJournal_IssueDate("");
    setJournal_PaperTitle("");
    setJournal_Name("");
    setJournal_Publisher("");
    setStudentID([]);
    setFacultyID([]);
    setJournal_Type("");
    setJournal_Abstract("");
    setJournal_nPage("");
    setBook_IssueDate("");
    setBook_Name("");
    setBook_PublishingOn("");
    setBook_AuthorName("");
    setBook_CoAuthor("");
    setBook_PublisherName("");
    setBook_VolumeNo("");
    setBook_Type("");
    setBook_PublicationNumber("");
    setBook_Remarks("");
    setstudentORFaculty("Student");

    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    //setdatevalidation(true);
    //setFromdatevalidation(false);
    console.log("reseted");
  };
  const students1 = (val) => {
    setstudentORFaculty(val);
// if (val === "Student") {
//       setFacultyID([]);
//     } else {
//       setStudentID([]);
//     }
  };
 
  const book_IssueDatechange = (val) => {
    setdatevalidation(false);
    setBook_IssueDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  const journal_IssueDatechange = (val) => {
    setdatevalidation(false);
    setJournal_IssueDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  const onBook_TypeSelect = (e) => {
    setBook_Type(e.target.value);
  };
  const onJournal_TypeSelect = (e) => {
    setJournal_Type(e.target.value);
  };
  const setOnOFFvalue = (val) => {
    setstudentORFaculty(val);
  };
  const onStudentIDChange = (data) => {
    setStudentID(data);
    setFacultyID([]);
  };

  const onFacultyIDChange = (data) => {
    setFacultyID(data);
    setStudentID([]);
  };
  const onStudentIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getStudentByName + "?DepartmentId="+JSON.parse(localStorage.getItem("user")).departmentId+ "&studentname=" + data)
      .then((res) => {
        setstudentList(res.data);
     setfacultyList([]);
      })
      .catch(() => setstudentList([]));
  };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?DepartmentId="+JSON.parse(localStorage.getItem("user")).departmentId+ "&facultyname=" + data)
      .then((res) => {
        setfacultyList(res.data);
        setstudentList([]);
      })
      .catch(() => setfacultyList([]));
  };

 const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(20,JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();
      return;
    }
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
   formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Journal/BookPublication"
    );

    try {
      const res = await AsyncPost(API.uploadFiles, formData);
  dispatch(fetchActivities(20,JSON.parse(localStorage.getItem("user")).departmentId));
      closePopup();
      clearState();
      
    } catch (ex) {
      console.log(ex);
    }
  };
const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 20,
      JournalIssueDate: JournalIssueDate,//new Date(Journal_IssueDate).toLocaleDateString(),
      Department:JSON.parse(localStorage.getItem("user")).department,
      JournalPaperTitle: JournalPaperTitle,
      JournalName: JournalName,
      JournalPublisher: JournalPublisher,
      JournalType: JournalType,
      JournalAbstract: JournalAbstract,    
   
      TotalJournalPages: TotalJournalPages==""?0:TotalJournalPages,
      BookIssueDate: BookIssueDate,//new Date(Book_IssueDate).toLocaleDateString(),
      BookName:BookName,
      BookPublishingOn:BookPublishingOn,
      BookAuthorName:BookAuthorName,
      BookCoAuthor:BookCoAuthor,
      BookPublisherName:BookPublisherName,
      BookVolumeNo:BookVolumeNo,
      BookType:BookType,
      BookPublicationNumber:BookPublicationNumber,
      BookRemarks:BookRemarks,
      studentORFaculty: studentORFaculty,
      
      Attachment:"",
      StudentID,
      FacultyID,
    };
    var activityData = {
      id: 0,
      activityID: 20,
      activityName: "Journal/BookPublication",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
      try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
    closePopup();
    clearState();
    }
  };

  const updateActivity = async() => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 20,
      JournalIssueDate: JournalIssueDate,//new Date(Journal_IssueDate).toLocaleDateString(),
      Department:JSON.parse(localStorage.getItem("user")).department,
      JournalPaperTitle: JournalPaperTitle,
      JournalName: JournalName,
      JournalPublisher: JournalPublisher,
      JournalType: JournalType,
      JournalAbstract: JournalAbstract,    
   
      TotalJournalPages: TotalJournalPages==""?0:TotalJournalPages,
      BookIssueDate: BookIssueDate,//new Date(Book_IssueDate).toLocaleDateString(),
      BookName:BookName,
      BookPublishingOn:BookPublishingOn,
      BookAuthorName:BookAuthorName,
      BookCoAuthor:BookCoAuthor,
      BookPublisherName:BookPublisherName,
      BookVolumeNo:BookVolumeNo,
      BookType:BookType,
      BookPublicationNumber:BookPublicationNumber,
      BookRemarks:BookRemarks,
      studentORFaculty: studentORFaculty,
      
      Attachment:"",
      StudentID,
      FacultyID,
    };
    const activityData = {
      ...activity,
      activityID:20,
	activityName: "Journal/BookPublication",
      data: JSON.stringify(data),
      //filePath: selectedFile,
    };
    try {
       dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      (StudentID?.length > 0 || FacultyID?.length > 0)        
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "jounal_papertitle":
        setJournal_PaperTitle(e.target.value);
        break;
        case "studentname":
          setStudentID(e.target.value);
          break;
        case "facultyname":
          setFacultyID(e.target.value);
          break;
      case "journal_name":
        setJournal_Name(e.target.value);
        break;
      case "journal_publisher":
        
        setJournal_Publisher(e.target.value);
      
        break;
      case "journal_abstract":
        setJournal_Abstract(e.target.value);
        break;
      case "journal_nPage":
        if(/^[0-9]*$/.test(e.target.value) == false){
          //error message
        }     else
        {
        setJournal_nPage(e.target.value);
        }
        break;
      case "book_name":
        setBook_Name(e.target.value);
        break;
        case "book_publishingOn":
          setBook_PublishingOn(e.target.value);
        break;
        case "book_authorName":
         
          setBook_AuthorName(e.target.value);
        
        break;
        case "book_coAuthor":
          
          setBook_CoAuthor(e.target.value);
          
        break;
        case "book_publisherName":
         
          setBook_PublisherName(e.target.value);
          
        break;
        case "book_volumeNo":
          
          setBook_VolumeNo(e.target.value);
          
        break;
        case "book_publicationNumber":
         
        setBook_PublicationNumber(e.target.value);
          
        break;
        case "book_remarks":
          setBook_Remarks(e.target.value);
       
    }
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    runFillActivityData.current = false;
 const obj = data;
    const objData = obj.data;
     console.log(obj);
    setJournal_IssueDate(objData.JournalIssueDate);
    setJournal_PaperTitle(objData.JournalPaperTitle);
    setJournal_Name(objData.JournalName);    
    setJournal_Publisher(objData.JournalPublisher);
    setJournal_Type(objData.JournalType);
    setJournal_Abstract(objData.JournalAbstract);
    setJournal_nPage(objData.TotalJournalPages);
    setStudentID(objData.StudentID || []);
    setFacultyID(objData.FacultyID || []);
    setBook_IssueDate(objData.BookIssueDate);
    setBook_Name(objData.BookName);
    setBook_PublishingOn(objData.BookPublishingOn);
    setBook_AuthorName(objData.BookAuthorName);
    setBook_CoAuthor(objData.BookCoAuthor);
    setBook_PublisherName(objData.BookPublisherName);
    setBook_VolumeNo(objData.BookVolumeNo);
    setBook_Type(objData.BookType);
    setBook_PublicationNumber(objData.BookPublicationNumber);
    setBook_Remarks(objData.BookRemarks);
    setstudentORFaculty(objData.studentORFaculty || "Student");
    setselectedFile(obj.filePath);
    setCreatedBy(obj.createdDate);
  setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
              <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="students"
                    value="2"
                    checked={studentORFaculty === "Student"}
                    onChange={() => students1("Student")}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Student
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="faculty"
                    value="3"
                    checked={studentORFaculty === "Faculty"}
                    onChange={() => students1("Faculty")}
                  />
                  <label className="form-check-label checkposition">
                    Faculty
                  </label>
                </div>
                </div>
              
            </div>
            <div className="row formadduser">
            {studentORFaculty === "Student" && (
                <div
                  id="students2"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Student ID</label>
                  <SearchBox
                    options={studentList}
                    onChangeList={(data) => onStudentIDChange(data)}
                    onInputChange={(e) => onStudentIDInputChange(e)}
                    selectedOptions={StudentID || []}
                    reset={studentORFaculty}
                    type="student"
                    
                  />
                  {!StudentID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}

              {studentORFaculty === "Faculty" && (
                <div
                  id="students3"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label><span style={{ color: "red" }}>*</span>Faculty ID</label>

                  <SearchBox
                    options={facultyList}
                    onChangeList={(data) => onFacultyIDChange(data)}
                    onInputChange={(e) => onFacultyIDInputChange(e)}
                    selectedOptions={FacultyID || []}
                        reset={studentORFaculty}
                    type="faculty"
                  />
                  {!FacultyID?.length > 0 && validate ? (
                    <h6 style={{ color: "red" }}>{"Required"}</h6>
                  ) : null}
                </div>
              )}
              
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Journal Issue Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(JournalIssueDate).format("YYYY-MM-DD")}
                  onChange={journal_IssueDatechange}
                  max={Moment().format('YYYY-MM-DD')}
                />
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Journal Paper Title</label>
                <input
                  className="form-control"
                  type="text"
                  id="jounal_papertitle"
                  value={JournalPaperTitle}
                  onChange={onTextValueChange}
                  maxLength={150}
                />               
              </div>
              <div className="col-xs-12col-md-12 col-lg-12 col-sm-12 form-group">
                <label>Journal Abstract</label>
                <textarea
                  className="form-control"
                  
                  id="journal_abstract"
                  value={JournalAbstract}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={1000}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Journal Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="journal_name"
                  value={JournalName}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Journal Publisher</label>
                <input
                  className="form-control"
                  type="text"
                  id="journal_publisher"
                  value={JournalPublisher}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Journal Page No </label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="journal_nPage"
                  value={TotalJournalPages}
                  onChange={onTextValueChange}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Journal Type</label>
                <select
                  className="form-select form-control"
                  onChange={onJournal_TypeSelect}
                  aria-label="Default select example"
                  value={JournalType}
                >
                  <option value="">Select Journal Type</option>
                  <option value="ISSN">ISSN</option>
                  <option value="ISBN">ISBN</option>
                  <option value="DOI">DOI</option>
                  <option value="Others">Others</option>
                </select>
                
              </div>
              
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Issue Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(BookIssueDate).format("YYYY-MM-DD")}
                  onChange={book_IssueDatechange}
                  max={Moment().format('YYYY-MM-DD')}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="book_name"
                  value={BookName}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Publishing On</label>
                <input
                  className="form-control"
                  type="text"
                  id="book_publishingOn"
                  value={BookPublishingOn}
                  onChange={onTextValueChange}
                  maxLength={150}
                  />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Author Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="book_authorName"
                  value={BookAuthorName}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Co-author</label>
                <input
                  className="form-control"
                  type="text"
                  id="book_coAuthor"
                  value={BookCoAuthor}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Publisher Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="book_publisherName"
                  value={BookPublisherName}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Volume No</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="book_volumeNo"
                  value={BookVolumeNo}
                  onChange={onTextValueChange}
                  maxlength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Type</label>
                <select
                  className="form-select form-control"
                  onChange={onBook_TypeSelect}
                  aria-label="Default select example"
                  value={BookType}
                >
                  <option value="">Select Book Type</option>
                  <option value="ISSN">ISSN</option>
                                        <option value="ISBN">ISBN</option>
                                        <option value="DOI">DOI</option>
                                        <option value="Others">Others</option>
                </select>
                
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group">
                <label>Book Remarks</label>
                <textarea
                  className="form-control"
                  type="text"
                  id="book_remarks"
                  value={BookRemarks}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={300}
                />
               
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Book Publication Number</label>
                <input
                  className="form-control"
                  type="text"
                  id="book_publicationNumber"
                  value={BookPublicationNumber}
                  onChange={onTextValueChange}
                  maxLength={20}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
        {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
           
           
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
           
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
