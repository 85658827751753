import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import "./styles.css";
import NewItem from "./newitem/index";
import {
  fetchActivities,
  activitySelector,
  deleteActivity,
} from "../../../app/redux/slices/activitySlice";
import Popup from "reactjs-popup";
import AdvanceTable from "./advanceTable";

const FacultyDevelopment = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, activityList } = useSelector(activitySelector);

  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [activity, setactivity] = useState();
  const [listOfActivity, setlistOfActivity] = useState(activityList || []);
  const [IsEdit, setIsEdit] = useState(false);
  // const mokData = [
  //   {
  //     activityID: 1,
  //     activityName: "Paper Presentation",
  //     createdBy: "",
  //     createdDate: "2022-05-27T12:00:38.209Z",
  //     data: '{"activityID":1,"Topic":"as","Abstract":"sd","Event":"sf","OrganizedBy":"df","Venue":"qw","Prize":"1","FromDate":"2022-05-28T00:00:00.000Z","ToDate":"2022-05-29T00:00:00.000Z","Impact":"rd","studentORFaculty":"Student","InternalOrExternal":"Internal","OnlineOrOffline":"Offline","blended":true,"Attachment":"","StudentID":[{"studentID":"1313205","studentName":"Nandhini  K","departmentName":"English"}],"FacultyID":[]}',
  //     filePath: "",
  //     id: 0,
  //     modifiedBy: null,
  //     modifiedDate: null,
  //   },
  // ];

  const togglePopup = () => {
    onAssessmentSelection("Faculty Development");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setactivity();
    }
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(activityList);

  const deleteActivityFun = (item) => {
    // console.log(item, "deleteActivityFun");
    dispatch(
      deleteActivity(
        item.id,
        16,
        null
      )
    );
  };

  const editActivity = (item) => {
    // console.log(item, "editActivity");
    setactivity({ ...item, data: JSON.parse(item.data) });
    setshowPopup(!showPopup);
    setIsEdit(true);
  };

  useEffect(() => {
    getActivityData();
  }, []);
  const getActivityData = () => {
    console.log("manoj");
    dispatch(
      fetchActivities(16, null)
    );
  };

  useEffect(() => {
    if (!activityList) {
      return;
    }
    setlistOfActivity(activityList);
  }, [activityList]);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">Faculty Development</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-container">
              <div className="fixed-table-body">
                <AdvanceTable
                  deleteActivityProp={(item) => deleteActivityFun(item)}
                  editActivityProp={(item) => editActivity(item)}
                  data={listOfActivity}
                />
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <NewItem
          isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            activity={activity}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default FacultyDevelopment;
