import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import Moment from "moment";
import validator from "validator";
import "./styles.css";
import {
  addStudentDetails,
  fetchStudents,
  UpdateStudentDetails,
} from "../../../app/redux/slices/studentSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";

const AddStudentDetails = ({ isEdit, item, student, closePopup }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!student) {
      return;
    }
    runFillStudentData.current = true;
    if (runFillStudentData.current) {
      fillStudentData(student);
    }
  }, []);

  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [Id, setId] = useState();

  const [Student_FirstName, setFirstName] = useState("");
  const [Student_MiddleName, setMiddleName] = useState("");
  const [Student_LastName, setLastName] = useState("");
  const [RollNo, setRollNo] = useState("");
  const [AdmissionNumber, setAdmissionNo] = useState("");
  const [Gender, setGender] = useState("");
  const [DepartmentId, setDepartment] = useState();

  const [DeparmentList, setDeparmentList] = useState([]);
  const [Sem, setSemester] = useState("");
  const [Year, setYearOfEducation] = useState("");
  const [AcadamicYearFrom, setAcadamicYearFrom] = useState("");
  const [AcadamicYearTo, setAcadamicYearTo] = useState("");
  const [DOB, setDob] = useState("");
  const [AdmissionThrough, setAdmissionThrough] = useState("");
  const [StudentMobileNumber_1, setContact_Primary] = useState("");
  const [StudentMobileNumber_2, setContact_Alt] = useState("");
  const [EmailId, setEmailID] = useState("");
  //const [Photo, setStudent_Photo] = useState("");
  const [Mode, setMode] = useState("");
  const [DateofJoining, setDateofJoining] = useState("");
  const [DateofLeaving, setDateofLeaving] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [Father_MobileNumber, setFather_MobileNumber] = useState("");
  const [CommunicationAddress, setCommunicationAddr] = useState("");
  const [PermanentAddress, setPermanentAddr] = useState("");
  const [Father_Occupation, setFather_Occupation] = useState("");
  const [AatharNumber, setAatharNumber] = useState("");
  const [Father_Remarks, setFather_Remarks] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [Mother_MobileNumber, setMother_MobileNumber] = useState("");
  const [Mother_Occupation, setMother_Occupation] = useState("");
  const [Mother_Remarks, setMother_Remarks] = useState("");
  const [NumberOfSibling, setNumberOfSibling] = useState();
  const [Remarks, setRemarks] = useState("");
  const [Community, setCommunity] = useState("");
  const [Religion, setReligion] = useState("");
  const [SSLC_SchoolName, setSSLC_SchoolName] = useState("");
  const [SSLC_Board, setSSLC_Board] = useState("");
  const [SSLC_TotalMark, setSSLC_TotalMark] = useState(0);
  const [SSLC_OutoffMark, setSSLC_OutoffMark] = useState(0);
  const [SSLC_Percentage, setSSLC_Percentage] = useState();
  const [HSC_SchoolName, setHSC_SchoolName] = useState("");

  const [HSC_Board, setHSC_Board] = useState("");
  const [HSC_TotalMark, setHSC_TotalMark] = useState(0);
  const [HSC_OutoffMark, setHSC_OutoffMark] = useState(0);
  const [HSC_Percentage, setHSC_Percentage] = useState();
  const [ITI_InstituteName, setITI_InstituteName] = useState("");
  const [BankName, setBankName] = useState("");
  const [ITI_TotalMark, setITI_TotalMark] = useState(0);
  const [ITI_OutoffMark, setITI_OutoffMark] = useState(0);
  const [ITI_Percentange, setITI_Percentange] = useState();
  const [Others_CollegeName, setOthers_CollegeName] = useState("");
  const [Others_TotalMark, setOthers_TotalMark] = useState(0);
  const [Others_Percentange, setOthers_Percentange] = useState();
  const [Others_OutoffMark, setOthers_OutoffMark] = useState(0);
  const [Branch, setBranch] = useState("");
  const [CityOrTown, setCityOrTown] = useState("");
  const [AccountNumber, setAccountNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [Scholarship_Name, setScholarship_Name] = useState("");
  const [Scholarship_Type, setScholarship_Type] = useState("");
  const [Scholarship_IsEligibleOrNot, setScholarship_IsEligibleOrNot] =
    useState(0);
  const [
    Scholarship_IsEligibleAppliedOrNot,
    setScholarship_IsEligibleAppliedOrNot,
  ] = useState(0);
  const [
    Scholarship_IsAppliedWillingOrNot,
    setScholarship_IsAppliedWillingOrNot,
  ] = useState(0);
  const [selectedFile, setselectedFile] = useState("");
  const [InternalOrExternal, setInternalOrExternal] = useState("Internal");
  const [OffLineOrOnLine, setOffLineOrOnLine] = useState("Offline");

  const [createdBy, setCreatedBy] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [aadharErrorMsg, setaadharErrorMsg] = useState("");
  const [datevalidation, setdatevalidation] = useState(true);
  const [validate, setvalidate] = useState(false);
  const [monthvalidation, setmonthvalidation] = useState(true);
  const [markvalidation, setmarkvalidation] = useState(false);
  const [hscmarkvalidation, sethscmarkvalidation] = useState(false);
  const [itimarkvalidation, setitimarkvalidation] = useState(false);
  const [othersmarkvalidation, setothersmarkvalidation] = useState(false);
  const runFillStudentData = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [studentMobErrorMsg, setstudentMobErrorMsg] = useState("");

  const [studentMob1ErrorMsg, setstudentMob1ErrorMsg] = useState("");
  const [fatherMobErrorMsg, setfatherMobErrorMsg] = useState("");

  const [motherMob1ErrorMsg, setmotherMob1ErrorMsg] = useState("");
  const [AcadamicYearFromvalidation1, setAcadamicYearFromvalidation] =
    useState(false);
  const [DateofJoiningvalidation, setDateofJoiningvalidation] = useState(false);
  const [SemStartValid,setSemStartValid]=useState(false);
  const [SemEndValid,setSemEndValid]=useState(true);
  const [SemStartDate, setSemStartDate] = useState("");
  const [SemEndDate, setSemEndDate] = useState("");
  const clearState = () => {
    setId("");
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setGender("");
    setRollNo("");
    setAdmissionNo("");
    // setTeamName("");
    setDepartment("");
    setSemester("");
    setYearOfEducation("");
    setAcadamicYearFrom("");
    setAcadamicYearTo("");
    setDob("");
    setAdmissionThrough("");
    setContact_Primary("");
    // setTeamName("");
    setContact_Alt("");
    setEmailID("");
    setMode("");
    setSemStartDate("");
    setSemEndDate("");

    setDateofJoining("");
    setDateofLeaving("");
    setFatherName("");
    setFather_MobileNumber("");
    setCommunicationAddr("");
    setFather_Occupation("");
    setPermanentAddr("");
    setAatharNumber("");
    setFather_Remarks("");
    setMotherName("");
    setMother_MobileNumber("");
    setMother_Occupation("");
    setMother_Remarks("");
    setNumberOfSibling("");
    setRemarks("");
    setCommunity("");
    setReligion("");
    setSSLC_SchoolName("");
    setSSLC_Board("");
    setSSLC_TotalMark("");
    setSSLC_Percentage("");
    setSSLC_OutoffMark("");
    setHSC_SchoolName("");
    setHSC_Board("");
    setHSC_TotalMark("");
    setHSC_OutoffMark("");
    setHSC_Percentage("");
    setITI_InstituteName("");
    setITI_OutoffMark("");
    setITI_TotalMark("");
    setITI_Percentange("");
    setOthers_OutoffMark("");
    setBranch("");
    setCityOrTown("");
    setOthers_TotalMark("");
    setOthers_Percentange("");
    setAccountNumber("");
    setIFSC("");
    setScholarship_Name("");
    setScholarship_Type("");
    setScholarship_IsEligibleOrNot(0);
    setScholarship_IsEligibleAppliedOrNot(0);
    setScholarship_IsAppliedWillingOrNot(0);
    setselectedFile("");
    setCreatedBy("");
    //setstudentList([]);
    //setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    //setFromdatevalidation(false);
    console.log("reseted");
  };
  const fName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setFirstName(val.target.value);
    }
  };
  const mName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setMiddleName(val.target.value);
    }
  };
  const lName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setLastName(val.target.value);
    }
  };
  const rollNo = (val) => {
    setRollNo(val.target.value);
    // if (/^[a-zA-Z0-9]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setRollNo(val.target.value);
    // }
  };
  const admissionNo = (val) => {
    setAdmissionNo(val.target.value);
    // if (/^[a-zA-Z0-9]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setAdmissionNo(val.target.value);
    // }
  };
  const genderVal = (val) => {
    setGender(val.target.value);
  };
  const dept = (val) => {
    setDepartment(val.target.value);
  };
  const sem = (val) => {
    setSemester(val.target.value);
  };
  /*const yearOfEducation = (val) => {
    if(/^[0-9]*$/.test(val.target.value) == false){
      //error message
    }     else
    {
      setYearOfEducation(val.target.value);
    }
   
  };*/
  const yearOfEducation = (val) => {
    setYearOfEducation(val.target.value);
  };
  const acadamicYearFrom = (val) => {
    setAcadamicYearFrom(val.target.value);
    setmonthvalidation(false);
  };
  const acadamicYearTo = (val) => {
    if (val.target.value != "") setAcadamicYearFromvalidation(true);
    else setAcadamicYearFromvalidation(false);
    setAcadamicYearTo(val.target.value);
    //setAcadamicYearTo(val.target.value);
  };
  const dob = (val) => {
    setDob(val.target.value);
  };
  const admissionThrough = (val) => {
    setAdmissionThrough(val.target.value);
  };
  const contactPrimary = (e) => {
    var phNo1 = e.target.value.replace(/\D/g, "");

    if (phNo1.length == 10) {
      setContact_Primary(phNo1);

      setstudentMobErrorMsg("");
    } else {
      setContact_Primary(phNo1);

      setstudentMobErrorMsg("Please enter valid Mobile No");
    }
  };
  const contactAlt = (e) => {
    var phNo2 = e.target.value.replace(/\D/g, "");

    if (phNo2.length == 10) {
      setContact_Alt(phNo2);

      setstudentMob1ErrorMsg("");
    } else {
      setContact_Alt(phNo2);
      if (phNo2 != "") setstudentMob1ErrorMsg("Please enter valid Mobile No");
      else setstudentMob1ErrorMsg("");
    }
  };

  const emailID = (val) => {
    if (validator.isEmail(val.target.value)) {
      setEmailErrorMsg("");
      emailRef.current.style.border = "";
    } else {
      emailRef.current.focus();
      emailRef.current.style.border = "1px solid red";
      setEmailErrorMsg("Enter valid Email!");
    }
    setEmailID(val.target.value);
  };
  // const student_Photo = (event) => {
  //   setStudent_Photo(event.target.files[0]);
  // };

  const mode = (val) => {
    setMode(val.target.value);
  };
  const doj = (val) => {
    setdatevalidation(false);

    setDateofJoining(val.target.value);
    setDateofLeaving("");
  };
  const dol = (val) => {
    if (val.target.value != "") setDateofJoiningvalidation(true);
    else setDateofJoiningvalidation(false);
    setDateofLeaving(val.target.value);
  };
  const semStartDa =(val)=>{
    setSemEndValid(false);
    setSemStartDate(val.target.value);
    setSemEndDate("");
  }
  const semEndDa = (val) =>{
    if (val.target.value != "") setSemStartValid(true);
    else setSemStartValid(false);
    setSemEndDate(val.target.value);
  }
  const fatherName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setFatherName(val.target.value);
    }
  };
  const fatherMob = (e) => {
    var phNo1 = e.target.value.replace(/\D/g, "");

    if (phNo1.length == 10) {
      setFather_MobileNumber(phNo1);

      setfatherMobErrorMsg("");
    } else {
      setFather_MobileNumber(phNo1);

      setfatherMobErrorMsg("Please enter valid Mobile No");
    }
  };

  const commAddr = (val) => {
    setCommunicationAddr(val.target.value);
  };
  const permanentAddr = (val) => {
    setPermanentAddr(val.target.value);
  };
  const fatherOccup = (val) => {
    setFather_Occupation(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setFather_Occupation(val.target.value);
    // }
  };
  const aathaarNo = (val) => {
    var aadhaar = val.target.value.replace(/\D/g, "");
    if (aadhaar.length < 12) setaadharErrorMsg("Aadhar must be 12 digits");
    else setaadharErrorMsg("");
    setAatharNumber(aadhaar);
  };
  const fatherRemarks = (val) => {
    setFather_Remarks(val.target.value);
  };
  const motherName = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setMotherName(val.target.value);
    }
  };
  const motherMob = (e) => {
    var phNo1 = e.target.value.replace(/\D/g, "");

    if (phNo1.length == 10) {
      setMother_MobileNumber(phNo1);

      setmotherMob1ErrorMsg("");
    } else {
      setMother_MobileNumber(phNo1);

      setmotherMob1ErrorMsg("Please enter valid Mobile No");
    }
  };

  const motherOcccup = (val) => {
    setMother_Occupation(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setMother_Occupation(val.target.value);
    // }
  };
  const motherRemarks = (val) => {
    setMother_Remarks(val.target.value);
  };
  const siblings = (val) => {
    var count = val.target.value.replace(/\D/g, "");
    setNumberOfSibling(count);
  };
  const remarks = (val) => {
    setRemarks(val.target.value);
  };
  const community = (val) => {
    setCommunity(val.target.value);
  };
  const religion = (val) => {
    setReligion(val.target.value);
  };
  const sslcSchoolName = (val) => {
    setSSLC_SchoolName(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setSSLC_SchoolName(val.target.value);
    // }
  };
  const sslc_Board = (val) => {
    setSSLC_Board(val.target.value);
  };

  const sslc_totalMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      if (val.target.value == "") {
        setmarkvalidation(false);
        setSSLC_TotalMark(val.target.value);
      } else if (Number(val.target.value) <= Number(SSLC_OutoffMark)) {
        setSSLC_TotalMark(val.target.value);
        setmarkvalidation(true);
      }
    }
  };

  const sslc_OutOfMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setSSLC_OutoffMark(val.target.value);
    }
    setmarkvalidation(false);
  };
  const sslc_percent = (val) => {
    if (val.target.value < 100) setSSLC_Percentage(val.target.value.toFixed(2));
  };
  const hscSchoolName = (val) => {
    setHSC_SchoolName(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setHSC_SchoolName(val.target.value);
    // }
  };
  const hsc_Board = (val) => {
    setHSC_Board(val.target.value);
  };
  const hsc_OutOfMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setHSC_OutoffMark(val.target.value);
    }
    sethscmarkvalidation(false);
  };

  const hsc_totalMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      if (val.target.value == "") {
        sethscmarkvalidation(false);
        setHSC_TotalMark(val.target.value);
      } else if (Number(val.target.value) <= Number(HSC_OutoffMark)) {
        setHSC_TotalMark(val.target.value);
        sethscmarkvalidation(true);
      }
    }
  };
  const hsc_percent = (val) => {
    if (val.target.value < 100) setHSC_Percentage(val.target.value);
  };

  const itiInstName = (val) => {
    setITI_InstituteName(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setITI_InstituteName(val.target.value);
    // }
  };
  const iti_OutoffMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setITI_OutoffMark(val.target.value);
    }
    setitimarkvalidation(false);
  };

  const iti_totalMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      if (val.target.value == "") {
        setitimarkvalidation(false);
        setITI_TotalMark(val.target.value);
      } else if (Number(val.target.value) <= Number(ITI_OutoffMark)) {
        setITI_TotalMark(val.target.value);
        setitimarkvalidation(true);
      }
    }
  };
  const iti_percent = (val) => {
    if (val.target.value < 100) setITI_Percentange(val.target.value);
  };
  const collegeInstName = (val) => {
    setOthers_CollegeName(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setOthers_CollegeName(val.target.value);
    // }
  };
  const college_OutoffMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setOthers_OutoffMark(val.target.value);
    }
    setothersmarkvalidation(false);
  };

  const college_totalMark = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      if (val.target.value == "") {
        setothersmarkvalidation(false);
        setOthers_TotalMark(val.target.value);
      } else if (Number(val.target.value) <= Number(Others_OutoffMark)) {
        setOthers_TotalMark(val.target.value);
        setothersmarkvalidation(true);
      }
    }
  };
  const college_percent = (val) => {
    if (val.target.value < 100) setOthers_Percentange(val.target.value);
  };
  const bankName = (val) => {
    setBankName(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setBankName(val.target.value);
    // }
  };
  const branch = (val) => {
    setBranch(val.target.value);
  };
  const cityOrTown = (val) => {
    setCityOrTown(val.target.value);

    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setCityOrTown(val.target.value);
    // }
  };
  const accountNo = (val) => {
    if (/^[0-9]*$/.test(val.target.value) == false) {
      //error message
    } else {
      setAccountNumber(val.target.value);
    }
  };

  const ifsc = (val) => {
    setIFSC(val.target.value);

    // if (/^[a-zA-Z0-9]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //   setIFSC(val.target.value);
    // }
  };
  const scholarship_Name = (val) => {
    setScholarship_Name(val.target.value);
  };
  const scholarship_Type = (val) => {
    setScholarship_Type(val.target.value);
  };
  const scholarship_IsEligibleOrNot = (val) => {
    setScholarship_IsEligibleOrNot(val);
  };
  const scholarship_IsEligibleAppliedOrNot = (val) => {
    setScholarship_IsEligibleAppliedOrNot(val);
  };
  const scholarship_IsAppliedWillingOrNot = (val) => {
    setScholarship_IsAppliedWillingOrNot(val);
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !Student_FirstName ||
      !Student_LastName ||
      !Gender ||
      !StudentMobileNumber_1 ||
      !Year ||
      !Sem ||
      !Religion ||
      !DOB ||
      !Community ||
      //!AdmissionNumber ||
      !RollNo ||
      !FatherName ||
      !MotherName ||
      !AdmissionThrough ||
      !PermanentAddress ||
      !CommunicationAddress ||
      !Father_MobileNumber ||
      !Mother_MobileNumber ||
      !AatharNumber ||

      !DepartmentId ||
      //!DateofJoining ||
      !EmailId ||

      !AcadamicYearFrom ||
      !AcadamicYearTo
    ) {
      setvalidate(false);
      seterrorMsg("Provide all required fields");
      alert("Provide all required fields");
      return false;
    } else {
      return true;
    }
  };
  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchStudents());
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const studentData = res ? res[0] : student;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("TypeofUser", "Student");
    formData.append("id", Number(studentData?.id || 0));

    try {
      //debugger;
      const res = await AsyncPost(API.uploadFacultyfiles, formData);
      dispatch(fetchStudents());
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    //debugger;

    AsyncGetFiles(API.downloadStudentFiles + "?id=" + student.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveStudentDetails = async () => {
    //debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var studentData = {
      Id: 0,
      Student_FirstName: Student_FirstName,
      Student_MiddleName: Student_MiddleName,
      Student_LastName: Student_LastName,
      RollNo: RollNo,
      AdmissionNumber: AdmissionNumber,
      DepartmentId: Number(DepartmentId) == null ? 0 : Number(DepartmentId),
      Sem: Sem,
      Year: Year,
      AcadamicYearFrom: AcadamicYearFrom,
      AcadamicYearTo: AcadamicYearTo,
      Gender: Gender,
      DOB: (DOB),
      AdmissionThrough: AdmissionThrough,
      StudentMobileNumber_1: StudentMobileNumber_1,
      StudentMobileNumber_2: StudentMobileNumber_2,
      CommunicationAddress: CommunicationAddress,
      PermanentAddress: PermanentAddress,
      AatharNumber: AatharNumber,
      EmailId: EmailId,
      Photo: "",
      Mode: Mode,
      SemStartDate:SemStartDate,
      SemEndDate:SemEndDate,
      DateofJoining: (DateofJoining),
      DateofLeaving: (DateofLeaving),
      FatherName: FatherName,
      Father_MobileNumber: Father_MobileNumber,
      Father_Occupation: Father_Occupation,
      Father_Remarks: Father_Remarks,

      MotherName: MotherName,
      Mother_MobileNumber: Mother_MobileNumber,
      Mother_Occupation: Mother_Occupation,
      Mother_Remarks: Mother_Remarks,
      NumberOfSibling:
        Number(NumberOfSibling) == null ? 0 : Number(NumberOfSibling),
      Remarks: Remarks,
      Community: Community,
      Religion: Religion,
      SSLC_SchoolName: SSLC_SchoolName,
      SSLC_Board: SSLC_Board,
      SSLC_TotalMark:
        Number(SSLC_TotalMark) == null ? 0 : Number(SSLC_TotalMark),
      SSLC_OutoffMark:
        Number(SSLC_OutoffMark) == null ? 0 : Number(SSLC_OutoffMark),
      SSLC_Percentage:
        Number(SSLC_Percentage) == null ? 0 : Number(SSLC_Percentage),
      HSC_SchoolName: HSC_SchoolName,
      HSC_Board: HSC_Board,
      HSC_TotalMark: Number(HSC_TotalMark) == null ? 0 : Number(HSC_TotalMark),
      HSC_OutoffMark:
        Number(HSC_OutoffMark) == null ? 0 : Number(HSC_OutoffMark),
      HSC_Percentage:
        Number(HSC_Percentage) == null ? 0 : Number(HSC_Percentage),
      ITI_InstituteName: ITI_InstituteName,
      BankName: BankName,
      ITI_TotalMark: Number(ITI_TotalMark) == null ? 0 : Number(ITI_TotalMark),
      ITI_OutoffMark:
        Number(ITI_OutoffMark) == null ? 0 : Number(ITI_OutoffMark),
      ITI_Percentange:
        Number(ITI_Percentange) == null ? 0 : Number(ITI_Percentange),
      Others_CollegeName: Others_CollegeName,
      Others_TotalMark:
        Number(Others_TotalMark) == null ? 0 : Number(Others_TotalMark),
      Others_Percentange:
        Number(Others_Percentange) == null ? 0 : Number(Others_Percentange),
      Others_OutoffMark:
        Number(Others_OutoffMark) == null ? 0 : Number(Others_OutoffMark),
      Branch: Branch,
      CityOrTown: CityOrTown,
      AccountNumber: AccountNumber,
      IFSC: IFSC,
      Scholarship_Name: Scholarship_Name,
      Scholarship_Type: Scholarship_Type,
      Scholarship_IsEligibleOrNot: Scholarship_IsEligibleOrNot,
      Scholarship_IsEligibleAppliedOrNot: Scholarship_IsEligibleAppliedOrNot,
      Scholarship_IsAppliedWillingOrNot: Scholarship_IsAppliedWillingOrNot,

      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };

    try {
      console.log("studentData", studentData);
      const resp = await addStudentDetails(studentData, dispatch);
      //debugger;
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateStudentDetails = async () => {
    //debugger;
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      Student_FirstName: Student_FirstName,
      Student_MiddleName: Student_MiddleName,
      Student_LastName: Student_LastName,
      DepartmentId: Number(DepartmentId),
      RollNo: RollNo,
      AdmissionNumber: AdmissionNumber,
      Sem: Sem,
      Year: Year,
      AcadamicYearFrom: AcadamicYearFrom,
      AcadamicYearTo: AcadamicYearTo,
      Gender: Gender,
      DOB: (DOB),
      AdmissionThrough: AdmissionThrough,
      StudentMobileNumber_1: StudentMobileNumber_1,
      StudentMobileNumber_2: StudentMobileNumber_2,
      CommunicationAddress: CommunicationAddress,
      PermanentAddress: PermanentAddress,
      AatharNumber: AatharNumber,
      EmailId: EmailId,
      Photo: "",
      Mode: Mode,
      SemStartDate:SemStartDate,
      SemEndDate:SemEndDate,
      DateofJoining:(DateofJoining),
      DateofLeaving: (DateofLeaving),
      FatherName: FatherName,
      Father_MobileNumber: Father_MobileNumber,
      Father_Occupation: Father_Occupation,
      Father_Remarks: Father_Remarks,
      MotherName: MotherName,
      Mother_MobileNumber: Mother_MobileNumber,
      Mother_Occupation: Mother_Occupation,
      Mother_Remarks: Mother_Remarks,
      NumberOfSibling:
        Number(NumberOfSibling) == null ? 0 : Number(NumberOfSibling),
      Remarks: Remarks,
      Community: Community,
      Religion: Religion,
      SSLC_SchoolName: SSLC_SchoolName,
      SSLC_Board: SSLC_Board,
      SSLC_TotalMark:
        Number(SSLC_TotalMark) == null ? 0 : Number(SSLC_TotalMark),
      SSLC_OutoffMark:
        Number(SSLC_OutoffMark) == null ? 0 : Number(SSLC_OutoffMark),
      SSLC_Percentage:
        Number(SSLC_Percentage) == null ? 0 : Number(SSLC_Percentage),
      HSC_SchoolName: HSC_SchoolName,
      HSC_Board: HSC_Board,
      HSC_TotalMark: Number(HSC_TotalMark) == null ? 0 : Number(HSC_TotalMark),
      HSC_OutoffMark:
        Number(HSC_OutoffMark) == null ? 0 : Number(HSC_OutoffMark),
      HSC_Percentage:
        Number(HSC_Percentage) == null ? 0 : Number(HSC_Percentage),
      ITI_InstituteName: ITI_InstituteName,
      BankName: BankName,
      ITI_TotalMark: Number(ITI_TotalMark) == null ? 0 : Number(ITI_TotalMark),
      ITI_OutoffMark:
        Number(ITI_OutoffMark) == null ? 0 : Number(ITI_OutoffMark),
      ITI_Percentange:
        Number(ITI_Percentange) == null ? 0 : Number(ITI_Percentange),
      Others_CollegeName: Others_CollegeName,
      Others_TotalMark:
        Number(Others_TotalMark) == null ? 0 : Number(Others_TotalMark),
      Others_Percentange:
        Number(Others_Percentange) == null ? 0 : Number(Others_Percentange),
      Others_OutoffMark:
        Number(Others_OutoffMark) == null ? 0 : Number(Others_OutoffMark),
      Branch: Branch,
      CityOrTown: CityOrTown,
      AccountNumber: AccountNumber,
      IFSC: IFSC,
      Scholarship_Name: Scholarship_Name,
      Scholarship_Type: Scholarship_Type,
      Scholarship_IsEligibleOrNot: Scholarship_IsEligibleOrNot,
      Scholarship_IsEligibleAppliedOrNot: Scholarship_IsEligibleAppliedOrNot,
      Scholarship_IsAppliedWillingOrNot: Scholarship_IsAppliedWillingOrNot,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };

    const studentData = {
      ...student,
      data: data,
    };

    try {
      await UpdateStudentDetails(studentData.data, dispatch);
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  // console.log("UpdateStudent", studentData.data);
  // dispatch(UpdateStudentDetails(studentData.data));
  // await onFileUpload();
  // closePopup();

  // window.location.reload(true);
  // dispatch(fetchStudents);}
  //
  // window.location.reload(true);

  //   } catch (error) {
  //     console.log(error);
  //     closePopup();
  //     dispatch(fetchStudents);
  //     window.location.reload(true);
  //   }
  // };

  const closePopupFun = () => {
    closePopup();
    //window.location.reload(false);
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillStudentData = (obj) => {
    runFillStudentData.current = false;
    // console.log(obj);
    setId(obj.data.id);
    setFirstName(obj.data.student_FirstName);
    setMiddleName(obj.data.student_MiddleName);
    setLastName(obj.data.student_LastName);
    setGender(obj.data.gender);
    setRollNo(obj.data.rollNo);
    setAdmissionNo(obj.data.admissionNumber);
    setDepartment(obj.data.departmentId);
    setSemester(obj.data.sem);
    setYearOfEducation(obj.data.year);
    setAcadamicYearFrom(obj.data.acadamicYearFrom);
    setAcadamicYearTo(obj.data.acadamicYearTo);
    setDob(obj.data.dob);
    setAdmissionThrough(obj.data.admissionThrough);
    setContact_Primary(obj.data.studentMobileNumber_1);
    setContact_Alt(obj.data.studentMobileNumber_2);
    setEmailID(obj.data.emailId);
    //setStudent_Photo(obj.data.photo);
    setMode(obj.data.mode);
    //debugger;
    
    setDateofJoining(obj.data.dateofJoining);
    setDateofLeaving(obj.data.dateofLeaving);
    setSemStartDate(obj.data.semStartDate);
    setSemEndDate(obj.data.semEndDate);
    setFatherName(obj.data.fatherName);
    setFather_MobileNumber(obj.data.father_MobileNumber);
    setCommunicationAddr(obj.data.communicationAddress);
    setPermanentAddr(obj.data.permanentAddress);
    setFather_Occupation(obj.data.father_Occupation);
    setAatharNumber(obj.data.aatharNumber);
    setFather_Remarks(obj.data.father_Remarks);

    setMotherName(obj.data.motherName);
    setMother_MobileNumber(obj.data.mother_MobileNumber);
    setMother_Occupation(obj.data.mother_Occupation);
    setMother_Remarks(obj.data.mother_Remarks);
    setNumberOfSibling(obj.data.numberOfSibling);
    setRemarks(obj.data.remarks);
    setCommunity(obj.data.community);
    setReligion(obj.data.religion);
    setSSLC_SchoolName(obj.data.sslC_SchoolName);
    setSSLC_Board(obj.data.sslC_Board);
    setSSLC_TotalMark(obj.data.sslC_TotalMark);
    setSSLC_Percentage(obj.data.sslC_Percentage);
    setSSLC_OutoffMark(obj.data.sslC_OutoffMark);
    setHSC_SchoolName(obj.data.hsC_SchoolName);

    setHSC_Board(obj.data.hsC_Board);
    setHSC_TotalMark(obj.data.hsC_TotalMark);
    setHSC_OutoffMark(obj.data.hsC_OutoffMark);
    setHSC_Percentage(obj.data.hsC_Percentage);
    setITI_InstituteName(obj.data.itI_InstituteName);
    setBankName(obj.data.bankName);
    setITI_TotalMark(obj.data.itI_TotalMark);
    setITI_OutoffMark(obj.data.itI_OutoffMark);
    setITI_Percentange(obj.data.itI_Percentange);
    setOthers_CollegeName(obj.data.others_CollegeName);
    setOthers_TotalMark(obj.data.others_TotalMark);
    setOthers_Percentange(obj.data.others_Percentange);
    setOthers_OutoffMark(obj.data.others_OutoffMark);
    setBranch(obj.data.branch);
    setCityOrTown(obj.data.cityOrTown);
    setAccountNumber(obj.data.accountNumber);
    setIFSC(obj.data.ifsc);
    setScholarship_Name(obj.data.scholarship_Name);
    setScholarship_Type(obj.data.scholarship_Type);
    setScholarship_IsEligibleOrNot(obj.data.scholarship_IsEligibleOrNot);
    setScholarship_IsEligibleAppliedOrNot(
      obj.data.scholarship_IsEligibleAppliedOrNot
    );
    setScholarship_IsAppliedWillingOrNot(
      obj.data.scholarship_IsAppliedWillingOrNot
    );
    //debugger;
    setfilesList(obj.files);
    setCreatedBy("");
    seterrorMsg("");
    runFillStudentData.current = true;
  };

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Student" : "Add New Student"}</h2>            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> From AcademicYear
                </label>
                <DatePicker
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                  maxDate={new Date()}
                  //disabled={AcadamicYearFromvalidation1}
                  selected={
                    AcadamicYearFrom ? new Date(`${AcadamicYearFrom}/01/01`) : new Date()
                  }
                  onChange={(date) => {
                    const dateIs = {
                      target: { value: dayjs(date).format("YYYY") },
                    };
                    acadamicYearFrom(dateIs);
                  }}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  {" "}
                  <span style={{ color: "red" }}>*</span>To AcademicYear
                </label>
               <DatePicker
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                  disabled={monthvalidation}
                  minDate={new Date(AcadamicYearFrom)}
                  selected={
                    AcadamicYearTo ? new Date(`${AcadamicYearTo}/01/01`) : new Date()
                  }
                  onChange={(date) => {
                    const dateIs = {
                      target: { value: dayjs(date).format("YYYY") },
                    };
                    acadamicYearTo(dateIs);
                  }}
                />              
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Department
                </label>
                <select
                  className="form-select form-control"
                  onChange={dept}
                  aria-label="Default select example"
                  value={DepartmentId}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  {" "}
                  <span style={{ color: "red" }}>*</span> First Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={Student_FirstName}
                  onChange={fName}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Middle Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={Student_MiddleName}
                  onChange={mName}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Last Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={Student_LastName}
                  onChange={lName}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Register No
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={RollNo}
                  onChange={rollNo}
                  maxLength={15}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                   Admission No
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={AdmissionNumber}
                  onChange={admissionNo}
                  maxLength={15}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> <span style={{ color: "red" }}>*</span>Year Of Education</label>
                <select
                  className="form-select form-control"
                  onChange={yearOfEducation}
                  aria-label="Default select example"
                  value={Year}
                >
                  <option value="">--select Year--</option>
                  <option value={1}>1st Year</option>
                  <option value={2}>2nd Year</option>
                  <option value={3}>3rd Year</option>
                </select>
              </div>
              {/*<input
                className="form-control"
                type="text"
                pattern="[0-9]*"

                value={Year}
                onChange={yearOfEducation}
                minLength={4}
                maxLength={4}
              />*/}

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Semester
                </label>
                <select
                  className="form-select form-control"
                  onChange={sem}
                  aria-label="Default select example"
                  value={Sem}
                >
                  <option value="">--Select Sem--</option>
                  <option value="Odd">Odd</option>
                  <option value="Even">Even</option>
                </select>
              </div>
             
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Admission Through
                </label>
                <select
                  className="form-select form-control"
                  onChange={admissionThrough}
                  aria-label="Default select example"
                  value={AdmissionThrough}
                >
                  <option value="">--Select Admission Through--</option>
                  <option value="Merit">Merit</option>
                  <option value="Counselling">Counselling</option>
                  <option value="Management">Management</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Gender
                </label>
                <select
                  className="form-select form-control"
                  onChange={genderVal}
                  aria-label="Default select example"
                  value={Gender}
                >
                  <option value="">--Select Gender--</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                 Date of Joining
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(DateofJoining).format("YYYY-MM-DD")}
                  disabled={DateofJoiningvalidation}
                  max={Moment().format("YYYY-MM-DD")}
                  min={dayjs(AcadamicYearFrom).format("YYYY-MM-DD")}
                  onChange={doj}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Date of Leaving</label>
                <input
                  className="form-control"
                  type="date"
                  disabled={datevalidation}
                  value={dayjs(DateofLeaving).format("YYYY-MM-DD")}
                  min={dayjs(DateofJoining).format("YYYY-MM-DD")}
                  onChange={dol}
                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                 Sem Start Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(SemStartDate).format("YYYY-MM-DD")}
                  disabled={SemStartValid}
                  max={Moment().format("YYYY-MM-DD")}
                  min={dayjs(DateofJoining).format("YYYY-MM-DD")}
                  onChange={semStartDa}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Sem End Date</label>
                <input
                  className="form-control"
                  type="date"
                 disabled={SemEndValid}
                  value={dayjs(SemEndDate).format("YYYY-MM-DD")}
                  min={dayjs(SemStartDate).format("YYYY-MM-DD")}
                  max={dayjs(DateofLeaving).format("YYYY-MM-DD")}

                  onChange={semEndDa}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> DOB
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(DOB).format("YYYY-MM-DD")}
                  max={Moment().subtract(15, "year").format("YYYY-MM-DD")}
                  onChange={dob}
                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Community
                </label>
                <select
                  className="form-select form-control"
                  onChange={community}
                  aria-label="Default select example"
                  value={Community}
                >
                  <option value="">--Select Community--</option>
                  <option value="OC">OC</option>
                  <option value="BC">BC</option>
                  <option value="MBC">MBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                  <option value="DNC">DNC</option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Religion
                </label>
                <select
                  className="form-select form-control"
                  onChange={religion}
                  aria-label="Default select example"
                  value={Religion}
                >
                  <option value="">--Select Religion--</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Muslim">Muslim</option>
                  <option value="Christian">Christian</option>
                  <option value="Others">Others</option>
                </select>
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Student Contact No. 1
                </label>
                <input
                  ref={mobileRef}
                  className="form-control"
                  type="text"
                  value={StudentMobileNumber_1}
                  onChange={contactPrimary}
                  //onChange={(e) => contactPrimary(e)}
                  //onChange={contactPrimary}
                  minLength={10}
                  maxLength={10}
                />
                <h6 style={{ color: "red" }}>{studentMobErrorMsg}</h6>
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Student Contact No. 2</label>
                <input
                  className="form-control"
                  type="text"
                  value={StudentMobileNumber_2}
                  onChange={contactAlt}
                  minLength={10}
                  maxLength={10}
                />
                <h6 style={{ color: "red" }}>{studentMob1ErrorMsg}</h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Email ID
                </label>
                <input
                  ref={emailRef}
                  className="form-control"
                  type="text"
                  value={EmailId}
                  onChange={(e) => emailID(e)}
                />
                <h6
                  style={{
                    color: "red",
                  }}
                >
                  {emailErrorMsg}
                </h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Father's Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={FatherName}
                  onChange={fatherName}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Father's ContactNo
                </label>
                <input
                  className="form-control"
                  onChange={fatherMob}
                  value={Father_MobileNumber}
                  type="text"
                  minLength={10}
                  maxLength={10}
                />
                <h6 style={{ color: "red" }}>{fatherMobErrorMsg}</h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Occupation</label>
                <input
                  className="form-control"
                  onChange={fatherOccup}
                  value={Father_Occupation}
                  type="text"
                  maxLength={200}
                />
              </div>
              {/*<div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
              <label>Remarks</label>
              <textarea
                className="form-control clsremarks"
                type="text"
                value={Father_Remarks}
                onChange={fatherRemarks}
              />
            </div>*/}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Mother's Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={MotherName}
                  onChange={motherName}
                  maxLength={150}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Mother's ContactNo
                </label>
                <input
                  className="form-control"
                  onChange={motherMob}
                  value={Mother_MobileNumber}
                  type="text"
                  minLength={10}
                  maxLength={10}
                />
                <h6 style={{ color: "red" }}>{motherMob1ErrorMsg}</h6>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Occupation</label>
                <input
                  className="form-control"
                  onChange={motherOcccup}
                  value={Mother_Occupation}
                  type="text"
                  maxLength={200}
                />
              </div>
             

              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
                <label>Remarks</label>
                <textarea
                  className="form-control clsremarks"
                  type="text"
                  value={Remarks}
                  onChange={remarks}
                  rows={5}
                  maxLength={300}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> Siblings</label>
                <input
                  className="form-control"
                  onChange={siblings}
                  value={NumberOfSibling}
                  type="text"
                  maxLength={2}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Aadhar Number
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={AatharNumber}
                  onChange={aathaarNo}
                  maxLength={12}
                />
                <h6
                  style={{
                    color: "red",
                  }}
                >
                  {aadharErrorMsg}
                </h6>
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Communication Address
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  value={CommunicationAddress}
                  onChange={commAddr}
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Permanent Address
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  value={PermanentAddress}
                  onChange={permanentAddr}
                  maxLength={500}
                ></textarea>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Mode</label>
                <select
                  className="form-select form-control"
                  onChange={mode}
                  aria-label="Default select example"
                  value={Mode}
                >
                  <option value="">--Select Mode of Education--</option>
                  <option value="Regular">Regular</option>
                  <option value="Rejoined">Rejoined</option>
                  <option value="Long Absent">Long Absent</option>
                  <option value="Discontinued">Discontinued</option>
                </select>
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> SSLC School Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={SSLC_SchoolName}
                  onChange={sslcSchoolName}
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>SSLC Board</label>
                <select
                  className="form-select form-control"
                  onChange={sslc_Board}
                  aria-label="Default select example"
                  value={SSLC_Board}
                >
                  <option value="">--Select SSLC Board--</option>
                  <option value="Govt">Govt</option>
                  <option value="Govt-aided">Govt-aided</option>
                  <option value="Private">Private</option>
                  <option value="Central">Central</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> SSLC Outof Marks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={SSLC_OutoffMark}
                  max={Moment()}
                  disabled={markvalidation}
                  onChange={sslc_OutOfMark}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> SSLC TotalMarks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={SSLC_TotalMark}
                  //disabled={markvalidation}
                  min={SSLC_TotalMark}
                  onChange={sslc_totalMark}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> SSLC Percentage</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={
                    parseFloat(
                      (Number(SSLC_TotalMark) / Number(SSLC_OutoffMark)) * 100
                    ).toFixed(2) == "NaN"
                      ? ""
                      : parseFloat(
                        (Number(SSLC_TotalMark) / Number(SSLC_OutoffMark)) *
                        100
                      ).toFixed(2)
                  }
                  //data-decimal="3"
                  step="0.01"
                  //value={(( Number(SSLC_TotalMark)/Number(SSLC_OutoffMark))*100)}
                  //value={SSLC_Percentage}
                  onChange={sslc_percent}
                //maxLength={5}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> HSC School Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={HSC_SchoolName}
                  onChange={hscSchoolName}
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>HSC Board</label>
                <select
                  className="form-select form-control"
                  onChange={hsc_Board}
                  aria-label="Default select example"
                  value={HSC_Board}
                >
                  <option value="">--Select HSC Board--</option>
                  <option value="Govt">Govt</option>
                  <option value="Govt-aided">Govt-aided</option>
                  <option value="Private">Private</option>
                  <option value="Central">Central</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> HSC Outof Marks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={HSC_OutoffMark}
                  max={Moment()}
                  disabled={hscmarkvalidation}
                  onChange={hsc_OutOfMark}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>HSC TotalMarks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={HSC_TotalMark}
                  //disabled={}
                  min={HSC_TotalMark}
                  onChange={hsc_totalMark}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> HSC Percentage</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={
                    parseFloat(
                      (Number(HSC_TotalMark) / Number(HSC_OutoffMark)) * 100
                    ).toFixed(2) == "NaN"
                      ? ""
                      : parseFloat(
                        (Number(HSC_TotalMark) / Number(HSC_OutoffMark)) * 100
                      ).toFixed(2)
                  }
                  //value={(( Number(HSC_TotalMark)/Number(HSC_OutoffMark))*100)}
                  //value={HSC_Percentage}
                  onChange={hsc_percent}
                  maxLength={5}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>ITI Institute Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={ITI_InstituteName}
                  onChange={itiInstName}
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>ITI Outof Marks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={ITI_OutoffMark}
                  max={Moment()}
                  onChange={iti_OutoffMark}
                  maxLength={4}
                  disabled={itimarkvalidation}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>ITI TotalMarks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={ITI_TotalMark}
                  //disabled={}
                  min={ITI_TotalMark}
                  onChange={iti_totalMark}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>ITI Percentage</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={
                    parseFloat(
                      (Number(ITI_TotalMark) / Number(ITI_OutoffMark)) * 100
                    ).toFixed(2) == "NaN"
                      ? ""
                      : parseFloat(
                        (Number(ITI_TotalMark) / Number(ITI_OutoffMark)) * 100
                      ).toFixed(2)
                  }
                  //value={(( Number(ITI_TotalMark)/Number(ITI_OutoffMark))*100)}
                  //value={ITI_Percentange}
                  onChange={iti_percent}
                  maxLength={5}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Other College Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={Others_CollegeName}
                  onChange={collegeInstName}
                  maxLength={500}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Other College Outof Marks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={Others_OutoffMark}
                  max={Moment()}
                  onChange={college_OutoffMark}
                  maxLength={4}
                  disabled={othersmarkvalidation}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Other College TotalMarks</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={Others_TotalMark}
                  min={Others_TotalMark}
                  // disabled={}
                  onChange={college_totalMark}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Other College Percentage</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={
                    parseFloat(
                      (Number(Others_TotalMark) / Number(Others_OutoffMark)) *
                      100
                    ).toFixed(2) == "NaN"
                      ? ""
                      : parseFloat(
                        (Number(Others_TotalMark) /
                          Number(Others_OutoffMark)) *
                        100
                      ).toFixed(2)
                  }
                  //value={(( Number(Others_TotalMark)/Number(Others_OutoffMark))*100)}
                  //value={Others_Percentange}
                  onChange={college_percent}
                  maxLength={5}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  Bank Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={BankName}
                  onChange={bankName}
                  maxLength={50}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  Account No
                </label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={AccountNumber}
                  onChange={accountNo}
                  maxLength={20}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  City
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={CityOrTown}
                  onChange={cityOrTown}
                  maxLength={50}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  IFSC
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={IFSC}
                  onChange={ifsc}
                  maxLength={11}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Scholarship Name</label>
                <select
                  className="form-control"
                  //type="text"
                  aria-label="Default select example"
                  value={Scholarship_Name}
                  onChange={scholarship_Name}
                  maxLength={150}
                >
                  {" "}
                  <option value="">--Select Scholarship Name--</option>
                  <option value="State Govt. Community Scholarship">
                    State Govt. Community Scholarship
                  </option>
                  <option value="Central Govt. Community Scholarship">
                    Central Govt. Community Scholarship
                  </option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Scholarship Type</label>

                <select
                  className="form-control"
                  //type="text"

                  value={Scholarship_Type}
                  aria-label="Default select example"
                  onChange={scholarship_Type}
                  maxLength={50}
                >
                  <option value="">--Select Scholarship Type--</option>

                  <option value="State Govt. Welfare Board Scholarship">
                    State Govt. Welfare Board Scholarship
                  </option>

                  <option value="Central Govt. Welfare Board Scholarship">
                    Central Govt. Welfare Board Scholarship
                  </option>

                  <option value="Govt. Special Scheme Scholarship">
                    Govt. Special Scheme Scholarship
                  </option>

                  <option value="NGO Scholarship">NGO Scholarship</option>

                  <option value="Alumni Scholarship">Alumni Scholarship</option>

                  <option value="Merit Scholarship">Merit Scholarship</option>

                  <option value="Management Scholarship">
                    Management Scholarship
                  </option>

                  <option value="Industry/Organisation CSR Scholarship">
                    Industry/Organisation CSR Scholarship
                  </option>

                  <option value="Private/Others Scholarship">
                    Private/Others Scholarship
                  </option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> Student Photo</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="eligle-radio-btn"
                    value="1"
                    checked={Scholarship_IsEligibleOrNot === 1}
                    onChange={() => scholarship_IsEligibleOrNot(1)}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Eligible
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="eligle-radio-btn"
                    value="0"
                    checked={Scholarship_IsEligibleOrNot === 0}
                    onChange={() => scholarship_IsEligibleOrNot(0)}
                  />
                  <label className="form-check-label checkposition">
                    Not Eligible
                  </label>
                </div>
              </div>
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="applied-radio-btn"
                    value="1"
                    checked={Scholarship_IsEligibleAppliedOrNot === 1}
                    onChange={() => scholarship_IsEligibleAppliedOrNot(1)}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Eligible and Applied
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="applied-radio-btn"
                    value="0"
                    checked={Scholarship_IsEligibleAppliedOrNot === 0}
                    onChange={() => scholarship_IsEligibleAppliedOrNot(0)}
                  />
                  <label className="form-check-label checkposition">
                    Not Applied
                  </label>
                </div>
              </div>
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="willing-radio-btn"
                    value="1"
                    checked={Scholarship_IsAppliedWillingOrNot === 1}
                    onChange={() => scholarship_IsAppliedWillingOrNot(1)}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Willing to Apply
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="willing-radio-btn"
                    value="0"
                    checked={Scholarship_IsAppliedWillingOrNot === 0}
                    onChange={() => scholarship_IsAppliedWillingOrNot(0)}
                  />
                  <label className="form-check-label checkposition">
                    Not Willing
                  </label>
                </div>
              </div>
            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!student ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveStudentDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateStudentDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStudentDetails;
