import { useState, useEffect, useRef } from "react";
import "./styles.css";
import dayjs from "dayjs";
import Moment from "moment";

import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
  AsyncPostFiles,
} from "../../../app/services/https";
import { API } from "../../../app/services/endpoints";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import RenderIf, { ExportToCsv } from "./utils";


const AttendancePage = () => {
  const dataTable = useRef();
  const [departmentOpt, setdepartmentOpt] = useState([]);
  const [department, setdepartment] = useState("");
  const [year, setyear] = useState("");
  const [semester, setsemester] = useState("");
  const [section, setsection] = useState("");
  const [sectionOpt, setsectionOpt] = useState([]);
  const [batch, setbatch] = useState("");
  const [multiBatch, setmultiBatch] = useState([]);
  const [batchOpt, setbatchOpt] = useState([]);
  const [hourOpt, sethourOpt] = useState([
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: '6', text: '6' },
    { value: '7', text: '7' },
    { value: '8', text: '8' },
    { value: '9', text: '9' },
    { value: '10', text: '10' },
  ]);
  const [unselectedBatcOpt, setunselectedBatcOpt] = useState([]);
  const [unselectedHourOpt, setunselectedHourOpt] = useState(hourOpt);

  const [date, setdate] = useState(new Date());
  const [loading, setloading] = useState(false);
  const [subjectName, setsubjectName] = useState("");
  const [hours, sethours] = useState("");
  const [multiHour, setmultiHour] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [attendance, setattendance] = useState([]);
  const [formatAttendance, setformatAttendance] = useState({});
  const [subjectOpts, setsubjectOpts] = useState([]);
  const [showReportOpts, setshowReportOpts] = useState(false);
  var count = 0;
  const inValidData =
    !department || !year || !semester || !section || !multiBatch?.length > 0 || !date;

  const validFormatAttendance = useMemo(() => {
    return Object.keys(formatAttendance).length > 0;
  }, [formatAttendance]);
  //   sethourOpt ([
  //     { value: '1', text: '1' },
  //     { value: '2', text: '2' },
  //     { value: '3', text: '3' },
  //     { value: '4', text: '4' },
  //     { value: '5', text: '5' },
  //     { value: '6', text: '6' },
  //     { value: '7', text: '7' },
  //     { value: '8', text: '8' },
  //     { value: '9', text: '9' },
  //     { value: '10', text: '10' },
  // ]);
  const clearState = (type) => {
    debugger;
    switch (type) {
      case "department": {
        setsection("");
        setbatch("");
        setmultiBatch([]);
        setmultiHour([]);
        setformatAttendance({});
        setattendance([]);
        setyear("");
        setsemester("");
        setsubjectName("");
        sethours(0);
        setDisabled(false);
        setdate("");
        return;
      }
      case "year": {
        setsection("");
        setbatch("");
        setmultiBatch([]);
        setmultiHour([]);

        setformatAttendance({});
        setattendance([]);
        setsemester("");
        setsubjectName("");
        sethours(0);
        setDisabled(false);
        setdate("");
        return;
      }
      case "semester": {
        setsection("");
        setbatch("");
        setmultiBatch([]);
        setmultiHour([]);

        setformatAttendance({});
        setattendance([]);
        setsubjectName("");
        sethours(0);
        setDisabled(false);
        setdate("");
        return;
      }
      case "section": {
        setbatch("");
        setmultiBatch([]);
        setmultiHour([]);
        setformatAttendance({});
        setattendance([]);
        setsubjectName("");
        sethours(0);
        setDisabled(false);
        setdate("");
        return;
      }
      case "batch": {
        setformatAttendance({});
        setsubjectName("");
        setattendance([]);
        setmultiHour([]);
        sethours(0);
        setDisabled(false);
        setdate("");
        return;
      }
      case "multiBatch": {
        setbatch("");
        if (multiBatch.length <= 1) {
          setsubjectName("");
          setattendance([]);
          setmultiHour([]);
          setformatAttendance({});
          sethours(0);
          setdate("");
        }
        return;
      }
      case "date": {
        setattendance([]);
        setsubjectName("");
        sethours(0);
        setformatAttendance({});
        //setdate("");
        setDisabled(false);
        setmultiHour([]);
        return;
      }
      case "subjectName": {
        sethours(0);
        setattendance([]);
        setDisabled(false);
        setmultiHour([]);
        setformatAttendance({});

        return;
      }
      case "hours": {
        // sethours(0);
        setattendance([]);
        //setDisabled(false);
        setformatAttendance({});

        return;
      }
      case "multiHour": {
        sethours("");
        if (multiHour.length > 0) {
          setattendance([]);

          setformatAttendance({});
        }
        return;
      }
      default: {
        setdepartment("");
        setyear("");
        setsemester("");
        setsection("");
        setbatch("");
        setmultiBatch([]);
        setsubjectName("");
        setmultiHour([]);
        sethours(0);
        setformatAttendance({});
        setattendance([]);
        setDisabled(false);
        return;
      }
    }
  };

  const _onChange = ({ key, data }) => {
    debugger;
    const functionallity = {
      department: (data) => {
        setdepartment(data);
        clearState("department");
      },
      year: (data) => {
        setyear(data);
        clearState("year");
      },
      semester: (data) => {
        setsemester(data);
        clearState("semester");
      },
      section: (data) => {
        setsection(data);
        clearState("section");
      },
      batch: (data) => {
        setbatch(data);
        const present = multiBatch.find((itm) => itm === data);
        //clearState("batch");
        if (present || !data) return;
        setmultiBatch((pre) => (pre.length > 0 ? [...pre, data] : [data]));
        clearState("multiBatch");
      },
      date: (data) => {
        setdate(data);
        clearState("date");
      },
      subject: (data) => {
        setsubjectName(data);
        clearState("subjectName");
      },
      hour: (data) => {
        debugger;
        if (data != "") {
          setDisabled(true);
          sethours(data);
          //setformatAttendance({});
          const hour = multiHour.find((itm) => itm === data);
          //clearState("batch");
          if (hour || !data) return;
          setmultiHour((pre) => (pre.length > 0 ? [...pre, data] : [data]));
          clearState("multiHour");
        }
        else {
          setDisabled(false);
          clearState("hours");
        }
      },
      checkBox: ({ item, checked }) => {
        const updateObj = attendance.find((obj) => obj.uiId === item.uiId);
        updateObj.isPresent = checked;
        setattendance([...attendance]);
      },
    };
    functionallity[key](data);
  };

  const getAllDepartmentFun = async (isSubscribed) => {
    try {
      if (!isSubscribed) {
        return;
      }
      const response = await AsyncGet(API.getAllDepartment);
      setdepartmentOpt(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getSectionFun = async (isSubscribed) => {
    try {
      if (isSubscribed && department && year && semester) {
        const body = {
          DepartmentId: Number(department),
          Year: Number(year),
          Sem: semester,
        };
        const response = await AsyncPost(API.getSection, body);
        setsectionOpt(response.data);
        // console.log(response.data, "section options");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBatchFun = async (isSubscribed) => {
    try {
      if (isSubscribed && department && year && semester && section) {
        const body = {
          DepartmentId: Number(department),
          Year: Number(year),
          Sem: semester,
          DeptSection: section,
        };
        const response = await AsyncPost(API.getBatchList, body);
        setbatchOpt(response.data);
        setunselectedBatcOpt(response.data);
        // console.log(response.data, "batch options");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSubjects = async (isSubscribed) => {
    try {
      debugger;
      if (isSubscribed && multiBatch.length > 0) {
        const response = await AsyncGet(
          API.getSubjectsForAttendance + "Department=" + Number(department) + "&Year=" + year + "&Sem=" + semester + "&batch=" + multiBatch[0] + "&Section=" + section
        );
        setsubjectOpts(response.data);
        // console.log(response.data, "batch options");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadSubjectwiseDailyReport = async () => {
    const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      API.generateAttendancesubjectwisereport +
      "SubjectCode=" +
      subjectName +
      "&Sem=" +
      semester +
      "&Year=" +
      year +
      "&Department=" +
      department +
      "&Section=" +
      section + "&AttendanceDate=" + attendanceDate
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/.xlsx",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert("There is no Attendance for selected subject on the given month");
      });
  };

  const downloadAttendancewiseReport = async () => {
    const attendanceDate = dayjs(date).format("YYYY-MM-DD");

    AsyncGetFiles(
      API.generateAttendancedynamicreport +
      "Sem=" +
      semester +
      "&Year=" +
      year +
      "&Department=" +
      department +
      "&Section=" +
      section
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // "application/vnd.ms-excel",
          })
          //URL.createObjectURL(new Blob([res.date]));
          //, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert("No Attendance");
      });
  };

  const downloadMonthwiseReport = async () => {
    const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      `${API.generateAttendanceMonthwisereport}Sem=${semester}&Year=${year}&Department=${department}&AttendanceDate=${attendanceDate}&Section=${section}`
    )
      .then((res) => {
        console.log(res, "res=======================");
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
        // =============================
      })
      .catch((err) => {
        console.log(err);
        alert("There is no attendance for selected month");
      });
  };

  const updateAllStudentIsPresent = (subjId, status) => {
    let oldArray = attendance;
    for (let i = 0; i < oldArray.length; i++) {
      const obj = oldArray[i];
      if (obj.subjectId === subjId) {
        obj.isPresent = status;
      }
    }
    setattendance([...oldArray]);
  };

  const checkCol = (index) => {
    let isChecked = null;
    const values = Object.values(formatAttendance);
    for (let i = 0; i < values.length; i++) {
      const arr = values[i];
      const checked = arr[index].isPresent;
      if (i > 0 && isChecked !== checked) {
        isChecked = false;
        break;
      }
      isChecked = checked;
    }
    return isChecked;
    // Object.values(formatAttendance).map((arr) => {
    //   isChecked = arr[index].isPresent == false;
    //   console.log(arr[index].isPresent);
    // });
    // if (isChecked) {
    //   return false;
    // } else {
    //   return true;
    // }
  };

  const formatDataFun = (data) => {
    if (data?.length < 1 || attendance?.length < 1) {
      return;
    }
    const attendanceData = data || attendance;
    const formatedData = attendanceData.reduce((acc, item) => {
      acc[`group_${item.studentId}`] = acc[`group_${item.studentId}`] || [];
      acc[`group_${item.studentId}`].push(item);
      return acc;
    }, {});
    setformatAttendance(formatedData);
  };

  const getAttendanceFun = async () => {
    debugger;
    if (inValidData) {
      return;
    }
    try {
      const attendanceDate = dayjs(date).format("YYYY-MM-DD");
      const response = await AsyncGet(
        `${API.getAllAttendance
        }?AttendanceDate=${attendanceDate}&department=${department}&Sem=${semester}&Section=${section}&batch=${multiBatch?.length > 0 ? multiBatch.toString() : batch
        }&year=${year}&Hoursday=${multiHour?.length > 0 ? multiHour.toString() : hours}&SubjectCode=${subjectName}`
      );
      console.log(response?.data, "getAllAttendance response");
      if (response?.data?.length < 1 || !response?.data) {
        alert("There is an attendance for selected hours or No student mapped for selected batch");
        return;
      }
      const dateAddedData = response?.data?.map((element) => {
        const obj = element;
        obj.date = dayjs(date).format();
        obj.uiId = uuidv4();
        return obj;
      });
      // console.log(dateAddedData);
      setattendance(dateAddedData);
      formatDataFun(dateAddedData);
      //BatchId =dateAddedData[0].batchId;
    } catch (error) {
      console.log(error);
    }
  };
  const updateAttendanceFun = async () => {
    console.log(attendance);
    for (let i = 0; i < attendance.length; i++) {
      attendance[i].hoursdays = multiHour?.length > 0 ? multiHour.toString() : hours;
    }
    try {
      const _attendance = [...attendance];
      const response = await AsyncPost(API.insertAttendance, _attendance);
      alert("Updated Successfully");
      console.log(response.data);
    } catch (error) {
      alert("Something went wrong");
      console.log(error);
    }
  };

  const deleteAttendanceFun = async () => {
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    debugger
    for (let i = 0; i < attendance.length; i++) {
      attendance[i].hoursday = Number(hours);
    }
    try {
      const _attendance = [...attendance];
      const response = await AsyncPost(API.deleteAttendance, _attendance);
      alert("Deleted Successfully");
      //console.log(response.data);
      clearState("date");
    }
    catch (error) {
      alert("Something went wrong");
      console.log(error);
    }
  };
  // ------------------get All Department function-------------------------
  useEffect(() => {
    if (multiBatch?.length > 0) {
      const result = batchOpt.filter(
        (opt1) => !multiBatch.some((opt2) => opt2 === opt1.batchName)
      );
      setunselectedBatcOpt([...result]);
      return;
    }
    if (batchOpt?.length > 0) setunselectedBatcOpt([...batchOpt]);
  }, [multiBatch, batchOpt]);
  useEffect(() => {
    if (multiHour?.length > 0) {
      const result = hourOpt.filter(
        (opt1) => !multiHour.some((opt2) => opt2 === opt1.value)
      );
      setunselectedHourOpt([...result]);
      return;
    }
    if (hourOpt?.length > 0) setunselectedHourOpt([...hourOpt]);
  }, [multiHour, hourOpt]);
  useEffect(() => {
    let isSubscribed = true;
    getAllDepartmentFun(isSubscribed);
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    let isSubscribed = true;
    getSectionFun(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester]);
  useEffect(() => {
    let isSubscribed = true;
    getBatchFun(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester, section]);
  useEffect(() => {
    let isSubscribed = true;
    getSubjects(isSubscribed);
    return () => (isSubscribed = false);
  }, [multiBatch]);
  useEffect(() => {
    if (attendance) {
      formatDataFun();
    }
  }, [attendance]);

  const renderItems = () => {
    return (
      <div
        className="container pb-5 pt-3 px-4 border"
        onClick={() => showReportOpts && setshowReportOpts(false)}
      >
        <p></p>
        <div className="row mb-4">
          <div className="col-lg-3">
            <p className="m-0">Department</p>
            <select
              className="form-select form-control"
              onChange={(e) =>
                _onChange({ key: "department", data: e.target.value })
              }
              value={department}
            >
              <option>
                {departmentOpt?.length > 0 ? "Select Department" : "No Options"}
              </option>
              {departmentOpt?.length > 0
                ? departmentOpt.map((opt, idx) => (
                  <option key={idx} value={opt?.id}>
                    {opt.name}
                  </option>
                ))
                : null}
            </select>
          </div>
          <RenderIf isTrue={department}>
            <div className="col-lg-3">
              <p className="m-0">Year</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "year", data: e.target.value })
                }
                value={year}
                maxLength={150}
              >
                <option>Select Year</option>
                <option value={1}>1st Year</option>
                <option value={2}>2nd Year</option>
                <option value={3}>3rd Year</option>
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={year}>
            <div className="col-lg-3">
              <p className="m-0">Semester</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "semester", data: e.target.value })
                }
                value={semester}
                maxLength={150}
              >
                <option>Select Semester</option>
                <option value={"Odd"}>Odd</option>
                <option value={"Even"}>Even</option>
              </select>
            </div>
          </RenderIf>

          <RenderIf isTrue={semester}>
            <div className="col-lg-3">
              <p className="m-0">Section</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "section", data: e.target.value })
                }
                value={section}
                maxLength={150}
              >
                <option>
                  {sectionOpt?.length > 0 ? "Select Section" : "No Options"}
                </option>
                {sectionOpt?.length > 0
                  ? sectionOpt.map((opt, idx) => (
                    <option key={idx} value={opt}>
                      {opt}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={section}>
            <div className="col-lg-3">
              <p className="m-0">Batch</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "batch", data: e.target.value })
                }
                value={batch}
                maxLength={150}
              >
                <option value="">
                  {batchOpt?.length > 0 ? "Select Batch" : "No Options"}
                </option>
                {unselectedBatcOpt?.length > 0
                  ? unselectedBatcOpt.map((opt, idx) => (
                    <option key={idx} value={opt.batchName}>
                      {opt.batchName}
                    </option>
                  ))
                  : null}
              </select>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {multiBatch.length > 0
                  ? multiBatch.map((itm) => (
                    <div
                      style={{
                        position: "relative",
                        display: "flex",
                        margin: "4px 3px 0px 0px",
                        background: "rgb(230,230,230)",
                        padding: "0px 0px 0px 5px",
                      }}
                    >
                      <label
                        style={{ border: "0px solid" }}
                        key={itm}
                      >{`${itm}`}</label>

                      <label
                        style={{
                          background: "rgb(200,200,200)",
                          margin: "0px 0px 0px 5px",
                          padding: "0px 5px",
                        }}
                        onClick={() => {
                          console.log(itm);
                          setmultiBatch((pre) => {
                            const newArray = pre.filter(
                              (item) => item !== itm
                            );
                            clearState("multiBatch");
                            return newArray;
                          });
                        }}
                      >
                        X
                      </label>
                    </div>
                  ))
                  : null}
              </div>
            </div>
          </RenderIf>

          <RenderIf isTrue={multiBatch.length > 0}>
            <div className="col-lg-3">
              <p className="m-0">Date</p>
              <input
                className="form-control"
                type="date"
                id="organisedby"
                value={dayjs(date).format("YYYY-MM-DD")}
                max={Moment().format("YYYY-MM-DD")}
                onChange={(e) =>
                  _onChange({ key: "date", data: e.target.value })
                }
              />
            </div>
          </RenderIf>
          <RenderIf isTrue={multiBatch.length > 0}>
            <div className="col-lg-3">
              <p className="m-0">Subject</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "subject", data: e.target.value })
                }
                value={subjectName}
                maxLength={150}
              // disabled={disabled}
              >
                <option>
                  {subjectOpts?.length > 0 ? "Select Subject" : "No Options"}
                </option>
                {subjectOpts?.length > 0
                  ? subjectOpts.map((opt, idx) => (
                    <option key={idx} value={opt.subjectCode}>
                      {opt.subjectName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={subjectName}>
            <div className="col-lg-3">
              <p className="m-0">Hours</p>
              <select
                className="form-select form-control"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  if (multiHour.length < 4 && selectedValue && !multiHour.includes(selectedValue)) {
                    setmultiHour((prev) => [...prev, selectedValue]);
                  } else if (multiHour.length >= 4) {
                    alert("You can select up to 4 hours only.");
                  }
                }}
                value={hours}
                maxLength={150}
              >
                <option value="">
                  {hourOpt?.length > 0 ? "Select Hour" : "No Options"}
                </option>
                {unselectedHourOpt?.length > 0
                  ? unselectedHourOpt.map((opt) => (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  ))
                  : null}
              </select>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {multiHour.length > 0
                  ? multiHour.map((itm) => (
                    <div
                      key={itm}
                      style={{
                        position: "relative",
                        display: "flex",
                        margin: "4px 3px 0px 0px",
                        background: "rgb(230,230,230)",
                        padding: "0px 0px 0px 5px",
                      }}
                    >
                      <label style={{ border: "0px solid" }}>{itm}</label>
                      <label
                        style={{
                          background: "rgb(200,200,200)",
                          margin: "0px 0px 0px 5px",
                          padding: "0px 5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setmultiHour((pre) => {
                            const newArray = pre.filter((item) => item !== itm);
                            clearState("multiHour");
                            return newArray;
                          });
                        }}
                      >
                        X
                      </label>
                    </div>
                  ))
                  : null}
              </div>
            </div>


            {/* <div className="col-lg-3">
              <p className="m-0">Hours</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "hour", data: e.target.value })
                }
                value={hours}
                maxLength={150}
              >
                <option value="">
                  {hourOpt?.length > 0 ? "Select Hour" : "No Options"}
                </option>
                {unselectedHourOpt?.length > 0
                  ? unselectedHourOpt.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    ))
                  : null}
              </select>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {multiHour.length > 0
                  ? multiHour.map((itm) => (
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          margin: "4px 3px 0px 0px",
                          background: "rgb(230,230,230)",
                          padding: "0px 0px 0px 5px",
                        }}
                      >
                        <label
                          style={{ border: "0px solid" }}
                          key={itm}
                        >{`${itm}`}</label>

                        <label
                          style={{
                            background: "rgb(200,200,200)",
                            margin: "0px 0px 0px 5px",
                            padding: "0px 5px",
                          }}
                          onClick={() => {
                            console.log(itm);
                          
                            setmultiHour((pre) => {
                              const newArray = pre.filter(
                                (item) => item !== itm
                              );
                              clearState("multiHour");
                              return newArray;
                            });
                          }}
                        >
                          X
                        </label>
                      </div>
                    ))
                  : null}
              </div>
            </div> */}
            {/* <div className="col-lg-3">
              <p className="m-0">Hours</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "hour", data: e.target.value})
                
                }
                value={hours}
               
              >
                <option value={0}>Select hours </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </select>
            </div> */}
          </RenderIf>
          <RenderIf isTrue={multiHour.length > 0}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={getAttendanceFun}
                className="btn btn-primary w-100"
              >
                Get Attendance
              </button>
            </div>
          </RenderIf>
          <RenderIf isTrue={multiHour.length > 0}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={clearState}
                className="btn btn-secondary w-100"
              >
                Reset
              </button>
            </div>
          </RenderIf>
          <RenderIf isTrue={multiHour.length > 0}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={() => {
                  attendance?.length < 1
                    ? alert(
                      "There are no attendance found, click on Get Attendance"
                    )
                    : setshowReportOpts(!showReportOpts);
                }}
                className="btn btn-success w-100"
              >
                Generate Report
              </button>
            </div>
          </RenderIf>
        </div>
        {/* -------------report-btns-block----------------- */}
        <RenderIf isTrue={subjectName && showReportOpts}>
          <div className="row report-btns-block mb-1 py-2">
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={() => downloadSubjectwiseDailyReport()}
                className="btn btn-success w-100"
              >
                Daywise Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={() => downloadMonthwiseReport()}
                className="btn btn-success w-100"
              >
                Monthwise Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={() => downloadAttendancewiseReport()}
                className="btn btn-success w-100"
              >
                Attendance Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <ExportToCsv data={formatAttendance} hours={hours} />
            </div>
          </div>
        </RenderIf>
        <RenderIf isTrue={validFormatAttendance}>
          <div className="mb-5 mt-2 grid-table " style={{ overflow: "auto" }}>
            <table ref={dataTable} className="w-100">
              <thead className="">
                <tr>
                  <td>SNo</td>

                  <td>Student RollNo and Name</td>
                  {Object.values(formatAttendance)[0]?.map((item, index) => {
                    // console.log(item);
                    debugger;
                    const checked = checkCol(index);
                    return (
                      <td key={index}>
                        <div className="d-flex justify-content-center">
                          <input
                            className="mx-2"
                            checked={checked}
                            type={"checkbox"}
                            onChange={(e) => {
                              updateAllStudentIsPresent(
                                item.subjectId,
                                e.target.checked
                              );
                            }}
                          />
                          {`${item.subjectCode}-${multiHour}hr`}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="">
                {formatAttendance
                  ? Object.values(formatAttendance).map((_item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{++count}</td>

                        <td>{_item[0].rollNo + "-" + _item[0].studentName || ""}</td>
                        {_item?.map((item, i) => {
                          debugger;
                          return (
                            <td key={i}>
                              <input
                                checked={item?.isPresent || false}
                                type={"checkbox"}
                                onChange={(e) =>
                                  _onChange({
                                    key: "checkBox",
                                    data: {
                                      item,
                                      checked: e.target.checked,
                                    },
                                  })
                                }
                              />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
        </RenderIf>
        <RenderIf isTrue={validFormatAttendance}>
          <div className="row">
            <div className="col-lg-12 "></div>
            <div className="col-lg-3 my-1">
              <button
                type="button"
                onClick={updateAttendanceFun}
                className="btn btn-primary w-100"
              >
                Update Attendance
              </button>
            </div>
            {/* <div className="col-lg-3 my-1">

              <button 
              type="button"
              onClick={deleteAttendanceFun}
              className="btn btn-primary w-100"
              > 
              Delete Attandance
              </button>
                  </div> */}
            <div className="col-lg-3"></div>
          </div>
        </RenderIf>
      </div>
    );
  };

  return <div>{renderItems()}</div>;
};
export default AttendancePage;
