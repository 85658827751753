import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const indentSlice = createSlice({
  name: "indentList",
  initialState: {
    loading: false,
    error: false,
    indentList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    indentDetails: [],
    submitIndentSuccess: "",
    saveIndentDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getIndent: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.indentList = payload;
    },
    saveIndent: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveIndentDataSuccess = payload;
    },
    submitIndent: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getIndent,
  submitIndent,
  setError,
  saveIndent,
} = indentSlice.actions;
export const indentSelector = (state) => state.indents || [];
export default indentSlice.reducer;

export const fetchindents = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getIndents)
    .then((response) => {
      console.log("response,", response);
      dispatch(getIndent(response.data));
      localStorage.removeItem("formDetails");
      


    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addIndentDetails =  async (data, dispatch)=> {
  try {
  //dispatch(setLoading());
  const response = await AsyncPost(API.saveIndents, data);
      //dispatch(setLoading());
      dispatch(saveIndent(response.data));
      dispatch(fetchindents());
      return response.data;
     // dispatch(fetchindents());
    }
    catch(error)  {
      console.log("failure of add faculty");
      dispatch(fetchindents());

      // dispatch(saveIndent(""));
      // dispatch(setError(true));
     // dispatch(setLoading());
}  
};
export const UpdateIndentDetails = (data) => (dispatch) =>{ 
  debugger;
//export const UpdateIndentDetails =  async (data, dispatch)=> {
  dispatch(setLoading());
  AsyncPost(API.updateIndentData, data)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of update Indent");
      dispatch(fetchindents());
      localStorage.removeItem("formDetails");

    })
    .catch((er) => {
      console.log("failure of add faculty");

     // dispatch(saveIndent(""));
      //dispatch(setError(true));
      //dispatch(setLoading());
    });
};
export const UpdateQuatationStatus = (data) => (dispatch) =>{ 
  debugger;
//export const UpdateIndentDetails =  async (data, dispatch)=> {
  dispatch(setLoading());
  AsyncPost(API.updateQuatationStatus, data)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of update quatation");
      dispatch(fetchindents());
      localStorage.removeItem("formDetails");
    })
    .catch((er) => {
      console.log("failure of update quatation");

     // dispatch(saveIndent(""));
      //dispatch(setError(true));
      //dispatch(setLoading());
    });
};

export const deleteIndent = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteIndentData + "?id=" + data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete indent");
      dispatch(fetchindents());
    })
    .catch((er) => {
      console.log("failure of add indent");
      dispatch(saveIndent(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
