import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddPurchasedOrderDetails from "./addpurchasedorderDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchPurchasedOrders,
  purchasedOrderSelector,
  deletePurchasedOrder,
} from "../../app/redux/slices/purchasedOrderDetailsSlice";

import Popup from "reactjs-popup";
import { AsyncGet , AsyncPost,AsyncGetFiles} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
const PurchasedOrderDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, purchasedOrderList } = useSelector(purchasedOrderSelector);
  const [IsEdit, setIsEdit] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [purchasedOrder, setPurchasedOrder] = useState();
  const [listOfPurchasedOrder, setlistOfPurchasedOrder] = useState(purchasedOrderList || []);
  const [IssueDate,SetIssueDate]=useState("");
  const [Store,SetStore]=useState("");
  const [budgetHeadList, setbudgetHeadList] = useState([]);

  
  const togglePopup = () => {
    onAssessmentSelection("PurchasedOrder Details");
    setshowPopup(!showPopup);
    setIsEdit(false);
    if (!showPopup) {
      setPurchasedOrder();
    }
  };
  const downloadReport = async () => {
    debugger;
    AsyncGetFiles(
      API.generatestockreport +
        "IssueDate=" +
        IssueDate +
        "&Store=" +
        Store 
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            // "application/vnd.ms-excel",
          })
          //URL.createObjectURL(new Blob([res.date]));
          //, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert("No Attendance");
      });
  };
  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };
  useEffect(() => {
    getPurchasedOrderData();
  }, []);
  useEffect(() => {
    AsyncGet(API.getBudgetHeads)
      .then((res) => {
        console.log(res.data, "getBudgetHead");
        setbudgetHeadList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", purchasedOrderList);

  const deletePurchasedOrderFun = (item) => {
    debugger;
    if(Number(item.availableQuantity)>=Number(item.receivedQuantity))
    {
      dispatch(deletePurchasedOrder(item.id));
    }
    else{
      alert("Unable to delete purchased order. Because deleting qty is higher than available stack");
    }
  };

  const editPurchasedOrder = (item) => {
    debugger;
      // AsyncGet(API.getBudgetHeads)
      //   .then((res) => {
      //     console.log(res.data, "getBudgetHead");
      //     setbudgetHeadList(res.data);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    setPurchasedOrder({ ...item, data: item ,budgetHeadList});
    setshowPopup(!showPopup);
    setIsEdit(true);

  };


  const getPurchasedOrderData = () => {
    dispatch(fetchPurchasedOrders());
  };

  useEffect(() => {
    if (!purchasedOrderList) {
      return;
    }
    setlistOfPurchasedOrder(purchasedOrderList.filter(x=>x.departmentId==JSON.parse(localStorage.getItem("user")).departmentId ));
  }, [purchasedOrderList]);

  console.log("Before Render purchasedOrderList", purchasedOrderList);
  console.log("Before Render listOfPurchasedOrder", listOfPurchasedOrder);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2"> Purchased List</h2>
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>
              {/*<button
                className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                type="submit"
                //style="width:160px!important"
                onClick={() => downloadReport()}
                
              >
                <span className="glyphicon glyphicon-remove"></span> Download Reports
    </button>*/}
            </div>
          </div>
          <div className="bootstrap-table">
            <div className="fixed-table-toolbar">
            </div>
            <div className="fixed-table-container">
              <div className="fixed-table-body">
              <AdvanceTable
                  deletePurchasedOrderProp={(item) => deletePurchasedOrderFun(item)}
                  editPurchasedOrderProp={(item) => editPurchasedOrder(item)}
                  data={listOfPurchasedOrder}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Role Id:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Role Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    {/* <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div> */}
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddPurchasedOrderDetails
          isEdit={IsEdit}
            item={assessment}
            closePopup={togglePopup}
            purchasedOrder={purchasedOrder}
            // BudgetHeadList={budgetHeadList}
          />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default PurchasedOrderDetails;
