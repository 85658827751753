import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";

import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const NewItem = ({isEdit, item, closePopup, activity }) => {
  // console.log(activity);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  //state
  const [DateOfAwardCeremony, setDateofAwardCeremony] = useState("");
  const [Year, setYear] = useState("");
  const [NameOfTheAwards, setNameoftheAwards] = useState("");
  const [DescriptionOfAwards, setDescriptionOfAwards] = useState("");
  const [StudentID, setStudentID] = useState([]);
  const [FacultyID, setFacultyID] = useState([]);
  const [DepartmentSemester, setDepartmentSemester] = useState("");
  const [DepartmentDesignation, setDepartmentDesignation] = useState("");
  const [AwardBestowedTo, setAwardBestowedTo] = useState("");
  const [NameOfTheInstitution, setNameOfTheInstitution] = useState("");
  const [NameOfThePrincipal, setNameOfThePrincipal] = useState("");
  const [NameoftheFaculty, setNameoftheFaculty] = useState("");
  const [NameoftheStudents, setNameoftheStudents] = useState("");
  const [Venue, setVenue] = useState("");
  const [Event, setEvent] = useState("");
  const [DateOfAnnouncement, setDateofAnnouncement] = useState("");
  const [NameOfOrganisers, setOrganisedBy] = useState("");
  const [studentORFaculty, setstudentORFaculty] = useState("Student");
  const [InternalOrExternal, setInternalOrExternal] = useState("Internal");
  const [OffLineOnLineOrBlended, setOffLineOrOnLine] = useState("Offline");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [datevalidation1, setdatevalidation] = useState(true);
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const clearState = () => {
    setDateofAwardCeremony("");
    setYear("");
    setNameoftheAwards("");
    setDescriptionOfAwards();
    setStudentID([]);
    setFacultyID([]);
    setDepartmentSemester("");
    setDepartmentDesignation("");
    setAwardBestowedTo("");
    setDateofAnnouncement("");

    setVenue("");
    setEvent("");
    setNameOfThePrincipal("");
    setNameoftheFaculty("");
    setNameoftheStudents("");
    setNameOfTheInstitution("");
    setOrganisedBy("");
    setstudentORFaculty("Student");
    setInternalOrExternal("Internal");
    setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };

  const students1 = (val) => {
    setstudentORFaculty(val);
   // if (val === "Student") {
//       setFacultyID([]);
//     } else {
//       setStudentID([]);
//     }
  };

  const fromDatechange = (val) => {
    setDateofAwardCeremony(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    // setToDate("");
    setdatevalidation(false);
  };

  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

  const onStudentIDChange = (data) => {
    setStudentID(data);
    setFacultyID([]);
    setNameOfThePrincipal("");  
    setNameOfTheInstitution("");
    
  };

  const onFacultyIDChange = (data) => {
    setFacultyID(data);
    setStudentID([]);setNameOfThePrincipal("");  
    setNameOfTheInstitution("");
  };
  const onStudentIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getStudentByName + "?studentname=" + data)
      .then((res) => {
        setstudentList(res.data);
        setfacultyList([]);
      })
      .catch(() => setstudentList([]));
  };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?facultyname=" + data)
      .then((res) => {
        setfacultyList(res.data);
        setstudentList([]);
      })
      .catch(() => setfacultyList([]));
  };

  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(
        fetchActivities(
          37, null
          //JSON.parse(localStorage.getItem("user")).departmentId
        )
      );
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Awards"
    );
    console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(
        fetchActivities(
          37, null
          // JSON.parse(localStorage.getItem("user")).departmentId
        )
      );
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initiateActivity = async () => {
    debugger;
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 37,
      NameOfTheAwards: NameOfTheAwards,
      DescriptionOfAwards: DescriptionOfAwards,
      AwardBestowedTo: AwardBestowedTo,
      NameOfOrganisers: NameOfOrganisers,
      Venue: Venue,
      Event: Event,
      DateOfAnnouncement: DateOfAnnouncement,
      DateOfAwardCeremony: DateOfAwardCeremony,//new Date(DateOfAwardCeremony).toLocaleDateString(),
      Year: Year,
      //NameoftheFaculty:NameoftheFaculty,
      NameOfThePrincipal: NameOfThePrincipal,
      // NameoftheStudents:NameoftheStudents,
      NameOfTheInstitution: NameOfTheInstitution,
      StudentID,
      FacultyID,
      Attachment: "",

    };
    var activityData = {
      id: 0,
      activityID: 37,
      activityName: "Awards",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: 0,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateActivity = async () => {

    debugger;
    // console.log("inside activity", activity);
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 37,
      NameOfTheAwards: NameOfTheAwards,
      DescriptionOfAwards: DescriptionOfAwards,
      AwardBestowedTo: AwardBestowedTo,
      NameOfOrganisers: NameOfOrganisers,
      Venue: Venue,
      Event: Event,
      DateOfAnnouncement: DateOfAnnouncement,
      DateOfAwardCeremony: DateOfAwardCeremony,// new Date(DateOfAwardCeremony).toLocaleDateString(),
      Year: Year,
      //NameoftheFaculty:NameoftheFaculty,
      NameOfThePrincipal: NameOfThePrincipal,
      // NameoftheStudents:NameoftheStudents,
      NameOfTheInstitution: NameOfTheInstitution,
      Attachment: "",
      StudentID,
      FacultyID,

    };
    const activityData = {
      ...activity,
      activityID: 37,
      activityName: "Awards",
      data: JSON.stringify(data),
    };

    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      NameOfTheAwards &&
      DateOfAwardCeremony &&
      // Year &&
      //  (StudentID?.length > 0 || FacultyID?.length > 0) &&    
      // Prize &&
      //  Venue &&
      NameOfOrganisers
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };
  const year = (e) => {
    setYear(e.target.value);
    //setmonthvalidation(false);
  };
  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "topic":
        setNameoftheAwards(e.target.value);
        break;
      case "nameofprincipal":
        setNameOfThePrincipal(e.target.value);
        setFacultyID("");
        setStudentID("");
        setNameOfTheInstitution("");
        break;
      case "nameofinstitution":
        setNameOfTheInstitution(e.target.value);
        setNameOfThePrincipal("");
        setFacultyID("");
        setStudentID("");
        break;
      case "nameoffaculty":
        setNameoftheFaculty(e.target.value);
        break;
      case "nameofstudent":
        setNameoftheStudents(e.target.value);
        break;
      case "descriptionOfAwards":
        setDescriptionOfAwards(e.target.value);
        break;
      case "studentname":
        setStudentID(e.target.value);

        break;
      case "facultyname":
        setFacultyID(e.target.value);

        break;
      case "departmentsemester":
        setDepartmentSemester(e.target.value);
        break;
      case "departmentdesigantion":
        setDepartmentDesignation(e.target.value);
        break;
      case "awards":
        setAwardBestowedTo(e.target.value);
        // setNameOfThePrincipal("");
        // setNameOfTheInstitution("");
        // setFacultyID("");
        // setStudentID("");

        break;
      case "event":
        setEvent(e.target.value);
        break;
      case "dateofannouncement":
        setDateofAnnouncement(e.target.value);
        break;

      case "organisedby":
        setOrganisedBy(e.target.value);
        break;
      case "venue":
        setVenue(e.target.value);
      // if (/^[a-z A-Z 0-9-]*$/.test(e.target.value) == false) {
      //   //error message
      // } else {
      //   setVenue(e.target.value);
      // }
    }
  };
  // On file select (from the pop up)
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    console.log(AllFiles);
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    debugger;
    runFillActivityData.current = false;
    const obj = data;
    const objData = obj.data;
    // console.log(obj);
    setDateofAwardCeremony(objData.DateOfAwardCeremony);
    setYear(objData.Year);
    setNameoftheAwards(objData.NameOfTheAwards);
    setNameOfTheInstitution(objData.NameOfTheInstitution);
    setNameOfThePrincipal(objData.NameOfThePrincipal);
    setNameoftheStudents(objData.NameoftheStudents);
    setNameoftheFaculty(objData.NameoftheFaculty);
    setDescriptionOfAwards(objData.DescriptionOfAwards);
    setStudentID(objData.StudentID || []);
    setFacultyID(objData.FacultyID || []);
    setAwardBestowedTo(objData.AwardBestowedTo);
    setDateofAnnouncement(objData.DateOfAnnouncement);
    setVenue(objData.Venue);
    setEvent(objData.Event);
    setOrganisedBy(objData.NameOfOrganisers);

    setCreatedBy(obj.createdDate);
    //setselectedFile(obj.filePath);
    setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);

      //AsyncGet(API.getActivityById + "?id=" + activity.id)
      //  .then((res) => {
      //  console.log(res, "response");
      //  })
      //catch((err) => {
      //    console.log(err, "error");
      //});
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span> Date of Award Ceremony
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(DateOfAwardCeremony).format("YYYY-MM-DD")}
                  disabled={Fromdatevalidation1}
                  onChange={fromDatechange}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!DateOfAwardCeremony && validate ? (
                  <h6 style={{ color: "red" }}>{"Select the Date"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Name of The Awards
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="topic"
                  value={NameOfTheAwards}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!NameOfTheAwards && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  Event
                </label>

                <select
                  className="form-control"
                  //type="text"

                  aria-label="Default select example"
                  id="event"
                  value={Event}
                  onChange={onTextValueChange}
                >
                  <option value="">Select Event</option>

                  <option value="State">State</option>
                  <option value="National">National</option>
                  <option value="International">International</option>
                  <option value="Regional">Regional</option>
                </select>
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  Award Bestowed To
                </label>

                <select
                  className="form-control"
                  //type="text"

                  aria-label="Default select example"
                  id="awards"
                  value={AwardBestowedTo}
                  onChange={onTextValueChange}
                >
                  <option value="">Select Award Bestowed To</option>
                  <option value="Institution">Institution</option>
                  <option value="Principal">Principal</option>
                  <option value="Faculty">Faculty</option>
                  <option value="Students">Students</option>

                </select>
              </div>


              {AwardBestowedTo === "Institution" && (
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt">
                  <label>
                    Name of the Institution
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="nameofinstitution"
                    value={NameOfTheInstitution}
                    onChange={onTextValueChange}
                    maxLength={150}

                  />

                </div>
              )}
              {AwardBestowedTo === "Principal" && (
                <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt">
                  <label>
                    Name of the Principal
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="nameofprincipal"
                    value={NameOfThePrincipal}
                    onChange={onTextValueChange}
                    maxLength={150}
                  />

                </div>
              )}
              {AwardBestowedTo === "Students" && (
                //    <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                //    <label>
                //      Name of the Student
                //    </label>
                //    <input
                //      className="form-control"
                //      type="text"
                //      id="nameofstudent"
                //      value={NameoftheStudents}
                //      onChange={onTextValueChange}
                //      maxLength={150}
                //    />

                //  </div>
                <div
                  id="students2"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label>
                    Name of the Student
                  </label>
                  <SearchBox
                    options={studentList}
                    onChangeList={(data) => onStudentIDChange(data)}
                    onInputChange={(e) => {
                      onStudentIDInputChange(e);
                    }}
                    selectedOptions={StudentID || []}
                    //reset={studentORFaculty}
                    type="student"
                  />

                </div>

              )}
              {AwardBestowedTo === "Faculty" && (
                <div
                  id="students3"
                  className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
                >
                  <label>
                    Name of the Faculty
                  </label>

                  <SearchBox
                    options={facultyList}
                    onChangeList={(data) => {
                      onFacultyIDChange(data);
                    }}
                    onInputChange={(e) => onFacultyIDInputChange(e)}
                    selectedOptions={FacultyID || []}
                    // reset={studentORFaculty}
                    type="faculty"
                  />

                </div>
              )}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Year</label>
                <DatePicker
                  className="form-control"
                  showYearPicker

                  dateFormat="yyyy"
                  maxDate={new Date()}
                  //disabled={AcadamicYearFromvalidation1}
                  selected={
                    Year ? new Date(`${Year}/01/01`) : new Date()
                  }
                  onChange={(date) => {
                    const dateIs = {
                      target: { value: dayjs(date).format("YYYY") },
                    };
                    year(dateIs);
                  }}
                />
              </div>


              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Date of Announcement </label>
                <input
                  className="form-control"
                  type="text"
                  id="dateofannouncement"
                  value={DateOfAnnouncement}
                  onChange={onTextValueChange}
                  maxLength={500}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> <span style={{ color: "red" }}>*</span>Name of Organiser </label>
                <input
                  className="form-control"
                  type="text"
                  id="organisedby"
                  value={NameOfOrganisers}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                {!NameOfOrganisers && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  Venue
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="venue"
                  value={Venue}
                  onChange={onTextValueChange}
                  maxLength={300}
                />

              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                <label>
                  Description of Awards
                </label>

                <textarea
                  className="form-control clsabstract"
                  id="descriptionOfAwards"
                  value={DescriptionOfAwards}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={1000}
                />

              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* &nbsp;*/}
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            {/*<button
              className="btn btn-secondary btn-block "
              type="button"
              onClick={onFileUpload}
            >
              upload
            </button>*/}
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
