import { configureStore     } from '@reduxjs/toolkit';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux';
import { createBrowserHistory } from "history";
import logger from 'redux-logger';
import welcomeReducer from './slices/welcomeSlice';
import productReducer from './slices/productSlice';
import loginReducer from './slices/loginSlice';
import activityReducer from './slices/activitySlice';
import facultyReducer from './slices/facultySlice';
import studentReducer from './slices/studentSlice';
import aluminiReducer from './slices/aluminiSlice';
import subjectReducer from './slices/subjectSlice';
import admissionReducer from './slices/admissionSlice';
import departmentReducer from './slices/departmentSlice';
import roleReducer from './slices/roleSlice';
import roleActivityReducer from './slices/roleActivityMappingSlice';
import batchReducer from './slices/batchDetailsSlice';
import batchStudReducer from './slices/batchStudentMappingSlice';
import batchSubReducer from './slices/batchSubjectMappingSlice';
import deptStudReducer from './slices/deptStudentMappingSlice';
import formReducer from './slices/formRoleSlice';
import indentReducer from './slices/indentSlice';
import fdpReducer from './slices/fdpSlice';
import odpReducer from './slices/odpSlice';
import bpeReducer from './slices/bpeSlice';

import facultySubjectMappingReducer from './slices/facultySubjectMappingSlice';

import AnnouncementReducer from './slices/announcementSlice'
import inventoryReducer from './slices/inventorySlice';
import inventoryspecReducer from './slices/inventorySpecSlice';
import purchasedOrderReducer from './slices/purchasedOrderDetailsSlice';
import stockInventoryReducer from './slices/stockInventorySlice';
import inventoryIssueReducer from "./slices/inventoryIssueSlice";
import labdetailsReducer from "./slices/labDetailsSlice";
import hoadetailsReducer from "./slices/hoaDetailsSlice";
import budgetReducer from "./slices/budgetSlice";
import memberReducer from './slices/memberSlice';
 
 
import thunk from "redux-thunk"

 const store= configureStore({
   reducer: {
      login: loginReducer,
      welcome: welcomeReducer,
      products:productReducer,
      activities:activityReducer,
      routing: routerReducer,
      faculties: facultyReducer,
      students: studentReducer,
      aluminies: aluminiReducer,
      admissions: admissionReducer,
      subject:subjectReducer,
      departments:departmentReducer,
      role:roleReducer,
      roleActivity: roleActivityReducer,
      batches: batchReducer,
      batchStuds: batchStudReducer,
      deptStuds: deptStudReducer,
      batchSubs: batchSubReducer,
      formRole:formReducer,
      indents:indentReducer,
      inventorys:inventoryReducer,
      inventoryspecs:inventoryspecReducer,
      purchasedOrders:purchasedOrderReducer,
      stockInventorys:stockInventoryReducer,
      inventoryIssues:inventoryIssueReducer,
      labdetails:labdetailsReducer,
      hoadetails:hoadetailsReducer,
      ods:fdpReducer,
      bpes:bpeReducer,
      odps:odpReducer,
      budget:budgetReducer,
      facultySubjectmappings:facultySubjectMappingReducer,
      memberDetails:memberReducer,

      announcements:AnnouncementReducer
   },
   middleware: [thunk, logger],
})
// export const history = syncHistoryWithStore(createBrowserHistory(), store)
export default store; 