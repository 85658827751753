import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
// import './bootstrap.css';
// import './bootstrap.min.css';
// import './styles.css';

import "./addnew.css";
//import logo from "../../app/assets/images/logo.png";
import logo from "../../app/assets/images/LogoLates.png";

import {
  loginsSelector,
  validateLogin,
} from "../../app/redux/slices/loginSlice";
import { Form, FormLabel } from "react-bootstrap";

const Login = () => {
  // set up dispatch
  const dispatch = useDispatch();
  //const history=useHistory();
  const navigate = useNavigate();
  // fetch data from our store
  const { errorMSG, successMsg } = useSelector((state) => state.login);

  console.log("login selector", loginsSelector);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [userNameErrorMsg, setUserNameErrorMsg] = useState("");
  const [pswdErrorMsg, setPswdErrorMsg] = useState("");
  const terms = () => {
    debugger;
    // navigate("/terms")
    window.location.href = "/termsandcondition.html";
  };
  const policy = () => {
    debugger;
    // navigate("/terms")
    window.location.href = "/policy.html";
  };
  const handledownload = async () => {
    debugger;
   /* const zip = new JSZip();

    // Fetch the folder contents (assuming APK files are in the `public/ApkFile/` folder)
    const files = [
      { name: "TPT-Pulse.apk", url: "/ApkFile/TPT-Pulse.apk" }
      //{ name: "file2.apk", url: "/ApkFile/file2.apk" },
    ];

    // Add files to the ZIP
    for (const file of files) {
      const response = await fetch(file.url);
      const blob = await response.blob();
      zip.file(file.name, blob);
    }

    // Generate the ZIP file
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "ApkFiles.zip");
    });*/
   
    /*const zip = new JSZip();

    // Simulate adding files to the ZIP (you can fetch files or use static files)
    const fileContent = await fetch('/ApkFile/TPT-Pulse.apk').then(res => res.blob());
  
    // Add the file to the ZIP archive
    zip.file('TPT-Pulse.apk', fileContent);
  
    // Generate the ZIP file
    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        // Trigger the download
        saveAs(content, 'TPT_Pulse_APK.zip');
      });*/
      /*const zip = new JSZip();

      try {
        // Fetch the APK file (replace with your file path or URL)
        const response = await fetch('/ApkFile/TPT-Pulse.apk'); // Ensure the file is publicly accessible
        const apkBlob = await response.blob();
  
        // Add the APK file to the ZIP
        zip.file('TPT-Pulse.apk', apkBlob);
  
        // Generate the ZIP file
        const zipBlob = await zip.generateAsync({ type: 'blob' });
  
        // Create a download link
        const url = window.URL.createObjectURL(zipBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'TPT-Pulse-APK.zip';
        a.click();
  
        // Cleanup
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading or zipping the APK file:', error);
      }*/
     // Define the URL to your APK file //process.env.PUBLIC_URL +
    const apkUrl ='/TPTPulse.apk'; // Path to your APK file

    // Create a temporary anchor element
    const a = document.createElement('a');
    a.href = apkUrl;
    a.download = 'TPTPulse.apk'; // The name that the APK will be saved as

    // Append the link to the document body (required for triggering click in some browsers)
    document.body.appendChild(a);
    
    // Programmatically click the link to start the download
    a.click();

    // Remove the link after the download is triggered
    document.body.removeChild(a);
    
  };

  const onClickLogin = () => {
    console.log(username, password);
    try {
      setUserNameErrorMsg("");
      setPswdErrorMsg("");
      const error = validationFun();
      if (error) {
        dispatch(validateLogin(username, password)).then(() => {
          console.log("success message just before navigation", successMsg);
          successMsg.length > 0 && navigate("/Welcome");
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    console.log("success message", successMsg);
    const getUser = JSON.parse(localStorage.getItem("user"));
    console.log(getUser);
    getUser && navigate("/Welcome"); //history.push('/Welcome')
  }, [successMsg]);

  const validationFun = () => {
    let isValid = true;
    if (!username) {
      setUserNameErrorMsg("Username is required");
      isValid = false;
    }

    if (!password) {
      setPswdErrorMsg("Password is required");
      isValid = false;
    }

    return isValid;
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "userName":
        setUserName(e.target.value);
        console.log(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
    }
  };

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  // render the items
  const renderItems = () => {
    // loading state
    //   if (loading) return <strong>Loading please wait...</strong>;

    //   // error state
    //   if (error) return <strong>Items not available at this time</strong>;
    // regular data workflow
    return (
      <div className="container">
        <div className="login-logo off-canvas">
          {" "}
          <a href="#">
            <img src={logo} width="340" />
          </a>{" "}
        </div>

        {/* <label htmlFor="Password" className="fw-bold">
          {errorMSG}
        </label> */}
        <div className="form-container off-canvas">
          <Form>
            <h2 className="text-center">Login</h2>
            <Form.Group>
              <label htmlFor="EmailAddress" className="fw-bold">
                User Name
              </label>
              <Form.Control
                type="text"
                name="username"
                id="userName"
                onChange={onTextValueChange}
                value={username}
                aria-required="true"
                aria-invalid="true"
                required
                onKeyDown={handleEnter}
              />
            </Form.Group>
            {userNameErrorMsg ? (
              <h6 style={{ color: "red" }}>{userNameErrorMsg}</h6>
            ) : null}
            <p></p>
            <Form.Group>
              <label htmlFor="Password" className="fw-bold">
                Password
              </label>
              <Form.Control
                type="password"
                name="password"
                id="password"
                onChange={onTextValueChange}
                value={password}
                aria-required="true"
                aria-invalid="true"
                required
                onKeyDown={handleEnter}
              />
            </Form.Group>
            {pswdErrorMsg ? (
              <h6 style={{ color: "red" }}>{pswdErrorMsg}</h6>
            ) : null}
            <br></br>
            {errorMSG ? (
              <h6
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {errorMSG}
              </h6>
            ) : null}
            <p className="text-center">
              <button
                type="button"
                className="btn btn-lg btn-primary btn-block form-roundedbtn"
                onClick={() => {
                  onClickLogin();
                }}
                onFocus={() => {
                  onClickLogin();
                }}
              >
                Sign in
              </button>
            </p>
            {/* Terms and Conditions and Privacy Policy Links */}
            <div className="text-center" style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "inline-block",
                  marginRight: "120px",
                  color: "#22678e",
                  textDecoration: "underline",
                }}
              >
                <a
                  //href="#"
                  onClick={terms}
                >
                  Terms and Conditions
                </a>
              </div>
              <div
                style={{
                  display: "inline-block",
                  color: "#22678e",
                  textDecoration: "underline",
                }}
              >
                <a
                  //href="#"
                  onClick={policy}
                >
                  Privacy Policy
                </a>
              </div>
            </div>
            <p className="text-center" style={{ marginTop: "20px" }}>
              <span>Need an mobile app APK: </span>
              <a
                //href="public/ApkFile" // Replace with your APK file URL
               // download="TPT Pulse.apk"
                onClick={handledownload}
                style={{ color: "#22678e", textDecoration: "underline" }}
              >
                Click here
              </a>
            </p>
          </Form>
        </div>
      </div>
    );
  };

  // template
  return <div>{renderItems()}</div>;
};

export default Login;
