import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const batchSlice = createSlice({
  name: "batchList",
  initialState: {
    loading: false,
    error: false,
    batchList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    batchDetails: [],
    submitBatchSuccess: "",
    saveBatchDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getBatch: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.batchList = payload;
    },
    saveBatch: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveBatchDataSuccess = payload;
    },
    submitBatch: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getBatch,
  submitBatch,
  setError,
  saveBatch,
} = batchSlice.actions;
export const batchSelector = (state) => state.batches || [];
export default batchSlice.reducer;

export const fetchBatches = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getBatches)
    .then((response) => {
      console.log("response,", response);
      dispatch(getBatch(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addBatchDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveBatches, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveBatch(response));
      dispatch(fetchBatches());
    })
    .catch((er) => {
      console.log("failure of add batch");
      dispatch(saveBatch(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

// export const UpdateBatchDetails = (data) => (dispatch) => {
//   // dispatch(setLoading());
//   AsyncPost(API.updateBatchData, data)
//     .then((response) => {
//       // dispatch(setLoading());
//       console.log(response, "success of update batch");
//       dispatch(fetchBatches());
//     })
//     .catch((er) => {
//       console.log("failure of add batch");
//       dispatch(saveBatch(""));
//       dispatch(setError(true));
//       dispatch(setLoading());
//     });
// };
// export const deleteBatch = (data) => (dispatch) => {
//   // dispatch(setLoading());
//   AsyncPost(API.deleteBatchData + "?id=" + data)
//     .then((response) => {
//       // dispatch(setLoading());
//       console.log(response, "success of delete batch");
//       dispatch(fetchBatches());
//     })
//     .catch((er) => {
//       console.log("failure of add batch");
//       dispatch(saveBatch(""));
//       dispatch(setError(true));
//       dispatch(setLoading());
//     });
// };
