import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const labDetailsSlice = createSlice({
  name: "labDetailsList",
  initialState: {
    loading: false,
    error: false,
    labDetailsList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    labDetails: [],
    submitLabDetailsSuccess: "",
    saveLabDetailsDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getLabDetails: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.labDetailsList = payload;
    },
    saveLabDetails: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveLabDetailsDataSuccess = payload;
    },
    submitLabDetails: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getLabDetails,
  submitLabDetails,
  setError,
  saveLabDetails,
} = labDetailsSlice.actions;
export const LabDetailsSelector = (state) => state.labdetails || [];
export default labDetailsSlice.reducer;

export const fetchLabDetails = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getlabdetails)
    .then((response) => {
      console.log("response,", response);
      dispatch(getLabDetails(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addLabDetails = (data) => (dispatch) => {
  debugger
  dispatch(setLoading());
  AsyncPost(API.savelabdetails, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveLabDetails(response));
      dispatch(fetchLabDetails());
    })
    .catch((er) => {
      console.log("failure of add LabDetails");
      dispatch(saveLabDetails(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateLabDetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updatelabdetails, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update LabDetails");
      dispatch(fetchLabDetails());
    })
    .catch((er) => {
      console.log("failure of Update LabDetails");
      dispatch(saveLabDetails(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteLabDetails = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deletelabdetails + "?id=" + data, "")
    .then((response) => {
      if (response.data!='Success')
      {      
                alert('Sorry! Unable to delete LabDetails.LabDetails is mapped with Indent form');

      }
      

      // dispatch(setLoading());
      console.log(response, "success of delete LabDetails");
      dispatch(fetchLabDetails());
    })
    .catch((er) => {
      console.log("failure of delete LabDetails");
      dispatch(saveLabDetails(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
