import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const inventoryspecSlice = createSlice({
  name: "inventoryspecList",
  initialState: {
    loading: false,
    error: false,
    inventoryspecList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    inventoryspecDetails: [],
    submitInventorySpecSuccess: "",
    saveInventorySpecDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getInventorySpec: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.inventoryspecList = payload;
    },
    saveInventorySpec: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveInventorySpecDataSuccess = payload;
    },
    submitInventorySpec: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getInventorySpec,
  submitInventorySpec,
  setError,
  saveInventorySpec,
} = inventoryspecSlice.actions;
export const inventoryspecSelector = (state) => state.inventoryspecs || [];
export default inventoryspecSlice.reducer;

export const fetchInventorySpecs = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getInventorySpecs)
    .then((response) => {
      console.log("response,", response);
      dispatch(getInventorySpec(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addInventorySpecDetails = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.saveInventorySpecs, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveInventorySpec(response));
      dispatch(fetchInventorySpecs());
    })
    .catch((er) => {
      console.log("failure of add Inventory");
      dispatch(saveInventorySpec(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateInventorySpecDetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updateInventorySpecs, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update Inventory");
      dispatch(fetchInventorySpecs());
    })
    .catch((er) => {
      console.log("failure of Update Inventory");
      dispatch(saveInventorySpec(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteInventorySpec = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deleteInventorySpecs + "?id=" + data, "")
    .then((response) => {
      if (response.data!='Success')
      {      
        alert('Sorry! Unable to delete Inventory.Inventory is mapped with purchase details');
      }
      
      // dispatch(setLoading());
      console.log(response, "success of delete Inventory");
      dispatch(fetchInventorySpecs());
    })
    .catch((er) => {
      console.log("failure of delete Inventory");
      dispatch(saveInventorySpec(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
