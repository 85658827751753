import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { PaginationBlock } from "./tableStyles";
import dayjs from "dayjs";
import { useState } from "react";
//import Modal from "../popup/index.jsx";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AsyncGet, AsyncPost, AsyncGetFiles } from "../../../app/services/https";
import { API } from "../../../app/services/endpoints";
//import { LiaSmsSolid } from "react-icons/lia";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaMessagelines } from "react-icons/fa";
//import Select from 'react-select';
let Messagedata;
let roleId=[];
function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="fixed-table-toolbar">
      <div className="float-start search  col-6">
        <input
          className="form-control"
          type="text"
          placeholder="Search"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
function DateFilter({ filterValue }) {
  const [value, setValue] = React.useState();

  const onChange = useAsyncDebounce((value) => {
    // const pattern = new RegExp('11/2012', 'gi');
    console.log(value);
    filterValue(value);
  }, 200);


  return (
    <div className="fixed-table-toolbar">
      <div className="float-start search  col-6">
        <input
          className="form-control mx-1"
          type="text"
          placeholder="Search by date"
          value={value}
          onChange={(e) => {
            // setValue(e.target.value);
            // onChange(e.target.value);
            filterValue(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
export function TableComp({
  columns,
  data,
  deleteIndentProp,
  editIndentProp,
  //modelopen
}) {
  // Use the state and functions returned from useTable to build your UI
  //modelopen={()=>
  //editIndentProp={(item) => editIndent(item)}
   // modelopen={(item) =>modelopensample(item)}
  
  const [secondFilterValue, setsecondFilterValue] = useState(null);
  const [filteredData, setfilteredData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [Message, setMessage] = useState("");
  const [validate, setvalidate] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [selectedOptions, setSelectedOptions] = useState("");
  {/*const options = [
    { value: "Maker", label: 'Faculty' },
    { value: "HOD", label: 'HOD/HOS' },
    { value: "Office Establishment", label: 'Office Establishment' },
    { value: "Office Superintendent", label: 'Office Superintendent'},
    
  ];*/}
  const message = (val) => {
    setMessage(val.target.value);
  }
  const selectedoptions = (val) => {
    setSelectedOptions(val.target.value);
  }
  const handleClose = () => {
    debugger;
    setMessage("");
    setSelectedOptions([]);
    setvalidate(false);
    setModalOpen(false);

  }

  const modelopen = (item) => {
    debugger;
    Messagedata = item.refNo;
    console.log(Messagedata, "data");
    setModalOpen(true);

  }
  const SendWhatsAppMsg = async (resdata) => {
    debugger;

    if ((Message == "") || (selectedOptions.length==0)) {
      setvalidate(true);
      seterrorMsg("Required");
      return true;

    }
    else {
     /* for(let i=0;i<selectedOptions.length;i++)
      {
       roleId[i]= selectedOptions[i].value;
      }*/
      //let concatenatedValues = roleId.join(', ');
      AsyncPost(API.SendWhatsAppMsg + "?id=" + Messagedata + "&message=" + Message + "&receiverRoleName=" + selectedOptions +"&formName=" + resdata +"&senderName=" + JSON.parse(localStorage.getItem("user")).facultyName)
        //"&department=" + Messagedata.nameOfTheProgramme + "&currentFileQueueStatus=" + Messagedata.currentFileQueueStatus)
        .then((res) => {                 
          debugger;
          if(res.data="Success")
          {
            alert("Message sent");
          }
          else{
            alert("Failed to sent Message!");
          }
          
          handleClose();
          console.log(res.data, "SendWhatsAppMsg");
        })
        .catch((err) => {
          console.log(err);
          alert("Something went wrong");

        });
    }
  };
  const filterData = (value) => {
    debugger
    if (!value) {
      setfilteredData(data);
      return;
    }
    const dataIs = data.filter((item) => {
      const date = dayjs(item.formDate).format("DD-MM-YYYY");
      return date.includes(`${value}`);
    });
    setfilteredData(dataIs);
    console.log(dataIs, "11111111111111111111");
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data:
        !secondFilterValue && filteredData?.length < 1 ? data : filteredData,
      initialState: { pageIndex: 0 },
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <table
        {...getTableProps()}
        className="table-responsive table table-hover"

      >
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              <DateFilter
                filterValue={(val) => {
                  filterData(val);
                  setsecondFilterValue(val);
                }}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const date = dayjs(row.original.formDate).format(
              "DD-MM-YYYY"
            );
            const dateString = date;
            // console.log(row, "row 777");
            return (
              <tr {...row.getRowProps()}>
                {/*<td>{row.values.id} </td>*/}
                <td>{row.index + 1}</td>
                <td>{`${dateString}`}</td>

                <td>{row.original.departmentName} </td>
                <td>{row.original.principalFlag == 1 ? "Approved! Office Establishment" : row.original.principalFlag == 2 ? "Quotation Submitted" :
                  row.original.principalFlag == 3 ? "Quotation Approved" : row.original.currentFileQueueStatus} </td>
                <td className="d-row">
                  {
                    row.original.currentFileQueueStatus === (JSON.parse(localStorage.getItem("user")).indentRoleName) ||
                      row.original.currentFileQueueStatus === null || Number(row.original.principalFlag) >= 1
                      ?
                      <span
                        onClick={() => {
                          editIndentProp(row.original);
                        }}
                        className="mx-2"
                      >
                        <AiFillEdit color="#FFC107" />
                      </span>

                      : "Pending"}
                  {
                    row.original.isMakerCompleted == false && (((JSON.parse(localStorage.getItem("user")).indentRoleName) == "Maker"
                      && row.original.currentFileQueueStatus == "Maker")
                      || ((JSON.parse(localStorage.getItem("user")).indentRoleName) == "HOD" && row.original.currentFileQueueStatus == "HOD")) ?

                      <span
                        onClick={() => {
                          if (window.confirm('Are you sure you want to delete?')) deleteIndentProp(row.values);
                        }}
                        className="mx-2"
                      >
                        <MdDelete color="#8E2E18" />
                      </span> : null}

                </td>
                {/* {row.original.currentFileQueueStatus!= "Quotation Approved" && row.original.currentFileQueueStatus != (JSON.parse(localStorage.getItem("user")).indentRoleName)? */}
                {(JSON.parse(localStorage.getItem("user")).indentRoleName) != "Maker"

                  /*&& row.original.currentFileQueueStatus != "Quotation Approved"
                        && row.original.currentFileQueueStatus != "Principal") && row.original.currentFileQueueStatus != "Quotation Submitted"*/
                  ?
                  <td>
                    <button

                      className="mx-2" style={{
                        backgroundColor: "#44d144",
                        border: "none",
                        borderRadius: "10px",

                      }}

                    >
                      {/*fas fa-sms () =>{modelopen()}*/}
                      {/*<i class="fab fa-whatsapp" onClick={modelopen} style={{ color: "white", marginTop: "3px", fontSize: "25px" }}></i>*/}

                      <i class="fab fa-whatsapp" onClick={() => modelopen(row.values)} style={{ color: "white", marginTop: "3px", fontSize: "25px" }}></i>


                      {isModalOpen && (
                        <Modal
                          //onClose={() => setModalOpen(false)}
                          show={isModalOpen}
                          onHide={handleClose}
                          backdrop="static"
                          keyboard={false}
                          centered
                        >
                          <Modal.Header closeButton>
                            <Modal.Title style={{ fontWeight: "bold", color: "#22678e" }}>Message</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group className="mgs-body" id="load_add_form">


                                <Form.Control as="textarea"
                                  placeholder="Type message here.."
                                  className="form-control clsabstract"
                                  value={Message}
                                  onChange={message}
                                  rows="5"
                                  maxLength={2000}
                                />
                                {!Message && validate ? (
                                  <h6 style={{ color: "red" }}>{errorMsg}</h6>
                                ) : null}
                              </Form.Group>

                              <Form.Group className="mgs-body mt-4" id="load_add_form">                       
                                <select
                                  className="form-select form-control"
                                  onChange={selectedoptions}
                                  value={selectedOptions}                                 
                                >
                                  <option>Send To</option>
                                  <option value={"Maker"}>Faculty</option>
                                  <option value={"HOD"}>HOD/HOS</option>
                                  <option value={"Office Establishment"}>Office Establishment</option>
                                  <option value={"Office Superintendent"}>Office Superintendent</option>
                                 
                                </select>
                                {!selectedOptions.length && validate ? (
                                  <h6 style={{ color: "red" }}>{errorMsg}</h6>
                                ) : null}
                               </Form.Group>
                             
                            </Form>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button className="btn btn-secondary btn-block form-roundedbtn-popup" onClick={handleClose}>
                              Cancel
                            </Button>

                            <Button className="btn btn-primary btn-block form-roundedbtn" onClick={() => SendWhatsAppMsg("INDENT")} type="submit">Send</Button>
                          </Modal.Footer>
                        </Modal>



                      )}
                    </button>
                  </td>:null
                  /*: (JSON.parse(localStorage.getItem("user")).indentRoleName) != "Principal" ? "" : <td></td>*/
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      <PaginationBlock>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <div>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </div>
        {/* <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "} */}
      </PaginationBlock>
    </>
  );
}

export default TableComp;
