import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import { AsyncGet, AsyncGetFiles, AsyncPost } from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [FromDate, setFromDate] = useState("");
  const [ToDate, setToDate] = useState("");
  //const [Duration, setDuration] = useState("");
  //const [nDays, setnDays] = useState("");
  const [SanctionedAmount, setSanctionedAmount] = useState("");
  const [FDPSponsoredBy, setFDPSponsoredBy] = useState("");
  const [CoOrdinator, setCoOrdinator] = useState("");
  const [CoCoordinator, setCoCoordinator] = useState("");
  const [nParticipants, setnParticipants] = useState("");
  const [ParticipantsType, setParticipantsType] = useState("");
  const [FacultyID, setFacultyID] = useState([]);
  const [Event, setEvent] = useState("");
  const [Venue, setVenue] = useState("");
  const [Impact, setImpact] = useState("");
  const [OrganisedBy, setOrganisedBy] = useState("");
  const [InternalOrExternal, setInternalOrExternal] = useState("Internal");
  const [OffLineOrOnLine, setOffLineOrOnLine] = useState("Offline");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
const[TitleOfTheProgram,setTitleOfTheProgram]=useState("");
const[TrainingAttendedBy,setTrainingAttendedBy]=useState("");
  const [datevalidation, setdatevalidation] = useState(true);


  const runFillActivityData = useRef();


  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);

  const clearState = () => {
    setFromDate("");
    setToDate("");
    setFDPSponsoredBy("");
    setCoOrdinator("");
    setnParticipants("");
    //setStudentID([]);
    setFacultyID([]);
    setParticipantsType("");
    setSanctionedAmount("");
    // setEvent("");
    // setPrize("");
    setVenue("");
    setImpact("");
    setOrganisedBy("");
    //setstudentORFaculty("Student");
    setInternalOrExternal("Internal");
    setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");
    //setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    setTitleOfTheProgram("");
    setTrainingAttendedBy("");
    console.log("reseted");
  };
  const setIntExtvalue = (val) => {
    setInternalOrExternal(val);
  };
  const setOnOFFvalue = (val) => {
    setOffLineOrOnLine(val);
  };
  const fromDatechange = (val) => {
    setFromDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setToDate('');
    setdatevalidation(false);
  };
  const toDatechange = (val) => {
    if (val.target.value != '')
      setFromdatevalidation(true);
    else
      setFromdatevalidation(false);
    setToDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  const onParticipantsTypeSelect = (e) => {
    setParticipantsType(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };
  const onEventSelect = (e) => {
    setEvent(e.target.value);
  };
  const onVenueSelect = (val) => {
    setVenue(val);
  };
  const onFDPSponsoredBySelect = (e) => {
    setFDPSponsoredBy(e.target.value);
  };

  const onFacultyIDChange = (data) => {
    setFacultyID(data);
  };
  const onFacultyIDInputChange = (data) => {
    if (!data) {
      return;
    }
    AsyncGet(API.getFacultyByName + "?facultyname=" + data)
      .then((res) => {

        setfacultyList(res.data);

      })
      .catch(() => setfacultyList([]));
  };
  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(16, null));
      closePopup();
      clearState();
      return;
    }


    console.log(res, "response");
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "FacultyDevelopment"
    );

    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(16, null));
      closePopup();
      clearState();

    } catch (ex) {
      console.log(ex);
    }
  };


  const downloadFileFun = async () => {

    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async () => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 16,
      Event: Event,
      OrganisedBy: OrganisedBy,
      Venue: Venue,
      //Duration: Duration,
      //nDays: nDays,
      // Department:JSON.parse(localStorage.getItem("user")).department,
      FDPSponsoredBy: FDPSponsoredBy,
      SanctionedAmount: SanctionedAmount,
      ParticipantsType: ParticipantsType,
      nParticipants: nParticipants,
      CoOrdinator: CoOrdinator,
      CoCoordinator: CoCoordinator,
      TitleOfTheProgram:TitleOfTheProgram,
      TrainingAttendedBy:TrainingAttendedBy,
      FromDate: FromDate,//new Date(FromDate).toLocaleDateString(),
      ToDate: ToDate,//new Date(ToDate).toLocaleDateString(), 
      Impact: Impact,
      InternalOrExternal: InternalOrExternal,
      OnlineOrOffline: OffLineOrOnLine,
      //Reports:"",
      Attachment: "",
      FacultyID,
    };
    var activityData = {
      id: 0,
      activityID: 16,
      activityName: "Faculty Development",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: 0,//JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }

  };

  const updateActivity = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 16,
      Event: Event,
      OrganisedBy: OrganisedBy,
      Venue: Venue,
      //Duration: Duration,
      //nDays: nDays,
      // Department:JSON.parse(localStorage.getItem("user")).department,
      FDPSponsoredBy: FDPSponsoredBy,
      SanctionedAmount: SanctionedAmount,
      ParticipantsType: ParticipantsType,
      nParticipants: nParticipants,
      CoOrdinator: CoOrdinator,
      CoCoordinator: CoCoordinator,
      TitleOfTheProgram:TitleOfTheProgram,
      TrainingAttendedBy:TrainingAttendedBy,
      FromDate: FromDate,//new Date(FromDate).toLocaleDateString(),
      ToDate: ToDate,//new Date(ToDate).toLocaleDateString(), 
      Impact: Impact,
      InternalOrExternal: InternalOrExternal,
      OnlineOrOffline: OffLineOrOnLine,
      //Reports:"",
      Attachment: "",
      FacultyID,
    };
    const activityData = {
      ...activity,
      activityID: 16,
      activityName: "Faculty Development",
      data: JSON.stringify(data),

    };
    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      FacultyID,
      FromDate &&
      ToDate &&
      // SanctionedAmount &&
      // FDPSponsoredBy &&
      (FacultyID?.length > 0) &&
      //nDays &&
      //Event &&
      Venue &&
      CoOrdinator &&
      ParticipantsType &&
      OrganisedBy 
      //TitleOfTheProgram &&
      //TrainingAttendedBy
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {

      case "facultyname":
        setFacultyID(e.target.value);
        break;
      case "event":
        setEvent(e.target.value);
        break;
      case "impact":
        setImpact(e.target.value);
        break;
      case "organisedby":
        setOrganisedBy(e.target.value);
        break;
      case "venue":
        setVenue(e.target.value);

        // if(/^[a-z A-Z 0-9-]*$/.test(e.target.value) == false){
        //   //error message
        // }     else
        // {
        // setVenue(e.target.value);
        // }
        break;
      case "coordinator":
        setCoOrdinator(e.target.value);

        // if(/^[a-z A-Z]*$/.test(e.target.value) == false){
        //   //error message
        // }     else
        // {
        // setCoOrdinator(e.target.value);
        // }
        break;
      case "cocoordinator":
        setCoCoordinator(e.target.value);

        // if(/^[a-z A-Z]*$/.test(e.target.value) == false){
        //   //error message
        // }     else
        // {
        // setCoCoordinator(e.target.value);
        // }
        break;

        case "titleofprogramme":
          setTitleOfTheProgram(e.target.value);
          break;
          case "trainingattendedby":
            setTrainingAttendedBy(e.target.value);
            break; 
      case "fdpsponsoredby":
        setFDPSponsoredBy(e.target.value);
        break;
      case "noofparticipants":
        var participantNo = e.target.value.replace(/\D/g, "");
        setnParticipants(participantNo);
        break;
      case "sanctionedamount":
        if (/^[0-9.]*$/.test(e.target.value) == false) {
          //error message  
        }
        else {
          setSanctionedAmount(e.target.value);
        }
        break;
      case "nparticipants":
        var participantNo = e.target.value.replace(/\D/g, "");
        setnParticipants(participantNo);
        break;
    }
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    runFillActivityData.current = false;

    const obj = data;
    const objData = obj.data;
    setFromDate(objData.FromDate);
    setToDate(objData.ToDate);
  setTitleOfTheProgram(objData.TitleOfTheProgram);
  setTrainingAttendedBy(objData.TrainingAttendedBy);
    setFacultyID(objData.FacultyID || []);
    setEvent(objData.Event);
    setFDPSponsoredBy(objData.FDPSponsoredBy);
    setCoOrdinator(objData.CoOrdinator);
    setCoCoordinator(objData.CoCoordinator);
    setnParticipants(objData.nParticipants);
    //setnDays(objData.nDays);
    setParticipantsType(objData.ParticipantsType);
    //setDuration(objData.Duration);
    setSanctionedAmount(objData.SanctionedAmount);
    setVenue(objData.Venue);
    setImpact(objData.Impact);
    setOrganisedBy(objData.OrganisedBy);
    setInternalOrExternal(objData.InternalOrExternal || "Internal");
    setOffLineOrOnLine(objData.OnlineOrOffline || "Offline");
    setCreatedBy(obj.createdDate);
    setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">

              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio3"
                    value="4"
                    checked={InternalOrExternal === "Internal"}
                    onChange={() => setIntExtvalue("Internal")}
                  />
                  <label className="form-check-label checkposition">
                    Internal
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio4"
                    value="5"
                    checked={InternalOrExternal === "External"}
                    onChange={() => setIntExtvalue("External")}
                  />
                  <label className="form-check-label checkposition">
                    External
                  </label>
                </div>
              </div>
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio5"
                    value="option3"
                    checked={OffLineOrOnLine === "Offline"}
                    onChange={() => setOnOFFvalue("Offline")}
                  />
                  <label className="form-check-label checkposition">
                    Offline
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio6"
                    value="option3"
                    checked={OffLineOrOnLine === "Online"}
                    onChange={() => setOnOFFvalue("Online")}
                  />
                  <label className="form-check-label checkposition">
                    Online
                  </label>
                </div>
              </div>
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>From Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={fromDatechange}
                  disabled={Fromdatevalidation1}
                />
                {!FromDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>To Date</label>
                <input
                  className="form-control"
                  type="date"
                  disabled={datevalidation}

                  value={dayjs(ToDate).format("YYYY-MM-DD")}

                  min={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={toDatechange}
                />
                {!ToDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select To Date"}</h6>
                ) : null}
              </div>

              <div

                className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt"
              >
                <label><span style={{ color: "red" }}>*</span>Faculty ID</label>
                <SearchBox
                  options={facultyList}
                  onChangeList={(data) => onFacultyIDChange(data)}
                  onInputChange={(e) => onFacultyIDInputChange(e)}
                  selectedOptions={FacultyID || []}

                  type="faculty"
                />
                {!FacultyID?.length > 0 && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
                <label>Impact</label>

                <textarea
                  className="form-control clsabstract"
                  id="impact"

                  value={Impact}
                  onChange={onTextValueChange}
                  rows="5"
                  maxLength={300}
                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Event</label>
                <select
                  className="form-select form-control"
                  onChange={onEventSelect}
                  aria-label="Default select example"
                  value={Event}
                  maxLength={150}
                >
                  <option value="">Select Event</option>
                  <option value="Conference">Conference</option>
                  <option value="Journal Paper">Journal Paper</option>
                  <option value="Proceeding">Proceeding</option>
                  <option value="Seminar">Seminar</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Training">Training</option>
                  <option value="Others">Others</option>
                </select>

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Organised By</label>
                <input
                  className="form-control"
                  type="text"
                  id="organisedby"
                  value={OrganisedBy}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                {!OrganisedBy && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>FDP Sponsored By</label>
                <select
                  className="form-select form-control"
                  onChange={onFDPSponsoredBySelect}
                  aria-label="Default select example"
                  value={FDPSponsoredBy}
                >
                  <option value="">Select FDP Sponsored </option>
                  <option value="AICTE">AICTE</option>
                  <option value="Internal">Internal</option>
                  <option value="State Govt">State Govt</option>
                  <option value="Others">Others</option>
                </select>
                {/* {!FDPSponsoredBy && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null} */}

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Sanctioned Amount</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="sanctionedamount"
                  value={SanctionedAmount}
                  onChange={onTextValueChange}
                  maxLength={10}
                />
                {/* {!SanctionedAmount && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null} */}

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Co-ordinator</label>
                <input
                  className="form-control"
                  type="text"
                  id="coordinator"
                  value={CoOrdinator}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!CoOrdinator && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Co-coordinator</label>
                <input
                  className="form-control"
                  type="text"
                  id="cocoordinator"
                  value={CoCoordinator}
                  onChange={onTextValueChange}
                  maxLength={150}
                />

              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>No of Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="noofparticipants"
                  value={nParticipants}
                  //value={Number(ExternalParticipants) + Number(nParticipantsGirls)+  Number(nParticipantsBoys)}
                  onChange={onTextValueChange}
                  maxLength={4}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Title of The Program</label>
                <input
                  className="form-control"
                  type="text"
                  id="titleofprogramme"
                  value={TitleOfTheProgram}
                  onChange={onTextValueChange}
                  maxLength={150}
                />

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Training Attended By</label>
                <input
                  className="form-control"
                  type="text"
                  id="trainingattendedby"
                  value={TrainingAttendedBy}
                  onChange={onTextValueChange}
                  maxLength={150}
                />

              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Participants Type</label>
                <select
                  className="form-select form-control"
                  onChange={onParticipantsTypeSelect}
                  aria-label="Default select example"
                  value={ParticipantsType}
                >
                  <option value="">Select Participants Type</option>
                  <option value="Internal">Internal</option>
                  <option value="External">External</option>
                  <option value="Internal and External">Internal and External</option>
                  <option value="Industry">Industry</option>
                  <option value="Others">Others</option>
                </select>
                {!ParticipantsType && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}

              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Venue</label>
                <input
                  className="form-control"
                  type="text"
                  id="venue"
                  value={Venue}
                  onChange={onTextValueChange}
                  maxLength={300}
                />
                {!Venue && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>


            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
