import { useState, useEffect, useRef } from "react";
import "./styles.css";
import dayjs from "dayjs";
import Moment from "moment";

import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
  AsyncPostFiles,
} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import RenderIf, { ExportToCsv } from "./utils";
import excel_file from "./blobfile.json";

const AttendancePage = () => {
  const dataTable = useRef();
 

  const [stockdate,setstockdate]=useState("");
  const [store,setStore]=useState("");


  const [loading, setloading] = useState(false);
  //const [subjectName, setsubjectName] = useState("");
  //const [hours, sethours] = useState(0);

  const [attendance, setattendance] = useState([]);
  const [formatAttendance, setformatAttendance] = useState({});
  const [subjectOpts, setsubjectOpts] = useState([]);
  const [showReportOpts, setshowReportOpts] = useState(false);


  const inValidData =
  !stockdate || !store  ;

  const validFormatAttendance = useMemo(() => {
    return Object.keys(formatAttendance).length > 0;
  }, [formatAttendance]);

  const clearState = (type) => {
    switch (type) {
      case "stockdate": {
        setStore("");
        //setstockdate("");
        return;
      }
      case "store": {
        
        return;
      }
      
      default: {
        setstockdate("");
        setStore("");        
        return;
      }
    }
  };

  const _onChange = ({ key, data }) => {
    const functionallity = {
      stockdate: (data) => {
        setstockdate(data);
        clearState("stockdate");
      },
      store: (data) => {
        setStore(data);
        clearState("store");
      },
     
    };
    functionallity[key](data);
  };

  const downloadDatewiseReports = async () => {
    debugger
   var params=(store=="All")?("StockDate=" +
   stockdate):("StockDate=" +stockdate + "&Store=" + store)
    AsyncGetFiles(
      API.generateDatereport + params
        /*"StockDate=" +
        stockdate +
        "&Store=" +
        store */
    )
      .then((res) => {
        console.log(res, "res=======================");
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //setshowReportOpts(false);
        // =============================
      })
      .catch((err) => {
        console.log(err);
        alert("failure");
      });
  };
  const downloadCumulativeReports = async () => {
    debugger
    var params=(store=="All")?("StockDate=" +
    stockdate):("StockDate=" +stockdate + "&Store=" + store)
     
    AsyncGetFiles(
      API.generatecumulativereport +params
      /*"&Store=" +
        store */
    )
      .then((res) => {
        console.log(res, "res=======================");
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //setshowReportOpts(false);
        // =============================
      })
      .catch((err) => {
        console.log(err);
        alert("failure");
      });
  };
  const downloadGenerateReport = async () => {
    debugger

    AsyncGetFiles(
      API.generatereport +
        
        "&Store=" +
        store 
    )
      .then((res) => {
        console.log(res, "res=======================");
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        //setshowReportOpts(false);
        // =============================
      })
      .catch((err) => {
        console.log(err);
        alert("failure");
      });
  };

  /*const updateAllStudentIsPresent = (subjId, status) => {
    let oldArray = attendance;
    for (let i = 0; i < oldArray.length; i++) {
      const obj = oldArray[i];
      if (obj.subjectId === subjId) {
        obj.isPresent = status;
      }
    }
    setattendance([...oldArray]);
  };*/

  const checkCol = (index) => {
    let isChecked = null;
    const values = Object.values(formatAttendance);
    for (let i = 0; i < values.length; i++) {
      const arr = values[i];
      const checked = arr[index].isPresent;
      if (i > 0 && isChecked !== checked) {
        isChecked = false;
        break;
      }
      isChecked = checked;
    }
    return isChecked;   
  };

  
  

  const renderItems = () => {
    return (
      <div
        className="container pb-5 pt-3 px-4 border"
        onClick={() => showReportOpts && setshowReportOpts(false)}
      >
        <p></p>
        <div className="row mb-4">
        <div className="col-lg-3">
              <p className="m-0">Issue Date</p>
              <input
                className="form-control"
                type="date"
                //id="organisedby"
                value={dayjs(stockdate).format("YYYY-MM-DD")}
                max={Moment().format("YYYY-MM-DD")}
                onChange={(e) =>
                  _onChange({ key: "stockdate", data: e.target.value })
                }
              />
            </div>
          <RenderIf isTrue={stockdate}>
            <div className="col-lg-3">
              <p className="m-0">Store</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "store", data: e.target.value })
                }
                value={store}
                maxLength={100}
              >
                <option>Select Store</option>
                <option value="Student Store">Student Store</option>
                  <option value="Office Store">Office Store</option>
                  <option value="All">All</option>
              </select>
            </div>
          </RenderIf>

          
          <RenderIf isTrue={store}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={clearState}
                className="btn btn-secondary w-100"
              >
                Reset
              </button>
            </div>
          </RenderIf>
          <RenderIf isTrue={store}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={() => {
                  
                     setshowReportOpts(!showReportOpts);
                }}
                //onClick={() =>   downloadDatewiseReports()}
                className="btn btn-success w-100"
              >
                Generate Report
              </button>
            </div>
          </RenderIf>
          
        </div>
        {/* -------------report-btns-block----------------- */}
        <RenderIf isTrue={store && showReportOpts}>
          <div className="row report-btns-block mb-1 py-2">
          <div className="col-lg-6 col-md-6 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={() => downloadDatewiseReports()}
                className="btn btn-success w-100"
              >
               Datewise Report
              </button>
            </div> 
             <div className="col-lg-6 col-md-6 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={() => downloadCumulativeReports()}
                className="btn btn-success w-100"
              >
                Cumulative Report
              </button>
            </div> 
          
            
        
              </div>
        </RenderIf>       
      </div>
    );
  };

  return <div>{renderItems()}</div>;
};
export default AttendancePage;