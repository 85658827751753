import { useState, useEffect, useRef } from "react";
import "./styles.css";
import dayjs from "dayjs";
import Moment from "moment";

import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
  AsyncPostFiles,
} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import RenderIf, { ExportToCsv } from "./utils";
import excel_file from "./blobfile.json";
let result = "";
const FeedbackPage = () => {
  let sequence = 0;
  var count = 0;

  const dataTable = useRef();
  const [departmentOpt, setdepartmentOpt] = useState([]);
  const [department, setdepartment] = useState("");
  const [departmentVal, setdepartmentVal] = useState("");
  //const [result ,setresult]=useState();

  //const [selectedFile, setselectedFile] = useState("");

  const [year, setyear] = useState("");
  //const [exam, setExam] = useState("");

  const [semester, setsemester] = useState("");
  const [section, setsection] = useState("");
  const [sectionOpt, setsectionOpt] = useState([]);
  const [subjectName, setsubjectName] = useState("");
  const [subjectOpts, setsubjectOpts] = useState([]);
  const [FacultyName, setFacultyName] = useState("");
  const [FacultyOpts, setFacultyOpts] = useState([]);
  const [IsSubmitted, setIsSubmitted] = useState(true);


  const [attendance, setattendance] = useState([]);
  const [formatAttendance, setformatAttendance] = useState({});

  const [showReportOpts, setshowReportOpts] = useState(false);

  const inValidData =
    !department || !year || !semester || !section;

  const validFormatAttendance = useMemo(() => {
    return Object.keys(formatAttendance).length > 0;
  }, [formatAttendance]);

  const clearState = (type) => {
    switch (type) {
      case "department": {
        setsection("");

        setformatAttendance({});
        setattendance([]);
        setyear("");
        setsemester("");
        setsubjectName("");
        setFacultyName("");
        //setresult ("") ;          
        return;
      }
      case "year": {
        setsection("");
        setformatAttendance({});
        setattendance([]);
        setsemester("");
        setsubjectName("");
        setFacultyName("");
        //setresult("");                
        return;
      }
      case "semester": {
        setsection("");
        setformatAttendance({});
        setattendance([]);
        setsubjectName("");
        setFacultyName("");
        // setresult ("") ;                   
        return;
      }
      case "section": {
        setformatAttendance({});
        setattendance([]);
        setsubjectName("");
        setFacultyName("");
        //setresult ("") ;        
        return;
      }
      case "subjectName": {
        setformatAttendance({});
        setattendance([]);
        setFacultyName("");
        //setresult ("") ; 
        return;
      }

      default: {
        setdepartment("");
        setyear("");
        setsemester("");
        setsection("");
        setdepartmentVal("");
        setformatAttendance({});
        setattendance([]);
        setsubjectName("");
        setFacultyName("");
        //setresult ("") ;  
        return;
      }
    }
  };

  const _onChange = ({ key, data }) => {
    const functionallity = {
      department: (data) => {
        setdepartment(data);
        var dept = departmentOpt.filter(x => x.id == data)[0];
        setdepartmentVal(dept.id + '-' + dept.name);
        clearState("department");
      },

      year: (data) => {
        setyear(data);
        clearState("year");
      },
      semester: (data) => {
        setsemester(data);
        clearState("semester");
      },
      section: (data) => {
        setsection(data);
        clearState("section");
      },
      subjectName: (data) => {
        setsubjectName(data);
        clearState("subjectName");
      },
      FacultyName: (data) => {
        setFacultyName(data);

      },
    };
    functionallity[key](data);
  };

  const getAllDepartmentFun = async (isSubscribed) => {
    try {
      if (!isSubscribed) {
        return;
      }
      const response = await AsyncGet(API.getAllDepartment);
      setdepartmentOpt(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getSectionFun = async (isSubscribed) => {
    try {
      if (isSubscribed && department && year && semester) {
        const body = {
          DepartmentId: Number(department),
          Year: Number(year),
          Sem: semester,
        };
        const response = await AsyncPost(API.getSection, body);
        setsectionOpt(response.data);
        // console.log(response.data, "section options");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSubjects = async (isSubscribed) => {
    try {
      debugger;
      if (isSubscribed) {
        const response = await AsyncGet(
          API.getSubjectsForMarks + "Department=" + Number(department) + "&Year=" + year + "&Sem=" + semester + "&Section=" + section
        );
        setsubjectOpts(response.data);
        // console.log(response.data, "batch options");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFacultys = async (isSubscribed) => {
    debugger;
    try {
      debugger;
      if (isSubscribed) {
        const response = await AsyncGet(
          API.getFacultySubjectforfeedback + "Department=" + Number(department) + "&Year=" + year + "&Sem=" + semester + "&Section=" + section + "&Subject=" + Number(subjectName)
        );
        //  var result=response.data.filter(x=>x.departmentId==Number(department) &&x.section==section&&x.sem==semester&&x.year==year&&x.subjectName==subjectName);
        if (response.data.length == 0) {
          setFacultyOpts([]);
        }
        else {
          setFacultyOpts(JSON.parse((response.data[0].facultyId)));
          //setFacultyOpts(JSON.parse(result));
          console.log(response.data, "batch options");
          // console.log(result, "batch options");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };



  const getQuestionWiseReport = async () => {
    debugger;
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      `${API.getQuestionWiseReport
      }departmentId=${department}&sem=${semester}&section=${section}&year=${year}&subjectId=${subjectName}&facultyId=${FacultyName}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.documentt",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "QuestionWiseAnalysisReport.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const getTeachingWiseReport = async () => {
    debugger;
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      `${API.getTeachingWiseReport
      }departmentId=${department}&sem=${semester}&section=${section}&year=${year}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.documentt",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TeachingWiseReport.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const  getYearWiseFeedbackReport = async () => {
    debugger;
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      `${API.getTeachingWiseReport
      }departmentId=${department}&sem=${semester}&year=${year}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.documentt",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "TeachingWiseReport.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const  getYearWiseSecFeedbackReport = async () => {
    debugger;
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      `${API.getYearWiseSecFeedbackReport
      }sem=${semester}&year=${year}&subjectId=${Number(subjectName)}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.documentt",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BatchwiseReport.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const getSecWiseFeedbackReport = async () => {
    debugger;
    //const attendanceDate = dayjs(date).format("YYYY-MM-DD");
    AsyncGetFiles(
      `${API.getSecWiseFeedbackReport
      }departmentId=${departmentVal}&sem=${semester}&section=${section}&year=${year}&isSubmitted=${IsSubmitted}`
    )
      .then((res) => {
        console.log(res);
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SecWiseFeedbackReport.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
const getEvaluationReport= async () =>{
  debugger;
  AsyncGetFiles(API.downloadEvaluationFiles + "?departmentId=" + department)
 
      .then((res) => {
        debugger;
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
      .catch((err) => {
        console.log(err);
      });
  };


  const formatDataFun = (data) => {
    debugger;
    if (data?.length < 1 || attendance?.length < 1) {
      return;
    }
    const attendanceData = data || attendance;
    const formatedData = attendanceData.reduce((acc, item) => {
      acc[`group_${item.studentId}`] = acc[`group_${item.studentId + ' - ' + item.studentName}`] || [];
      acc[`group_${item.studentId}`].push(item);
      return acc;
    }, {});
    setformatAttendance(formatedData);
  };

  // ------------------get All Department function-------------------------


  useEffect(() => {
    let isSubscribed = true;
    getAllDepartmentFun(isSubscribed);
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    let isSubscribed = true;
    getSectionFun(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester]);

  useEffect(() => {
    let isSubscribed = true;
    getSubjects(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester, section]);
  useEffect(() => {
    let isSubscribed = true;
    getFacultys(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester, section, subjectName]);
  useEffect(() => {
    if (attendance) {
      formatDataFun();
    }
  }, [attendance]);

  const renderItems = () => {
    return (
      <div
        className="container pb-5 pt-3 px-4 border"
        onClick={() => showReportOpts && setshowReportOpts(false)}
      >
        <p></p>
        <div className="row mb-4">
          <div className="col-lg-3">
            <p className="m-0">Department</p>
            <select
              className="form-select form-control"
              onChange={(e) =>
                _onChange({ key: "department", data: e.target.value })
              }
              value={department}
            >
              <option>
                {departmentOpt?.length > 0 ? "Select Department" : "No Options"}
              </option>
              {departmentOpt?.length > 0
                ? departmentOpt.map((opt, idx) => (
                  <option key={idx} value={opt?.id}>
                    {opt.name}
                  </option>
                ))
                : null}
            </select>
          </div>
          <RenderIf isTrue={department}>
            <div className="col-lg-3">
              <p className="m-0">Year</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "year", data: e.target.value })
                }
                value={year}
                maxLength={150}
              >
                <option>Select Year</option>
                <option value={1}>1st Year</option>
                <option value={2}>2nd Year</option>
                <option value={3}>3rd Year</option>
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={year}>
            <div className="col-lg-3">
              <p className="m-0">Semester</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "semester", data: e.target.value })
                }
                value={semester}
                maxLength={150}
              >
                <option>Select Semester</option>
                <option value={"Odd"}>Odd</option>
                <option value={"Even"}>Even</option>
              </select>
            </div>
          </RenderIf>

          <RenderIf isTrue={semester}>
            <div className="col-lg-3">
              <p className="m-0">Section</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "section", data: e.target.value })
                }
                value={section}
                maxLength={150}
              >
                <option>
                  {sectionOpt?.length > 0 ? "Select Section" : "No Options"}
                </option>
                {sectionOpt?.length > 0
                  ? sectionOpt.map((opt, idx) => (
                    <option key={idx} value={opt}>
                      {opt}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={section}>
            <div className="col-lg-3">
              <p className="m-0">Subject</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "subjectName", data: e.target.value })
                }
                value={subjectName}
                maxLength={150}

              >
                <option>
                  {subjectOpts?.length > 0 ? "Select Subject" : "No Options"}
                </option>
                {subjectOpts?.length > 0
                  ? subjectOpts.map((opt, idx) => (
                    <option key={idx} value={opt.id}>
                      {opt.subjectName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={subjectName}>
            <div className="col-lg-3">
              <p className="m-0">Faculty</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "FacultyName", data: e.target.value })
                }
                value={FacultyName}
                maxLength={150}

              >
                <option>
                  {FacultyOpts?.length > 0 ? "Select Faculty" : "No Options"}
                </option>
                {FacultyOpts?.length > 0
                  ? FacultyOpts.map((opt, idx) => (
                    <option key={idx} value={opt.id}>
                      {opt.facultyName}
                    </option>
                  ))
                  : null}
              </select>
            </div>
          </RenderIf>

        </div>
        <div className="row mb-4">
          <RenderIf isTrue={FacultyName}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              
              <button
                type="button"
                onClick={() => {
                  setshowReportOpts(!showReportOpts);
                }}
                className="btn btn-success w-100"
              >
                Generate Report
              </button>
            </div>
          </RenderIf>
          <RenderIf isTrue={department}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              
              <button
                type="button"
                onClick={getEvaluationReport
                }
                className="btn btn-success w-100"
              >
                Student Evaluation Report
              </button>
            </div>
          </RenderIf>
        </div>
        <RenderIf isTrue={showReportOpts}>
          <div className="row report-btns-block mb-1 py-2">
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getQuestionWiseReport}
                className="btn btn-success w-100"
              >
                Question Wise Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getTeachingWiseReport}
                className="btn btn-success w-100"
              >
                Teaching Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getSecWiseFeedbackReport}
                className="btn btn-success w-100"
              >
                Section Wise Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getYearWiseFeedbackReport}
                className="btn btn-success w-100"
              >
                Year Wise Report
              </button>
            </div>
            <div className="col-lg-4 d-flex my-1 flex-column justify-content-center">
              <button
                type="button"
                onClick={getYearWiseSecFeedbackReport}
                className="btn btn-success w-100"
              >
                Batch Wise Report
              </button>
            </div>
          </div>
         
         
        </RenderIf>
      </div>

    );
  };

  return <div>{renderItems()}</div>;
};
export default FeedbackPage;
