import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";

import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  // console.log(activity);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  //state
  const [MOUDate, setMOUDate] = useState("");
  const [MOUStartDate, setMOUStartDate] = useState("");
  const [MOUEndDate, setMOUEndDate] = useState("");
  const [NameOfTheIndustryOrOrganization, setNameOfTheIndustryOrOrganization] = useState("");

  const [DepartmentSemester, setDepartmentSemester] = useState("");
  const [DepartmentDesignation, setDepartmentDesignation] = useState("");
  //const [PeriodOfMOU, setPeriodOfMOU] = useState("");
  
  //const [NatureOfServicesByTheOrganization, setNatureOfServicesByTheOrganization] = useState("");

  const [NatureOfServicesByTheInstitutionOrOrganization, setNatureOfServicesByTheInstitutionOrOrganization] = useState("");
 
  const [InstitutionOrProgramme, setInstitutionOrProgramme] = useState("Institution");
  
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [datevalidation1, setdatevalidation] = useState(true);
  const [moudatevalidation1, setdatevalidation1] = useState(true);
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const clearState = () => {
    setMOUDate("");  
    setMOUStartDate("");
    setMOUEndDate(""); 
    setNameOfTheIndustryOrOrganization("");      
    setDepartmentSemester("");
    setDepartmentDesignation("");
    //setPeriodOfMOU("");   
    //setNatureOfServicesByTheOrganization("");    
    setNatureOfServicesByTheInstitutionOrOrganization("");   
    setInstitutionOrProgramme("Institution");   
    setselectedFile("");
    setCreatedBy("");
    setstudentList([]);
    setfacultyList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setdatevalidation1(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };

  
  const setInsProvalue = (val) => {
    setInstitutionOrProgramme(val);
  };
 
  const mouDatechange = (val) => {
    setMOUDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    //setMOUDate(new Date(val.target.value).toLocaleDateString());
    //setMOUEndDate("");
    setdatevalidation1(false);
  };
  const fromDatechange = (val) => {
    setMOUStartDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    setMOUEndDate("");
    setdatevalidation(false);
  };
  const toDatechange = (val) => {
    if (val.target.value != "") setFromdatevalidation(true);
    else setFromdatevalidation(false);
    setMOUEndDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
 
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

 

  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(
        fetchActivities(
          41,null
          //JSON.parse(localStorage.getItem("user")).departmentId
        )
      );
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "MOUs"
    );
    console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(
        fetchActivities(
          41,null
          //JSON.parse(localStorage.getItem("user")).departmentId
        )
      );
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initiateActivity = async () => {
    debugger;
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 41,
      NameOfTheIndustryOrOrganization: NameOfTheIndustryOrOrganization,   
      //PeriodOfMOU: PeriodOfMOU,
      NatureOfServicesByTheInstitutionOrOrganization: NatureOfServicesByTheInstitutionOrOrganization,
      //NatureOfServicesByTheOrganization: NatureOfServicesByTheOrganization,     
      //Department: 0,
      MOUStartDate: MOUStartDate,//`${new Date(MOUStartDate).getMonth()+1}/${new Date(MOUStartDate).getDate()}/${new Date(MOUStartDate).getFullYear()}`, //new Date(MOUStartDate).toLocaleDateString(),
      MOUEndDate: MOUEndDate,//`${new Date(MOUEndDate).getMonth()+1}/${new Date(MOUEndDate).getDate()}/${new Date(MOUEndDate).getFullYear()}`,//new Date(MOUEndDate).toLocaleDateString(),
      MOUDate:MOUDate,// `${new Date(MOUDate).getMonth()+1}/${new Date(MOUDate).getDate()}/${new Date(MOUDate).getFullYear()}`,//new Date(MOUDate).toLocaleDateString(),        
      InstitutionOrProgramme: InstitutionOrProgramme,      
      Attachment: "",
      
    };
    var activityData = {
      id: 0,
      activityID: 41,
      activityName: "MOUs",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: 0,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateActivity = async () => {
    // debugger;
    // console.log("inside activity", activity);
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 41,
      NameOfTheIndustryOrOrganization: NameOfTheIndustryOrOrganization,      
      //PeriodOfMOU: PeriodOfMOU,
      NatureOfServicesByTheInstitutionOrOrganization: NatureOfServicesByTheInstitutionOrOrganization,
      //NatureOfServicesByTheOrganization: NatureOfServicesByTheOrganization,     
      //Department: 0,
      MOUStartDate: MOUStartDate,//new Date(MOUStartDate).toLocaleDateString(),
      MOUEndDate: MOUEndDate,//new Date(MOUEndDate).toLocaleDateString(),
      MOUDate: MOUDate,//new Date(MOUDate).toLocaleDateString(),
    
      
      InstitutionOrProgramme: InstitutionOrProgramme,
      
      Attachment: "",
     
    };
    const activityData = {
      ...activity,
      activityID: 41,
      activityName: "MOUs",
      data: JSON.stringify(data),
    };

    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      NameOfTheIndustryOrOrganization &&
      MOUDate &&    
      MOUStartDate&&
      MOUEndDate  
      //NatureOfServicesByTheOrganization &&
      //NatureOfServicesByTheInstitutionOrOrganization &&
     // PeriodOfMOU
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "nameoftheIndustry":
        setNameOfTheIndustryOrOrganization(e.target.value);
        break;
     
      case "departmentsemester":
        setDepartmentSemester(e.target.value);
        break;
      case "departmentdesigantion":
        setDepartmentDesignation(e.target.value);
        break;
      // case "periodofmou":
      //   setPeriodOfMOU(e.target.value);
      //   break;
     
      case "natureOfServicesByTheInstitution":
        setNatureOfServicesByTheInstitutionOrOrganization(e.target.value);
        break;
     /* case "natureOfServicesByTheOrganization":
        if (/^[a-z A-Z 0-9-]*$/.test(e.target.value) == false) {
          //error message
        } else {
          setNatureOfServicesByTheOrganization(e.target.value);
        }*/
    }
  };
  // On file select (from the pop up)
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    console.log(AllFiles);
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    debugger;
    runFillActivityData.current = false;
    const obj = data;
    const objData = obj.data;
    // console.log(obj);
    setMOUDate(objData.MOUDate); 
    setMOUStartDate(objData.MOUStartDate);
    setMOUEndDate(objData.MOUEndDate);   
    setNameOfTheIndustryOrOrganization(objData.NameOfTheIndustryOrOrganization);    
    //setPeriodOfMOU(objData.PeriodOfMOU);    
   // setNatureOfServicesByTheOrganization(objData.NatureOfServicesByTheOrganization);   
    setNatureOfServicesByTheInstitutionOrOrganization(objData.NatureOfServicesByTheInstitutionOrOrganization);   
    setInstitutionOrProgramme(objData.InstitutionOrProgramme);    
    setCreatedBy(obj.createdDate);
    //setselectedFile(obj.filePath);
    setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);

      //AsyncGet(API.getActivityById + "?id=" + activity.id)
      //  .then((res) => {
      //  console.log(res, "response");
      //  })
      //catch((err) => {
      //    console.log(err, "error");
      //});
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
              
              <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio3"
                    value="4"
                    checked={InstitutionOrProgramme === "Institution"}
                    onChange={() => setInsProvalue("Institution")}
                  />
                  <label className="form-check-label checkposition">
                  Institution
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn1"
                    id="inlineRadio4"
                    value="5"
                    checked={InstitutionOrProgramme === "Programme"}
                    onChange={() => setInsProvalue("Programme")}
                  />
                  <label className="form-check-label checkposition">
                  Programme
                  </label>
                </div>
              </div>
             
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>MOU Date
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(MOUDate).format("YYYY-MM-DD")}
                  //disabled={Fromdatevalidation1}
                  onChange={mouDatechange}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!MOUDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select The Date"}</h6>
                ) : null}
              </div>
             
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Name of the Industry/Organization
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="nameoftheIndustry"
                  value={NameOfTheIndustryOrOrganization}
                  onChange={onTextValueChange}
                  maxLength={250}
                />
                {!NameOfTheIndustryOrOrganization && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                             
               <label>
               <span style={{ color: "red" }}>*</span>MOU StartDate
                </label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(MOUStartDate).format("YYYY-MM-DD")}
                  disabled={Fromdatevalidation1}
                  onChange={fromDatechange}
                  max={Moment().format("YYYY-MM-DD")}
                />
                {!MOUStartDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}  
                </div>
                <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                <span style={{ color: "red" }}>*</span>MOU EndDate
                </label>
                <input
                  className="form-control"
                  type="date"
                  disabled={datevalidation1}
                  min={dayjs(MOUStartDate).format("YYYY-MM-DD")}
                  value={dayjs(MOUEndDate).format("YYYY-MM-DD")}
                  // max={Moment().format("YYYY-MM-DD")}
                  onChange={toDatechange}
                />
                
                      {!MOUEndDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}  
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label> Nature of Services by the Institution/Organization</label>
                <input
                  className="form-control"
                  type="text"
                  id="natureOfServicesByTheInstitution"
                  value={NatureOfServicesByTheInstitutionOrOrganization}
                  onChange={onTextValueChange}
                  maxLength={250}
                />
               
              </div>
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>
                  <span style={{ color: "red" }}>*</span>Nature of Services by the Organization
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="natureOfServicesByTheOrganization"
                  value={NatureOfServicesByTheOrganization}
                  onChange={onTextValueChange}
                  maxLength={300}
                />
                {!NatureOfServicesByTheOrganization && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
                </div>*/}
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>MoU Documents </label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* &nbsp;*/}
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            {/*<button
              className="btn btn-secondary btn-block "
              type="button"
              onClick={onFileUpload}
            >
              upload
            </button>*/}
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
