import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { PaginationBlock } from "./tableStyles";
import dayjs from "dayjs";

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  // return (
  //   <div className="fixed-table-toolbar">
  //     <div className="float-start search  col-6">
  //       <input
  //         className="form-control"
  //         type="text"
  //         placeholder="Search"
  //         value={value}
  //         onChange={(e) => {
  //           setValue(e.target.value);
  //           onChange(e.target.value);
  //         }}
  //       />
  //     </div>
  //   </div>
  // );
}

export function TableComp({
  columns,
  data,
  editRoleMappingProp,
}) {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    state,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // Render the UI for your table
  return (
    <>
      <table
        {...getTableProps()}
        className="table-responsive table table-hover"
      >
        <thead>
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            // console.log(row, "row 777");
            return (
              <tr {...row.getRowProps()}>
                <td>{row.index+1}</td>
                <td>{row.original.roleName} </td>
                <td>{
                  <span>
                    <input type="checkbox"
                      checked={row.original.paperPresentation}
                      id="paperPresentation" /> <label>Paper Presentation</label><br></br>

                    <input type="checkbox"
                      checked={row.original.projectOrModel}
                      id="projectOrModel" /> <label>Project or Model</label><br></br>

                    <input type="checkbox"
                      checked={row.original.guestLectures}
                      id="guestLectures" /> <label>Guest Lectures</label><br></br>
                     <input type="checkbox"
                      checked={row.original.consultancy}
                      id="consultancy" /> <label>Consultancy</label><br></br>
                    <input type="checkbox"
                      checked={row.original.implantTrainingOrInternship}
                      id="implantTraningOrInternship" /> <label>Inplant Training or Internship</label><br></br>

                    <input type="checkbox"
                      checked={row.original.industrialVisits}
                      id="industrialVisits" /> <label>Industrial Visit</label><br></br>

                    <input type="checkbox"
                      checked={row.original.sportsAndGames}
                      id="sportsAndGames" /> <label>Sports and Games</label><br></br>

                    <input type="checkbox"
                      checked={row.original.ncc}
                      id="ncc" /> <label>NCC</label><br></br>

                    <input type="checkbox"
                      checked={row.original.nss}
                      id="nss" /> <label>NSS</label><br></br>

                    <input type="checkbox"
                      checked={row.original.facultyDevelopment}
                      id="facultyDevelopment" /> <label>Faculty Development</label><br></br>

                    <input type="checkbox"
                      checked={row.original.womenInDevelopment}
                      id="womenInDevelopment" /> <label>Women in Development</label><br></br>

                    <input type="checkbox"
                      checked={row.original.journalOrBookpublication}
                      id="journalOrBookpublication" /> <label>Journal or Book Publication</label><br></br>

                    <input type="checkbox"
                      checked={row.original.patentDetails}
                      id="patentDetails" /> <label>Patent Details</label><br></br>

                    <input type="checkbox"
                      checked={row.original.semesterResult}
                      id="semesterResult" /> <label>Semester Results</label><br></br>

                    <input type="checkbox"
                      checked={row.original.symposiumAndExpo}
                      id="symposiumAndExpo" /> <label>Symposium and Expo</label><br></br>

                    <input type="checkbox"
                      checked={row.original.placement}
                      id="placement" /> <label>Placement</label><br></br>

                    <input type="checkbox"
                      checked={row.original.extensionServices}
                      id="extensionServices" /> <label>Extension Services</label><br></br>

                    <input type="checkbox"
                      checked={row.original.grants}
                      id="grants" /> <label>Grants</label><br></br>                  

                    <input type="checkbox"
                      checked={row.original.alumniEvent}
                      id="alumniEvent" /> <label>Alumni Event</label><br></br>

                    <input type="checkbox"
                      checked={row.original.miscellaneous}
                      id="miscellaneous" /> <label>Miscellaneous</label><br></br>

                      <input type="checkbox"
                      checked={row.original.awards}
                      id="awards" /> <label>Awards</label><br></br>

                      <input type="checkbox"
                      checked={row.original.events}
                      id="events" /> <label>Events</label><br></br>

                     <input type="checkbox"
                      checked={row.original.moUs}
                      id="moUs" /> <label>MOUs</label><br></br>

                      <input type="checkbox"
                      checked={row.original.advertisements}
                      id="advertisements" /> <label>Advertisements</label><br></br>

                       <input type="checkbox"
                      checked={row.original.pressReports}
                      id="pressReports" /> <label>Press Reports</label><br></br>
                      <input type="checkbox"
                      checked={row.original.upcomingEvents}
                      id="pressReports" /> <label>Upcoming Events</label><br></br>
                  </span>
                }</td>
                <td className="d-row">
                  <span
                    onClick={() => {
                      editRoleMappingProp(row.original);
                    }}
                    className="mx-2"
                  >
                    <AiFillEdit color="#FFC107" />
                  </span>
                </td>

                {/* <td>{"Cell"}</td> */}
                {/* {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })} */}
              </tr>
            );
          })}
        </tbody>
      </table>
      <PaginationBlock>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <div>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
        </div>
        {/* <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "} */}
      </PaginationBlock>
    </>
  );
}

export default TableComp;
