import { useState, useEffect, useRef } from "react";
import "./styles.css";
import dayjs from "dayjs";
import Moment from "moment";
import DatePicker from "react-datepicker";

import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
  AsyncPostFiles,
} from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import { Spinner } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useMemo } from "react";
import RenderIf, { ExportToCsv } from "./utils";
import excel_file from "./blobfile.json";

const AttendancePage = () => {
  const dataTable = useRef();
  const [departmentOpt, setdepartmentOpt] = useState([]);
  const [department, setdepartment] = useState("");
  const [year, setyear] = useState("");
  const [semester, setsemester] = useState("");
  const [section, setsection] = useState("");
  const [sectionOpt, setsectionOpt] = useState([]);
  
  //const [date, setdate] = useState(new Date());
  const [loading, setloading] = useState(false);
  //const [subjectName, setsubjectName] = useState("");
  //const [hours, sethours] = useState(0);
  const [AcadamicYearFrom, setAcadamicYearFrom] = useState("");
  const [AcadamicYearTo, setAcadamicYearTo] = useState("");
  const [attendance, setattendance] = useState([]);
  const [formatAttendance, setformatAttendance] = useState({});
  const [subjectOpts, setsubjectOpts] = useState([]);
  const [showReportOpts, setshowReportOpts] = useState(false);
  const [AcadamicYearFromvalidation1, setAcadamicYearFromvalidation] =  useState(false);
  const [monthvalidation, setmonthvalidation] = useState(true);

  const inValidData =
    !department || !year || !semester || !section ;

  const validFormatAttendance = useMemo(() => {
    return Object.keys(formatAttendance).length > 0;
  }, [formatAttendance]);

  const clearState = (type) => {
    switch (type) {
      case "department": {
        setsection("");
        
        setformatAttendance({});
        setattendance([]);
        setyear("");
        setsemester("");
        setAcadamicYearFrom("");
    setAcadamicYearTo("");
       
        return;
      }
      case "year": {
        setsection("");
        
        setformatAttendance({});
        setattendance([]);
        setsemester("");
        setAcadamicYearFrom("");
    setAcadamicYearTo("");
        
        return;
      }
      case "semester": {
        setsection("");
        
        setformatAttendance({});
        setattendance([]);
        setAcadamicYearFrom("");
    setAcadamicYearTo("");
       
        return;
      }
      case "section": {
        
        setformatAttendance({});
        setattendance([]);
        setAcadamicYearFrom("");
       setAcadamicYearTo("");
        
        return;
      }
     
      
      default: {
        setdepartment("");
        setyear("");
        setsemester("");
        setsection("");
        
        setformatAttendance({});
        setattendance([]);
        setAcadamicYearFrom("");
        setAcadamicYearTo("");
        return;
      }
    }
  };
  const acadamicYearFrom = (val) => {
    setAcadamicYearFrom(val.target.value);
    setmonthvalidation(false);
  };
  const acadamicYearTo = (val) => {
    if (val.target.value != "") setAcadamicYearFromvalidation(true);
    else setAcadamicYearFromvalidation(false);
    setAcadamicYearTo(val.target.value);
    //setAcadamicYearTo(val.target.value);
  };
  const _onChange = ({ key, data }) => {
    const functionallity = {
      department: (data) => {
        setdepartment(data);
        clearState("department");
      },
      year: (data) => {
        setyear(data);
        clearState("year");
      },
      semester: (data) => {
        setsemester(data);
        clearState("semester");
      },
      section: (data) => {
        setsection(data);
        clearState("section");
      },
    
    };
    functionallity[key](data);
  };

  const getAllDepartmentFun = async (isSubscribed) => {
    try {
      if (!isSubscribed) {
        return;
      }
      const response = await AsyncGet(API.getAllDepartment);
      setdepartmentOpt(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getSectionFun = async (isSubscribed) => {
    try {
      if (isSubscribed && department && year && semester) {
        const body = {
          DepartmentId: Number(department),
          Year: Number(year),
          Sem: semester,
        };
        const response = await AsyncPost(API.getSection, body);
        setsectionOpt(response.data);
        // console.log(response.data, "section options");
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const downloadSemAttandanceReport = async () => {
    debugger

    AsyncGetFiles(
      `${API.generateAttendanceSemwisereport}Sem=${semester}&Year=${year}&Department=${department}&Section=${section}&AcademicFrom=${AcadamicYearFrom}&AcademicTo=${AcadamicYearTo}`
     
    )
      .then((res) => {
        console.log(res, "res=======================");
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setshowReportOpts(false);
        // =============================
      })
      .catch((err) => {
        console.log(err);
        alert("failure");
      });
  };

  const updateAllStudentIsPresent = (subjId, status) => {
    let oldArray = attendance;
    for (let i = 0; i < oldArray.length; i++) {
      const obj = oldArray[i];
      if (obj.subjectId === subjId) {
        obj.isPresent = status;
      }
    }
    setattendance([...oldArray]);
  };

  const checkCol = (index) => {
    let isChecked = null;
    const values = Object.values(formatAttendance);
    for (let i = 0; i < values.length; i++) {
      const arr = values[i];
      const checked = arr[index].isPresent;
      if (i > 0 && isChecked !== checked) {
        isChecked = false;
        break;
      }
      isChecked = checked;
    }
    return isChecked;
      };

  const formatDataFun = (data) => {
    if (data?.length < 1 || attendance?.length < 1) {
      return;
    }
    const attendanceData = data || attendance;
    const formatedData = attendanceData.reduce((acc, item) => {
      acc[`group_${item.studentId}`] = acc[`group_${item.studentId}`] || [];
      acc[`group_${item.studentId}`].push(item);
      return acc;
    }, {});
    setformatAttendance(formatedData);
  };

  

  useEffect(() => {
    let isSubscribed = true;
    getAllDepartmentFun(isSubscribed);
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    let isSubscribed = true;
    getSectionFun(isSubscribed);
    return () => (isSubscribed = false);
  }, [department, year, semester]);
  
  useEffect(() => {
    if (attendance) {
      formatDataFun();
    }
  }, [attendance]);

  const renderItems = () => {
    return (
      <div
        className="container pb-5 pt-3 px-4 border"
        onClick={() => showReportOpts && setshowReportOpts(false)}
      >
        <p></p>
        <div className="row mb-4">
          <div className="col-lg-3">
            <p className="m-0">Department</p>
            <select
              className="form-select form-control"
              onChange={(e) =>
                _onChange({ key: "department", data: e.target.value })
              }
              value={department}
            >
              <option>
                {departmentOpt?.length > 0 ? "Select Department" : "No Options"}
              </option>
              {departmentOpt?.length > 0
                ? departmentOpt.map((opt, idx) => (
                    <option key={idx} value={opt?.id}>
                      {opt.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <RenderIf isTrue={department}>
            <div className="col-lg-3">
              <p className="m-0">Year</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "year", data: e.target.value })
                }
                value={year}
                maxLength={150}
              >
                <option>Select Year</option>
                <option value={1}>1st Year</option>
                <option value={2}>2nd Year</option>
                <option value={3}>3rd Year</option>
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={year}>
            <div className="col-lg-3">
              <p className="m-0">Semester</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "semester", data: e.target.value })
                }
                value={semester}
                maxLength={150}
              >
                <option>Select Semester</option>
                <option value={"Odd"}>Odd</option>
                <option value={"Even"}>Even</option>
              </select>
            </div>
          </RenderIf>

          <RenderIf isTrue={semester}>
            <div className="col-lg-3">
              <p className="m-0">Section</p>
              <select
                className="form-select form-control"
                onChange={(e) =>
                  _onChange({ key: "section", data: e.target.value })
                }
                value={section}
                maxLength={150}
              >
                <option>
                  {sectionOpt?.length > 0 ? "Select Section" : "No Options"}
                </option>
                {sectionOpt?.length > 0
                  ? sectionOpt.map((opt, idx) => (
                      <option key={idx} value={opt}>
                        {opt}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </RenderIf>
          <RenderIf isTrue={semester}>
            <div className="col-lg-3">
            <label>
                  <span style={{ color: "red" }}>*</span> From AcademicYear
                </label>
                <DatePicker
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                  maxDate={new Date()}
                  //disabled={AcadamicYearFromvalidation1}
                  selected={
                    AcadamicYearFrom ? new Date(`${AcadamicYearFrom}/01/01`) : new Date()
                  }
                  onChange={(date) => {
                    const dateIs = {
                      target: { value: dayjs(date).format("YYYY") },
                    };
                    acadamicYearFrom(dateIs);
                  }}
                />
              </div>
              </RenderIf>
              <RenderIf isTrue={AcadamicYearFrom}>
            <div className="col-lg-3">
            
            <label>
                  {" "}
                  <span style={{ color: "red" }}>*</span>To AcademicYear
                </label>
               <DatePicker
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                  disabled={monthvalidation}
                  minDate={new Date(AcadamicYearFrom)}
                  selected={
                    AcadamicYearTo ? new Date(`${AcadamicYearTo}/01/01`) : new Date()
                  }
                  onChange={(date) => {
                    const dateIs = {
                      target: { value: dayjs(date).format("YYYY") },
                    };
                    acadamicYearTo(dateIs);
                  }}
                />              
              </div>
              </RenderIf>
    
            </div>
            <div className="row mb-4">
          <RenderIf isTrue={AcadamicYearTo}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={clearState}
                className="btn btn-secondary w-100"
              >
                Reset
              </button>
              </div>
          </RenderIf>
          <RenderIf isTrue={AcadamicYearTo}>
            <div className="col-lg-3">
              <p className="m-0">&nbsp;</p>
              <button
                type="button"
                onClick={() =>   downloadSemAttandanceReport()}
                className="btn btn-success w-100"
              >
                Generate Report
              </button>
            </div>
          </RenderIf>
         </div>
       </div>
    );
  };

  return <div>{renderItems()}</div>;
};
export default AttendancePage;
