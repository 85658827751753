import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from "@ag-grid-community/all-modules"
import "./styles.css";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  addBpeDetails,
  fetchbpes,
  UpdateBpeDetails,
} from "../../../app/redux/slices/bpeSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
import { enUS, GridAutoSizer } from "@mui/x-data-grid";
import { DragSourceType, GridApi, ValueFormatterParams } from "ag-grid-community";

import { SetLeftFeature } from "@ag-grid-community/core";
import NumericCellEditor from "./numericCellEditor";
import { style } from "@mui/system";
let sequence = 0, flag = 0;
let makerOrHodFlag = 0;
let makerDate; let checker1Date;let checker2Date;let checker3Date;let checker4Date;

let isMakerCompleted = false;
let isChecker1Completed = false, isChecker2Completed = false, isChecker3Completed = false, isChecker4Completed = false;
let maker = "", checker1 = "", checker2 = "", checker3 = "", checker4 = "";
let makerStatus = "", checker1Status = "", checker2Status = "", checker3Status = "", checker4Status = "";
let currentFileQueueStatus = "Maker";
//var date = new Date().getDate();
const AddBpeDetails = ({ isEdit, item, closePopup, bpe ,Department,Faculty}) => {
  const dispatch = useDispatch();
  const [refNo, setrefNo] = useState();
  const [Note, setNote] = useState("");
  const [FormFile, setFormFile] = useState("");
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [FormDate, setformDate] = useState("");
  const [RelievingOrderIssuedon, setRelievingOrderIssuedon] = useState("");
  const [ReleivingOrderNo, setReleivingOrderNo] = useState("");
  const [datevalidation, setdatevalidation] = useState(true);
  const [PostApproValid, setPostApproValid] = useState(false);
  const [FieldValid, setFieldValid] = useState(false);
  const [NoteValid, setNoteValid] = useState(false);
  const [ExamSession, setExamSession] = useState("");
  const [DOTEReference, setDOTEReference] = useState("");
  //const [AppointedOthers, setAppointedOthers] = useState("");
  //const [LrNo, setLrNo] = useState("");

  //const [NameOfThePractical, setNameOfThePractical] = useState("");
  //const [AppointedAs, setAppointedAs] = useState("");

  const [validation, setvalidation] = useState(false);
  const [DisableValid, setDisableValid] = useState(true);

  const [selectedFile, setselectedFile] = useState("");
  /*no aggrid
    const [NameOfFaculty, setNameOfFaculty] = useState("");
    const [ExamCentre, setExamCentre] = useState("");
    const [DesignationId, setDesignationId] = useState("");
    const [DepartmentId, setDepartmentId] = useState();
    const [deparmentList, setDeparmentList] = useState([]);
    const [DesignationList, setDesignationList] = useState([]);*/
  const [ExaminersInfo, setExaminersInfo] = useState("");
  // const [CreatedBy, setCreatedBy] = useState("");

  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [ProgrammeName, setProgrammeName] = useState();
  const [ProgrammeNameAided, setProgrammeNameAided] = useState("");
  const [ProgrammeNamess, setProgrammeNamess] = useState("");

  const [deparmentList, setDeparmentList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [sNo, setSNo] = useState("");
  const [nameofFaculty, setnameofFaculty] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [collegeCodeAndName, setCollegeCodeAndName] = useState("");
  const [designation, setDesignation] = useState("");
  const [doteStaffId, setDoteStaffId] = useState("");
  const [collegeAddress, setCollegeAddress] = useState("");
  const[dutyAs,setDutyAs]= useState("");
  const [ExamStartDate, setExamStartDate] = useState();
  const [ExamEndDate, setExamEndDate] = useState();
  const [NoOfDays, setNoOfDays] = useState();
  const [HeadList, setHeadList] = useState([]);
  const [collegeType, setcollegeType] = useState("");
 // const [DutyAs, setDutyAs] = useState("");
  const runFillBpeData = useRef();

  let cars = [...new Array()].map(() => createOneCarReport());

  const [sizeValidate, setsizeValidate] = useState("");

  const clearState = () => {
    setrefNo("");
    setNote("");
    setformDate("");
    setFormFile("");
    setRelievingOrderIssuedon("");
    setReleivingOrderNo("");
    setselectedFile("");
    setDOTEReference("");
    //setAppointedOthers("");
    /* no aggrid
    setDeparmentList([]);
    setDesignationList([]);
    setNameOfFaculty("");
    setDepartmentId("");
    setDesignationId("");
    setExamCentre("");*/

    //setDutyAs("");
    setProgrammeNameAided("");
    setProgrammeNamess("");
    setDeparmentList([]);
    setProgrammeName("");
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setcollegeType("");
    //makerOrHodFlag = 0;
    setFieldValid(false);
    setExamStartDate("");
    setExamEndDate("");
    setNoOfDays();
    setHeadList([]);
    setNoteValid(false);
    setSNo("");
    setnameofFaculty("");
    setDesignation("");
    setDoteStaffId("");
    setDepartmentName("");
    setCollegeAddress("");
    setCollegeCodeAndName("");
    setDutyAs("");
    //setRegistrationFees("");
    //setTotalBudget("");
    setPostApproValid(false);
    setvalidation(false);
    setDisableValid(true);
    setExaminersInfo("");
    isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false;
    isChecker3Completed = false;
    isChecker4Completed = false;
    maker = ""; checker1 = ""; checker2 = ""; checker3 = ""; checker4 = "";
    makerStatus = ""; checker1Status = ""; checker2Status = ""; checker3Status = ""; checker4Status = "";
    currentFileQueueStatus = "Maker";
    flag = 0;
    sequence = 0; //makerOrHodFlag = 0;
    console.log("reseted");
  };
  const gridRef = useRef();
  var curFormatter = Intl.NumberFormat('en-US', {

    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  });

 const duty =["External Examiner","Internal Examiner","Others"];
  const [rowData, setRowData] = useState(cars);
  const [columnDefs, setColumnDefs] = useState([

    { headerName: 'S.No', field: 'sNo', width: 90, editable: false },
    { headerName: '*Name of Faculty', field: 'nameofFaculty', cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Faculty},width: 300 },
    { headerName: 'DOTE Staff Id', field: 'doteStaffId', width: 220 },
    { headerName: 'Designation', field: 'designation' },
    { headerName: 'Department', field: 'departmentName' ,cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Department},},    
    { headerName: 'Duty As', field: 'dutyAs', cellEditor: 'agSelectCellEditor', cellEditorParams: { values: duty } },
    { headerName: 'College Code & Name',width:250, field: 'collegeCodeandName' },
    { headerName: 'College Address',width:280, field: 'collegeAddress' }
  ])

  const defaultColDef = useMemo(() => ({
    sortable: true,
    width: 150,
    autoHeaderHeight: true,
    rowSelection: 'multiple',
    domLayout: 'autoHeight',
    wrapText: true,
    autoHeight: true,
    //singleClickEdit:true,
    //lockPinned: true,
    //cellClass: 'locked-col',
    lockPosition: 'left',
    //filter:true,
    resizeable: true,
    //suppressSizeToFit: true,
    editable: true,

  }), []);

  const gridOptions = {
    domLayout: 'autoHeight'
  }

  const OnInsertOne = useCallback(() => {
    debugger;
    let i = rowData.length - 1;
    //let newrecord;

    if ((i == -1) || (rowData[i].nameofFaculty != "")) {
      const newrecord = createOneCarReport();
      cars = [newrecord, ...cars];
      setRowData(rowData.slice(0).concat(cars));
    }


  });

  function createOneCarReport() {
    debugger;
    sequence = rowData.length + 1;

    const res = {
      sNo: sequence,
      nameofFaculty: "",
      doteStaffId:"",      
      designation: "",
      departmentName: "",
      dutyAs:"",
      collegeCodeandName: "",
      collegeAddress: ""  

    };

    return res;
  }

  const onRemove = useCallback(() => {

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map(node => node.data.sNo);
    cars = rowData;
    cars = cars.filter(car => selectedIds.indexOf(car.sNo) < 0);
    console.log(cars);
    for (let i = 0; i < cars.length; i++) {
      cars[i].sNo = i + 1;
    }
    setRowData(cars);
  });
  /*useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getRoles)
      .then((res) => {
        console.log(res.data, "getRole");
        setDesignationList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);*/

 

  if (!isMakerCompleted) {
    makerOrHodFlag = (JSON.parse(localStorage.getItem("user")).fdpRoleName) == "Maker" ? 1 : 2;
    console.log("makerOrHodFlag...." + makerOrHodFlag);
  }
  const examsession = (val) => {
    setExamSession(val.target.value);

  };

  /*const lrno = (val) => {
    setLrNo(val.target.value);

  };*/
  const formfile = (val) => {
    setFormFile(val.target.value);

  };

 /* const particalname = (val) => {
    setNameOfThePractical(val.target.value);

  };*/
 /* const appointedas = (val) => {
    setAppointedAs(val.target.value);

  };
  const appointedothers = (val) => {
    setAppointedOthers(val.target.value);

  };*/

  /* no aggrid
   const fltyname = (val) => {
 
     setNameOfFaculty(val.target.value);
 
   };
   const examcentre = (val) => {
     setExamCentre(val.target.value);
   
   };
   const designation = (val) => {
     setDesignationId(val.target.value);
   
   };
 
   const dept = (val) => {
     setDepartmentId(val.target.value);
   };
 */

  const setnote = (val) => {
    setNote(val.target.value);
  }
  const dept = (val) => {
    if(collegeType=="Aided"){

    setProgrammeNameAided(val.target.value);
    setProgrammeNamess("");
    setProgrammeName(val.target.value);
    }
    else if(collegeType=="SS")
    {
      setProgrammeNamess(val.target.value);
      
      setProgrammeNameAided("");
      setProgrammeName(val.target.value);
    }
  };

  const noOfDays = (val) => {
    if (/^[0-9.]*$/.test(val.target.value) === false) {
    } else {
      setNoOfDays(val.target.value);
    }
  };

  /*const dutyas = (val) => {
    setDutyAs(val.target.value);
  };*/
  const setcollege = (val) => {
    setcollegeType(val);
  }
  const formdate = (val) => {
    debugger;
    setformDate(val.target.value);
    //setRelievingOrderIssuedon(val.target.value);
  };
  const relievingOrderIssud = (val) => {
    debugger;
    setRelievingOrderIssuedon(val.target.value);
  };
  const relievingOrderno = (val) => {
    debugger;
    setReleivingOrderNo(val.target.value);
  };

  const dotereference= (val) => {
    debugger;
    setDOTEReference(val.target.value);
  };

  const startdateChange = (date) => {
    debugger;
    //setStartDate(val.target.value);
    setExamStartDate(new Date(date));
    //setStartDate(Moment(new Date(date)).format());
    //console.log(new Date(datetime));

  }
  const enddateChange = (date) => {
    debugger;
    //setEndDate(val.target.value);
    setExamEndDate(new Date(date));
    //setEndDate(Moment(new Date(date)).format());
    console.log(new Date(date));
  }



  const submitBpeDetails1 = async () => {
    debugger;
    if (makerOrHodFlag == 2) {
      isChecker1Completed = true;
      checker1Status = "Submitted";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      //currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveBpeDetails();
    }
    else {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveBpeDetails();
    }
  };

  console.log(isMakerCompleted + "MAker value......");
  const submitBpeDetails2 = async () => {
    debugger;
    flag = makerOrHodFlag == 2 ? 2 : 1;
    updateBpeDetails();
  };
  const rejectBpeDetails = async () => {
    if (currentFileQueueStatus == "HOD") {
      checker1Status = "Rejected";
    }
    else if (currentFileQueueStatus == "Office Establishment") {
      checker2Status = "Rejected";
    } else if (currentFileQueueStatus == "Office Superintendent") {
      checker3Status = "Rejected";
    } else if (currentFileQueueStatus == "Principal") {
      checker4Status = "Rejected";
    }
    // else if (currentFileQueueStatus == "Checker3") {
    //   checker3Status = "Rejected";
    // }
    isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false;
    isChecker3Completed = false;
    isChecker4Completed = false;
    currentFileQueueStatus = makerOrHodFlag == 2 ? "HOD" : "Maker";
    checker1="";
    checker2="";
    checker3 = "";
    checker4 = "";
    updateBpeDetails();
  }

  useEffect(() => {
    if (!bpe) {
      return;
    }
    runFillBpeData.current = true;
    debugger;
    if (runFillBpeData.current) {
      // if (JSON.parse(localStorage.getItem("user")).fdpRoleName != "Maker") {
      //   setvalidation(true);
      // }

      fillBpeData(bpe);
    }
  }, []);


  const DownloadForm = () => {
    AsyncGetFiles(API.downloadBpeForm + "?id=" + refNo)
      .then((res) => {
        console.log(res);
        debugger;
        const url = window.URL.createObjectURL(
          new Blob([res.data], {

            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "BPEForm.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        // alert("");
      });
  };

  const validationFun = () => {
    setvalidate(true);
    if (

      NoOfDays &&
      rowData.length &&
      DOTEReference &&
      ProgrammeName &&
      //ExamCentre &&
     // NameOfThePractical &&
     // AppointedAs &&
      //DepartmentId &&
      ExamStartDate &&
      ExamEndDate &&
      //DesignationId &&
      //LrNo &&
      //AppointedAs &&
      FormDate
    ) {
      setvalidate(false);

      return false;
    } else {
      seterrorMsg("Provide all required fields");
      return true;
    }
  };
  const onFileUpload = async (res) => {
    debugger;
    if (selectedFile?.length < 1) {
      dispatch(fetchbpes());
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const BpeData = res ? res[0] : bpe;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("TypeofUser", "BPE");
    formData.append("id", Number(BpeData?.refNo || 0));

    try {
      //debugger;
      const res = await AsyncPost(API.uploadFacultyfiles, formData);
      dispatch(fetchbpes());
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    debugger;

    AsyncGetFiles(API.downloadBpeFiles + "?id=" + bpe.refNo)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const saveBpeDetails = async () => {
    debugger;
    seterrorMsg("");
    //makerOrHodFlag = (JSON.parse(localStorage.getItem("user")).fdpRoleName) == "Maker" ? 1 : 2;
    if (makerOrHodFlag == 2 && isMakerCompleted == false) {
      currentFileQueueStatus = "HOD";
    }
    const error = validationFun();
    if (error) {
      return;
    }

    var BpeData = {

      //Id: 0,
      refNo: refNo,
      FormDate: (FormDate),
      ProgrammeName: Number(ProgrammeName),
      RelievingOrderIssuedon: (FormDate),
      DOTEReference:DOTEReference,
      ReleivingOrderNo: ReleivingOrderNo,
      makerOrHodFlag: makerOrHodFlag,
      ExamSession: ExamSession,
      //LrNo: LrNo,
      //NameOfThePractical: NameOfThePractical,
      //AppointedAs: AppointedAs,
      //AppointedOthers:AppointedOthers,
      ExamStartDate: (ExamStartDate),
      ExamEndDate: (ExamEndDate),
      Note: Note,
      Photo: "",
      NoOfDays: Number(NoOfDays),
      collegeType: collegeType,
      //DutyAs: DutyAs,
      isMakerCompleted: isMakerCompleted,
      makerStatus: makerStatus,
      isChecker1Completed: isChecker1Completed,
      currentFileQueueStatus: currentFileQueueStatus,
      checker1: checker1,
      ExaminersInfo: JSON.stringify(rowData),
      maker: maker,
      /*NameOfFaculty: NameOfFaculty,
        ExamCentre:ExamCentre,
         DesignationId:Number(DesignationId),
          DepartmentId: Number(DepartmentId),*/


      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };
    try {
      console.log("BpeData", BpeData);
      const resp = await addBpeDetails(BpeData, dispatch);
      //debugger;
      closePopup();
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }


  };

  const updateBpeDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }

    if (currentFileQueueStatus == "Principal" && isChecker3Completed == true) {
      isChecker4Completed = true;
      checker4Status = "Approved";
      currentFileQueueStatus = "Approved";
      checker4 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    if (currentFileQueueStatus == "Office Superintendent" && isChecker2Completed == true) {
      isChecker3Completed = true;
      checker3Status = "Approved";
      currentFileQueueStatus = "Principal";
      checker3 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    if (currentFileQueueStatus == "Office Establishment" && isChecker1Completed == true) {
      isChecker2Completed = true;
      checker2Status = "Approved";
      currentFileQueueStatus = "Office Superintendent";
      checker2 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "HOD" && isMakerCompleted == true) {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "HOD" && flag == 2) {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "Maker" && flag == 1) {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      checker1Status = "";
      checker2Status = "";
      checker3Status = "";
      checker4Status = "";

    }

    const BpeData = {
      refNo: refNo,
      FormDate: (FormDate),
      ProgrammeName: Number(ProgrammeName),
      DOTEReference:DOTEReference,
      RelievingOrderIssuedon: (RelievingOrderIssuedon),
      ReleivingOrderNo: ReleivingOrderNo,     
      ExamStartDate: (ExamStartDate),
      ExamEndDate: (ExamEndDate),
      ExamSession: ExamSession,      
      //: LrNo,
      
      /* no ag grid
     ExamCentre: ExamCentre,
      DesignationId: Number(DesignationId),
      DepartmentId: Number(DepartmentId),
      NameOfFaculty: NameOfFaculty,
      */
     // NameOfThePractical: NameOfThePractical,
      //AppointedAs: AppointedAs,
      //AppointedOthers:AppointedOthers,
      Note: Note,
      Photo: "",
      //DutyAs: DutyAs,
      NoOfDays: Number(NoOfDays),    
      isMakerCompleted: isMakerCompleted,     
      isChecker1Completed: isChecker1Completed,
      isChecker2Completed: isChecker2Completed,
      currentFileQueueStatus: currentFileQueueStatus,
      collegeType: collegeType,
      makerStatus: makerStatus,
      makerOrHodFlag: makerOrHodFlag,
      checker1Status: checker1Status,
      checker2Status: checker2Status,
      checker3Status: checker3Status,
      checker4Status: checker4Status,
      isChecker3Completed: isChecker3Completed,
      isChecker4Completed: isChecker4Completed,
      checker1: checker1,
      checker2: checker2,
      checker3: checker3,
      checker4: checker4,     
      ExaminersInfo: JSON.stringify(rowData),
      maker: maker,      
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };


    try {
      // dispatch(UpdateFacultyDetails(indentData));
      dispatch(UpdateBpeDetails(BpeData));
      await onFileUpload();
      closePopup();
      clearState();

    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
        console.log(deparmentList.map(x=>x.name));
      })
      .catch((err) => {
        console.log(err);
      }); 
  }, []);
  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const fillBpeData = (obj) => {
    debugger;
    runFillBpeData.current = false;
    // console.log(obj);
    setrefNo(obj.data.refNo);
    //setMaker(obj.data.isMakerCompleted);
    maker = obj.data.maker;
    checker1 = (obj.data.checker1);
    checker2 = (obj.data.checker2);
    checker3 = (obj.data.checker3);
    checker4 = (obj.data.checker4);

    makerStatus = (obj.data.makerStatus);
    checker1Status = obj.data.checker1Status;
    checker2Status = obj.data.checker2Status;
    checker3Status = obj.data.checker3Status;
    checker4Status = obj.data.checker4Status;

    isMakerCompleted = (obj.data.isMakerCompleted);
    isChecker1Completed = obj.data.isChecker1Completed;
    isChecker2Completed = obj.data.isChecker2Completed;
    isChecker3Completed = obj.data.isChecker3Completed;
    isChecker4Completed = obj.data.isChecker4Completed;
    makerOrHodFlag = (obj.data.makerOrHodFlag);

    makerDate=obj.data.makerDate;
    checker1Date=obj.data.checker1Date;
    checker2Date=obj.data.checker2Date;
    checker3Date=obj.data.checker3Date;
    checker4Date=obj.data.checker4Date;

    currentFileQueueStatus = (obj.data.currentFileQueueStatus);
    setformDate(obj.data.formDate);
    setRelievingOrderIssuedon(obj.data.relievingOrderIssuedon);
    setReleivingOrderNo(obj.data.releivingOrderNo);
    setExamSession(obj.data.examSession);
   
   if(obj.data.collegeType=="Aided")
    {
      
    setProgrammeName(obj.data.programmeName);
    setProgrammeNameAided(obj.data.programmeName);
    setProgrammeNamess("");
    }
    else if(obj.data.collegeType=="SS"){
      setProgrammeName(obj.data.programmeName);
    setProgrammeNamess(obj.data.programmeName);
    setProgrammeNameAided("");
    }
    setNote(obj.data.note);
    setDOTEReference(obj.data.doteReference);
    setfilesList(obj.files);
    setNoOfDays(obj.data.noOfDays);
    setExamStartDate(new Date(obj.data.examStartDate));
    setExamEndDate(new Date(obj.data.examEndDate));
    setcollegeType(obj.data.collegeType);
    //setDutyAs(obj.data.dutyAs);
    setSNo(obj.data.sNo);
    setnameofFaculty(obj.data.nameofFaculty);
    setDepartmentName(obj.data.departmentName);
    setDesignation(obj.data.designation);
    setDoteStaffId(obj.data.doteStaffId);
    setCollegeCodeAndName(obj.data.collegeCodeAndName);
    setCollegeAddress(obj.data.collegeAddress);
    setDutyAs(obj.data.dutyAs);
    //setprogrammeDetails(obj.data.programmeDetails);
    setRowData(JSON.parse(obj.data.examinersInfo));    
    setPostApproValid(((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Establishment") &&
      (obj.data.currentFileQueueStatus == "Approved")) ? true : false);
    setDisableValid((obj.data.currentFileQueueStatus == "Approved") ? false : true);
    setvalidation((obj.data.currentFileQueueStatus == "Maker") ? false : true);
    setFieldValid((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Superintendent") || (JSON.parse(localStorage.getItem("user")).fdpRoleName == "Principal")
      || (obj.data.currentFileQueueStatus == "Approved") ? true : false);
    setNoteValid((obj.data.currentFileQueueStatus == "Approved") && (JSON.parse(localStorage.getItem("user")).fdpRoleName != "Office Establishment") ? true : false);
    //&&(JSON.parse(localStorage.getItem("user")).fdpRoleName != "Office Establishment")
    seterrorMsg("");
    console.log(rowData);

    runFillBpeData.current = true;
  };

  return (
    <div className="popup">

      <div className="col">
        <div className="formdata-check">
          <button
            className="col-xs-1 col-md-1 col-lg-1 col-sm-1 btn btn-lg btnclose-x"
            onClick={() => closePopupFun()}
            type="button"
          >
            X
          </button>
          <div className="row">

            <h2 className="col heading">{isEdit ? "Edit BPE Form" : "BPE Form"}</h2>
            {((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Establishment")
              || (JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Superintendent")) && currentFileQueueStatus === "Approved" ?
              <div className="but col text-end">
                <button className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                  type="button" onClick={() => DownloadForm()}>
  <span className="glyphicon glyphicon-remove"></span> Download Form</button>
              </div> : null}


          </div>


          <div className="d-flex justify-content-center align-items-enter">
            {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
          </div>
          <div className="row">

            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn"
                  id="inlineRadio"
                  value="5"
                  checked={collegeType === "Aided"}
                  onChange={() => {
                    setcollegeType("Aided");
                    setProgrammeName(ProgrammeNameAided);
                    //setLabListUpdated(LabList.filter(x => x.collegeType == "Aided"));


                    // setHeadListUpdated(HeadList.filter(x =>x.collegeType == "Aided"));
                  }}

                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn"
                  id="inlineRadio"
                  value="6"
                  checked={collegeType === "SS"}
                  onChange={() => {
                    setcollegeType("SS");
                    setProgrammeName(ProgrammeNamess);
                    //setLabListUpdated(LabList.filter(x => x.collegeType == "SS"));


                    //setHeadListUpdated(HeadList.filter(x =>x.collegeType == "SS"));
                  }}
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  SS
                </label>

              </div>

            </div>

          </div>
          <div className="row formadduser">
          <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>DOTE Reference</label>
                <input
                  className="form-control"
                  type="text"
                  value={DOTEReference}
                  maxLength={30}
                  onChange={dotereference}

                //disabled={validation}
                />
               {!DOTEReference && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
              </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label><span style={{ color: "red" }}>*</span> Form Date</label>
              <input
                className="form-control"
                type="date"
                value={dayjs(FormDate).format("YYYY-MM-DD")}
                // max={Moment().format('YYYY-MM-DD')}
                onChange={formdate}
                disabled={FieldValid}
              />
              {!FormDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
         
             <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Programme Name
              </label>
              <select
                className="form-select form-control"
                onChange={dept}
                aria-label="Default select example"
                value={ProgrammeName}
                disabled={FieldValid}
              >
                <option value="">--Select Programme--</option>
                {deparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {!ProgrammeName && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
              </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <span style={{ color: "red" }}>*</span><label>Exam Session</label>
              <input
                className="form-control"
                type="month"
                value={ExamSession}
                onChange={examsession}
                //maxLength={15}
                disabled={FieldValid}

              />
              {!ExamSession && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

           
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Exam Start Date
              </label>
              <DatePicker
                className="form-select form-control"

                placeholderText="Select From Date"
                showTimeSelect
                //value={startDate}
                timeFormat="HH:mm"
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                selected={ExamStartDate}
                //maxDate={new Date()}
                //minDate={new Date()}
                dataTimezone='IST'
                displayTimezone='IST'
                selectsStart
                ExamStartDate={ExamStartDate}
                ExamEndDate={ExamEndDate}
                onChange={startdateChange}
                disabled={FieldValid}
              />
              {!ExamStartDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Exam End Date
              </label>
              {/* <DateTimePicker
                
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              /> */}
              <DatePicker
                className="form-select form-control"
                //value={endDate}
                timeFormat="HH:mm"
                timeCaption="time"
                placeholderText="Select To Date"
                showTimeSelect
                //dateFormat="dd-mm-yyyy hh:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                selected={ExamEndDate}
                dataTimezone='IST'
                displayTimezone='IST'
                selectsEnd
                ExamStartDate={ExamStartDate}
                ExamEndDate={ExamEndDate}
                minDate={ExamStartDate}
                onChange={enddateChange}
                disabled={FieldValid}
              />
              {!ExamEndDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Duration in Day(s)
              </label>
              <input
                className="form-control"
                type="text"
                onChange={noOfDays}
                maxLength={3}
                value={NoOfDays}
                disabled={FieldValid}
              >
              </input>
              {!NoOfDays && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
           {/* <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Duty As
              </label>
              <select
                className="form-select form-control"
                aria-label="Default select example"
                onChange={dutyas}
                //maxLength={3}
                value={DutyAs}
                disabled={FieldValid}
              >
                <option value="">--Select Duty As--</option>
                <option value="External Examiner">External Examiner</option>
                <option value="Chief Superintendent">Chief Superintendent</option>
                <option value="Hall Superintendent">Hall Superintendent</option>
                <option value="Chief Examiner/Examiners">Chief Examiner/Examiners</option>
              </select>
              {!DutyAs && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div> */}           
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group ag-theme-alpine">
              <div className="row">
                <label className="col pt-2 form-group-x" style={{ fontSize: 16 }}><span style={{ color: "red" }}>*</span>Examiner(s) Info</label>
                {DisableValid ? <div className="but col text-end">
                  <button className="btn btn-lg btn-success btn-block form-roundedbtn3 mb-3" onClick={OnInsertOne}>
                    <span className="glyphicon glyphicon-plus"></span> Add </button>
                  <button className="btn btn-lg btn-danger btn-block form-roundedbtn2 mb-3" onClick={onRemove}>
                    <span className="glyphicon glyphicon-plus"></span> Delete</button>

                </div> : null}
                <div style={{ pointerEvents: DisableValid ? 'auto' : 'none', fontFamily: 'Open sans', fontSize: 16 ,width: '100%', height: '100%', fontWeight: 'normal'}}>
                  <AgGridReact ref={gridRef}
                    modules={AllCommunityModules}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    rowSelection={'multiple'}
                    editable={DisableValid}
                    columnDefs={columnDefs}
                  >
                  </AgGridReact>
                </div>
                {!rowData.length && validate ? (<h6 style={{ color: "red", fontWeight: "normal" }}>{"Requird"}</h6>) : null}
              </div>
            </div>
            {PostApproValid ?
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Releiving OrderNo</label>
                <input
                  className="form-control"
                  type="text"
                  value={ReleivingOrderNo}
                  maxLength={15}
                  onChange={relievingOrderno}
                //disabled={validation}
                />

              </div> : null}
            {PostApproValid ?
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Relieving Order Issued On</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(RelievingOrderIssuedon).format("YYYY-MM-DD")}
                  // max={Moment().format('YYYY-MM-DD')}
                  onChange={relievingOrderIssud}
                //disabled={validation}
                />

              </div> : null}


            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
              <label>
                Note/Remarks
              </label>
              <textarea
                className="form-control clsabstract"
                id="note"
                value={Note}
                onChange={setnote}
                rows="5"
                maxLength={1000}
                disabled={NoteValid}
              />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>Attachment</label>
              <input
                className="form-control"
                onChange={onFileChange}
                type="file"
                id="formFileMultiple"
                disabled={NoteValid}

              />
              {filesList?.length > 0 && (
                <div className="d-flex align-items-center mt-2  rounded">
                  <div className="border rounded download-list-block">
                    {filesList?.map((item, index) => (
                      <h6 className="border-bottom ms-1" key={index}>
                        {item || "No File Name"}
                      </h6>
                    ))}
                  </div>
                  <button
                    className="btn btn-sm btn-primary btn-block "
                    type="button"
                    onClick={() => downloadFileFun()}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="row formadduser form-group"><label className="form-check-label" style={{ color: "#515151" }}>Required Attachments</label>
            <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
              <li className="form-check-label checkposition">Brochure /Poster</li>
              <li className="form-check-label checkposition">Permission letter from the Head of Institution</li>
              <li className="form-check-label checkposition">Acceptance letter from Organization/Industry</li>
            </ul> </div>
            <div className="row formadduser form-group">

              <label className="form-check-label" style={{ color: "#515151" }}> Approved By</label>

              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul >
                  {maker == null || maker == "" ? " " : maker + " [" + dayjs(makerDate).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label className="mr-5" style={{ fontWeight: 'bold' }}>Staff-in-Charge</label>
                </ul>
              </div>
              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul >
                  {checker1 == null || checker1 == "" ? " " : checker1 + " [" + dayjs(checker1Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>Head of Department </label>
                </ul>
              </div>
              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul >
                  {checker2 == null || checker2 == "" ? " " : checker2 + " [" + dayjs(checker2Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>Office Establishment</label>
                </ul>
              </div>
            </div>


            <div className="row formadduser form-group">
              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul>
                  {checker3 == null || checker3 == "" ? " " : checker3 + " [" + dayjs(checker3Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>Office Superintendent</label>
                </ul>
              </div>

              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul>
                  {checker4 == null || checker4 == "" ? " " : checker4 + " [" + dayjs(checker4Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>PRINCIPAL</label>
                </ul>
              </div>
            </div>
          {/* <div className="row formadduser">
            <label className="">Required Attachments</label>
            <label className="form-cform-check-xheck-label checkposition">
                1. Brochure /Poster
              </label>
              <label className="form-check-label checkposition">
                2. Permission letter from the Head of Institution
              </label><label className="form-check-label checkposition">
              3. Acceptance letter from Organization/Industry              </label>
            </div> */}
       
          &nbsp;
          <div className="d-flex justify-content-center align-items-enter">
            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
          </div>
          {PostApproValid ? <div>
            <button
              className="btn btn-lg btn-primary btn-block form-btnverify"
              type="button"
              onClick={() => updateBpeDetails()}
            >
              Submit
            </button></div> : <p>{
              DisableValid ?
                <p>
                  {
                    validation && isMakerCompleted ?
                      (
                        <div>
                          {(JSON.parse(localStorage.getItem("user")).fdpRoleName) == 'HOD' ?
                            <button
                              className="btn btn-lg btn-primary btn-block form-btnapprove"
                              type="button"
                              onClick={() => updateBpeDetails()}
                            >
                              Submit
                            </button> :
                            (JSON.parse(localStorage.getItem("user")).fdpRoleName) == 'Principal' ?
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnapprove"
                                type="button"
                                onClick={() => updateBpeDetails()}
                              >
                                Approve
                              </button> :
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnverify"
                                type="button"
                                onClick={() => updateBpeDetails()}
                              >
                                Verify & Submit
                              </button>}
                          <button
                            className="btn btn-lg btn-primary btn-block form-btnreject"
                            type="button"
                            onClick={() => rejectBpeDetails()}
                          >
                            Reject
                          </button>
                        </div>
                      ) :
                      (!bpe ? (
                        <div>
                          <button
                            className="btn btn-primary btn-block form-roundedbtn"
                            type="button"
                            onClick={() => saveBpeDetails()}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-primary btn-block form-roundedsubmitbtn "
                            type="button"
                            onClick={() => submitBpeDetails1()}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            className="btn btn-lg btn-primary btn-block form-roundedbtn"
                            type="button"
                            onClick={() => updateBpeDetails()}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-lg btn-primary btn-block form-roundedsubmitbtn "
                            type="button"
                            onClick={() => submitBpeDetails2()}
                          >
                            Submit
                          </button>
                        </div>
                      ))
                  }
                </p>
                : <div>
                  {/*<button
                    className="btn btn-lg btn-primary btn-block form-roundedbtn3 buttonW"
                    type="button"
                    onClick={() => updateBpeDetails()}
                  >
                    Submit Feedback
                  </button>*/}</div>}</p>}
        </div>
      </div >
    </div >
  );
};

export default AddBpeDetails;