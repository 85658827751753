
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import {
  addHOADetails,
  UpdateHOADetail
} from "../../../app/redux/slices/hoaDetailsSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
const AddHOADetails = ({isEdit, item, closePopup, hoaDetails }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!hoaDetails) {
      return;
    }
    runFillHOADetailsData.current = true;
    if (runFillHOADetailsData.current) {
      fillHOADetailsData(hoaDetails);
    }
  }, []);
  const [SINo,setSINo]=useState("");
  const[CollegeType, setCollegeType]=useState("");
  const[HeadOfAccount,setHeadOfAccount]=useState("");
  const [CollegeTypeList, setCollegeTypeList] = useState([]);
  
  const [IsActiveFdp,setIsActiveFdp] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillHOADetailsData = useRef();
 
  const clearState = () => {  
    setSINo("");
    setCollegeType("");
    setHeadOfAccount("");
    setIsActiveFdp("");
  };
  const collegetype = (val) => {
  
      
    setCollegeType(val.target.value);
      
  };
  const headofaccount = (val) => {
  
      
    setHeadOfAccount(val.target.value);
      
  };
  const isactive= (e) => {
    setIsActiveFdp(e.target.checked);
    
    
  }
   

  
  // if (val==1)
  // {IsActive=true;}
  // if (val==0)
  // setIsActive(false);

  useEffect(() => {
    AsyncGet(API.getlabdetails)
      .then((res) => {
        console.log(res.data, "getlabdetails");
        setCollegeTypeList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  const saveHOADetails = () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var hoaDetailsData = {
      SINo:0,
      CollegeType:CollegeType,
      HeadOfAccount:HeadOfAccount,     
      IsActiveFdp:IsActiveFdp,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addHOADetails(hoaDetailsData));
    closePopup();
    clearState();
  };

  const updateHOADetails = () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      SINo: SINo,
      CollegeType:CollegeType,
      HeadOfAccount:HeadOfAccount,
      IsActiveFdp:IsActiveFdp,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const hoaDetailsData = {
      ...hoaDetails,
        data: data
    };

    console.log("UpdateHOADetails", hoaDetailsData.data);
    dispatch(UpdateHOADetail(hoaDetailsData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !CollegeType ||
      !HeadOfAccount 
     
    ) {
      setvalidate(false);
      seterrorMsg("Provide required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillHOADetailsData = (obj) => {
    debugger;
    runFillHOADetailsData.current = false;
    setSINo(obj.data.siNo);
    setCollegeType(obj.data.collegeType);
    setHeadOfAccount(obj.data.headOfAccount);
    setIsActiveFdp(obj.data.isActiveFdp);
   // setInventoryDescription(obj.data.inventoryId);
    
    runFillHOADetailsData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit HOA" : "Add New HOA"}</h2>            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>College Type</label>
                
                
                {/*<input
                  className="form-control"
                  type="text"
                  value={CollegeType}
                  onChange={collegetype}
                  maxLength={50}
                 />*/}
               
                <select
                 className="form-select form-control"
                 onChange={collegetype}
                 aria-label="Default select example"
                 value={CollegeType}
               >
                 <option value="">--Select CollegeType--</option>
                 <option value="Aided">Aided</option>
                 <option value="SS">Self Supporting</option>
                 
               </select>
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Head of Account</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={HeadOfAccount}
                  onChange={headofaccount}
                  maxLength={150}
                />
                
              </div>  
              <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="checkbox"
                    //name="eligle-radio-btn"
                    //value="1"
                    checked={IsActiveFdp}
                    //id="isActive"
                    onChange={isactive}
                  />
                 
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Is Active for FDP Form
                  </label>
                </div>
                
                
              </div>
              
               
              </div>
             
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!hoaDetails ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveHOADetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateHOADetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHOADetails;