import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from "@ag-grid-community/all-modules"
import "../addIndentDetails/styles.css";
import { useNavigate } from "react-router-dom";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  addIndentDetails,
  fetchindents,
  UpdateIndentDetails,
} from "../../../app/redux/slices/indentSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";

import NumericCellEditor from "./numericCellEditor";
import { Navigate } from "react-router";
import { FormControlUnstyledContext } from "@mui/base";

let sequence = 0, flag = 0;
let principalFlag = 0;
var tot =0;

let makerOrHodFlag = 0;
let isMakerCompleted = false;
let isChecker1Completed = false, isChecker2Completed = false, isChecker3Completed = false, isChecker4Completed = false;
let maker = "", checker1 = "", checker2 = "", checker3 = "", checker4 = "";
let makerStatus = "", checker1Status = "", checker2Status = "", checker3Status = "", checker4Status = "";;
let currentFileQueueStatus = "Maker";
var budgetData;
let makerDate; let checker1Date;let checker2Date;let checker3Date;let checker4Date;
const AddIndentDetails = ({ isEdit,item, closePopup, indent, BudgetHeadList }) => {
  const dispatch = useDispatch();

  const [refNo, setrefNo] = useState();
  const [referenceNo, setReferenceNo] = useState();
  const [nameOfTheProgramme, setNameOfTheProgramme] = useState("");
  const [nameOfTheLaboratory, setNameOfTheLaboratory] = useState("");
  const [purpose, setPurpose] = useState("");
  const [listOfRecommendedSupplier1, setListOfRecommendedSupplier1] = useState("");
  const [listOfRecommendedSupplier2, setListOfRecommendedSupplier2] = useState("");
  const [listOfRecommendedSupplier3, setListOfRecommendedSupplier3] = useState("");
  const [listOfRecommendedSupplier4, setListOfRecommendedSupplier4] = useState("");
  const [BudgetAmt, setBudgetAmt] = useState(0);
  const [headOfAccount, setHeadOfTheAccount] = useState("");
  const [collegeType, setcollegeType] = useState("");
  const [formDate, setformDate] = useState("");
  const [datevalidation, setdatevalidation] = useState(true);
  const [validation, setvalidation] = useState(true);
  const [FieldValid, setFieldValid] = useState(false);
  const [OfficeValid, setOfficeValid] = useState(true);
  const [QuotationValid, setQuotationValid] = useState(false);
  const [DisableValid, setDisableValid] = useState(false);
  const [selectedFile, setselectedFile] = useState("");
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [nature, setNature] = useState("Recurring");
  const [requirement, setRequirement] = useState("Immediate");
  const [productInfo, setproductInfo] = useState("");
  const [NameOfTheProgrammeAided, setNameOfTheProgrammeAided] = useState("");
  const [HeadOfAccountAided, setHeadOfAccountAided] = useState("");
  const [NameOfTheProgrammess, setNameOfTheProgrammess] = useState("");
  const [HeadOfAccountss, setHeadOfAccountss] = useState("");
  const [NameOfTheProject,setNameOfTheProject]=useState("");
  const [BudgetAided, setBudgetAided] = useState("");
  const [Budgetss, setBudgetss] = useState("");
  //const [TotalCost, setTotalCost] = useState(0);
  //const [fileName, setFileName] = useState();
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [GridValid, setGridValid] = useState(true);
  const [sNo, setSNo] = useState("");
  const [descriptionofItems, setDescriptionofItems] = useState("");
  const [costPerUnit, setcostPerUnit] = useState("");
  const [qtyRequired, setQtyRequired] = useState("");
  const [totalBudget, setTotalBudget] = useState("");
  const [budget, setBudget] = useState("");
  const [units, setUnits] = useState("");

  const [cumulativeExpenses, setCumulativeExpenses] = useState("");
  const [DeparmentList, setDeparmentList] = useState([]);
  const [LabList, setLabList] = useState([]);
  const [HeadList, setHeadList] = useState([]);
  const [LabListUpdated, setLabListUpdated] = useState([]);
  const [HeadListUpdated, setHeadListUpdated] = useState([]);
  const [FacultyName, setFacultyName] = useState("");
  const [note, setNote] = useState("");
  const [addNewValid, setaddNewValid] = useState(false);
  //const[SNo,setSNo]= useState("");
  const runFillIndentData = useRef();
  let cars = [...new Array()].map(() => createOneCarReport());
  const [rowData, setRowData] = useState(cars);

  const clearState = () => {
    setrefNo("");
    setNameOfTheProgramme("");
    setNameOfTheLaboratory("");
    setPurpose("");
    setHeadOfTheAccount("");
    setformDate("");
    setselectedFile("");
    setNature("Recurring");
    setcollegeType("");
    setRequirement("Immediate");
    //setCreatedBy("");
    seterrorMsg("");
    setvalidate(false);
    setvalidation(true);
    setFile("");
    setfilesList([]);
    setFileName();
    setNote("");
    setFacultyName("");
    setQuotationValid("");
    setFieldValid(false);
    setOfficeValid(true);
    setQuotationValid(false);
    setDisableValid(false);
    setGridValid(true);
    setNameOfTheProject("");
    // setChecker1("");
    // setChecker2("");
    // setChecker3("");
    setListOfRecommendedSupplier1("");
    setListOfRecommendedSupplier2("");
    setListOfRecommendedSupplier3("");
    setListOfRecommendedSupplier4("");
    setReferenceNo("");
    budgetData = 0;
    setBudgetAmt(0);
    setNameOfTheProgrammeAided("");
    setNameOfTheProgrammess("");
    setHeadOfAccountss("");
    setHeadOfAccountAided("");
    setBudgetAided("");
    setBudgetss("");
    setDeparmentList([]);
    setLabList([]);
    setHeadList([]);
    setLabListUpdated([]);
    setHeadListUpdated([]);
    //setTotalCost(0);
    setDescriptionofItems("");
    setSNo("");
    setQtyRequired("");
    setTotalBudget("");
    setcostPerUnit("");
    setBudget("");
    setUnits(" ");
    setCumulativeExpenses("");
    setproductInfo(""); isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false; isChecker3Completed = false; isChecker4Completed = false;
    maker = ""; checker1 = ""; checker2 = ""; checker3 = ""; checker4 = "";
    makerStatus = ""; checker1Status = ""; checker2Status = ""; checker3Status = ""; checker4Status = "";
    currentFileQueueStatus = "Maker";
    flag = 0;
    sequence = 0; principalFlag = 0; makerOrHodFlag = 0;
    console.log("reseted");
  };
  const gridRef = useRef();
  var curFormatter = Intl.NumberFormat('en-US', {

    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  });
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.gethoadetails)
      .then((res) => {
        console.log(res.data, "getHOADetails");
        setHeadList(res.data);
        setHeadListUpdated(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getlabdetails)
      .then((res) => {
        console.log(res.data, "getLabDetails");
        setLabList(res.data);
        setLabListUpdated(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  var myCurrencyFormatter = p => curFormatter.format(p.value);
  const cellEditorSelector = (params) => {
    return {
      component: NumericCellEditor,
    };
  };
  const Total = (params) => {
    debugger;
    params.data.totalBudget = params.data.qtyRequired * params.data.costPerUnit;   
    if (rowData.length >0)
    {
    tot =0;
    for(var i=0;i<rowData.length;i++)
    {
      tot = rowData.totalBudget[i] +tot;
    }
  }
    return params.data.totalBudget;
  };
  // const TotalQty = (params) => {
  //   params.data.totalQty = params.data.totalQty + params.data.totalBudget;
  //   return params.data.totalQty;
  // };
  // const budgets = (params)=> {
  //   var budgetamount= Number(BudgetAmt)-params.data.totalBudget;
  //   setBudgetAmt(budgetamount);
  //   return budgetamount;
  // }

  const size = ['Doz', 'Nos', 'Qr'];
  const [columnDefs, setColumnDefs] = useState([
    { headerName: 'S.No', field: 'sNo', width: 75, editable: false },
    { headerName: '*Description of Items/Product', field: 'descriptionofItems', width: 550, wrapText: true, autoHeight: true },
    { headerName: 'Qty Required', field: 'qtyRequired', cellEditorSelector: cellEditorSelector },
    { headerName: 'Units', field: 'units', width: 80, cellEditor: 'agSelectCellEditor', cellEditorParams: { values: size }, },
    { headerName: 'Cost Per Unit', field: 'costPerUnit', cellEditorSelector: cellEditorSelector, valueFormatter: myCurrencyFormatter },
    { headerName: 'Total Budget (Approx)', field: 'totalBudget', editable: false, width: 220, valueGetter: Total, valueFormatter: myCurrencyFormatter },
    { headerName: 'Budget', field: 'budget', cellEditorSelector: cellEditorSelector, valueFormatter: myCurrencyFormatter, width: 115 },
    { headerName: 'Cum.Expenses', field: 'cumulativeExpenses', cellEditorSelector: cellEditorSelector, valueFormatter: myCurrencyFormatter, width: 150 }
    // { headerName: 'Total Qty', field: 'totalQty', valueGetter:TotalQty, valueFormatter: myCurrencyFormatter, width: 110 }

  ]);
  const defaultColDef = useMemo(() => ({
    sortable: true,
    width: 140,
    autoHeaderHeight: true,
    rowSelection: 'multiple',
    domLayout: 'autoHeight',
    lockPosition: 'left',
    //filter:true,
    resizeable: true,
    //suppressSizeToFit: true,
    editable: true,
  }), []);

  const gridOptions = {
    domLayout: 'autoHeight',
  }
  const navigate = useNavigate();
  const OnInsertOne = useCallback(() => {
    debugger;
    let i = rowData.length - 1;
    //let newrecord;
    //rowData[i].totalBudget = Number(rowData[i].qtyRequired) *  Number(rowData[i].costPerUnit);
    if ((i == -1) || (rowData[i].descriptionofItems != "")) {
      const newrecord = createOneCarReport();
      cars = [newrecord, ...cars];
      setRowData(rowData.slice(0).concat(cars));
      
    }
  });
  const callForQuotation = () => {
    navigate("/QuatationFile");
  };
  function createOneCarReport() {
    debugger;
    sequence = rowData.length + 1;

    const res = {
      sNo: sequence,
      descriptionofItems: "",
      qtyRequired: "",
      units: "Nos",
      costPerUnit: "",
      totalBudget: "",
      budget: "",
      cumulativeExpenses: ""
     // totalQty:totalQty
    };

    return res;
  }
  console.log("validation=" + validation, "QuotationValid" + QuotationValid);
  const onRemove = useCallback(() => {

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map(node => node.data.sNo);
    cars = rowData;
    var delId = cars.filter (x=>x.sNo !=selectedIds.indexOf(x.sNo));
    console.log( "delId...."+delId);

    cars = cars.filter(car => selectedIds.indexOf(car.sNo) < 0);
    console.log( "cars...."+cars);
    for (let i = 0; i < cars.length; i++) {
      cars[i].sNo = i + 1;
    }
    setRowData(cars);
  });

  //if((JSON.parse(localStorage.getItem("user")).indentRoleName) =="Maker" || (JSON.parse(localStorage.getItem("user")).indentRoleName) =="HOD" )
  if (!isMakerCompleted) {
    makerOrHodFlag = (JSON.parse(localStorage.getItem("user")).indentRoleName) == "Maker" ? 1 : 2;
    console.log("makerOrHodFlag...." + makerOrHodFlag);
  }
  const nameOfTheprogramme = (val) => {
    debugger;
    if(collegeType=="Aided")
    {
      setHeadOfTheAccount("");
      setNameOfTheLaboratory("");
      setHeadOfAccountAided("");
      setBudgetAmt("");
      setBudgetAided("");
      setNameOfTheProgrammeAided(val.target.value);
      setNameOfTheProgrammess("");
      setNameOfTheProgramme(val.target.value);
      budgetData = BudgetHeadList.filter(x => x.departmentId == (val.target.value) 
      && x.headOfAccount == headOfAccount);
    if (budgetData.length > 0) {
      setBudgetAmt(Number(budgetData[0].headAmt));
      setBudgetAided(Number(budgetData[0].headAmt));
    }
    else { setBudgetAmt(0);
      setBudgetAided(0);
    }
    }
    else if(collegeType=="SS"){
      setHeadOfTheAccount("");
      setHeadOfAccountss("");
      setNameOfTheLaboratory("");
      setBudgetAmt("");
      setBudgetss("");
      setNameOfTheProgrammess(val.target.value);
      setNameOfTheProgrammeAided("");
      setNameOfTheProgramme(val.target.value);
      budgetData = BudgetHeadList.filter(x => x.departmentId == (val.target.value) 
      && x.headOfAccount == headOfAccount);
    if (budgetData.length > 0) {
      setBudgetAmt(Number(budgetData[0].headAmt));
      setBudgetss(Number(budgetData[0].headAmt));
    }
    else { setBudgetAmt(0);
      setBudgetss(0);
    }
    }
    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    //setNameOfTheProgramme(val.target.value);
    
    // }
  };
  const nameOfThelaboratory = (val) => {
    // if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
    //   //error message
    // } else {
    setNameOfTheLaboratory(val.target.value);
    // }
  };
  const intpurpose = (val) => {
    
      setPurpose(val.target.value);
    
  };
  console.log(rowData+"rowData...");

  // const totalcost = () =>{
  //   debugger;
  //   var tCost =0;
    
  //   setTotalCost(tCost);
  //   return tCost;
  // }
  // if (rowData.length >0)
  // {
  //   for(var i=0;i<rowData.length;i++)
  //   {
  //     tot = rowData.totalBudget[i] +tot;
  //   }
  // }
 
  const fltyname = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
    setFacultyName(val.target.value);
    }
  };
  const projectname = (val) => {
   
    setNameOfTheProject(val.target.value);
    
  };
  const setnote = (val) => {
    setNote(val.target.value);
  }
  const headOfTheaccount = (val) => {
    if(collegeType=="Aided")
    {
      setHeadOfAccountAided(val.target.value);
      setHeadOfAccountss("");
      setHeadOfTheAccount(val.target.value);
      budgetData = BudgetHeadList.filter(x => x.departmentId == nameOfTheProgramme 
        && x.headOfAccount == (val.target.value));
      if (budgetData.length > 0) {
        setBudgetAmt(Number(budgetData[0].headAmt));
        setBudgetAided(Number(budgetData[0].headAmt));
      }
      else { setBudgetAmt(0);
        setBudgetAided(0);
       }
    }
    else if(collegeType=="SS")
    {
      setHeadOfAccountss(val.target.value);
      setHeadOfAccountAided("");
      setHeadOfTheAccount(val.target.value);
      budgetData = BudgetHeadList.filter(x => x.departmentId == nameOfTheProgramme 
        && x.headOfAccount == (val.target.value));
      if (budgetData.length > 0) {
        setBudgetAmt(Number(budgetData[0].headAmt));
        setBudgetss(Number(budgetData[0].headAmt));

      }
      else { setBudgetAmt(0);
        setBudgetss(0);
       }
    }
    //setHeadOfTheAccount(val.target.value);
    
  };
  const suppliersone = (val) => {
    setListOfRecommendedSupplier1(val.target.value);
  };
  const supplierstwo = (val) => {
    setListOfRecommendedSupplier2(val.target.value);
  };
  const suppliersthree = (val) => {
    setListOfRecommendedSupplier3(val.target.value);
  };
  const suppliersfour = (val) => {
    setListOfRecommendedSupplier4(val.target.value);
  };

  const referenceno = (val) => {
    setReferenceNo(val.target.value);
  };

  const formdate = (val) => {
    debugger;
    setformDate(val.target.value);
  };
  const setcollege = (val) => {
      
    setcollegeType(val);
    //setNameOfTheProgramme("");
  }
  const setrecurringOrNonRecurring = (val) => {
    setNature(val);
  };
  const setimmediateOrNormalOrInduecourse = (val) => {
    setRequirement(val);
  };

  
  const submitIndentDetails1 = async () => {
    debugger;
    if (makerOrHodFlag == 2) {
      isChecker1Completed = true;
      checker1Status = "Submitted";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      //currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveIndentDetails();
    }
    else {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      //checker1="";
      saveIndentDetails();
    }

  };

  console.log(isMakerCompleted + "MAker value......");
  const submitIndentDetails2 = async () => {
    debugger;
    flag = makerOrHodFlag == 2 ? 2 : 1;
    // flag = 1;
    updateIndentDetails();
  };
  const rejectIndentDetails = async () => {
    if (currentFileQueueStatus == "HOD") {
      checker1Status = "Rejected";
    }
    else if (currentFileQueueStatus == "Office Establishment") {
      checker2Status = "Rejected";
    }
    else if (currentFileQueueStatus == "Office Superintendent") {
      checker3Status = "Rejected";
    }
    else if (currentFileQueueStatus == "Principal") {
      checker4Status = "Rejected";
    }

    isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false; isChecker3Completed = false;
    isChecker4Completed = false;
    currentFileQueueStatus = makerOrHodFlag == 2 ? "HOD" : "Maker";
    checker1 ="";
    checker2="";
    checker3 = "";
    checker4 = "";
    updateIndentDetails();

  };
  useEffect(() => {
    if (!indent) {
      return;
    }
    runFillIndentData.current = true;
    debugger;
    if (runFillIndentData.current) {
      fillIndentData(indent);
      localStorage.setItem("formDetails", JSON.stringify(indent));

    }
  }, []);


  const DownloadForm = () => {
    AsyncGetFiles(API.downloadIndentForm + "?id=" + refNo)
      .then((res) => {
        console.log(res);
        debugger;
        const url = window.URL.createObjectURL(
          new Blob([res.data], {

            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "IndentForm.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        // alert("");
      });
  };
  const validationFun = () => {
    debugger

    setvalidate(true);
    if (
      nameOfTheProgramme &&
      nameOfTheLaboratory &&
      purpose &&
      headOfAccount &&
      rowData.length &&
      formDate
    ) {
      setvalidate(false);
      return false;
    } else {
      seterrorMsg("Provide all required fields");
      return true;
    }
  };
  const onFileUpload = async (res) => {
    debugger;
    if (selectedFile?.length < 1) {
      dispatch(fetchindents());
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const indentData = res ? res[0] : indent;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("TypeofUser", "INDENT");
    formData.append("id", Number(indentData?.refNo || 0));

    try {
      //debugger;
      const res = await AsyncPost(API.uploadFacultyfiles, formData);
      dispatch(fetchindents());
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    //debugger;

    AsyncGetFiles(API.downloadIndentFiles + "?id=" + indent.refNo)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveIndentDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (makerOrHodFlag == 2 && isMakerCompleted == false) {
      currentFileQueueStatus = "HOD";
    }
    if (error) {
      return;
    }
    var indentData = { // Id: 0,
      refNo: refNo,
      referenceNo: referenceNo,
      formDate: (formDate),
      nameOfTheProgramme: Number(nameOfTheProgramme),
      nameOfTheLaboratory: Number(nameOfTheLaboratory),
      headOfAccount: Number(headOfAccount),
      purpose: purpose,
      nature: nature,
      collegeType: collegeType,
      requirement: requirement,
      note: note,
      FacultyName: FacultyName,
      NameOfTheProject:NameOfTheProject,
      isMakerCompleted: isMakerCompleted,
      makerStatus: makerStatus,
      isChecker1Completed: isChecker1Completed,
      checker1Status: checker1Status,
      currentFileQueueStatus: currentFileQueueStatus,
      //checker1: JSON.parse(localStorage.getItem("user")).facultyName,
      principalFlag: principalFlag,
      // cmakerOrHodFlaghecker3: checker3,
      // checker4: checker4,
      makerOrHodFlag: makerOrHodFlag,
      productInfo: JSON.stringify(rowData),
      //nature: nature,
      checker1:checker1,
       maker:maker,
      //maker: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };
    try {
      console.log("indentData", indentData);
      const resp = await addIndentDetails(indentData, dispatch);
      //debugger;
      closePopup();
      await onFileUpload(resp);
      clearState();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateIndentDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    // if(referenceNo !="")
    // {
    //   principalFlag++;
    // }
    if (currentFileQueueStatus == "Waiting for Quotation" && Number(principalFlag) == 1) {
      currentFileQueueStatus = "Quotation Submitted";
      principalFlag = 2;//2
    }
    else if (currentFileQueueStatus == "Principal" && isChecker3Completed == true) {
      isChecker4Completed = true;
      checker4Status = "Approved";
      currentFileQueueStatus = "Waiting for Quotation";
      checker4 = JSON.parse(localStorage.getItem("user")).facultyName;
      ++principalFlag;//1
    }
    else if (currentFileQueueStatus == "Office Superintendent" && isChecker2Completed == true) {
      isChecker3Completed = true;
      checker3Status = "Approved";
      currentFileQueueStatus = "Principal";
      checker3 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "Office Establishment" && isChecker1Completed == true) {
      isChecker2Completed = true;
      checker2Status = "Approved";
      currentFileQueueStatus = "Office Superintendent";
      checker2 = JSON.parse(localStorage.getItem("user")).facultyName;

    }
    else if (currentFileQueueStatus == "HOD" && isMakerCompleted == true) {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "HOD" && flag == 2) {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "Maker" && flag == 1) {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "HOD";
      checker1Status = "";
      checker2Status = "";
      checker3Status = "";
      checker4Status = "";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      checker1="";
    }
    const indentData = {
      refNo: refNo,
      referenceNo: referenceNo,
      nameOfTheProgramme: Number(nameOfTheProgramme),
      nameOfTheLaboratory: Number(nameOfTheLaboratory),
      purpose: purpose,
      headOfAccount: Number(headOfAccount),
      formDate: formDate,
      note: note,
      collegeType: collegeType,
      FacultyName: FacultyName,
      NameOfTheProject:NameOfTheProject,

      isMakerCompleted: isMakerCompleted,
      isChecker1Completed: isChecker1Completed,
      isChecker2Completed: isChecker2Completed,
      isChecker3Completed: isChecker3Completed,
      isChecker4Completed: isChecker4Completed,
      makerStatus: makerStatus,
      checker1Status: checker1Status,
      checker2Status: checker2Status,
      checker3Status: checker3Status,
      checker4Status: checker4Status,
      currentFileQueueStatus: currentFileQueueStatus,
      checker1: checker1,
      checker2: checker2,
      checker3: checker3,
      checker4: checker4,
      principalFlag: Number(principalFlag),
      makerOrHodFlag: Number(makerOrHodFlag),
      nature: nature,
      requirement: requirement,
      productInfo: JSON.stringify(rowData),
      maker: maker,
      listOfRecommendedSupplier1: listOfRecommendedSupplier1,
      listOfRecommendedSupplier2: listOfRecommendedSupplier2,
      listOfRecommendedSupplier3: listOfRecommendedSupplier3,
      listOfRecommendedSupplier4: listOfRecommendedSupplier4,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };


    try {
      // dispatch(UpdateFacultyDetails(indentData));
      dispatch(UpdateIndentDetails(indentData));
      await onFileUpload();
      closePopup();
      clearState();

    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const closePopupFun = () => {
    debugger;
    clearState();
    closePopup();
    //window.location.reload(false);
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const fillIndentData = (obj) => {
    debugger;
    runFillIndentData.current = false;
    // console.log(obj);
    setrefNo(obj.data.refNo);

    //setMaker(obj.data.isMakerCompleted);
    maker = obj.data.maker;
    checker1 = (obj.data.checker1);
    checker2 = (obj.data.checker2);
    checker3 = (obj.data.checker3);
    checker4 = (obj.data.checker4);

    makerStatus = (obj.data.makerStatus);
    checker1Status = obj.data.checker1Status;
    checker2Status = obj.data.checker2Status;
    checker3Status = obj.data.checker3Status;
    checker4Status = obj.data.checker4Status;

    makerDate=obj.data.makerDate;
    checker1Date=obj.data.checker1Date;
    checker2Date=obj.data.checker2Date;
    checker3Date=obj.data.checker3Date;
    checker4Date=obj.data.checker4Date;

    isMakerCompleted = (obj.data.isMakerCompleted);
    isChecker1Completed = obj.data.isChecker1Completed;
    isChecker2Completed = obj.data.isChecker2Completed;
    isChecker3Completed = obj.data.isChecker3Completed;
    isChecker4Completed = obj.data.isChecker4Completed;
    currentFileQueueStatus = (obj.data.currentFileQueueStatus);
    principalFlag = (obj.data.principalFlag);
    makerOrHodFlag = (obj.data.makerOrHodFlag);
    if(obj.data.collegeType=="Aided")
    {
      setNameOfTheProgramme(obj.data.nameOfTheProgramme);
      setHeadOfTheAccount(obj.data.headOfAccount);
      setNameOfTheProgrammeAided(obj.data.nameOfTheProgramme);
      setHeadOfAccountAided(obj.data.headOfAccount);
      setHeadOfAccountss("");
      setNameOfTheProgrammess("");
      budgetData = BudgetHeadList.filter(x => x.departmentId == (obj.data.nameOfTheProgramme) 
      && x.headOfAccount == (obj.data.headOfAccount));
    if (budgetData.length > 0) {
      setBudgetAmt(Number(budgetData[0].headAmt));
      setBudgetAided(Number(budgetData[0].headAmt));
      setBudgetss(0);
    }
    else { setBudgetAmt(0);
      setBudgetAided(0);
      setBudgetss(0);
     }
    }
  else if(obj.data.collegeType=="SS"){
    setNameOfTheProgramme(obj.data.nameOfTheProgramme);
      setHeadOfTheAccount(obj.data.headOfAccount);
      setNameOfTheProgrammeAided("");
      setHeadOfAccountAided("");
      setHeadOfAccountss(obj.data.headOfAccount);
      setNameOfTheProgrammess(obj.data.nameOfTheProgramme);
      budgetData = BudgetHeadList.filter(x => x.departmentId == (obj.data.nameOfTheProgramme) 
      && x.headOfAccount == (obj.data.headOfAccount));
    if (budgetData.length > 0) {
      setBudgetAmt(Number(budgetData[0].headAmt));
      setBudgetAided(0);
      setBudgetss(Number(budgetData[0].headAmt));
    }
    else { setBudgetAmt(0); 
      setBudgetAided(0);
      setBudgetss(0);
    }
  }
    setformDate(obj.data.formDate);
   
    setNameOfTheLaboratory(obj.data.nameOfTheLaboratory);
    setPurpose(obj.data.purpose);
 
    setNature(obj.data.nature);
    //setReferenceNumber(obj.data.id);
    setRequirement(obj.data.requirement);
    setListOfRecommendedSupplier1(obj.data.listOfRecommendedSupplier1);
    setListOfRecommendedSupplier2(obj.data.listOfRecommendedSupplier2);
    setListOfRecommendedSupplier3(obj.data.listOfRecommendedSupplier3);
    setListOfRecommendedSupplier4(obj.data.listOfRecommendedSupplier4);
    setReferenceNo(obj.data.referenceNo);
    setNameOfTheProject(obj.data.nameOfTheProject);

    setFacultyName(obj.data.facultyName);
    setSNo(obj.data.sNo);
    setNote(obj.data.note);
    setDescriptionofItems(obj.data.descriptionofItems);
    setQtyRequired(obj.data.qtyRequired);
    setcostPerUnit(obj.data.costPerUnit);
    setBudget(obj.data.budget);
    setUnits(obj.data.units);
    setcollegeType(obj.data.collegeType);
    setCumulativeExpenses(obj.data.cumulativeExpenses);
    //setTentativeCost(obj.data.tentativeCost);
    setTotalBudget(obj.data.totalBudget);
    setfilesList(obj.files);

    //setproductInfo(obj.data.productInfo);
    setRowData(JSON.parse(obj.data.productInfo));
    if (Number(obj.data.principalFlag >= 1)) {
      if ((JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment")) {
        setDisableValid(false);
      }
      else {
        setDisableValid(true);
      }
    }
    else {
      setvalidation((JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Superintendent") ||
        (JSON.parse(localStorage.getItem("user")).indentRoleName == "Principal") ? true : false);
    }

    //setFaculty_Photo(obj.data.photo);
    //setDisableValid((obj.data.currentFileQueueStatus == "Waiting for Quotation") ? false : true);
    // setvalidation(((obj.data.currentFileQueueStatus == "Maker") || (JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment")
    // ||(JSON.parse(localStorage.getItem("user")).indentRoleName == "HOD")
    // && principalFlag< 2) ? false : true);

    setOfficeValid((JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment") ? false : true);
    setQuotationValid((Number(obj.data.principalFlag <= 1)) ? false : true);
    setGridValid(((principalFlag == 1 && (JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment")) || principalFlag == 0) ? true : false);
    setFieldValid((((JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Superintendent") ||
      (JSON.parse(localStorage.getItem("user")).indentRoleName == "Principal")) && principalFlag == 0) || ((principalFlag >= 1) &&
        (JSON.parse(localStorage.getItem("user")).indentRoleName != "Office Establishment")
        || ((JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment") && principalFlag == 2)) ? true : false);
    console.log("FieldValid....." + FieldValid);
    seterrorMsg("");
    
    console.log("prFlag...." + principalFlag);
    runFillIndentData.current = true;
  };

  return (
    <div className="popup">

      <div className="col">
        <div className="formdata-check">
          <button
            className="col-xs-1 col-md-1 col-lg-1 col-sm-1 btn btn-lg btnclose-x"
            onClick={() => closePopupFun()}
            type="button"
          >
            X
          </button>
          <div className="row">

            <h2 className="col heading">{isEdit ? "Edit Indent Form" : "Indent Form"}</h2>
            {((JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment")
              || (JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Superintendent")) && (principalFlag > 1) ?
              <div className="but col text-end">
                <button className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                  type="button" onClick={() => DownloadForm()}>
                  <span className="glyphicon glyphicon-remove"></span> Download Form</button>
              </div> : null}</div>
          <div className="d-flex justify-content-center align-items-enter">
            {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
          </div>
          <div className="row">

            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio5"
                  value="5"
                  checked={collegeType === "Aided"}
                  onChange={() => {
                    setcollegeType("Aided");
                    setLabListUpdated(LabList.filter(x => x.collegeType == "Aided"));
                    
                    setNameOfTheProgramme(NameOfTheProgrammeAided);
                    setHeadOfTheAccount(HeadOfAccountAided);
                    setBudgetAmt(BudgetAided);

                    // setHeadListUpdated(HeadList.filter(x =>x.collegeType == "Aided"));
                  }}

                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio6"
                  value="6"
                  checked={collegeType === "SS"}
                  onChange={() => {
                    setcollegeType("SS");
                    setLabListUpdated(LabList.filter(x => x.collegeType == "SS"));
                    setNameOfTheProgramme(NameOfTheProgrammess);
                    setHeadOfTheAccount(HeadOfAccountss);
                    setBudgetAmt(Budgetss);

                    //setHeadListUpdated(HeadList.filter(x =>x.collegeType == "SS"));
                  }}
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Self Supporting
                </label>

              </div>

            </div>
            <div className="checksection col">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn1"
                  id="inlineRadio1"
                  value="1"
                  checked={nature === "Recurring"}
                  onChange={() => setrecurringOrNonRecurring("Recurring")
                  }
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Recurring
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn1"
                  id="inlineRadio2"
                  value="2"
                  checked={nature === "NonRecurring"}
                  onChange={() => setrecurringOrNonRecurring("NonRecurring")}
                  disabled={FieldValid}
                />
                <label className="form-check-label checkposition">
                  NonRecurring
                </label>
              </div>
            </div>
            <div className="checksection col">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn2"
                  id="inlineRadio3"
                  value="3"
                  checked={requirement === "Immediate"}
                  onChange={() => setimmediateOrNormalOrInduecourse("Immediate")}
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Immediate
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn2"
                  id="inlineRadio4"
                  value="4"
                  checked={requirement === "Normal"}
                  onChange={() => setimmediateOrNormalOrInduecourse("Normal")}
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Normal
                </label>
              </div>
            </div>
          </div>
          <div className="row formadduser">

            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label><span style={{ color: "red" }}>*</span>Indent Request Form Date</label>
              <input
                className="form-control"
                type="date"
                value={dayjs(formDate).format("YYYY-MM-DD")}
                //max={Moment().format('YYYY-MM-DD')}
                onChange={formdate}
                disabled={FieldValid}
              />
              {!formDate && validate ? (
                <h6 style={{ color: "red" }}>{"Required"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Name of the Programme
              </label>
              <select
                className="form-select form-control"
                onChange={nameOfTheprogramme}
                aria-label="Default select example"
                value={nameOfTheProgramme}
                disabled={FieldValid}
              >
                <option value="">--Select Department--</option>
                {DeparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}
              </select>
              {!nameOfTheProgramme && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label><span style={{ color: "red" }}>*</span>Name of the Laboratory</label>
              <select
                className="form-select form-control"
                onChange={nameOfThelaboratory}
                aria-label="Default select example"
                value={nameOfTheLaboratory}
                disabled={FieldValid}
              >
                <option value="">--Select Laboratory--</option>
                {(LabListUpdated)?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.labName}
                  </option>
                ))}
              </select>
              {!nameOfTheLaboratory && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <select
                className="form-select form-control"
                onChange={headOfTheaccount}
                aria-label="Default select example"
                value={headOfAccount}
                disabled={FieldValid}
              >
                <option value="">--Select Head--</option>
                {(HeadListUpdated)?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.headOfAccount}
                  </option>
                ))}
              </select>
              {!headOfAccount && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            {((JSON.parse(localStorage.getItem("user")).indentRoleName == "Maker") ||
              (JSON.parse(localStorage.getItem("user")).indentRoleName == "HOD")) ? null :
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt ">
                <label>Available Budget</label>
                <input
                  className="form-control"
                  type="text"
                  value={BudgetAmt}
                  //onChange={ispecification}
                  //maxLength={150}
                  disabled
                />
              </div>}
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>Faculty Name/Electrical Engineer/Civil Engineer</label>
              <input
                className="form-control"
                type="text"
                value={FacultyName}
                onChange={fltyname}
                maxLength={300}
                disabled={FieldValid}

              />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>Name of the Project</label>
              <input
                className="form-control"
                type="text"
                value={NameOfTheProject}
                onChange={projectname}
                maxLength={300}
                disabled={FieldValid}

              />
            </div>
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
              <label>
                <span style={{ color: "red" }}>*</span>Purpose
              </label>
              <textarea
                className="form-control clsabstract"
                value={purpose}
                onChange={intpurpose}
                rows="3"
                maxLength={300}
                disabled={FieldValid}
              />
              {!purpose && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  ag-theme-alpine form-group ">
              <div className="row">

                <label className="col pt-2 form-group-x" style={{ fontSize: 16 }}><span style={{ color: "red" }}>*</span>Product Information</label>

                {GridValid ?
                  <div className="but col text-end">

                    <button
                      className="btn btn-lg btn-success btn-block form-roundedbtn3 mb-3"

                      onClick={OnInsertOne}>
                      <span className="glyphicon glyphicon-plus"></span> Add
                    </button>
                    <button
                      className="btn btn-lg btn-danger btn-block form-roundedbtn2 mb-3"
                      onClick={onRemove}>
                      <span className="glyphicon glyphicon-plus"></span> Delete
                    </button>
                    {/* <button onClick={onRemove}>Remove Row</button> */}
                  </div>
                  : null}

                <div style={{ pointerEvents: (GridValid) ? 'auto' : 'none', fontFamily: 'Open sans', fontSize: 16, width: '100%', height: '100%', fontWeight: 'normal' }}>
                  <AgGridReact
                    modules={AllCommunityModules}
                    ref={gridRef}
                    defaultColDef={defaultColDef}

                    rowData={rowData}
                    gridOptions={gridOptions}

                    rowSelection={'multiple'}
                    editable={GridValid}

                    columnDefs={columnDefs}

                  >
                  </AgGridReact>
                </div>
                {!rowData.length && validate ? (
                  <h6 style={{ color: "red", fontWeight: "normal" }}>{"Requird"}</h6>
                ) : null}

              </div>
            </div>
            {/* <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>Total Item Cost</label>
              <input
                className="form-control"
                type="text"
                value={tot}
                //onChange={totalcost}
                maxLength={10}
                disabled

              />
            </div> */}
            {(principalFlag >= 1 && (JSON.parse(localStorage.getItem("user")).indentRoleName == "Office Establishment")) ?
              <div>
                <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                  <label>
                    Reference No
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={referenceNo}
                    onChange={referenceno}
                    maxLength={150}

                  />
                </div>
                <div className="row formadduser">

                  <label style={{ color: "#515151" }}>List of Recommended Suppliers</label>
                  <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                    <input
                      className="form-control"
                      type="text"
                      onChange={suppliersone}
                      placeholder={1}
                      value={listOfRecommendedSupplier1}
                      maxLength={500}
                    >
                    </input>
                  </div>
                  <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                    <input
                      className="form-control"
                      type="text"
                      onChange={supplierstwo}
                      placeholder={2}
                      value={listOfRecommendedSupplier2}
                      maxLength={500}
                    >
                    </input>
                  </div>
                  <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                    <input
                      className="form-control"
                      type="text"
                      onChange={suppliersthree}
                      placeholder={3}
                      value={listOfRecommendedSupplier3}
                      maxLength={500}
                    >
                    </input>
                  </div>
                  <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                    <input
                      className="form-control"
                      type="text"
                      onChange={suppliersfour}
                      placeholder={4}
                      value={listOfRecommendedSupplier4}
                      maxLength={500}
                    >
                    </input>
                  </div>  </div> </div> : null}
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
              <label>
                Note/Remarks
              </label>
              <textarea
                className="form-control clsabstract"
                id="note"
                value={note}
                onChange={setnote}
                rows="5"
                maxLength={1000}
              //disabled={QuotationValid && OfficeValid}
              />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>Attachment</label>
              <input
                className="form-control"
                onChange={onFileChange}
                type="file"
                id="formFileMultiple"
              // disabled={QuotationValid && OfficeValid}
              />
              {filesList?.length > 0 && (
                <div className="d-flex align-items-center mt-2  rounded">
                  <div className="border rounded download-list-block">
                    {filesList?.map((item, index) => (
                      <h6 className="border-bottom ms-1" key={index}>
                        {item || "No File Name"}
                      </h6>
                    ))}
                  </div>
                  <button
                    className="btn btn-sm btn-primary btn-block "
                    type="button"
                    onClick={() => downloadFileFun()}
                  //disabled={validation}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
             </div>
            <div className="row formadduser form-group">

              <label className="form-check-label" style={{ color: "#515151" }}> Approved By</label>

              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul >
                  {maker == null || maker == "" ? " " : maker + " [" + dayjs(makerDate).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label className="mr-5" style={{ fontWeight: 'bold' }}>Staff-in-Charge</label>
                </ul>
              </div>
              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul >
                  {checker1 == null || checker1 == "" ? " " : checker1 + " [" + dayjs(checker1Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>Head of Department </label>
                </ul>
              </div>
              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul >
                  {checker2 == null || checker2 == "" ? " " : checker2 + " [" + dayjs(checker2Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>Office Establishment</label>
                </ul>
              </div>
            </div>


            <div className="row formadduser form-group">
              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul>
                  {checker3 == null || checker3 == "" ? " " : checker3 + " [" + dayjs(checker3Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>Office Superintendent</label>
                </ul>
              </div>

              <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
                <ul>
                  {checker4 == null || checker4 == "" ? " " : checker4 + " [" + dayjs(checker4Date).format("DD-MM-YYYY") + "]"}
                  <br></br>
                  <label style={{ fontWeight: 'bold' }}>PRINCIPAL</label>
                </ul>
              </div>
            </div>
         
          &nbsp;
          <div className="d-flex justify-content-center align-items-enter">
            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
          </div>
          {principalFlag > 0 ?

            <div>
              {!OfficeValid ? <button
                className="btn btn-lg btn-primary btn-block form-btnverify"
                type="button"
                onClick={() => updateIndentDetails()}
              >
                Add Suppliers
              </button> : null}
              {((!OfficeValid || (JSON.parse(localStorage.getItem("user")).indentRoleName) == "Principal") && principalFlag >= 2) ?
                <button className="btn btn-lg btn-primary btn-block form-btncall"
                  type="button" onClick={() => callForQuotation()}>
                  <span className="glyphicon glyphicon-remove"></span> View Quotation</button> : null}
            </div> :
            <p> {!QuotationValid ?
              <p>
                {!indent ? (
                  <div>
                    <button
                      className="btn btn-primary btn-block form-roundedbtn"
                      type="button"
                      onClick={() => saveIndentDetails()}
                    >
                      Save
                    </button>
                    <button
                      className="btn btn-primary btn-block form-roundedsubmitbtn "
                      type="button"
                      onClick={() => submitIndentDetails1()}
                    >
                      Submit
                    </button>
                  </div>
                ) :
                  <p>{
                    (JSON.parse(localStorage.getItem("user")).indentRoleName) == "Principal" && principalFlag == 0 ?
                      <div>
                        <button
                          className="btn btn-lg btn-primary btn-block form-btnverify"
                          type="button"
                          onClick={() => updateIndentDetails()}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-lg btn-primary btn-block form-btnreject"
                          type="button"
                          onClick={() => rejectIndentDetails()}
                        >
                          Reject
                        </button> </div> :

                      <p>
                        {
                          (JSON.parse(localStorage.getItem("user")).indentRoleName) == "Office Establishment"
                            || (JSON.parse(localStorage.getItem("user")).indentRoleName) == "Office Superintendent" ?
                            <div>
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnverify"
                                type="button"
                                onClick={() => updateIndentDetails()}
                              >
                                Verify & Submit
                              </button>
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnreject"
                                type="button"
                                onClick={() => rejectIndentDetails()}
                              >
                                Reject
                              </button> </div> : <p>
                              {(makerOrHodFlag == 1) && (isMakerCompleted) && principalFlag == 0 ? <div><button
                                className="btn btn-lg btn-primary btn-block form-btnapprove"
                                type="button"
                                onClick={() => updateIndentDetails()}
                              >Submit
                              </button>
                                <button
                                  className="btn btn-lg btn-primary btn-block form-btnreject"
                                  type="button"
                                  onClick={() => rejectIndentDetails()}
                                >
                                  Reject
                                </button></div>
                                :
                                <div>{principalFlag == 0 ?
                                  <div>
                                    <button
                                      className="btn btn-lg btn-primary btn-block form-roundedbtn"
                                      type="button"
                                      onClick={() => updateIndentDetails()}
                                    >
                                      Update
                                    </button>
                                    <button
                                      className="btn btn-lg btn-primary btn-block form-roundedsubmitbtn "
                                      type="button"
                                      onClick={() => submitIndentDetails2()}
                                    >
                                      Submit
                                    </button>
                                  </div> : null}</div>} </p>}
                      </p>
                  } </p>}
              </p> : null
            }
            </p>}

        </div>
      </div >
    </div >
  );
};

export default AddIndentDetails;
