import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const inventoryIssueSlice = createSlice({
  name: "inventoryIssueList",
  initialState: {
    loading: false,
    error: false,
    inventoryIssueList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    inventoryIssueDetails: [],
    submitInventoryIssueSuccess: "",
    saveInventoryIssueDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getInventoryIssue: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.inventoryIssueList = payload;
    },
    saveInventoryIssue: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveInventoryIssueDataSuccess = payload;
    },
    submitInventoryIssue: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getInventoryIssue,
  submitInventoryIssue,
  setError,
  saveInventoryIssue,
} = inventoryIssueSlice.actions;
export const inventoryIssueSelector = (state) => state.inventoryIssues || [];
export default inventoryIssueSlice.reducer;

export const fetchInventoryIssues = () => (dispatch) => {
 
  dispatch(setLoading());
  AsyncGet(API.getInventoryIssuedDetails)
    .then((response) => {
      console.log("response,", response);
      dispatch(getInventoryIssue(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addInventoryIssueDetails = (data) => (dispatch) => {
  debugger
  dispatch(setLoading());
  AsyncPost(API.saveInventoryIssuedDetails, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveInventoryIssue(response));
      dispatch(fetchInventoryIssues());
    })
    .catch((er) => {
      console.log("failure of add Inventory");
      dispatch(saveInventoryIssue(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateInventoryIssueDetail = (data) => (dispatch) => {
    debugger;
  dispatch(setLoading());
  AsyncPost(API.updateInventoryIssuedDetails, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update Inventory");
      dispatch(fetchInventoryIssues());
    })
    .catch((er) => {
      console.log("failure of Update Inventory");
      dispatch(saveInventoryIssue(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteInventoryIssue = (data) => (dispatch) => {
  // dispatch(setLoading());
  debugger;
  AsyncPost(API.deleteInventoryIssuedDetails + "?id=" + data, "")
    .then((response) => {
      /*if (response!='Success')
      {      
        alert('Sorry! Unable to delete Inventory');
      }*/
      

      // dispatch(setLoading());
      console.log(response, "success of delete Inventory");
      dispatch(fetchInventoryIssues());
    })
    .catch((er) => {
      console.log("failure of delete Inventory");
      dispatch(saveInventoryIssue(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
