import React from "react";
import  "./footer.css";
import logo from '../../app/assets/images/logo.png';
const Footer = () => {
  return (
    <div>
      
    </div>
  );
};
export default Footer;