import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import { AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from "@ag-grid-community/all-modules"
import "../addfdpDetails/styles.css";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
//import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  addOdDetails,
  fetchods,
  UpdateOdDetails,
} from "../../../app/redux/slices/fdpSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
import { enUS, GridAutoSizer } from "@mui/x-data-grid";
import { GridApi, ValueFormatterParams } from "ag-grid-community";

import { SetLeftFeature } from "@ag-grid-community/core";
import NumericCellEditor from "./numericCellEditor";
import { style } from "@mui/system";
let sequence = 0, flag = 0;
let makerOrHodFlag = 0;

let isMakerCompleted = false;
let isChecker1Completed = false, isChecker2Completed = false, isChecker3Completed = false, isChecker4Completed = false;
let maker = "", checker1 = "", checker2 = "", checker3 = "", checker4 = "";
let makerStatus = "", checker1Status = "", checker2Status = "", checker3Status = "", checker4Status = "";
let currentFileQueueStatus = "Maker";
let makerDate; let checker1Date;let checker2Date;let checker3Date;let checker4Date;
//var date = new Date().getDate();
const AddOdDetails = ({ isEdit, item, closePopup, od,Faculty,Department }) => {
  const dispatch = useDispatch();

  const [refNo, setrefNo] = useState();
  const [getfdp, setfdpList] = useState([]);
  const [titleOfTheProgramme, settitleOfTheProgramme] = useState("");
  const [conductedBy, setConductedBy] = useState("");
  const [venue, setVenue] = useState("");
  const [typeOfProgramme, setTypeOfProgramme] = useState("");
  const [note, setNote] = useState("");
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [level, setLevel] = useState("");
  const [descriptionOfOthers, setDescriptionOfOthers] = useState("");
  const [levelOthers, setlevelOthers] = useState("");
  const [headOfAccount, setHeadOfTheAccount] = useState("");
  const [HeadOfAccountAided, setHeadOfAccountAided] = useState("");
  const [HeadOfAccountss, setHeadOfAccountss] = useState("");
  const [formDate, setformDate] = useState("");
  const [RelievingOrderIssuedon, setRelievingOrderIssuedon] = useState("");
  const [datevalidation, setdatevalidation] = useState(true);
  const [PostApproValid, setPostApproValid] = useState(false);
  const [FieldValid, setFieldValid] = useState(false);
  const [NoteValid, setNoteValid] = useState(false);

  const [validation, setvalidation] = useState(false);
  const [DisableValid, setDisableValid] = useState(true);
  const [departmentIdAided, setDepartmentIdAided] = useState();
  const [departmentIdss, setDepartmentIdss] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [deparmentList, setDeparmentList] = useState([]);
  const [selectedFile, setselectedFile] = useState("");
  //const [file, setFile] = useState();
  const [submittedReleivingOrder, setSubmittedReleivingOrder] = useState("");
  const [submittedCopyOfCertificate, setSubmittedCopyOfCertificate] = useState("");
  const [FacultyName, setFacultyName] = useState("");
  const [taDaBroneBy, setTaDaBroneBy] = useState("");
  const [programmeDetails, setProgrammeDetails] = useState("");
  // const [CreatedBy, setCreatedBy] = useState("");
  //const [fileName, setFileName] = useState();
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [sNo, setSNo] = useState("");
  const [nameofFaculty, setNameofFaculty] = useState("");
  const [designation, setDesignation] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [registrationFees, setRegistrationFees] = useState("");
  const [appexTAandDA, setAppexTAandDA] = useState("");
  const [totalBudget, setTotalBudget] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [noOfDaysAttended, setNoOfDaysAttended] = useState();
  const [HeadList, setHeadList] = useState([]);
  const [collegeType, setcollegeType] = useState("");

  //const[SNo,setSNo]= useState("");
  const runFillOdData = useRef();

  let cars = [...new Array()].map(() => createOneCarReport());

  const [sizeValidate, setsizeValidate] = useState("");

  const clearState = () => {
    setrefNo("");
    settitleOfTheProgramme("");
    setConductedBy("");
    setTypeOfProgramme("");
    setNote("");
    setLevel("");
    setDepartmentId("");
    setVenue("");
    setHeadOfTheAccount("");
    setformDate("");
    setRelievingOrderIssuedon("");
    setselectedFile("");
    setSubmittedReleivingOrder("");
    setTaDaBroneBy("");
    setSubmittedCopyOfCertificate("")
    setDescriptionOfOthers("");
    setDeparmentList([]);
    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setcollegeType("");
    setFacultyName("");
    makerOrHodFlag = 0;
    setFieldValid(false);
    setStartDate("");
    setEndDate("");
    setNoOfDaysAttended();
    setHeadList([]);
    setlevelOthers("");
    setNameofFaculty("");
    setDesignation("");
    setDepartmentName("");
    setNoteValid(false);
    setSNo("");
    setAppexTAandDA("");
    setRegistrationFees("");
    setTotalBudget("");
    setPostApproValid(false);
    setvalidation(false);
    setDepartmentIdAided("");
    setDepartmentIdss("");
    setHeadOfAccountAided("");
    setHeadOfAccountss("");
    setDisableValid(true);
    setProgrammeDetails(""); isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false;
    isChecker3Completed = false;
    isChecker4Completed = false;
    maker = ""; checker1 = ""; checker2 = ""; checker3 = ""; checker4 = "";
    makerStatus = ""; checker1Status = ""; checker2Status = ""; checker3Status = ""; checker4Status = "";
    currentFileQueueStatus = "Maker";
    flag = 0;
    sequence = 0; makerOrHodFlag = 0;
    console.log("reseted");
  };
  const gridRef = useRef();
  var curFormatter = Intl.NumberFormat('en-US', {

    style: 'currency',
    currency: 'INR',
    maximumFractionDigits: 0
  });

  var myCurrencyFormatter = p => curFormatter.format(p.value);
  const cellEditorSelector = (params) => {
    return {
      component: NumericCellEditor,
    };
  };

  var numberValueFormatter = function (params) {
    return params.value.toFixed(2);
  };

  const [rowData, setRowData] = useState(cars);
  const [columnDefs, setColumnDefs] = useState([

    { headerName: 'S.No', field: 'sNo', width: 90, editable: false },
    { headerName: '*Name of Faculty', field: 'nameofFaculty',cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Faculty}, width: 500 },
    { headerName: 'Designation', field: 'designation' },
    { headerName: 'Department', field: 'departmentName' ,cellEditor: 'agSelectCellEditor', cellEditorParams: { values:Department},},    

    { headerName: 'Registration Fees', field: 'registrationFees', cellEditorSelector: cellEditorSelector, valueFormatter: myCurrencyFormatter },
    { headerName: 'Appx TA/DA', field: 'appexTAandDA' },
    { headerName: 'Total Budget', field: 'totalBudget', cellEditorSelector: cellEditorSelector, valueFormatter: myCurrencyFormatter }
  ])

  const defaultColDef = useMemo(() => ({
    sortable: true,
    width: 170,
    autoHeaderHeight: true,
    rowSelection: 'multiple',
    domLayout: 'autoHeight',
    wrapText: true,
    autoHeight: true,
    //singleClickEdit:true,
    //lockPinned: true,
    //cellClass: 'locked-col',
    lockPosition: 'left',
    //filter:true,
    resizeable: true,
    //suppressSizeToFit: true,
    editable: true,

  }), []);

  const gridOptions = {
    domLayout: 'autoHeight'
  }

  const OnInsertOne = useCallback(() => {
    debugger;
    let i = rowData.length - 1;
    //let newrecord;

    if ((i == -1) || (rowData[i].nameofFaculty != "")) {
      const newrecord = createOneCarReport();
      cars = [newrecord, ...cars];
      setRowData(rowData.slice(0).concat(cars));
    }


  });

  function createOneCarReport() {
    debugger;
    sequence = rowData.length + 1;

    const res = {
      sNo: sequence,
      nameofFaculty: "",
      designation: "",
      departmentName:"",
      registrationFees: 0,
      appexTAandDA: "",
      totalBudget: 0,
    };

    return res;
  }

  const onRemove = useCallback(() => {

    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedIds = selectedNodes.map(node => node.data.sNo);
    cars = rowData;
    cars = cars.filter(car => selectedIds.indexOf(car.sNo) < 0);
    console.log(cars);
    for (let i = 0; i < cars.length; i++) {
      cars[i].sNo = i + 1;
    }
    setRowData(cars);
  });
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  /*useEffect(() => {
    AsyncGet(API.getFdsForms + "?id=" +refNo)    
   
    
      .then((res) => {
        console.log(res.data, "getFdsForms");
        setfdpList(res.data);
        console.log(res.data,"FdpList");
        getfdp=(res.data);
        console.log(getfdp,"FdpList");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);*/
  useEffect(() => {
    AsyncGet(API.gethoadetails)
      .then((res) => {
        console.log(res.data, "getHOADetails");
        setHeadList(res.data);
        // setHeadListUpdated(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const titleOfTheprogramme = (val) => {

    settitleOfTheProgramme(val.target.value);

  };
  const conductedby = (val) => {

    setConductedBy(val.target.value);

  };
  const intvenue = (val) => {

    setVenue(val.target.value);

  };
  const fltyname = (val) => {
    if (/^[a-z A-Z]*$/.test(val.target.value) == false) {
      //error message
    } else {
    setFacultyName(val.target.value);
    }
  };
  //if (((JSON.parse((localStorage.getItem("user")).fdpRoleName) == "Maker") || ((JSON.parse(localStorage.getItem("user")).fdpRoleName) == "HOD"))) 

  if(!isMakerCompleted)
  {
    makerOrHodFlag = (JSON.parse(localStorage.getItem("user")).fdpRoleName) == "Maker" ? 1 : 2;
    console.log("makerOrHodFlag...." + makerOrHodFlag);
  }
  const typeofprogramme = (val) => {
    setTypeOfProgramme(val.target.value);
    setDescriptionOfOthers("");
  };
  const setnote = (val) => {
    setNote(val.target.value);
  }
  const intlevel = (val) => {
    setLevel(val.target.value);
  };
  const intothers = (val) => {
    setDescriptionOfOthers(val.target.value);
  };
  const levelothers = (val) => {
    setlevelOthers(val.target.value);
  };
  const headOfTheaccount = (val) => {
    if(collegeType=="Aided"){
    setHeadOfTheAccount(val.target.value);
    setHeadOfAccountAided(val.target.value);
    setHeadOfAccountss("");

    }
    else if(collegeType=="SS"){
      setHeadOfTheAccount(val.target.value);
    setHeadOfAccountAided("");
    setHeadOfAccountss(val.target.value);
    }
  };
  const noOfDays = (val) =>{
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
      setNoOfDaysAttended(val.target.value);
    }
  }; 
  const dept = (val) => {
    if(collegeType=="Aided"){
      setHeadOfTheAccount("");
      setHeadOfAccountAided("");
      setDepartmentId(val.target.value);
      setDepartmentIdAided(val.target.value);
      setDepartmentIdss("")
    }
    else if(collegeType=="SS"){
      setHeadOfTheAccount("");
      setHeadOfAccountss("");
      setDepartmentId(val.target.value);
      setDepartmentIdAided("");
      setDepartmentIdss(val.target.value)

    }
    
  };
  const setcollege = (val) => {
    setcollegeType(val);
  }
  const formdate = (val) => {
    debugger;
    setformDate(val.target.value);
    //setRelievingOrderIssuedon(val.target.value);
  };
  const relievingOrderIssud = (val) => {
    debugger;
    setRelievingOrderIssuedon(val.target.value);
  };

  const startdateChange = (date) => {
    debugger;
    //setStartDate(val.target.value);
    setStartDate(new Date(date));
    //setStartDate(Moment(new Date(date)).format());
    //console.log(new Date(datetime));

  }
  const enddateChange = (date) => {
    debugger;
    //setEndDate(val.target.value);
    setEndDate(new Date(date));
    //setEndDate(Moment(new Date(date)).format());
    console.log(new Date(date));
  }

  const setrelievingOrder = (val) => {
    setSubmittedReleivingOrder(val);
  };
  const taanddaBorne = (val) => {
    setTaDaBroneBy(val);
  };
  const intcertificate = (val) => {
    setSubmittedCopyOfCertificate(val);
  };

  const submitOdDetails1 = async () => {
    debugger;
    if (makerOrHodFlag == 2) {
      isChecker1Completed = true;
      checker1Status = "Submitted";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      //currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveOdDetails();
    }
    else {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      saveOdDetails();
    }
  };

  console.log(isMakerCompleted + "MAker value......");
  const submitOdDetails2 = async () => {
    debugger;
    flag = makerOrHodFlag == 2 ? 2 : 1;
    updateOdDetails();
  };
  const rejectOdDetails = async () => {
    if (currentFileQueueStatus == "HOD") {
      checker1Status = "Rejected";
    }
    else if (currentFileQueueStatus == "Office Establishment") {
      checker2Status = "Rejected";
    } else if (currentFileQueueStatus == "Office Superintendent") {
      checker3Status = "Rejected";
    } else if (currentFileQueueStatus == "Principal") {
      checker4Status = "Rejected";
    }
    // else if (currentFileQueueStatus == "Checker3") {
    //   checker3Status = "Rejected";
    // }
    isMakerCompleted = false;
    isChecker1Completed = false; isChecker2Completed = false;
    isChecker3Completed = false;
    isChecker4Completed = false;
    currentFileQueueStatus = makerOrHodFlag == 2 ? "HOD" : "Maker";
    checker1="";
    checker2="";
    checker3 = "";
    checker4 = "";
    updateOdDetails();
  }

  useEffect(() => {
    if (!od) {
      return;
    }
    runFillOdData.current = true;
    debugger;
    if (runFillOdData.current) {
      // if (JSON.parse(localStorage.getItem("user")).fdpRoleName != "Maker") {
      //   setvalidation(true);
      // }

      fillOdData(od);
    }
  }, []);


  const DownloadForm = () => {
    AsyncGetFiles(API.downloadFdpForm + "?id=" + refNo)
      .then((res) => {
        console.log(res);
        debugger;
        const url = window.URL.createObjectURL(
          new Blob([res.data], {

            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "FDPForm.docx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        // alert("");
      });
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      titleOfTheProgramme &&
      headOfAccount &&
      noOfDaysAttended &&
      level &&
      typeOfProgramme &&
      departmentId &&
      startDate &&
      endDate &&
      rowData.length &&
      formDate
    ) {
      setvalidate(false);

      return false;
    } else {
      seterrorMsg("Provide all required fields");
      return true;
    }
  };
  const onFileUpload = async (res) => {
    debugger;
    if (selectedFile?.length < 1) {
      dispatch(fetchods());
      closePopup();
      clearState();
      return;
    }

    console.log(res, "response");
    const odData = res ? res[0] : od;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("TypeofUser", "FDP");
    formData.append("id", Number(odData?.refNo || 0));

    try {
      //debugger;
      const res = await AsyncPost(API.uploadFacultyfiles, formData);
      dispatch(fetchods());
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };

  const downloadFileFun = async () => {
    //debugger;

    AsyncGetFiles(API.downloadFdpFiles + "?id=" + od.refNo)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const saveOdDetails = async () => {
    debugger;
    seterrorMsg("");
    //makerOrHodFlag = (JSON.parse(localStorage.getItem("user")).fdpRoleName) == "Maker" ? 1 : 2;
    if (makerOrHodFlag == 2 && isMakerCompleted == false) {
      currentFileQueueStatus = "HOD";
    }
    const error = validationFun();
    if (error) {
      return;
    }

    var odData = {

      //Id: 0,
      refNo: refNo,
      formDate: (formDate),
      RelievingOrderIssuedon: (formDate),
      makerOrHodFlag: makerOrHodFlag,
      departmentId: Number(departmentId),
      titleOfTheProgramme: titleOfTheProgramme,
      startDate: (startDate),
      endDate: (endDate),
      conductedBy: conductedBy,
      typeOfProgramme: typeOfProgramme,
      note: note,
      Photo: "",
      level: level,
      headOfAccount: Number(headOfAccount),
      noOfDaysAttended: Number(noOfDaysAttended),
      venue: venue,
      submittedReleivingOrder: submittedReleivingOrder,
      taDaBroneBy: taDaBroneBy,
      collegeType: collegeType,
      submittedCopyOfCertificate: submittedCopyOfCertificate,
      descriptionOfOthers: descriptionOfOthers,
      levelOthers: levelOthers,
      isMakerCompleted: isMakerCompleted,
      makerStatus: makerStatus,
									
      isChecker1Completed: isChecker1Completed,
      currentFileQueueStatus: currentFileQueueStatus,
      checker1: checker1,
      //checker2: checker2,
      checker1Status:checker1Status,
      //checker3: checker3,
      programmeDetails: JSON.stringify(rowData),
      maker: maker,
      FacultyName: FacultyName,
      //startDate: startDate,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date(),
    };
    try {
      console.log("odData", odData);
      const resp = await addOdDetails(odData, dispatch);
      //debugger;
      closePopup();
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }


  };

  const updateOdDetails = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    if(currentFileQueueStatus=="Approved! Feedback Pending" )
    //&& (submittedReleivingOrder || submittedCopyOfCertificate||taDaBroneBy))
{
  currentFileQueueStatus="Approved";
}
   else if (currentFileQueueStatus == "Principal" && isChecker3Completed == true) {
      isChecker4Completed = true;
      checker4Status = "Approved";
      currentFileQueueStatus = "Approved! Feedback Pending";
      checker4 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
  else  if (currentFileQueueStatus == "Office Superintendent" && isChecker2Completed == true) {
      isChecker3Completed = true;
      checker3Status = "Approved";
      currentFileQueueStatus = "Principal";
      checker3 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
  else  if (currentFileQueueStatus == "Office Establishment" && isChecker1Completed == true) {
      isChecker2Completed = true;
      checker2Status = "Approved";
      currentFileQueueStatus = "Office Superintendent";
      checker2 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "HOD" && isMakerCompleted == true) {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
    }
    else if (currentFileQueueStatus == "HOD" && flag == 2) {
      isChecker1Completed = true;
      checker1Status = "Approved";
      currentFileQueueStatus = "Office Establishment";
      checker1 = JSON.parse(localStorage.getItem("user")).facultyName;
      isMakerCompleted = true;
      makerStatus = "Submitted";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      
    }
    else if (currentFileQueueStatus == "Maker" && flag == 1) {
      isMakerCompleted = true;
      makerStatus = "Submitted";
      currentFileQueueStatus = "HOD";
      maker = JSON.parse(localStorage.getItem("user")).facultyName;
      checker1Status = "";
      checker2Status = "";
      checker3Status = "";
      checker4Status = "";
      


    }

    const odData = {
      refNo: refNo,
      formDate: (formDate),
      RelievingOrderIssuedon: (RelievingOrderIssuedon),
      departmentId: Number(departmentId),
      titleOfTheProgramme: titleOfTheProgramme,
      startDate: (startDate),
      endDate: (endDate),
      conductedBy: conductedBy,
      makerOrHodFlag: makerOrHodFlag,
      typeOfProgramme: typeOfProgramme,
      note: note,
      Photo: "",
      level: level,
      headOfAccount: Number(headOfAccount),
      noOfDaysAttended: Number(noOfDaysAttended),
      venue: venue,
      submittedReleivingOrder: submittedReleivingOrder,
      taDaBroneBy: taDaBroneBy,
      submittedCopyOfCertificate: submittedCopyOfCertificate,
      isMakerCompleted: isMakerCompleted,
      descriptionOfOthers: descriptionOfOthers,
      levelOthers: levelOthers,
      isChecker1Completed: isChecker1Completed,
      isChecker2Completed: isChecker2Completed,
      currentFileQueueStatus: currentFileQueueStatus,
      collegeType: collegeType,
      makerStatus: makerStatus,
      checker1Status: checker1Status,
      checker2Status: checker2Status,
      checker3Status: checker3Status,
      checker4Status: checker4Status,
      isChecker3Completed: isChecker3Completed,
      isChecker4Completed: isChecker4Completed,
      checker1: checker1,
      checker2: checker2,
      checker3: checker3,
      checker4: checker4,

      //startDate: startDate,
      //endDate: endDate,
      programmeDetails: JSON.stringify(rowData),
      maker: maker,
      FacultyName: FacultyName,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };


    try {
      // dispatch(UpdateFacultyDetails(indentData));
      dispatch(UpdateOdDetails(odData));
      await onFileUpload();
      closePopup();
      clearState();

    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  const fillOdData = (obj) => {
    debugger;
    runFillOdData.current = false;
    // console.log(obj);
    setrefNo(obj.data.refNo);
    //setMaker(obj.data.isMakerCompleted);
    maker = obj.data.maker;
    checker1 = (obj.data.checker1);
    checker2 = (obj.data.checker2);
    checker3 = (obj.data.checker3);
    checker4 = (obj.data.checker4);

    makerStatus = (obj.data.makerStatus);
    checker1Status = obj.data.checker1Status;
    checker2Status = obj.data.checker2Status;
    checker3Status = obj.data.checker3Status;
    checker4Status = obj.data.checker4Status;

    isMakerCompleted = (obj.data.isMakerCompleted);
    isChecker1Completed = obj.data.isChecker1Completed;
    isChecker2Completed = obj.data.isChecker2Completed;
    isChecker3Completed = obj.data.isChecker3Completed;
    isChecker4Completed = obj.data.isChecker4Completed;
    makerOrHodFlag = (obj.data.makerOrHodFlag);
    
    makerDate=obj.data.makerDate;
    checker1Date=obj.data.checker1Date;
    checker2Date=obj.data.checker2Date;
    checker3Date=obj.data.checker3Date;
    checker4Date=obj.data.checker4Date;

    currentFileQueueStatus = (obj.data.currentFileQueueStatus);
    if(obj.data.collegeType=="Aided")
    {
      setDepartmentId(obj.data.departmentId);
      setDepartmentIdAided(obj.data.departmentId);
      setDepartmentIdss("");
      setHeadOfAccountAided(obj.data.headOfAccount);
      setHeadOfAccountss("");
      setHeadOfTheAccount(obj.data.headOfAccount);
    }
    else if(obj.data.collegeType=="SS"){
      setDepartmentId(obj.data.departmentId);
      setDepartmentIdAided("");
      setDepartmentIdss(obj.data.departmentId);
      setHeadOfAccountAided("");
      setHeadOfAccountss(obj.data.headOfAccount);
      setHeadOfTheAccount(obj.data.headOfAccount);
    }
    setformDate(obj.data.formDate);
    setRelievingOrderIssuedon(obj.data.relievingOrderIssuedon);
    settitleOfTheProgramme(obj.data.titleOfTheProgramme);
    setConductedBy(obj.data.conductedBy);
    setVenue(obj.data.venue);
    setTypeOfProgramme(obj.data.typeOfProgramme);
    setNote(obj.data.note);
    setfilesList(obj.files);
    setLevel(obj.data.level);
    
    setNoOfDaysAttended(obj.data.noOfDaysAttended);
    setDescriptionOfOthers(obj.data.descriptionOfOthers);
    setlevelOthers(obj.data.levelOthers);
    
    setStartDate(new Date(obj.data.startDate));
    setEndDate(new Date(obj.data.endDate));
    setFacultyName(obj.data.facultyName);
    setSubmittedCopyOfCertificate(obj.data.submittedCopyOfCertificate);
    setTaDaBroneBy(obj.data.taDaBroneBy);
    setSubmittedReleivingOrder(obj.data.submittedReleivingOrder);
    setcollegeType(obj.data.collegeType);
    setSNo(obj.data.sNo);
    setNameofFaculty(obj.data.nameofFaculty);
    setDesignation(obj.data.designation);
    setDepartmentName(obj.data.departmentName);
    setAppexTAandDA(obj.data.appexTAandDA);
    setRegistrationFees(obj.data.registrationFees);
    setTotalBudget(obj.data.totalBudget);
    //setprogrammeDetails(obj.data.programmeDetails);
    setRowData(JSON.parse(obj.data.programmeDetails));
    //setFaculty_Photo(obj.data.photo);
    setPostApproValid(((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Establishment") &&
    ((obj.data.currentFileQueueStatus == "Approved! Feedback Pending")|| (obj.data.currentFileQueueStatus == "Approved"))) ? true : false);
    setDisableValid(((obj.data.currentFileQueueStatus == "Approved! Feedback Pending")|| (obj.data.currentFileQueueStatus == "Approved"))? false : true);
    setvalidation((obj.data.currentFileQueueStatus == "Maker") ? false : true);
    setFieldValid((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Superintendent") || (JSON.parse(localStorage.getItem("user")).fdpRoleName == "Principal")
      || ((obj.data.currentFileQueueStatus == "Approved! Feedback Pending")|| (obj.data.currentFileQueueStatus == "Approved")) ? true : false);
    setNoteValid(((obj.data.currentFileQueueStatus == "Approved! Feedback Pending")|| (obj.data.currentFileQueueStatus == "Approved")) && (JSON.parse(localStorage.getItem("user")).fdpRoleName != "Office Establishment") ? true : false);
    //&&(JSON.parse(localStorage.getItem("user")).fdpRoleName != "Office Establishment")
    seterrorMsg("");
    console.log(rowData);

    runFillOdData.current = true;
  };

  return (
    <div className="popup">

      <div className="col">
        <div className="formdata-check">
          <button
            className="col-xs-1 col-md-1 col-lg-1 col-sm-1 btn btn-lg btnclose-x"
            onClick={() => closePopupFun()}
            type="button"
          >
            X
          </button>
          <div className="row">
          {/* <h2 className="col heading">{isEdit ? "Edit" : "Add New "}</h2> */}
            <h2 className="col heading"> {isEdit ? "Edit FDP Form" : "FDP Form"}</h2>
            {((JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Establishment")
              || (JSON.parse(localStorage.getItem("user")).fdpRoleName == "Office Superintendent")) && (currentFileQueueStatus === "Approved! Feedback Pending"||currentFileQueueStatus === "Approved") ?
              <div className="but col text-end">
                <button className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                  type="button" onClick={() => DownloadForm()}>
                  <span className="glyphicon glyphicon-remove"></span> Download Form</button>
              </div> : null}


          </div>


          <div className="d-flex justify-content-center align-items-enter">
            {/*{errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}*/}
          </div>
          <div className="row">

            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn"
                  id="inlineRadio"
                  value="5"
                  checked={collegeType === "Aided"}
                  onChange={() => {
                    setcollegeType("Aided");
                    setDepartmentId(departmentIdAided);
                    setHeadOfTheAccount(HeadOfAccountAided);
                    //setLabListUpdated(LabList.filter(x => x.collegeType == "Aided"));


                    // setHeadListUpdated(HeadList.filter(x =>x.collegeType == "Aided"));
                  }}

                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                  Aided
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn"
                  id="inlineRadio"
                  value="6"
                  checked={collegeType === "SS"}
                  onChange={() => {
                    setcollegeType("SS");
                    setDepartmentId(departmentIdss);
                    setHeadOfTheAccount(HeadOfAccountss);
                    //setLabListUpdated(LabList.filter(x => x.collegeType == "SS"));


                    //setHeadListUpdated(HeadList.filter(x =>x.collegeType == "SS"));
                  }}
                  disabled={FieldValid}

                />
                <label className="form-check-label checkposition">
                Self Supporting
                </label>

              </div>

            </div>

          </div>
          <div className="row formadduser">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label><span style={{ color: "red" }}>*</span>FDP Request Form Date</label>
              <input
                className="form-control"
                type="date"
                value={dayjs(formDate).format("YYYY-MM-DD")}
                // max={Moment().format('YYYY-MM-DD')}
                onChange={formdate}
                disabled={FieldValid}
              />
              {!formDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Title of the Programme
              </label>
              <input
                className="form-control"
                type="text"
                value={titleOfTheProgramme}
                onChange={titleOfTheprogramme}
                maxLength={150}
                disabled={FieldValid}

              />
              {!titleOfTheProgramme && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>Conducted By</label>
              <input
                className="form-control"
                type="text"
                value={conductedBy}
                onChange={conductedby}
                maxLength={150}
                disabled={FieldValid}

              />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>Faculty Name</label>
              <input
                className="form-control"
                type="text"
                value={FacultyName}
                onChange={fltyname}
                maxLength={300}
                disabled={FieldValid}

              />
            </div>

            {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <input
                className="form-control"
                type="text"
                onChange={headOfTheaccount}
                maxLength={150}
                value={headOfAccount}
                disabled={validation}
              >

              </input>
              {!headOfAccount && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>*/}
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Programme Name
              </label>
              <select
                className="form-select form-control"
                onChange={dept}
                aria-label="Default select example"
                value={departmentId}
                disabled={FieldValid}
              >
                <option value="">--Select Programme--</option>
                {deparmentList?.map((opt) => (
                  <option key={opt.id} value={opt.id}>
                    {opt.name}
                  </option>
                ))}

              </select>
              {!departmentId && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <select
                className="form-select form-control"
                onChange={headOfTheaccount}
                aria-label="Default select example"
                value={headOfAccount}
                disabled={FieldValid}
              >
                <option value="">--Select Head--</option>
                {(HeadList.filter(x => x.isActiveFdp == 1))?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.headOfAccount}
                  </option>
                ))}
              </select>
              {!headOfAccount && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Start Date
              </label>
              <DatePicker
                className="form-select form-control"

                placeholderText="Select Start Date"
                showTimeSelect
                //value={startDate}
                timeFormat="HH:mm"
                timeCaption="time"
                dateFormat="yyyy-MM-dd HH:mm"
                selected={startDate}
                //maxDate={new Date()}
                //minDate={new Date()}
                dataTimezone='IST'
                displayTimezone='IST'
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={startdateChange}
                disabled={FieldValid}
              />
              {!startDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>
                <span style={{ color: "red" }}>*</span>End Date
              </label>
              {/* <DateTimePicker
                
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              /> */}
              <DatePicker
                className="form-select form-control"
                //value={endDate}
                timeFormat="HH:mm"
                timeCaption="time"
                placeholderText="Select End Date"
                showTimeSelect
                //dateFormat="dd-mm-yyyy hh:mm"
                dateFormat="yyyy-MM-dd HH:mm"
                selected={endDate}
                dataTimezone='IST'
                displayTimezone='IST'
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={enddateChange}
                disabled={FieldValid}
              />
              {!endDate && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Duration in Day(s)
              </label>
              <input
                className="form-control"
                type="text"
                onChange={noOfDays}
                maxLength={3}
                value={noOfDaysAttended}
                disabled={FieldValid}
              >
              </input>
              {!noOfDaysAttended && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Level
              </label>
              <select
                className="form-select form-control"
                onChange={intlevel}
                aria-label="Default select example"
                value={level}
                disabled={FieldValid}
              >
                <option value="">--Select Level--</option>
                <option value="District">District</option>
                <option value="State">State</option>
                <option value="Regional">Regional</option>
                <option value="International">International</option>
                <option value="Inhouse">Inhouse</option>
                <option value="Other">Other</option>

              </select>
              {!level && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            {level === "Other" && (
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6 form-group">
                <label>
                  Description of Other
                </label>
                <input
                  className="form-control"
                  //id="descriptionOfAdmissions"
                  value={levelOthers}
                  onChange={levelothers}
                  //rows="5"
                  maxLength={150}
                  disabled={FieldValid}
                >
                </input>
              </div>

            )}
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Venue
              </label>
              <input
                className="form-control"
                value={venue}
                onChange={intvenue}
                //rows="5"
                maxLength={300}
                disabled={FieldValid}
              >
              </input>

              {!venue && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Type of Programme
              </label>
              <select
                className="form-select form-control"
                onChange={typeofprogramme}
                aria-label="Default select example"
                value={typeOfProgramme}
                disabled={FieldValid}
              >
                <option value="">--Select Type of Programme--</option>
                <option value="Seminar">Seminar</option>
                <option value="Workshop">Workshop</option>
                <option value="Conference">Conference</option>
                <option value="Paper Presentation">Paper Presentation</option>
                <option value="Industrial Tour">Industrial Tour</option>
                <option value="Study Visit">Study Visit</option>
                <option value="Others">Others</option>

              </select>
              {!typeOfProgramme && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>
            {typeOfProgramme === "Others" && (
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-6 form-group">
                <label>
                  Description of Others
                </label>
                <input
                  className="form-control"
                  //id="descriptionOfAdmissions"
                  value={descriptionOfOthers}
                  onChange={intothers}
                  //rows="5"
                  maxLength={150}
                  disabled={FieldValid}
                >
                </input>
              </div>

            )}
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group ag-theme-alpine">
              <div className="row">
                <label className="col pt-2 form-group-x" style={{ fontSize: 16 }}><span style={{ color: "red" }}>*</span>Programme Details</label>
                {DisableValid ? <div className="but col text-end">
                  <button className="btn btn-lg btn-success btn-block form-roundedbtn3 mb-3" onClick={OnInsertOne}>
                    <span className="glyphicon glyphicon-plus"></span> Add </button>
                  <button className="btn btn-lg btn-danger btn-block form-roundedbtn2 mb-3" onClick={onRemove}>
                    <span className="glyphicon glyphicon-plus"></span> Delete</button>
                  {/* <button onClick={onRemove}>Remove Row</button> */}
                </div> : null}
                <div style={{ pointerEvents: DisableValid ? 'auto' : 'none', fontFamily: 'Open sans', fontSize: 16 ,width: '100%', height: '100%', fontWeight: 'normal'  }}>
                  <AgGridReact ref={gridRef}
                    modules={AllCommunityModules}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    gridOptions={gridOptions}
                    rowSelection={'multiple'}
                    editable={DisableValid}
                    columnDefs={columnDefs}
                  >
                  </AgGridReact>
                </div>
                {!rowData.length && validate ? (<h6 style={{ color: "red", fontWeight: "normal" }}>{"Requird"}</h6>) : null}
              </div>
            </div>
            {PostApproValid ?
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Relieving Order Issued On</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(RelievingOrderIssuedon).format("YYYY-MM-DD")}
                  // max={Moment().format('YYYY-MM-DD')}
                  onChange={relievingOrderIssud}
                //disabled={validation}
                />

              </div> : null}
            <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12 form-group tt txtarea">
              <label>
                Note/Remarks
              </label>
              <textarea
                className="form-control clsabstract"
                id="note"
                value={note}
                onChange={setnote}
                rows="5"
                maxLength={1000}
                disabled={NoteValid}
              />
            </div>
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
              <label>Attachment</label>
              <input
                className="form-control"
                onChange={onFileChange}
                type="file"
                id="formFileMultiple"
                disabled={NoteValid}

              />
              {filesList?.length > 0 && (
                <div className="d-flex align-items-center mt-2  rounded">
                  <div className="border rounded download-list-block">
                    {filesList?.map((item, index) => (
                      <h6 className="border-bottom ms-1" key={index}>
                        {item || "No File Name"}
                      </h6>
                    ))}
                  </div>
                  <button
                    className="btn btn-sm btn-primary btn-block "
                    type="button"
                    onClick={() => downloadFileFun()}
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="row formadduser form-group"><label className="form-check-label" style={{ color: "#515151" }}>Required Attachments</label>
            <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
              <li className="form-check-label checkposition">Brochure /Poster</li>
              <li className="form-check-label checkposition">Permission letter from the Head of Institution</li>
              <li className="form-check-label checkposition">Acceptance letter from Organization/Industry</li>
            </ul> </div>
          
          <div className="row formadduser form-group">

            <label className="form-check-label" style={{ color: "#515151" }}> Approved By</label>
            
            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
            <ul >
              {maker ==null || maker == ""?" ": maker+ " [" + dayjs(makerDate).format("DD-MM-YYYY")+"]"}  
              <br></br>              
              <label className="mr-5" style={{ fontWeight: 'bold' }}>Staff-in-Charge</label>
            </ul>
           </div>
           <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
            <ul >
             {checker1 ==null || checker1 == ""?" ":checker1 + " [" +dayjs(checker1Date).format("DD-MM-YYYY")+"]"}
             <br></br> 
             <label style={{ fontWeight: 'bold' }}>Head of Department </label>
            </ul>
            </div>
            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
            <ul >
            {checker2 ==null || checker2 == ""?" ":checker2+" ["+dayjs(checker2Date).format("DD-MM-YYYY")+"]"} 
            <br></br>          
              <label style={{ fontWeight: 'bold' }}>Office Establishment</label>
            </ul>
            </div>
            </div>
          
           
            <div className="row formadduser form-group">
            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
            <ul>
            {checker3 ==null || checker3 == ""?" ":checker3+" ["+dayjs(checker3Date).format("DD-MM-YYYY")+"]"}
            <br></br>            
              <label style={{ fontWeight: 'bold' }}>Office Superintendent</label>
            </ul>
            </div>

            <div className="col-xs-6 col-md-4 col-lg-4 col-sm-12 mt-4">
            <ul>
            {checker4 ==null || checker4 == ""?" ":checker4+" ["+dayjs(checker4Date).format("DD-MM-YYYY")+"]"}
            <br></br>             
              <label style={{fontWeight:'bold'}}>PRINCIPAL</label>
            </ul>
            </div>
          </div>
           
            {/*<ul style={{ listStyleType: 'disc', }}>
              <li className="form-check-label checkposition">Office Superintendent:{checker3}

              </li>
              <li className="form-check-label checkposition">Office Superintendent Approved Date:{dayjs(checker3Date).format("DD-MM-YYYY")}</li>
              <li className="form-check-label checkposition">Office Superintendent Status:{checker3Status}</li>
            </ul>*/}
            
          {/* <div className="row formadduser">
            <label className="">Required Attachments</label>
            <label className="form-cform-check-xheck-label checkposition">
                1. Brochure /Poster
              </label>
              <label className="form-check-label checkposition">
                2. Permission letter from the Head of Institution
              </label><label className="form-check-label checkposition">
              3. Acceptance letter from Organization/Industry              </label>
            </div> */}
          <div className="row formadduser" style={{color: "#515151"}}>
            <label>Feed Back</label>
            <div className="checksection">
              <label className="form-check-label checkposition">
                Whether submitted the Relieving order
              </label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn1"
                  id="inlineRadio3"
                  value="4"
                  checked={submittedReleivingOrder == "true"}
                  onChange={() => setrelievingOrder("true")}
                />
                <label className="form-check-label checkposition">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn1"
                  id="inlineRadio4"
                  value="5"
                  checked={submittedReleivingOrder == "false"}
                  onChange={() => setrelievingOrder("false")}
                />
                <label className="form-check-label checkposition">
                  No
                </label>
              </div>
            </div>
            <div className="checksection">
              <label className="form-check-label checkposition">
                Whether submitted the copy of certificate
              </label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn custom-control-input"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio5"
                  value="1"
                  //type="checkbox"
                  checked={submittedCopyOfCertificate == "true"}
                  onChange={() => intcertificate("true")}
                />
                <label className="form-check-label checkposition">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn3"
                  id="inlineRadio6"
                  value="2"
                  checked={submittedCopyOfCertificate == "false"}
                  onChange={() => intcertificate("false")}
                />
                <label className="form-check-label checkposition">
                  No
                </label>
              </div>
            </div>
            <div className="checksection">
              <label className="form-check-label checkposition">Whether TA/DA borne by</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn2"
                  id="inlineRadio5"
                  // type="checkbox"
                  // checked={taDaBroneBy}
                  // onChange={taanddaBorne}
                  checked={taDaBroneBy === "TPT"}
                  onChange={() => taanddaBorne("TPT")}
                />
                <label className="form-check-label checkposition">
                  TPT
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn"
                  type="radio"
                  name="radio-btn2"
                  id="inlineRadio6"
                  //type="checkbox"
                  //checked={taDaBroneBy}
                  //onChange={taanddaBorne}
                  checked={taDaBroneBy === "Convenors"}
                  onChange={() => taanddaBorne("Convenors")}
                />
                <label className="form-check-label checkposition">
                  Convenors
                </label>
              </div>
            </div>
          </div>
          &nbsp;
          <div className="d-flex justify-content-center align-items-enter">
            {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
          </div>
          {PostApproValid ? <div>
            <button
              className="btn btn-lg btn-primary btn-block form-btnverify"
              type="button"
              onClick={() => updateOdDetails()}
            >
              Submit
            </button></div> : <p>{
              DisableValid ?
                <p>
                  {
                    validation && isMakerCompleted ?
                      (
                        <div>
                          {(JSON.parse(localStorage.getItem("user")).fdpRoleName) == 'HOD' ?
                            <button
                              className="btn btn-lg btn-primary btn-block form-btnapprove"
                              type="button"
                              onClick={() => updateOdDetails()}
                            >
                              Submit
                            </button> :
                            (JSON.parse(localStorage.getItem("user")).fdpRoleName) == 'Principal' ?
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnapprove"
                                type="button"
                                onClick={() => updateOdDetails()}
                              >
                                Approve
                              </button> :
                              <button
                                className="btn btn-lg btn-primary btn-block form-btnverify"
                                type="button"
                                onClick={() => updateOdDetails()}
                              >
                                Verify & Submit
                              </button>}
                          <button
                            className="btn btn-lg btn-primary btn-block form-btnreject"
                            type="button"
                            onClick={() => rejectOdDetails()}
                          >
                            Reject
                          </button>
                        </div>
                      ) :
                      (!od ? (
                        <div>
                          <button
                            className="btn btn-primary btn-block form-roundedbtn"
                            type="button"
                            onClick={() => saveOdDetails()}
                          >
                            Save
                          </button>
                          <button
                            className="btn btn-primary btn-block form-roundedsubmitbtn "
                            type="button"
                            onClick={() => submitOdDetails1()}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            className="btn btn-lg btn-primary btn-block form-roundedbtn"
                            type="button"
                            onClick={() => updateOdDetails()}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-lg btn-primary btn-block form-roundedsubmitbtn "
                            type="button"
                            onClick={() => submitOdDetails2()}
                          >
                            Submit
                          </button>
                        </div>
                      ))
                  }
                </p>
                : <div>
                  <button
                    className="btn btn-lg btn-primary btn-block form-roundedbtn3 buttonW"
                    type="button"
                    onClick={() => updateOdDetails()}
                  >
                    Submit Feedback
                  </button></div>}</p>}
        </div>
      </div >
    </div >
  );
};

export default AddOdDetails;