//
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";


import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";
import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";

const NewItem = ({isEdit, item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  // const navigate = useNavigate();
  //state
  const [EventDate, setEventDate] = useState("");

  const [Topic, setTopic] = useState("");
  //const [Abstract, setAbstract] = useState("");

  const [DepartmentSemester, setDepartmentSemester] = useState("");
  const [DepartmentDesignation, setDepartmentDesignation] = useState("");
  const [TypeOfEvent, setEvent] = useState("");
  const [nParticipants, setnParticipants] = useState("");
  const [EventName, setEventName] = useState("");
 // const [Impact, setImpact] = useState("");
  const [NameOfTheChiefGuest, setGuestName] = useState("");
  const [NameOfTheChiefGuest2, setGuestName2] = useState("");
  const [NameOfTheChiefGuest3, setGuestName3] = useState("");
  const [NameOfTheChiefGuest4, setGuestName4] = useState("");
  const [NameOfTheChiefGuest5, setGuestName5] = useState("");

  //const [OffLineOrOnLine, setOffLineOrOnLine] = useState("Offline");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  //const [datevalidation1, setdatevalidation] = useState(true);
  const [datevalidation, setdatevalidation] = useState(true);
  const [resetStudentORFaculty, setresetStudentORFaculty] = useState(false);
  const runFillActivityData = useRef();
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const clearState = () => {
    setEventDate("");

    setTopic("");
    setnParticipants("");


    setEvent("");

    setEventName("");
    //setImpact("");
    setGuestName("");
    setGuestName2("");
    setGuestName3("");
    setGuestName4("");
    setGuestName5("");


    //setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");

    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };


  const fromDatechange = (val) => {

    setEventDate(`${new Date(val.target.value).getMonth() + 1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
    //setToDate('');
    setdatevalidation(false);
  };

  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };


  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(48, null));
      closePopup();
      clearState();
      return;
    }
    const activityData = res ? res[0] : activity;
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Events"
    );
    console.log(Number(activityData?.id || 0), "Number(activityData?.id || 0)");
    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(48, null));
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };
  const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async () => {
    debugger;
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 48,
      Topic: Topic,
      EventName: EventName,
      nParticipants: nParticipants,
      NameOfTheChiefGuest: NameOfTheChiefGuest,
      NameOfTheChiefGuest2:NameOfTheChiefGuest2,
      NameOfTheChiefGuest3:NameOfTheChiefGuest3,
      NameOfTheChiefGuest4:NameOfTheChiefGuest4,
      NameOfTheChiefGuest5:NameOfTheChiefGuest5,
      EventDate: EventDate,//new Date(EventDate).toLocaleDateString(),      
      
      Attachment: "",// selectedFile,

    };
    var activityData = {
      id: 0,
      activityID: 48,
      activityName: "Events",
      data: JSON.stringify(data),
      filePath: "",//selectedFile
      typeOfEvent:TypeOfEvent,
      departmentID: 0,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate: new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };

  const updateActivity = async () => {
    debugger;
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 48,
      Topic: Topic,
      TypeOfEvent: TypeOfEvent,
      NameOfTheChiefGuest: NameOfTheChiefGuest,
      NameOfTheChiefGuest2:NameOfTheChiefGuest2,
      NameOfTheChiefGuest3:NameOfTheChiefGuest3,
      NameOfTheChiefGuest4:NameOfTheChiefGuest4,
      NameOfTheChiefGuest5:NameOfTheChiefGuest5,

      EventName: EventName,
      nParticipants: nParticipants,
      EventDate: EventDate,//new Date(EventDate).toLocaleDateString(),

     
      Attachment: "",

    };
    const activityData = {
      ...activity,
      activityID: 48,
      activityName: "Events",
      data: JSON.stringify(data),
      //filePath: selectedFile,
    };

    try {
      dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };


  const validationFun = () => {
    setvalidate(true);
    if (
      Topic &&
      EventDate &&

      EventName &&
      NameOfTheChiefGuest2 &&
      NameOfTheChiefGuest
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "topic":
        setTopic(e.target.value);
        break;

      case "nparticipants":

        var nparticipants = e.target.value.replace(/\D/g, "");
        setnParticipants(nparticipants);

        break;

      case "departmentsemester":
        setDepartmentSemester(e.target.value);
        break;
      case "departmentdesigantion":
        setDepartmentDesignation(e.target.value);
        break;
      case "event":
        setEvent(e.target.value);
        break;
      
      case "nameOfTheChiefGuest":
        setGuestName(e.target.value);
        break;
        case "nameOfTheChiefGuest2":
        setGuestName2(e.target.value);
        break;
        case "nameOfTheChiefGuest3":
        setGuestName3(e.target.value);
        break;
        case "nameOfTheChiefGuest4":
        setGuestName4(e.target.value);
        break;
        case "nameOfTheChiefGuest5":
        setGuestName5(e.target.value);
        break;
      case "eventName":
        setEventName(e.target.value);

      // if(/^[a-z A-Z 0-9-]*$/.test(e.target.value) == false){
      //   //error message
      // }     else
      // {
      // setEventName(e.target.value);
      // }
    }
  };
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    console.log(AllFiles);
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const fillActivityData = (data) => {
    runFillActivityData.current = false;
    const obj = data;
    const objData = obj.data;
    console.log(obj);
    setEventDate(objData.EventDate);
    setTopic(objData.Topic);
    setEvent(objData.TypeOfEvent);
    setnParticipants(objData.nParticipants);
    setEventName(objData.EventName);
    //setImpact(objData.Impact);
    setGuestName(objData.NameOfTheChiefGuest);
    setGuestName2(objData.NameOfTheChiefGuest2);
    setGuestName3(objData.NameOfTheChiefGuest3);
    setGuestName4(objData.NameOfTheChiefGuest4);
    setGuestName5(objData.NameOfTheChiefGuest5);

    // setOffLineOrOnLine(objData.OnlineOrOffline);
    setfilesList(obj.files);
    setCreatedBy(obj.createdDate);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">



            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Event Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(EventDate).format("YYYY-MM-DD")}
                  min={Moment().format('YYYY-MM-DD')}
                  onChange={fromDatechange}
                  disabled={Fromdatevalidation1}
                />
                {!EventDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select The Date"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Event Name</label>
                <input
                  className="form-control"
                  type="text"
                  id="eventName"
                  value={EventName}
                  onChange={onTextValueChange}
                  maxLength={300}
                />
                {!EventName && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>

            
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Topic</label>
                <input
                  className="form-control"
                  type="text"
                  id="topic"
                  value={Topic}
                  onChange={onTextValueChange}
                  maxLength={150}
                />
                {!Topic && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>Type of Event</label>
                <input
                  className="form-control"
                  type="text"
                  id="event"
                  value={TypeOfEvent}
                  onChange={onTextValueChange}
                  maxLength={150}
                />

              </div>
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group tt txtarea">
                <label><span style={{ color: "red" }}>*</span>Name of the Chief Guest</label>
                <textarea
                  className="form-control clsabstract"
                  type="text"
                  id="nameOfTheChiefGuest"
                  value={NameOfTheChiefGuest}
                  onChange={onTextValueChange}
                  maxLength={512}
                  rows={25}
                    cols={85}
                />
                {!NameOfTheChiefGuest && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12  col-sm-12  form-group tt txtarea">
                <label><span style={{ color: "red" }}>*</span>Name of the Chief Guest 2</label>
                <textarea
                  className="form-control clsabstract"
                  type="text"
                  id="nameOfTheChiefGuest2"
                  value={NameOfTheChiefGuest2}
                  onChange={onTextValueChange}
                  maxLength={512}
                  rows={25}
                    cols={85}
                />
                {!NameOfTheChiefGuest2 && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12  col-sm-12  form-group tt txtarea">
                <label>Name of the Chief Guest 3</label>
                <textarea
                  className="form-control clsabstract"
                  type="text"
                  id="nameOfTheChiefGuest3"
                  value={NameOfTheChiefGuest3}
                  onChange={onTextValueChange}
                  maxLength={512}
                  rows={25}
                    cols={85}
                />
                
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12  col-sm-12  form-group tt txtarea">
                <label>Name of the Chief Guest 4</label>
                <textarea
                  className="form-control clsabstract"
                  type="text"
                  id="nameOfTheChiefGuest4"
                  value={NameOfTheChiefGuest4}
                  onChange={onTextValueChange}
                  maxLength={512}
                  rows={25}
                    cols={85}
                />
               
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12  col-sm-12  form-group tt tt txtarea">
                <label>Name of the Chief Guest 5</label>
                <textarea
                  className="form-control clsabstract"
                  type="text"
                  id="nameOfTheChiefGuest5"
                  value={NameOfTheChiefGuest5}
                  onChange={onTextValueChange}
                  maxLength={512}
                  rows={25}
                    cols={85}
                />
                
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>No of Participants</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nparticipants"
                  value={nParticipants}
                  //value={Number(ExternalParticipants) + Number(nParticipantsGirls)+  Number(nParticipantsBoys)}
                  onChange={onTextValueChange}
                  maxLength={4}

                />
              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
                {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/*&nbsp;*/}
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>


            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
