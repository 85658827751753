import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//import { AsyncPost } from "../../../app/services/https";
import { API } from "../../../app/services/endpoints";
import{baseURL} from "../../../app/services/https";
import {
  AsyncGetFiles,
} from "../../../app/services/https";
import {
  UpdateQuatationStatus
} from "../../../app/redux/slices/indentSlice";
const headers = {
  "Access-Control-Allow-Origin": "*",
  "Contant-Type": "application/pdf",
  //"content-length": "110358"

};
var fileData;
//const [Pdf, setPdf]=useState("");

var formdata = 0;
var id = 0;
var flag = 0;
var validation = false, DowValidation = false;
//let currentFileQueueStatus =formdata.currentFileQueueStatus;
const DocumentViewer = ({ item, closePop, documentsviewer }) => {

  const [Pdf, setPdf]=useState([]);

  formdata = JSON.parse(localStorage.getItem("formDetails"));
  id = formdata.refNo;
  flag = formdata.principalFlag;
  console.log("formdata   " + formdata);
  validation = (JSON.parse(localStorage.getItem("user")).indentRoleName == "Principal") && formdata.principalFlag == 2 ? true : false;
  DowValidation = formdata.principalFlag > 2 ? true : false;
  console.log("validation =" + validation);
  const navigate = useNavigate();
  const closePopupFun = () => {
    debugger;
    closePop();

    //window.location.reload(false);
  };
  // useEffect(() => {
  //   debugger;
  //   AsyncGetFiles(API.downloadQuatationForm + "?id=" + 53 +"&flag="+3)
  //     .then((response) => {
  //       setPdf(response.data);
  //       console.log(response.data);
  //       //fileData=response.data;
  //        //file = new Blob([response.data], { type: "application/pdf" }); // For  API which returns blob
  //       //setPdf(file); // Need store file and correct render like PDF
  //     })
  //     .catch((err) => {
  //      // process err        console.log(err);

  //     });
  // }, []);
  const docs = [

    {
      //uri:(AsyncGetFiles(API.downloadQuatationForm + "?id=" + id + "&flag=" + flag).then((response)=>{console.log(response);return new Blob([response.data], { type: "application/pdf" });}).catch((err)=>{console.log(err);})),
      uri:(baseURL+API.downloadQuatationForm+"?id=" + id + "&flag=" + flag),
      //uri:fileData,
      fileType: "pdf",
      fileName: "Report.pdf",
    }

  ];
 
  const UpdateQuatation = async (data) => {
    debugger;
    var flag = formdata.principalFlag;

    if (data) {
      formdata.principalFlag = 3;
      formdata.currentFileQueueStatus = "Quotation Approved";
    }
    else {
      formdata.principalFlag = 1;
      // formdata.currentFileQueueStatus="Maker";
      // formdata.isMakerCompleted = false;
      // formdata.isChecker1Completed = false; formdata.isChecker2Completed = false; formdata.isChecker3Completed = false;
      // formdata.isChecker4Completed = false;
      formdata.currentFileQueueStatus = "Waiting for Quotation";
    }
    var quatationData =
    {
      RefNo: formdata.refNo,
      principalFlag: formdata.principalFlag,
      currentFileQueueStatus: formdata.currentFileQueueStatus,
      isMakerCompleted: formdata.isMakerCompleted,
      isChecker1Completed: formdata.isChecker1Completed,
      isChecker2Completed: formdata.isChecker2Completed,
      isChecker3Completed: formdata.isChecker3Completed,
      isChecker4Completed: formdata.isChecker4Completed,
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date(),
    };
    try {
      // dispatch(UpdateFacultyDetails(indentData));
      console.log("quatationData", quatationData);
      dispatch(UpdateQuatationStatus(quatationData));
      //await onFileUpload();
      // closePopup();
      // clearState();
      gotoindent();

    } catch (error) {
      console.log(error);
      gotoindent();
      // closePopup();
      // clearState();
    }
    // AsyncPost(API.UpdateQuatationStatus +"?id="+formId+"&principalFlag="+flag)
  };
  const dispatch = useDispatch();
  const downloadQuatation = () => {

    AsyncGetFiles(API.downloadQuatationForm + "?id=" + id +"&flag="+flag)
      .then((res) => {
        console.log(res);
        debugger;
        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: "application/pdf",
          }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "QuotationForm.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        // alert("");
      });

  }

  const gotoindent = () => {
    navigate(-1);
  }
  return (
    <>
      <div className="popup border">

        <div className="col">
          <div className="formdata-check">
            <h2 className="heading">Quotation Form</h2>
            <button
              className="col-xs-1 col-md-1 col-lg-1 col-sm-1 btn btn-lg btnclose-x"
              onClick={() => gotoindent()}
              type="button"
            >
              X
            </button>

            {DowValidation ?
              <div className="but col text-end">
                <button className="btn btn-lg btn-secondary btn-block form-roundedbtn2 buttonW"
                  type="button" onClick={() => downloadQuatation()}>
                  <span className="glyphicon glyphicon-remove"></span> Download</button>
              </div> : null}
            <div className="row formadduser">
              <DocViewer
              //  documents={(fileData => ({
              //   uri: window.URL.createObjectURL(new Blob([fileData], {
              //     type: "application/pdf",
              //     charset:"utf-8",
              //   })),             
              //   fileName: "Report.pdf",
              // }))}
                documents={docs}
               prefetchMethod="GET"
                style={{ width: 1350, alignContent: "center" }}
                pluginRenderers={DocViewerRenderers}
                requestHeaders={headers}

              />
            </div>
            <div>

              {validation ?
                //(formdata.currentFileQueueStatus == "Quotation Submitted")) ?
                <div>
                  <button
                    className="btn btn-lg btn-primary btn-block form-btnapprove"
                    type="button"
                    onClick={() => UpdateQuatation(true)}
                  >
                    Approve
                  </button>

                  <button
                    className="btn btn-lg btn-primary btn-block form-btnreject"
                    type="button"
                    onClick={() => UpdateQuatation(false)}
                  >
                    Reject
                  </button> </div> : null}</div>

          </div>
        </div>
      </div>

    </>
  );
}
export default DocumentViewer;