import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import Moment from "moment";
import {
  addPurchasedOrderDetails,
  UpdatePurchasedOrderDetail
} from "../../../app/redux/slices/purchasedOrderDetailsSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet
} from "../../../app/services/https";
var UpdatedStock=0;
var budgetData;
var stackData;
var UpdatedPrize=0;
const AddPurchasedOrderDetails = ({ isEdit, item, closePopup, purchasedOrder }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!purchasedOrder) {
      return;
    }
    runFillPurchasedOrderData.current = true;
    if (runFillPurchasedOrderData.current) {
      fillPurchasedOrderData(purchasedOrder);
    }
  }, []);
  
  const [Id, setId] = useState("");
  const [InventoryId,setInventoryId]= useState("");
  const [Specification,setSpecification]= useState("");
  const [InventoryDescription,setInventoryDescription]= useState("");
  //const [AvailableQuantity,setAvailableQuantity]= useState("");
  const [PurchasedDate,setPurchasedDate] = useState("");
  //const [PurchasedBy,setPurchasedBy] = useState("");
  const [Store,setStore] = useState("");
  const [InventoryList, setInventoryList] = useState([]);
  const [VendarDetails,setVendarDetails] = useState("");
  const [RequestedBy,setRequestedBy] = useState("");
  const [HeadOfAccount, setHeadOfTheAccount] = useState("");
  const [HeadOfAccountAided, setHeadOfTheAccountAided] = useState("");
  const [HeadOfAccountss, setHeadOfTheAccountss] = useState("");
  const [HeadList, setHeadList] = useState([]);
  const [StackList, setStackList] = useState([]);
  const[UpdateCheck, setUpdateCheck] =useState(false);
  //const[UpdatedStock,setUpdatedStock]=useState("");
  const [IndentQuantity,setIndentQuantity] = useState("");
  const [PrintedQuantity,setPrintedQuantity] = useState("");
  const [ReceivedQuantity,setReceivedQuantity] = useState("");
  const [AvailableQuantity,setAvailableQuantity] = useState("");

  const [UnitPrize,setUnitPrize] = useState("");
  const [TotalPrize,setTotalPrize] = useState(0);
  const [OldPrize,setOldPrize] = useState(0);

  const [Discount,setDiscount] = useState();
  const [DeparmentList, setDeparmentList] = useState([]);
  //const [BudgetHeadList, setBudgetHeadList] = useState([]);
  const [BudgetAmt, setBudgetAmt] = useState(0);
  const [BudgetAmtAided, setBudgetAmtAided] = useState();
  const [BudgetAmtss, setBudgetAmtss] = useState();
  const [BudgetHeadList, setBudgetHeadList] = useState([]);

  const[DepartmentId,setDepartmentId]=useState("");
  const[DepartmentIdAided,setDepartmentIdAided]=useState("");
  const[DepartmentIdss,setDepartmentIdss]=useState("");
  const [CollegeType, setCollegeType] = useState("");
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [TotalPrizeerrorMsg, setTotalPrizeerrorMsg] = useState("");
  const [QuantityerrorMsg, setQuantityerrorMsg] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillPurchasedOrderData = useRef();
  useEffect(() => {
    AsyncGet(API.getInventorySpecs)
      .then((res) => {
        console.log(res.data, "getInventorySpecs");

        setInventoryList(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    AsyncGet(API.getBudgetHeads)
      .then((res) => {
        console.log(res.data, "getBudgetHead");
        setBudgetHeadList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const clearState = () => {
    setInventoryId("");
    setPurchasedDate("");
    setDepartmentIdss("");
    setDepartmentIdAided("");
    setHeadOfTheAccountAided("");
    setHeadOfTheAccountss("");
    setBudgetAmtAided("");
    setBudgetAmtss("");
    setId("");    
    setInventoryList([]);    
    //setPurchasedBy("");    
    seterrorMsg("");    
    setvalidate(false);    
    setDiscount("");
     setTotalPrize("");    
    setUnitPrize("");    
    setIndentQuantity("");    
    setPrintedQuantity("");    
    setReceivedQuantity("");    
    setRequestedBy("");    
    setVendarDetails("");    
    setInventoryDescription("");    
    setDepartmentId("");    
    setCollegeType("");    
    setStackList([]);    
    setDeparmentList([]);    
    setAvailableQuantity("");    
    setStore("");    
    setHeadOfTheAccount("");    
    setSpecification("");    
    setOldPrize(0);    
    setHeadList([]);    
    setStackList([]);    
    setUpdateCheck(false);    
    setBudgetAmt(0);    
    setBudgetHeadList([]);    
    UpdatedPrize=0;    
    budgetData=[];    
    stackData=[];    
    UpdatedStock=0;
  };
  useEffect(() => {
    AsyncGet(API.gethoadetails)
      .then((res) => {
        console.log(res.data, "getHOADetails");
        setHeadList(res.data);
        //setHeadListUpdated(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    debugger
    AsyncGet(API.getStockInventorys)
      .then((res) => {
        console.log(res.data, "getStockInventorys");
        setStackList(res.data);        
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const iname = (val) => {      
    setInventoryId(val.target.value);      
  };
  const ispecification = (val) => {
    setSpecification(val.target.value);
    stackData =StackList.filter(x =>x.inventoryId == val.target.value);
    if(stackData.length>0)
    {
      setAvailableQuantity(stackData[0].availableQuantity);
    }
    else{
      setAvailableQuantity(0);
    }
  };
  // const inventoryDescription = (val) => {
  //   setInventoryDescription(val.target.value);
  // };
  const headOfTheaccount = (val) => {
    debugger;
    if(CollegeType=="Aided"){
      setHeadOfTheAccount(val.target.value);
      setHeadOfTheAccountAided(val.target.value);
      setHeadOfTheAccountss("");
      budgetData =BudgetHeadList.filter(x =>x.departmentId==(DepartmentId)  && x.headOfAccount == val.target.value);
    if(budgetData.length>0)
    {
      setBudgetAmt(budgetData[0].headAmt);    
      setBudgetAmtAided(budgetData[0].headAmt); 
      setBudgetAmtss(0);
    }else{setBudgetAmt(0);
      setBudgetAmtAided(0);
      setBudgetAmtss(0);
    }
    }
    else if(CollegeType=="SS")
    {
      setHeadOfTheAccount(val.target.value);
      setHeadOfTheAccountAided("");
      setHeadOfTheAccountss(val.target.value);
      budgetData =BudgetHeadList.filter(x =>x.departmentId==(DepartmentId)  && x.headOfAccount == val.target.value);
    if(budgetData.length>0)
    {
      setBudgetAmt(budgetData[0].headAmt);    
      setBudgetAmtss(budgetData[0].headAmt); 
      setBudgetAmtAided(0);
    }else{setBudgetAmt(0);
      setBudgetAmtss(0);
      setBudgetAmtAided(0);
    }
    }
    
     
    
  };
  const depertmentname = (val) => {  
      debugger;
      if(CollegeType=="Aided"){
      setBudgetAmt(0);
      setHeadOfTheAccount("");
      setBudgetAmtAided("");
      setHeadOfTheAccountAided("");
    setDepartmentId(val.target.value);
  setDepartmentIdAided(val.target.value);
  setDepartmentIdss("");
  }
  else if(CollegeType=="SS"){
     setBudgetAmt(0);
      setHeadOfTheAccount("");
      setHeadOfTheAccountss("");
      setBudgetAmtss("");
    setDepartmentId(val.target.value);
  setDepartmentIdAided("");
  setDepartmentIdss(val.target.value);
  }


    /* budgetData =BudgetHeadList.filter(x =>x.departmentId==(val.target.value) && x.collegeType ==CollegeType);
    if(budgetData.length>0)
    {
      setBudgetAmt(budgetData[0].headAmt);    

    }else{setBudgetAmt(0);}*/
  };
  const idate = (val) => {  
  
  setPurchasedDate(val.target.value);
    };

  /*const purchasedby = (val) => {
    setPurchasedBy(val.target.value);
  }*/
  const qty = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
    setIndentQuantity(val.target.value);
    }
  }
  /*const availableqty = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) == false){
    }     else

    {
    setAvailableQuantity(val.target.value);
    }
  }*/
  const printedqty = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
    setPrintedQuantity(val.target.value);
    }
  }
  const receivedqty = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     
    else{
    setReceivedQuantity(val.target.value);
    setQuantityerrorMsg("");
    setTotalPrizeerrorMsg("");
    //setAvailableQuantity(Number(AvailableQuantity)+Number(val.target.value));
    }
 }
   
    
  
  const requestby = (val) => {
    setRequestedBy(val.target.value);
  }
  const venderdetails = (val) => {
    setVendarDetails(val.target.value);
  }
  const iStore = (val) => {
    setStore(val.target.value);
  }
  const idiscount = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
    setDiscount(val.target.value);
    setTotalPrizeerrorMsg("");
    }
  }
  const unitprize = (val) => {
    if(/^[0-9.]*$/.test(val.target.value) === false){
    }     else

    {
    setUnitPrize(val.target.value);
    setTotalPrizeerrorMsg("");
    }
  }
  const totalprize = (val) => {
    setTotalPrize(val.target.value);
    //setTotalPrizeerrorMsg("");
  }
  
  // if (val==1)
  // {IsActive=true;}
  // if (val==0)
  // setIsActive(false);
  const collegetype = (val) => {
    debugger;
    setCollegeType(val);
  };
 

  
  const savePurchasedOrderDetails = () => {
    debugger
    console.log(((Number(UnitPrize) * Number(ReceivedQuantity))-Number(Discount)));
   if(BudgetAmt>((Number(UnitPrize) * Number(ReceivedQuantity))-Number(Discount)))
    {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    
    
    var purchasedOrderData = {
      Id:0,
      
      InventoryId :Number(Specification),
      PurchasedDate:(PurchasedDate),
      //PurchasedBy:PurchasedBy,
      DepartmentId:Number(DepartmentId),
      CollegeType:CollegeType,
      RequestedBy:RequestedBy,
      Store:Store,
      HeadOfAccount: Number(HeadOfAccount),
      //AvailableQuantity:AvailableQuantity,
      IndentQuantity:IndentQuantity,
      PrintedQuantity:PrintedQuantity,
      ReceivedQuantity:ReceivedQuantity,
      UpdatedStock:(Number(ReceivedQuantity)-Number(UpdatedStock)).toString(),
      TotalPrize:((Number(UnitPrize) * Number(ReceivedQuantity))-Number(Discount)).toString(),
      UpdatedPrize:(Number(OldPrize) -((Number(UnitPrize) * Number(ReceivedQuantity))-Number(Discount))).toString(),
      UnitPrize:UnitPrize,
      Discount:Discount,
      VendarDetails:VendarDetails,      
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addPurchasedOrderDetails(purchasedOrderData));
    closePopup();
    clearState();
   }
   else{
    setTotalPrizeerrorMsg ("Total prize is higher than budget amount")
   }
  };

  const updatePurchasedOrderDetails = () => {
    debugger
    var balanceStock=0;
    if(Number(AvailableQuantity) <Number(UpdatedStock))

    {

      balanceStock=Number(UpdatedStock)-Number(AvailableQuantity);

    }

    if((Number(balanceStock)<=Number(ReceivedQuantity))||(Number(AvailableQuantity)>=Number(UpdatedStock)))

    {

    if(Number(BudgetAmt)>((Number(UnitPrize) * Number(ReceivedQuantity))-Number(Discount)))

    {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
     
      InventoryId :Number(Specification),
      PurchasedDate:(PurchasedDate),
      DepartmentId:Number(DepartmentId),
      HeadOfAccount: Number(HeadOfAccount),
      CollegeType:CollegeType,
      //PurchasedBy:PurchasedBy,
      RequestedBy:RequestedBy,
      Store:Store,
      //AvailableQuantity:AvailableQuantity,
      IndentQuantity:IndentQuantity,
      PrintedQuantity:PrintedQuantity,
      ReceivedQuantity:ReceivedQuantity,
      UpdatedStock:(Number(ReceivedQuantity)-Number(UpdatedStock)).toString(),
      TotalPrize:((Number(UnitPrize) * Number(ReceivedQuantity))-Number(Discount)).toString(),
      UpdatedPrize:OldPrize,
      UnitPrize:UnitPrize,
      Discount:Discount,
      VendarDetails:VendarDetails,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const purchasedOrderData = {
      ...purchasedOrder,
        data: data
    };

    console.log("UpdatePurchasedOrder", purchasedOrderData.data);
    dispatch(UpdatePurchasedOrderDetail(purchasedOrderData.data));
    closePopup();
    clearState();
  }
  else{
   setTotalPrizeerrorMsg ("Total prize is higher than budget amount")
  }}
  else{
    setQuantityerrorMsg("Unable to update received qty");
  }
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !InventoryId ||
      !PurchasedDate ||
      !Store||
      !RequestedBy||
      !ReceivedQuantity ||
      !UnitPrize ||
      !Discount ||
      !DepartmentId ||
      !HeadOfAccount
      
    ) {
      setvalidate(false);
      seterrorMsg("Provide All Required Field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillPurchasedOrderData = (obj) => {
    debugger;
    runFillPurchasedOrderData.current = false;
    if(obj.data.collegeType=="Aided")
    {
      setHeadOfTheAccount(obj.data.headOfAccount);
      setHeadOfTheAccountAided(obj.data.headOfAccount);
      setHeadOfTheAccountss("");
      setDepartmentId(obj.data.departmentId);
      setDepartmentIdAided(obj.data.departmentId);
      setDepartmentIdss("");
      budgetData =obj.budgetHeadList.filter(x =>x.departmentId==(obj.data.departmentId)  && x.headOfAccount == obj.data.headOfAccount);
    if(budgetData.length>0)
    {
      setBudgetAmt(budgetData[0].headAmt);    
      setBudgetAmtAided(budgetData[0].headAmt);
      setBudgetAmtss (0);
    }else{setBudgetAmt(0);
      setBudgetAmtAided(0);
      setBudgetAmtss(0);
    }
    }
    else if(obj.data.collegeType=="SS"){
      setHeadOfTheAccount(obj.data.headOfAccount);
      setHeadOfTheAccountAided("");
      setHeadOfTheAccountss(obj.data.headOfAccount);
      setDepartmentId(obj.data.departmentId);
      setDepartmentIdAided("");
      setDepartmentIdss(obj.data.departmentId);
      budgetData = obj.budgetHeadList.filter(x =>x.departmentId==(obj.data.departmentId)  && x.headOfAccount == obj.data.headOfAccount);
    if(budgetData.length>0)
    {
      setBudgetAmt(budgetData[0].headAmt);    
      setBudgetAmtAided(0);
      setBudgetAmtss (budgetData[0].headAmt);
    }else{setBudgetAmt(0);
      setBudgetAmtss(0);
      setBudgetAmtAided(0);
    }
    }
    setId(obj.data.id);
    
    setInventoryId(obj.data.inventoryName);
    setSpecification(obj.data.inventoryId)
   // setInventoryDescription(obj.data.inventoryId);
    setPurchasedDate(obj.data.purchasedDate);
    setAvailableQuantity(obj.data.availableQuantity);
    setDiscount(obj.data.discount);
    //setPurchasedBy(obj.data.purchasedBy);
    setIndentQuantity(obj.data.indentQuantity);
    setRequestedBy(obj.data.requestedBy);
    setTotalPrize(obj.data.totalPrize);
    setUnitPrize(obj.data.unitPrize);
    setVendarDetails(obj.data.vendarDetails);
    setPrintedQuantity(obj.data.printedQuantity);
    setReceivedQuantity(obj.data.receivedQuantity);
    setInventoryDescription(obj.data.inventoryId);
    setStore(obj.data.store);
    
    setCollegeType(obj.data.collegeType);

    //setBudgetAmt(obj.data.budgetAmt);
    UpdatedStock=(obj.data.receivedQuantity);
    setOldPrize(obj.data.totalPrize);
    /*budgetData = obj.budgetHeadList.filter(x => x.departmentId == (obj.data.departmentId) && x.collegeType == (obj.data.collegeType));
    if (budgetData.length > 0) {
     // setBudgetAmt(Number(budgetData[0].headAmt)+Number(obj.data.totalPrize));
     setBudgetAmt(Number(budgetData[0].headAmt));
    }

    else { setBudgetAmt(0); }*/
    //setUpdateCheck((Number(obj.data.receivedQuantity)>=Number(obj.data.availableQuantity))?true:false);
    runFillPurchasedOrderData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Purchase" : "Add New Purchase"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row">
            <div className="checksection col custom-control custom-radio custom-control-inline">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="students"
                    value="2"
                    checked={CollegeType === "Aided"}
                    onChange={() => {
                      setCollegeType("Aided");
                      setHeadOfTheAccount(HeadOfAccountAided);
                      setDepartmentId(DepartmentIdAided);
                      setBudgetAmt(BudgetAmtAided);
                      /*var budgetData =BudgetHeadList.filter(x =>x.departmentId==DepartmentId && x.collegeType =="Aided" && x.headOfAccount==HeadOfAccount);
                      if(budgetData.length>0)
                      {
                        setBudgetAmt(budgetData[0].headAmt); 
                        //setDepartmentId(budgetData[0].depertmentname);
                      }
                      else{setBudgetAmt(0);
                      
                     }*/
                     /*setDepartmentId("");

                  setHeadOfTheAccount("");
                     
                     setBudgetAmt(0);*/
                    }}
                  />
                  <label
                    htmlFor="students41"
                    className="form-check-label checkposition"
                  >
                    Aided
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn custom-control-input"
                    type="radio"
                    name="radio-btn"
                    id="faculty"
                    value="3"
                    checked={CollegeType === "SS"}
                    onChange={() => {setCollegeType("SS");
                    setHeadOfTheAccount(HeadOfAccountss);
                    setDepartmentId(DepartmentIdss);
                    setBudgetAmt(BudgetAmtss);
                    /*var budgetData =BudgetHeadList.filter(x =>x.departmentId==DepartmentId && x.collegeType =="SS" && x.headOfAccount==HeadOfAccount);
                    if(budgetData.length>0)
                    {
                      setBudgetAmt(budgetData[0].headAmt);    
                      //setDepartmentId(budgetData[0].departmentId);
                    }
                  else{
                    //setDepartmentId(0);
                    setBudgetAmt(0);
                  }*/
                    /*setDepartmentId("");

                      setHeadOfTheAccount("");

                      setBudgetAmt(0);*/
                }
                  }
                  />
                  <label className="form-check-label checkposition">
                  Self Supporting
                  </label>
                </div>
              </div>
              </div>
            <div className="row formadduser">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>
                <span style={{ color: "red" }}>*</span> Store
                </label>
                <select
                  className="form-select form-control"
                  onChange={iStore}
                  aria-label="Default select example"
                  value={Store}
                >
                  <option value="">Select Store</option>
                  <option value="Student Store">Student Store</option>
                  <option value="Office Store">Office Store</option>
                  
                </select>
                
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Name</label>
                
                
                <select
                  className="form-select form-control"
                  onChange={iname}
                  aria-label="Default select example"
                  value={InventoryId}
                >
                  <option value="">--Select Inventry--</option>

                  {InventoryList.filter(x=>x.store==Store)?.map((opt) => (
                    <option key={opt.id} value={opt.inventoryName}>
                      {opt.inventoryName}
                    </option>
                  ))}
                </select>
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Specification</label>
                
                
                <select
                  className="form-select form-control"
                  onChange={ispecification}
                  aria-label="Default select example"
                  value={Specification}
                >
                  <option value="">--Select Specification--</option>

                  {InventoryList.filter(x=>x.inventoryName==InventoryId)?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.specification}
                    </option>
                  ))}
                </select>
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Available Stock</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={AvailableQuantity}
                  disabled
                  //onChange={issuedby}
                  
                  //maxLength={150}
                />
                
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Department Name</label>
                
               
                  <select
                  className="form-select form-control"
                  onChange={depertmentname}
                  aria-label="Default select example"
                  value={DepartmentId}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
                
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
              <label>
                <span style={{ color: "red" }}>*</span>Head of the Account
              </label>
              <select
                className="form-select form-control"
                onChange={headOfTheaccount}
                aria-label="Default select example"
                value={HeadOfAccount}
               // disabled={validation && OfficeValid} 
                >
                <option value="">--Select Head--</option>
                {(HeadList)?.map((opt) => (
                  <option key={opt.siNo} value={opt.siNo}>
                    {opt.headOfAccount}
                  </option>
                ))}
              </select>
              {!HeadOfAccount && validate ? (
                <h6 style={{ color: "red" }}>{"Requird"}</h6>
              ) : null}
            </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Budget Amount</label>              
               
                <input
                className="form-control"
                type="text"
                value= {BudgetAmt}
               // max={Moment().format('YYYY-MM-DD')}
               // onChange={idate}
                disabled
              />

              </div>
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Inventory Description</label>
                
                
                <select
                  className="form-select form-control"
                  onChange={inventoryDescription}
                  aria-label="Default select example"
                  value={InventoryDescription}
                >
                  <option value="">--Select Inventry--</option>
                     
                  {InventoryList.filter(x=>x.name==InventoryId)?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.description}
                    </option>
                  ))}
                </select>
              </div>*/}  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Purchased Date</label>
                
               
                <input
                className="form-control"
                type="date"
                value={dayjs(PurchasedDate).format("YYYY-MM-DD")}
                max={Moment().format('YYYY-MM-DD')}
                onChange={idate}
                //disabled={validation}
              />

              
               {!PurchasedDate && validate ? (
                 <h6 style={{ color: "red" }}>{"Requird"}</h6>
               ) : null}
               
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Request By</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={RequestedBy}
                  onChange={requestby}
                  maxLength={150}
                />
              </div>  
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Purchased By</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={PurchasedBy}
                  onChange={purchasedby}
                  maxLength={500}
                />
              </div>  */}
            
               
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Indent Quantity</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={IndentQuantity}
                  onChange={qty}
                  maxLength={15}
                  
                />
                
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Printed Quantity</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={PrintedQuantity}
                  onChange={printedqty}
                  maxLength={15}
                />
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Received Quantity</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={ReceivedQuantity}
                  onChange={receivedqty}
                  maxLength={15}
                />
                 <h6 style={{ color: "red" }}>{QuantityerrorMsg}</h6>
              </div>  
              {/*<div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Available Quantity</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={AvailableQuantity}
                  onChange={availableqty}
                  maxLength={15}
                  //disabled
                />
              </div>*/}
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Unit Prize</label>
                
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={UnitPrize}
                  onChange={unitprize}
                  maxLength={15}
                />
              </div>  
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Discount</label>
                
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  value={Discount}
                  placeholder="Discount in Rupees"
                  onChange={idiscount}
                  maxLength={10}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Total Prize</label>
                
                <input
                  className="form-control"
                  type="text"
                  //value={TotalPrize}
                  
                  value={((parseFloat(UnitPrize) * parseFloat(ReceivedQuantity))-parseFloat(Discount)).toFixed(2) === 'NaN' ? '' :((parseFloat(UnitPrize) * parseFloat(ReceivedQuantity))-parseFloat(Discount)).toFixed(2)}
                  onChange={totalprize}
                  disabled
                 // maxLength={150}
                />
                <h6 style={{ color: "red" }}>{TotalPrizeerrorMsg}</h6>
              </div>  
              
              <div className="col-xs-12 col-md-12 col-lg-12 col-sm-12  form-group">
                <label>Vendar Details</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={VendarDetails}
                  onChange={venderdetails}
                  maxLength={500}
                />
              </div>      
              </div>
             
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!purchasedOrder ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => savePurchasedOrderDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updatePurchasedOrderDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPurchasedOrderDetails;