import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const BpeSlice = createSlice({
  name: "BpeList",
  initialState: {
    loading: false,
    error: false,
    BpeList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    BpeDetails: [],
    submitBpeSuccess: "",
    saveBpeDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getBpe: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.BpeList = payload;
    },
    saveBpe: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveBpeDataSuccess = payload;
    },
    submitBpe: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getBpe,
  submitBpe,
  setError,
  saveBpe,
} = BpeSlice.actions;
export const BpeSelector = (state) => state.bpes || [];
export default BpeSlice.reducer;

export const fetchbpes = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getBpesForms)
    .then((response) => {
      console.log("response,", response);
      dispatch(getBpe(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addBpeDetails =  async (data, dispatch)=> {
  debugger;
  try {
  //dispatch(setLoading());
  const response = await AsyncPost(API.saveBpesForms, data);
  debugger;
      //dispatch(setLoading());
      dispatch(saveBpe(response.data));
      dispatch(fetchbpes());
      return response.data;
     // dispatch(fetchods());
    }
    catch(error)  {
      console.log("failure of add faculty");
      dispatch(fetchbpes());

      // dispatch(saveOd(""));
      // dispatch(setError(true));
     // dispatch(setLoading());
}  
};
export const UpdateBpeDetails = (data) => (dispatch) =>{ 
  debugger;
//export const UpdateIndentDetails =  async (data, dispatch)=> {
  dispatch(setLoading());
  AsyncPost(API.updateBpesFormData, data)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of update Indent");
      dispatch(fetchbpes());
    })
    .catch((er) => {
      console.log("failure of add faculty");

     // dispatch(saveOd(""));
      //dispatch(setError(true));
      //dispatch(setLoading());
    });
};
export const deleteBpe = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteBpesFormData + "?id=" + data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete indent");
      dispatch(fetchbpes());
    })
    .catch((er) => {
      console.log("failure of add indent");
      dispatch(saveBpe(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
