import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  addInventoryDetails,
  UpdateInventoryDetail
} from "../../../app/redux/slices/inventorySlice";

//import {inventoryList} from "../index";
const AddInventoryDetails = ({ isEdit, item, closePopup, inventory, inventoryList }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory) {
      return;
    }

    runFillInventoryData.current = true;
    if (runFillInventoryData.current) {
      fillInventoryData(inventory);

    }
  }, []);
  console.log("inventoryList", inventoryList);
  const [Id, setId] = useState("");
  const [Name, setName] = useState("");

  const [IsActive, setisActive] = useState(false);
  const [enablename, setenablename] = useState(false);
  const [enableinventory, setenableinventory] = useState(false);
  // const [CreatedBy, setCreatedBy] = useState("");
  // const [CreatedDate, setCreate] = useState("");
  // const [ModifiedBy, setCompanyAddr] = useState("");
  // const [ModifiedDate, setCurrentWorkLocation] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [Store, setStore] = useState("");
  const runFillInventoryData = useRef();

  const clearState = () => {
    setName("");

    setisActive("");
    setStore("");

  };
  const iname = (val) => {
    debugger;

    setName(val.target.value);
    /*setenableinventory(true);
    if(val.target.value=="")
    {
      setenableinventory(false);
    }*/
  };
 
  
  const iInventory = (val) => {
    debugger;

    setName(val.target.value);
    setenablename(true);
    if (val.target.value == "") {
      setenablename(false);
    }

  };
  const iStore = (val) => {
    setStore(val.target.value);
  }


  const isactive = (e) => {
    setisActive(e.target.checked);

    // if (val==1)
    // {IsActive=true;}
    // if (val==0)
    // setIsActive(false);
  }



  const saveInventoryDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var inventoryData = {
      Store: Store,
      Name: Name,

      IsActive: IsActive,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addInventoryDetails(inventoryData));
    closePopup();
    clearState();
  };

  const updateInventoryDetails = () => {
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      Id: Id,
      Store: Store,
      Name: Name,
      IsActive: IsActive,

      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const inventoryData = {
      ...inventory,
      data: data
    };

    console.log("UpdateInventory", inventoryData.data);
    dispatch(UpdateInventoryDetail(inventoryData.data));
    closePopup();
    clearState();

  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !Name || !Store
    ) {
      setvalidate(false);
      seterrorMsg("Provide required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillInventoryData = (obj) => {
    debugger;
    runFillInventoryData.current = false;
    setId(obj.data.id);
    setName(obj.data.name);

    setisActive(obj.data.isActive);
    setenablename(false);
    setenableinventory(true);
    setStore(obj.data.store);
    runFillInventoryData.current = true;
  };

  return (
    <div className="popup">
      <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Inventory" : "Add New Inventory"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">

            </div>

            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Store
                </label>
                <select
                  className="form-select form-control"
                  onChange={iStore}
                  aria-label="Default select example"
                  value={Store}
                >
                  <option value="">Select Store</option>
                  <option value="Student Store">Student Store</option>
                  <option value="Office Store">Office Store</option>

                </select>

              </div>

              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt ">
                <label><span style={{ color: "red" }}>*</span> Inventory Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={Name}
                  onChange={iname}
                  maxLength={150}
                // disabled={enablename}
                />
              </div>             
            </div> 
           
        
    
            <div className="checksection col custom-control custom-radio custom-control-inline">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input radio-btn custom-control-input"
                  type="checkbox"
                  //name="eligle-radio-btn"
                  //value="1"
                  checked={IsActive}
                  //id="isActive"
                  onChange={isactive}
                />

                <label
                  htmlFor="students41"
                  className="form-check-label checkposition"
                >
                  IsActive
                </label>
              </div>


            </div>
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!inventory ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveInventoryDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateInventoryDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>


      </div></div>

  );
};

export default AddInventoryDetails;