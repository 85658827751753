import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";

import AddOdpDetails from "./addodpDetails/index";
import AdvanceTable from "./advanceTable";
import {
  fetchodps,
  odpSelector,
  deleteOdp,
} from "../../app/redux/slices/odpSlice";
import { AsyncGet, AsyncPost, AsyncGetFiles } from "../../app/services/https";
import { API } from "../../app/services/endpoints";
import Popup from "reactjs-popup";
import "../../app/styles/addnew.css";
var department=[];
var faculty=[];
const OdpDetails = () => {
  // set up dispatch
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { loading, error, odpList } = useSelector(odpSelector);
  const [deparmentList, setDeparmentList] = useState([]);
  const [FacultyList, setFacultyList] = useState([]);
  const [showPopup, setshowPopup] = useState(false);
  const [assessment, setassessment] = useState("");
  const [odp, setOdp] = useState();
  const [listOfOdp, setlistOfOdp] = useState(odpList || []);
  const [showAdd, setshowAdd] = useState();
  const [IsEdit, setIsEdit] = useState(false);

  const [file, setFile] = useState();
  const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const [selectedFile, setselectedFile] = useState("");
  const togglePopup = () => {
    debugger;
    onAssessmentSelection("Odp");
    setshowPopup(!showPopup);
    setIsEdit(false);

    if (!showPopup) {
      setOdp();

    }
  };
  
  
  /*const closePopupFun = () => {
    closePopup();
    window.location.reload(false);
  };*/
  const onFileChange = async (e) => {
    let AllFiles = e.target.files;
    setselectedFile(AllFiles);
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const onAssessmentSelection = (assessment) => {
    setassessment(assessment);
  };

  // fetch data from our store

  // console.log(loading);
  console.log("Fetch data from Store", odpList);

  const deleteOdpFun = (item) => {
    debugger;
    dispatch(deleteOdp(item.refNo));
  };

  const editOdp = (item) => {
    debugger;
    setOdp({ ...item, data: item });
   // setrowData({ ...item, productInfo: item });
    setshowPopup(!showPopup);
    setIsEdit(true);

  };

  useEffect(() => {
    getOdpData();
  }, []);

  const getOdpData = () => {
    dispatch(fetchodps());
  };
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
        department = (res.data.map(x=>x.name));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    debugger;
    AsyncGet(API.getFaculties)
      .then((res) => {
        console.log(res.data, "getFacultyByName");
        setFacultyList(res.data);
      console.log(FacultyList,"facultylist");
       faculty = (res.data.map(x=>x.facultyId+"-"+x.facultyName));
       console.log(faculty,"Faculty");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(faculty,"faculty");

  /*var data=["apple","banana","cat","Fish"];
  let strAllUserNames = data.toString().split(",");
  //let strRollNumber = string.Join(",", data); 
  console.log(strAllUserNames,"data");*/

  useEffect(() => {
    if (!odpList) {
      return;
    }
    const sortData=[...odpList].sort(a => {
      return a.currentFileQueueStatus==JSON.parse(localStorage.getItem("user")).indentRoleName ?-1 :1
    });
    if((JSON.parse(localStorage.getItem("user")).fdpRoleName =="Maker")||(JSON.parse(localStorage.getItem("user")).fdpRoleName =="HOD"))
    {
      setlistOfOdp(sortData.filter(x =>x.programmeName ==JSON.parse(localStorage.getItem("user")).departmentId ));
    }
    else if((JSON.parse(localStorage.getItem("user")).fdpRoleName ==null))
    {
      setlistOfOdp([]);
    }
    else{
      setlistOfOdp(sortData);
    }
   // setlistOfOdp(odpList);
    setshowAdd(JSON.parse(localStorage.getItem("user")).fdpRoleName);
  }, [odpList]);

  console.log("Before Render odpList", odpList);
  console.log("Before Render listOfOdp", listOfOdp);

  // render the items
  const renderItems = () => {
    //loading state
    if (loading) return <strong>Loading please wait...</strong>;

    // error state
    if (error) return <strong>Items not available at this time</strong>;

    // regular data workflow
    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="formsec">
          <div className="row mb-5">
            <h2 className="heading col pt-2">ODP Form</h2>
            
            {/* (JSON.parse(localStorage.getItem("user")).fdpRoleName) === "Maker" ||
            (JSON.parse(localStorage.getItem("user")).fdpRoleName) === "HOD" */}
            {(JSON.parse(localStorage.getItem("user")).fdpRoleName) === "Office Establishment"
             ? (
            <div className="but col text-end">
              <button
                onClick={() => togglePopup()}
                className="btn btn-lg btn-success btn-block form-roundedbtn3"
                type="submit"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                <span className="glyphicon glyphicon-plus"></span> ADD NEW
              </button>            

            </div>
  ) : null}
          </div>
          {/*<h4 class="w-100  fw-bold mb-0 blue-heading">Bulk Upload </h4>

          <div className="col-xs-3 col-md-3 col-lg-3 col-sm-12  form-group">
            <input
              className="form-control"
              onChange={onFileChange}
              type="file"
              accept=".xls,.xlsx"
              id="formFileMultiple"
            />
          </div>
          <button
            className="btn btn-lg btn-secondary btn-block form-roundedbtn2"
            type="submit"
            onClick={onFileUpload}
          >
            <span className="glyphicon glyphicon-remove"></span> Upload
          </button>*/}

          <div className="bootstrap-table">
            <div className="fixed-table-toolbar"></div>
            <div className="fixed-table-container">
              <div
                className="fixed-table-body"
                style={{
                  height: "65vh",
                  overflow: "auto",
                  marginTop: 20,
                }}
              >
                <AdvanceTable
                  deleteOdpProp={(item) => deleteOdpFun(item)}
                  editOdpProp={(item) => editOdp(item)}
                  data={listOfOdp}
                />
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
            {showAdd === "Office Establishment" && (

              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    ADD NEW
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Title:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Event:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="message-text" className="col-form-label">
                        Event Date:
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="recipient-name"
                      />
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    ADD
                  </button>
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
        {showPopup ? (
          <AddOdpDetails
          isEdit ={IsEdit}

            item={assessment}
            closePopup={togglePopup}
            odp={odp}
            Department={department}
            Faculty={faculty}
        />
        ) : null}
      </div>
    );
  };

  // template
  return (
    // onWheel={()=> {dispatch(fetchProducts("Sarees"));}}
    <div>{renderItems()}</div>
  );
};
export default OdpDetails;
