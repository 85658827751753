import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
//import { saveAs } from "file-saver";
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import validator from "validator";  
import {
  addActivity,
  fetchActivities,
  UpdateActivity,
} from "../../../../app/redux/slices/activitySlice";
import "./styles.css";
import "../../../../app/styles/addnew.css";
import {
  AsyncGet,
  AsyncGetFiles,
  AsyncPost,
} from "../../../../app/services/https";

import { API } from "../../../../app/services/endpoints";
import SearchBox from "./searchBox";
import axios from "axios";
//import { API } from "../../../app/services/endpoints";
//import { AsyncGet } from "../../../app/services/https";


const NewItem = ({isEdit, item, closePopup, activity }) => {
  // 	if (!item) {
  // 		return <div>loading</div>
  // 	}
  //  // set up dispatch
  const dispatch = useDispatch();
   const [id, setId] = useState("");
  // const navigate = useNavigate();
  //state
  const [FromDate, setFromDate] = useState("");  
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState(""); 
  const [Department, setDepartment] = useState(""); 
  const [DeparmentList, setDeparmentList] = useState([]);
  const [nStudentAttended, setnStudentAttended] = useState("");
  const [nStudentAttendedBoys, setnStudentAttendedBoys] = useState("");
  const [nStudentAttendedGirls, setnStudentAttendedGirls] = useState("");
  const [nStudentPlaced, setnStudentPlaced] = useState("");
  const [nStudentPlacedBoys, setnStudentPlacedBoys] = useState("");
  const [nStudentPlacedGirls, setnStudentPlacedGirls] = useState("");
  const [CompanyType, setCompanyType] = useState("");  
  const [SalaryPerAnnum, setSalaryPerAnnum] = useState("");   
  const [OffCampusOrOnCampus, setOffCampusOrOnCampus] = useState("OffCampus");
  const [selectedFile, setselectedFile] = useState("");
  const [createdBy, setCreatedBy] = useState("");
  const [studentList, setstudentList] = useState([]);
  const [facultyList, setfacultyList] = useState([]);
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  const [file, setFile] = useState();
 const [filesList, setfilesList] = useState([]);
  const [fileName, setFileName] = useState();
  const[datevalidation, setdatevalidation] = useState(true);
 const[enableBoysplaced, setenableBoysplaced] = useState(true);
 const[enableBoysattended, setenableBoysattended] = useState(false);
 const[enableGirlsattended, setenableGirlsattended] = useState(false);
  const[enableGirlsplaced, setenableGirlsplaced] = useState(true);
  const runFillActivityData = useRef();
  const [Fromdatevalidation1, setFromdatevalidation] = useState(false);
  const clearState = () => {
    setFromDate("");
    setCompanyName("");
    setCompanyAddress("");
    setCompanyType("");

    setDepartment("");
    setSalaryPerAnnum("");
    setnStudentAttended("");
    setnStudentAttendedBoys("");
    setnStudentAttendedGirls("");
    setnStudentPlaced("");
    setnStudentPlacedBoys("");
    setnStudentPlacedGirls("");
    setOffCampusOrOnCampus("OffCampus");
    //setOffLineOrOnLine("Offline");
    setselectedFile("");
    setCreatedBy("");

    seterrorMsg("");
    setvalidate("");
    setFile("");
    setfilesList([]);
    setFileName("");
    setdatevalidation(true);
    setFromdatevalidation(false);
    console.log("reseted");
  };
  const setOnOFFvalue = (val) => {
    setOffCampusOrOnCampus(val);
  };
  const fromDatechange = (val) => {
    setdatevalidation(false);
    setFromDate(`${new Date(val.target.value).getMonth()+1}/${new Date(val.target.value).getDate()}/${new Date(val.target.value).getFullYear()}`);
  };
  const onDepartmentSelect = (e) => {
    setDepartment(e.target.value);
  };
  const onCompanyTypeSelect = (e) => {
    setCompanyType(e.target.value);
  };
  const onCreatedBySelect = (e) => {
    setCreatedBy(e.target.value);
  };

 
  const onFileUpload = async (res) => {
    if (selectedFile?.length < 1) {
      dispatch(fetchActivities(29,null));
      closePopup();
      clearState();
      return;
    }
    const activityData = res ? res[0] : activity;    
    const formData = new FormData();
    for (let index = 0; index < selectedFile.length; index++) {
      const fileUploaded = selectedFile[index];
      formData.append("FormFiles", fileUploaded);
    }

    formData.append("fileName", fileName);
    formData.append("id", Number(activityData?.id || 0));
    formData.append(
      "activityName",
      activityData?.activityName || "Placement"
    );

    try {
      const res = await AsyncPost(API.uploadFiles, formData);
      dispatch(fetchActivities(29,null));
      closePopup();
      clearState();
    } catch (ex) {
      console.log(ex);
    }
  };
const downloadFileFun = async () => {
    AsyncGetFiles(API.downloadFiles + "?id=" + activity.id)
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initiateActivity = async() => {
    console.log("Manoj you are here");
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    var data = {
      activityID: 29,
      
      FromDate:FromDate,// new Date(FromDate).toLocaleDateString(),     
      CompanyName: CompanyName,
      CompanyAddress:CompanyAddress,
      CompanyType:CompanyType,
      Department:Department,
      nStudentAttended:Number(nStudentAttendedBoys)+  Number(nStudentAttendedGirls),
      nStudentAttendedBoys:nStudentAttendedBoys,
      nStudentAttendedGirls:nStudentAttendedGirls,
      nStudentPlaced:Number(nStudentPlacedBoys)+  Number(nStudentPlacedGirls),
      nStudentPlacedBoys:nStudentPlacedBoys,
      nStudentPlacedGirls:nStudentPlacedGirls,      
      OffCampusOrOnCampus: OffCampusOrOnCampus,  
      SalaryPerAnnum:SalaryPerAnnum,
      Attachment: "",
    };
    var activityData = {
      id: 0,
      activityID: 29,
      activityName: "Placement",
      data: JSON.stringify(data),
      filePath: "",
      departmentID: JSON.parse(localStorage.getItem("user")).departmentId,
      createdBy: JSON.parse(localStorage.getItem("user")).facultyName,
      createdDate: new Date(),
      modifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      modifiedDate:  new Date(),
    };
    try {
      const resp = await addActivity(activityData, dispatch);
      await onFileUpload(resp);
    } catch (error) {
      console.log(error);    
      closePopup();
      clearState();
}
  };

  const updateActivity = async() => {
    seterrorMsg("");
    const error = validationFun();
    if (error) {
      return;
    }
    const data = {
      activityID: 29,
      
      FromDate:FromDate,// new Date(FromDate).toLocaleDateString(), 
      CompanyName: CompanyName,
      CompanyAddress:CompanyAddress,
      CompanyType:CompanyType,
      Department:Department,
      nStudentAttended:Number(nStudentAttendedBoys)+  Number(nStudentAttendedGirls),
      nStudentAttendedBoys:nStudentAttendedBoys,
      nStudentAttendedGirls:nStudentAttendedGirls,
      nStudentPlaced: Number(nStudentPlacedBoys)+  Number(nStudentPlacedGirls),
      nStudentPlacedBoys:nStudentPlacedBoys,
      nStudentPlacedGirls:nStudentPlacedGirls,      
      OffCampusOrOnCampus: OffCampusOrOnCampus,
      SalaryPerAnnum:SalaryPerAnnum,     
      Attachment: "",
    };
    const activityData = {
      ...activity,
      activityID:29,
      activityName: "Placement",
      data: JSON.stringify(data),
     //filePath: selectedFile,
    };
    try {
    dispatch(UpdateActivity(activityData));
      await onFileUpload();
      //closePopup();
    } catch (error) {
      console.log(error);
      closePopup();
      clearState();
    }
  };
  const validationFun = () => {
    setvalidate(true);
    if (
      CompanyName &&
      FromDate &&
      CompanyType &&
      Department &&      
      nStudentAttendedBoys &&
      nStudentAttendedGirls &&
      //nStudentPlaced &&
      nStudentPlacedBoys &&
      nStudentPlacedGirls 
    ) {
      setvalidate(false);
      return false;
    }
    seterrorMsg("Provide all required fields");
    return true;
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const onTextValueChange = (e) => {
    switch (e.target.id) {
      case "companyname":
        setCompanyName(e.target.value);
        break;
      case "companyaddress":
        setCompanyAddress(e.target.value);
        break;
      
     /* case "department":
        setDepartment(e.target.value);
        break;*/
      
      case "companytype":
        setCompanyType(e.target.value);
        break;
      case "nstudentattended":
        var nparticipants = e.target.value.replace(/\D/g,"");
        setnStudentAttended(nparticipants);
        
        break;
        case "nstudentattendedboys":
          var nstudentattendedboys = e.target.value.replace(/\D/g,"");
             setnStudentAttendedBoys(nstudentattendedboys); 
              setnStudentPlacedBoys("");   
        break;
        case "nstudentattendedgirls":
          var nstudentattendedgirls = e.target.value.replace(/\D/g,"");
            setnStudentPlacedGirls("");
           setnStudentAttendedGirls(nstudentattendedgirls);
         
        break;
        case "nstudentplaced":
          var nstudentplaced = e.target.value.replace(/\D/g,"");
        setnStudentPlaced(nstudentplaced);
          
        break;
        case "nstudentplacedboys":
          var nstudentplacedboys = e.target.value.replace(/\D/g,"");
            if (Number(nstudentplacedboys) <= Number(nStudentAttendedBoys))
            {
            if (nstudentplacedboys=="")
            {
              setenableBoysattended(false)
            }
           
            else
            {
              setenableBoysattended(true)
              setnStudentPlacedBoys(nstudentplacedboys);
            }                  
        }
 break;
        case "nstudentplacedgirls":
          var nstudentplacedgirls = e.target.value.replace(/\D/g,"");
 if (Number(nstudentplacedgirls) <= Number(nStudentAttendedGirls))
 {
  if (nstudentplacedgirls=='')
  {
    setenableGirlsattended(false)
  }
  
  else
  {

 
  setenableGirlsattended(true)
        setnStudentPlacedGirls(nstudentplacedgirls);          
       
      }
     
 }
 break;
      case "salaryperannum":
        if(/^[0-9.]*$/.test(e.target.value) == false){
        }     else

        {
        setSalaryPerAnnum(e.target.value);
        }
      
    }
  };
  
 const onFileChange = async (e) => {
  let AllFiles = e.target.files;
  setselectedFile(AllFiles);
  setFile(e.target.files[0]);
  setFileName(e.target.files[0].name);
};

  const fillActivityData = (data) => {
    runFillActivityData.current = false;
 const obj = data;
    const objData = obj.data;
    // console.log(obj);
    setFromDate(obj.data.FromDate);
    setCompanyName(obj.data.CompanyName);
    setCompanyAddress(obj.data.CompanyAddress);
    setCompanyType(obj.data.CompanyType);
    setDepartment(obj.data.Department)
    setSalaryPerAnnum(obj.data.SalaryPerAnnum);
    setnStudentAttended(obj.data.nStudentAttended);
    setnStudentAttendedBoys(obj.data.nStudentAttendedBoys);
    setnStudentAttendedGirls(obj.data.nStudentAttendedGirls);
    setnStudentPlaced(obj.data.nStudentPlaced);
    setnStudentPlacedBoys(obj.data.nStudentPlacedBoys);
    setnStudentPlacedGirls(obj.data.nStudentPlacedGirls);   
    setOffCampusOrOnCampus(obj.data.OffCampusOrOnCampus||"OffCampus");
    setselectedFile(obj.filePath);
    setCreatedBy(obj.createdDate);
  setfilesList(obj.files);
    runFillActivityData.current = true;
  };

  useEffect(() => {
    if (!activity) {
      return;
    }
    runFillActivityData.current = true;
    if (runFillActivityData.current) {
      fillActivityData(activity);
    }
  }, []);
  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="popup">
    <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
            <h2 className="heading">{isEdit ? "Edit" : "Add New"}</h2>
            <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="row">
            <div className="checksection col">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio5"
                    value="option3"
                    checked={OffCampusOrOnCampus === "OffCampus"}
                    onChange={() => setOnOFFvalue("OffCampus")}
                  />
                  <label className="form-check-label checkposition">
                  OffCampus
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input radio-btn"
                    type="radio"
                    name="radio-btn2"
                    id="inlineRadio6"
                    value="option3"
                    checked={OffCampusOrOnCampus === "OnCampus"}
                    onChange={() => setOnOFFvalue("OnCampus")}
                  />
                  <label className="form-check-label checkposition">
                  OnCampus
                  </label>
                </div>
              </div>
            </div>
            <div className="row formadduser">
            <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Date</label>
                <input
                  className="form-control"
                  type="date"
                  value={dayjs(FromDate).format("YYYY-MM-DD")}
                  max={Moment().format('YYYY-MM-DD')}
                  onChange={fromDatechange}
                /> {!FromDate && validate ? (
                  <h6 style={{ color: "red" }}>{"Select From Date"}</h6>
                ) : null}
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>Department</label>
                <select
                  className="form-select form-control"
                  onChange={onDepartmentSelect}
                  aria-label="Default select example"
                  value={Department}
                >
                 <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.name}>
                      {opt.name}
                    </option>
                  ))}
                </select>
             
                {!Department && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div
                id="students4"
                className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group tt"
              >
                <label><span style={{ color: "red" }}>*</span>CompanyName</label>
                <input
                  className="form-control"
                  type="text"
                  id="companyname"
                  value={CompanyName}
                  onChange={onTextValueChange}
                  maxLength={200}
                />{!CompanyName && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>CompanyAddress</label>
                <input
                  className="form-control"
                  type="text"
                  id="companyaddress"
                  value={CompanyAddress}
                  onChange={onTextValueChange}
                  maxLength={500}
                />
                </div>
                <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>CompanyType</label>
                <select
                  className="form-select form-control"
                  onChange={onCompanyTypeSelect}
                  aria-label="Default select example"
                  value={CompanyType}
                >
                  <option value ="">Select CompanyType</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Design">Design</option>
                  <option value="Development">Development</option>
                  <option value="Services">Services</option>                  
                  <option value="Indian">Indian</option>
                  <option value="MNC">MNC</option>
                  <option value="Others">Others</option>
                </select>
                {!CompanyType && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              
              {/* <div
                id="students5"
                className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group tt"
              >
                <label>Department, Designation</label>
                <input
                  className="form-control"
                  type="text"
                  id="departmentdesigantion"
                  onChange={onTextValueChange}
                />
              </div> */}
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Boys Attended</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentattendedboys"
                  value={nStudentAttendedBoys}
                  onChange={onTextValueChange}
                  maxLength={4}
                  disabled={enableBoysattended}
                />{!nStudentAttendedBoys && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Girls Attended</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentattendedgirls"
                  value={nStudentAttendedGirls}
                  onChange={onTextValueChange}
                  disabled={enableGirlsattended}
                  maxLength={4}
                />{!nStudentAttendedGirls && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>No of Student Attended</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentattended"
                  //value={nStudentAttended}
                  value={ Number(nStudentAttendedBoys)+  Number(nStudentAttendedGirls)}
                  onChange={onTextValueChange}
                  maxLength={4}
                />
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Boys Placed</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentplacedboys"
                  value={nStudentPlacedBoys}
                  maxLength={4}
                 //disabled={enableBoysplaced}
                  onChange={onTextValueChange}
                />{!nStudentPlacedBoys && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label><span style={{ color: "red" }}>*</span>No of Girls Placed</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentplacedgirls"
                  value={nStudentPlacedGirls}
                 //disabled={enableGirlsplaced}
                  onChange={onTextValueChange}
                  maxLength={4}
                />{!nStudentPlacedGirls && validate ? (
                  <h6 style={{ color: "red" }}>{"Required"}</h6>
                ) : null}
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12 form-group">
                <label>No of Student Placed</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="nstudentplaced"
                  //value={nStudentPlaced}
                  value={ Number(nStudentPlacedBoys)+  Number(nStudentPlacedGirls)}
                  onChange={onTextValueChange}
                  maxLength={4}
                />
              </div>
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>SalaryPerAnnum</label>
                <input
                  className="form-control"
                  type="text"
                  pattern="[0-9]*"
                  id="salaryperannum"
                  value={SalaryPerAnnum}
                  onChange={onTextValueChange}
                  maxlength={11}
                />
              </div>
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label>Attachment</label>
                <input
                  className="form-control"
                  onChange={onFileChange}
                  type="file"
                  id="formFileMultiple"
                  multiple
                />
            {filesList?.length > 0 && (
                  <div className="d-flex align-items-center mt-2  rounded">
                    <div className="border rounded download-list-block">
                      {filesList?.map((item, index) => (
                        <h6 className="border-bottom ms-1" key={index}>
                          {item || "No File Name"}
                        </h6>
                      ))}
                    </div>
                    <button
                      className="btn btn-sm btn-primary btn-block "
                      type="button"
                      onClick={() => downloadFileFun()}
                    >
                      Download
                    </button>
                  </div>
                )}
              </div>
            </div>
          
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>

            
            <p>
              {!activity ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => initiateActivity()}
                >
                  Initiate
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateActivity()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewItem;
