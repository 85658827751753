import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const facultySubjectMappingSlice = createSlice({
  name: "facultySubjectMappingList",
  initialState: {
    loading: false,
    error: false,
    facultySubjectMappingList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    facultySubjectMappingDetails: [],
    submitFacultySubjectMappingSuccess: "",
    saveFacultySubjectMappingDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getFacultySubjectMapping: (state, { payload }) => {

      console.log(payload);
      state.loading = false;
      state.error = false;
      state.facultySubjectMappingList = payload;
    },
    saveFacultySubjectMapping: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveDepartmentDataSuccess = payload;
    },
    submitFacultySubjectMapping: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getFacultySubjectMapping,
  submitFacultySubjectMapping,
  setError,
  saveFacultySubjectMapping,
} = facultySubjectMappingSlice.actions;
export const facultySubjectMappingSelector = (state) => state.facultySubjectmappings || [];
export default facultySubjectMappingSlice.reducer;

export const fetchFacultySubjectMappings = () => (dispatch) => {
  debugger;
  dispatch(setLoading());
  AsyncGet(API.getFacultySubjectMappings)
    .then((response) => {
      console.log("response,", response);
      dispatch(getFacultySubjectMapping(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addFacultySubjectMappingDetails = (data) => (dispatch) => {
debugger;
  dispatch(setLoading());
  AsyncPost(API.saveFacultySubjectMappings, data)
    .then((response) => {
      dispatch(setLoading());
     
      dispatch(saveFacultySubjectMapping(response));
      if(response.data=="2627"){
        alert("Unable to insert! This mapping is already present.");
      }
      dispatch(fetchFacultySubjectMappings());
    })
    .catch((er) => {
      console.log("failure of add department");
      dispatch(saveFacultySubjectMapping(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateFacultySubjectMappingDetails = (data) => (dispatch) => {
  dispatch(setLoading());

  AsyncPost(API.updateFacultySubjectMappings, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update department");
      if(response.data=="2627"){
        alert("Unable to update! This mapping is already present.");
      }
      dispatch(fetchFacultySubjectMappings());
    })
    .catch((er) => {
      console.log("failure of add department");
      dispatch(saveFacultySubjectMapping(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteFacultySubjectMapping = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.deleteFacultySubjectMappings+ "?id=" + data, "")
    .then((response) => {
      debugger;
      
       dispatch(setLoading());
      console.log(response, "success of delete department");
      dispatch(fetchFacultySubjectMappings());
    })
    .catch((er) => {
      console.log("failure of delete department");
      //alert('Cannot delete Department. Department is mapped with Activities!')
      dispatch(saveFacultySubjectMapping(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
