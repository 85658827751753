
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import dayjs, { Dayjs } from "dayjs";
import Moment from "moment";
import {
  addLabDetails,
  UpdateLabDetail
} from "../../../app/redux/slices/labDetailsSlice";
import { API } from "../../../app/services/endpoints";
import {
  AsyncGet,
  AsyncPost,
  AsyncGetFiles,
} from "../../../app/services/https";
const AddLabDetails = ({ isEdit, item, closePopup, labDetails }) => {

  const dispatch = useDispatch();

  useEffect(() => {
    if (!labDetails) {
      return;
    }
    runFillLabDetailsData.current = true;
    if (runFillLabDetailsData.current) {
      fillLabDetailsData(labDetails);
    }
  }, []);
  const [SINo,setSINo]=useState("");
  const[CollegeType, setCollegeType]=useState("");
  const[DepartmentId,setDepartmentId]=useState("");
  const[LabName,setLabName]=useState("");
  const [DeparmentList, setDeparmentList] = useState([]);
  
  const [errorMsg, seterrorMsg] = useState("");
  const [validate, setvalidate] = useState(false);
  //const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const runFillLabDetailsData = useRef();
 
  const clearState = () => {  
    setSINo("");
    setCollegeType("");
    setDepartmentId("");
    setLabName("");
  };
  const collegetype = (val) => {
  
      
    setCollegeType(val.target.value);
      
  };
  const depertmentname = (val) => {
  
      
    setDepartmentId(val.target.value);
      
  };
  const labname = (val) => {  
  
    setLabName(val.target.value);
    };

  
  // if (val==1)
  // {IsActive=true;}
  // if (val==0)
  // setIsActive(false);

  useEffect(() => {
    AsyncGet(API.getDepartments)
      .then((res) => {
        console.log(res.data, "getDepartments");
        setDeparmentList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  const saveLabDetails = () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var labDetailsData = {
      SINo:0,
      CollegeType:CollegeType,
      DepartmentId:Number(DepartmentId),
      LabName:LabName,       
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: "",
      ModifiedDate: new Date()
    };

    // console.log("activitydata", activityData);
    dispatch(addLabDetails(labDetailsData));
    closePopup();
    clearState();
  };

  const updateLabDetails = () => {
    debugger
    seterrorMsg("");
    const error = validationFun();
    if (!error) {
      return;
    }
    var data = {
      SINo: SINo,
      CollegeType:CollegeType,
      DepartmentId:Number(DepartmentId),
      LabName:LabName,
      CreatedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      CreatedDate: new Date(),
      ModifiedBy: JSON.parse(localStorage.getItem("user")).facultyName,
      ModifiedDate: new Date()
    };

    const labDetailsData = {
      ...labDetails,
        data: data
    };

    console.log("UpdateLabDetails", labDetailsData.data);
    dispatch(UpdateLabDetail(labDetailsData.data));
    closePopup();
    clearState();
  };

  const validationFun = () => {
    setvalidate(true);
    if (
      !CollegeType ||
      !DepartmentId ||
      !LabName
    ) {
      setvalidate(false);
      seterrorMsg("Provide required field");
      return false;
    }
    else {
      return true;
    }
  };

  const closePopupFun = () => {
    clearState();
    closePopup();
    //window.location.reload(false);
  };

  const fillLabDetailsData = (obj) => {
    debugger;
    runFillLabDetailsData.current = false;
    setSINo(obj.data.siNo);
    setCollegeType(obj.data.collegeType);
    setDepartmentId(obj.data.departmentId);
    setLabName(obj.data.labName)
   // setInventoryDescription(obj.data.inventoryId);
    
    runFillLabDetailsData.current = true;
  };

  return (
    <div className="popup">
  <div className="popup-inner modal-content">
        <div className="col">
          <div className="formdata">
          <h2 className="heading">{isEdit ? "Edit Laboratory" : "Add New Laboratory"}</h2>
             <button
              className="btn btn-lg btnclose"
              onClick={() => closePopupFun()}
              type="button"
            >
              X
            </button>
            <div className="d-flex justify-content-center align-items-enter">
              
            </div>
            <div className="row formadduser">
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>College Type</label>
                
                <select
                 className="form-select form-control"
                 onChange={collegetype}
                 aria-label="Default select example"
                 value={CollegeType}
               >
                 <option value="">--Select CollegeType--</option>
                 <option value="Aided">Aided</option>
                 <option value="SS">Self Supporting</option>
                 
               </select>
                {/*<input
                  className="form-control"
                  type="text"
                  value={CollegeType}
                  onChange={collegetype}
                  maxLength={50}
                />*/}
              </div> 
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Department Name</label>
                
                {/*<input
                  className="form-control"
                  type="text"
                  value={DepartmentName}
                  onChange={depertmentname}
                  maxLength={150}
              />*/}
                  <select
                  className="form-select form-control"
                  onChange={depertmentname}
                  aria-label="Default select example"
                  value={DepartmentId}
                >
                  <option value="">--Select Department--</option>
                  {DeparmentList?.map((opt) => (
                    <option key={opt.id} value={opt.id}>
                      {opt.name}
                    </option>
                  ))}
                </select>
                
              </div>  
              
              <div className="col-xs-6 col-md-6 col-lg-6 col-sm-12  form-group">
                <label><span style={{ color: "red" }}>*</span>Lab Name</label>
                
                <input
                  className="form-control"
                  type="text"
                  value={LabName}
                  onChange={labname}
                  maxLength={150}
                />
              </div>  
               
              </div>
             
            &nbsp;
            <div className="d-flex justify-content-center align-items-enter">
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <p>
              {!labDetails ? (
                <button
                  className="btn btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => saveLabDetails()}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn btn-lg btn-primary btn-block form-roundedbtn"
                  type="button"
                  onClick={() => updateLabDetails()}
                >
                  Update
                </button>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLabDetails;