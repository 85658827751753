import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const odpSlice = createSlice({
  name: "odpList",
  initialState: {
    loading: false,
    error: false,
    odpList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    odpDetails: [],
    submitOdpSuccess: "",
    saveOdpDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getOdp: (state, { payload }) => {
      console.log(payload);
      state.loading = false;
      state.error = false;
      state.odpList = payload;
    },
    saveOdp: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveOdpDataSuccess = payload;
    },
    submitOdp: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getOdp,
  submitOdp,
  setError,
  saveOdp,
} = odpSlice.actions;
export const odpSelector = (state) => state.odps || [];
export default odpSlice.reducer;

export const fetchodps = () => (dispatch) => {
  dispatch(setLoading());
  AsyncGet(API.getOdpForms)
    .then((response) => {
      console.log("response,", response);
      dispatch(getOdp(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addOdpDetails =  async (data, dispatch)=> {
  debugger;
  try {
  //dispatch(setLoading());
  const response = await AsyncPost(API.saveOdpForms, data);
      //dispatch(setLoading());
      dispatch(saveOdp(response.data));
      dispatch(fetchodps());
      return response.data;
     // dispatch(fetchods());
    }
    catch(error)  {
      console.log("failure of add faculty");
      dispatch(fetchodps());

      // dispatch(saveOd(""));
      // dispatch(setError(true));
     // dispatch(setLoading());
}  
};
export const UpdateOdpDetails = (data) => (dispatch) =>{ 
  debugger;
//export const UpdateIndentDetails =  async (data, dispatch)=> {
  dispatch(setLoading());
  AsyncPost(API.updateOdpFormData, data)
    .then((response) => {
      dispatch(setLoading());
      console.log(response, "success of update Indent");
      dispatch(fetchodps());
    })
    .catch((er) => {
      console.log("failure of add faculty");

     // dispatch(saveOd(""));
      //dispatch(setError(true));
      //dispatch(setLoading());
    });
};
export const deleteOdp = (data) => (dispatch) => {
  // dispatch(setLoading());
  AsyncPost(API.deleteOdpFormData + "?id=" + data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of delete indent");
      dispatch(fetchodps());
    })
    .catch((er) => {
      console.log("failure of add indent");
      dispatch(saveOdp(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
