import { createSlice } from "@reduxjs/toolkit";
import { AsyncGet, AsyncPost } from "../../services/https";

import { API } from "../../services/endpoints";

export const departmentSlice = createSlice({
  name: "departmentList",
  initialState: {
    loading: false,
    error: false,
    departmentList: [],
    value: 0,
    curIndex: 0,
    Orders: [],
    departmentDetails: [],
    submitDepartmentSuccess: "",
    saveDepartmentDataSuccess: "",
  },
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    getDepartment: (state, { payload }) => {

      console.log(payload);
      state.loading = false;
      state.error = false;
      state.departmentList = payload;
    },
    saveDepartment: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.saveDepartmentDataSuccess = payload;
    },
    submitDepartment: (state, action) => {
      state.value += action.payload;
    },
    setError: (state) => {
      state.error = true;
    },
  },
});

export const {
  setLoading,
  getDepartment,
  submitDepartment,
  setError,
  saveDepartment,
} = departmentSlice.actions;
export const departmentSelector = (state) => state.departments || [];
export default departmentSlice.reducer;

export const fetchDepartments = () => (dispatch) => {
  debugger;
  dispatch(setLoading());
  AsyncGet(API.getDepartments)
    .then((response) => {
      console.log("response,", response);
      dispatch(getDepartment(response.data));
    })
    .catch((er) => {
      console.log("am in error,", er);
      dispatch(setError());
      dispatch(setLoading());
    });
};

export const addDepartmentDetails = (data) => (dispatch) => {

  dispatch(setLoading());
  AsyncPost(API.saveDepartments, data)
    .then((response) => {
      dispatch(setLoading());
      dispatch(saveDepartment(response));
      dispatch(fetchDepartments());
    })
    .catch((er) => {
      console.log("failure of add department");
      dispatch(saveDepartment(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};

export const UpdateDepartmentDetails = (data) => (dispatch) => {
  dispatch(setLoading());

  AsyncPost(API.updateDepartmentData, data)
    .then((response) => {
      // dispatch(setLoading());
      console.log(response, "success of update department");
      dispatch(fetchDepartments());
    })
    .catch((er) => {
      console.log("failure of add department");
      dispatch(saveDepartment(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
export const deleteDepartment = (data) => (dispatch) => {
  dispatch(setLoading());
  AsyncPost(API.deleteDepartmentData + "?id=" + data, "")
    .then((response) => {
      debugger;
      if (response.data != 'Success') {
        alert('Sorry! Unable to delete this department. Department is mapped with Activities');
      }
      // dispatch(setLoading());
      console.log(response, "success of delete department");
      dispatch(fetchDepartments());
    })
    .catch((er) => {
      console.log("failure of delete department");
      //alert('Cannot delete Department. Department is mapped with Activities!')
      dispatch(saveDepartment(""));
      dispatch(setError(true));
      dispatch(setLoading());
    });
};
